import React from 'react'
import { Link, useHistory } from 'react-router-dom'

import { BasePageHeader, CaretLeftIcon, LinkContainer, LinkContainerInner, Title } from './style'

export interface PageHeaderProps {
  pageTitle: string
  backLink: boolean
  linkLabel?: string
  backLinkURL?: string
}

export const PageHeader: React.FC<PageHeaderProps> = ({
  pageTitle,
  backLink,
  linkLabel = undefined,
  backLinkURL = undefined,
}) => {
  const history = useHistory()
  const url = backLinkURL ? backLinkURL : '#'

  return (
    <BasePageHeader>
      {(() => {
        if (backLink === false) {
          return
        }

        if (url !== undefined && linkLabel !== undefined) {
          return (
            <LinkContainer>
              <Link to={url}>
                <LinkContainerInner>
                  <CaretLeftIcon />
                  {linkLabel}
                </LinkContainerInner>
              </Link>
            </LinkContainer>
          )
        } else {
          return (
            <LinkContainer>
              <Link to={url} onClick={() => history.goBack()}>
                <LinkContainerInner>
                  <CaretLeftIcon />
                  {linkLabel ? linkLabel : '戻る'}
                </LinkContainerInner>
              </Link>
            </LinkContainer>
          )
        }
      })()}

      <Title>{pageTitle}</Title>
    </BasePageHeader>
  )
}
