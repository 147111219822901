/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for OMR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 補正の種別
 * @export
 * @enum {string}
 */
export enum AdjustmentType {
    NoAdjustment = 'noAdjustment',
    AchievementRateIn4Weeks = 'achievementRateIn4Weeks',
    AchievementRateIn13Weeks = 'achievementRateIn13Weeks',
    AverageSalesIn4Weeks = 'averageSalesIn4Weeks',
    AverageSalesIn13Weeks = 'averageSalesIn13Weeks',
    YearOverYearIn4Weeks = 'yearOverYearIn4Weeks',
    YearOverYearIn13Weeks = 'yearOverYearIn13Weeks',
    CustomRate = 'customRate'
}

export function AdjustmentTypeFromJSON(json: any): AdjustmentType {
    return AdjustmentTypeFromJSONTyped(json, false);
}

export function AdjustmentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdjustmentType {
    return json as AdjustmentType;
}

export function AdjustmentTypeToJSON(value?: AdjustmentType | null): any {
    return value as any;
}

