import React from 'react'
import { Link } from 'react-router-dom'

import { User, UserRole } from '../../../api-client'

import {
  DetailLinkContainer,
  EmailContainer,
  LogoutButton,
  LogoutButtonContainer,
  Name,
  NameContainer,
  UserMenuContainer,
} from './style'

export interface UserMenuProps {
  user: User
  logout: () => void
}

export const UserMenu: React.FC<UserMenuProps> = ({ user, logout }) => (
  <UserMenuContainer>
    <NameContainer onClick={(event) => event.stopPropagation()} data-testid="user-menu-name">
      <Name>{user.name}</Name>
      <span>（{user.role === UserRole.Manager ? '部門管理者' : '一般ユーザー'}）</span>
    </NameContainer>
    <EmailContainer onClick={(event) => event.stopPropagation()} data-testid="user-menu-email">
      <span>{user.email}</span>
    </EmailContainer>
    <DetailLinkContainer>
      <Link to={`/users/${user.id}`}>アカウントを管理</Link>
    </DetailLinkContainer>
    <LogoutButtonContainer>
      <LogoutButton onClick={() => logout()} data-testid="user-menu-logout-button">
        ログアウト
      </LogoutButton>
    </LogoutButtonContainer>
  </UserMenuContainer>
)
