import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import { SKUTotalInventoryPlanPerWeek, Week } from '../../../api-client'

import { PurchaseOrdersModalWindow } from '../../atoms'

import {
  BaseInventoryCalendarTable,
  InventoryCalendarTableBody,
  InventoryCalendarTableContainer,
  InventoryCalendarTableHeader,
  PONoDisplay,
} from './style'

export interface SKUTotalInventoryCalendarTableProps {
  /**
   * 在庫計画
   */
  inventoryCalendar: { [key: string]: SKUTotalInventoryPlanPerWeek }

  /**
   * ヤード残数
   */
  yardQuantity: number

  /**
   * 週
   */
  weeks: Week[]

  /**
   * ハイライト対象の週
   */
  highlightedWeekDate: string

  /**
   * スクロール位置
   */
  scrollLeft: number

  /**
   *  ハイライト対象が変更された時に呼び出されるハンドラー
   */
  onHighlightedWeekDateChangeHandler: (week: string) => void

  /**
   * テーブルがスクロールされた時に呼び出されるハンドラー
   */
  onScrollHandler: (scrollLeft: number) => void
}

export const SKUTotalInventoryCalendarTable: React.FC<SKUTotalInventoryCalendarTableProps> = ({
  inventoryCalendar,
  yardQuantity,
  weeks,
  highlightedWeekDate,
  scrollLeft,
  onHighlightedWeekDateChangeHandler,
  onScrollHandler,
}) => {
  const timeoutId = useRef<number | null>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    // jsdom may not have scrollTo method. Ref: https://github.com/jsdom/jsdom/issues/1422
    if (containerRef.current != null && containerRef.current.scrollTo != null) {
      containerRef.current.scrollTo(scrollLeft, 0)
    }
  }, [scrollLeft])

  const [indexOfVisiblePONumberModal, setIndexOfVisiblePONumberModal] = useState(-1)

  return (
    <InventoryCalendarTableContainer
      ref={containerRef}
      onScroll={(elem) => {
        if (timeoutId.current != null) {
          clearTimeout(timeoutId.current)
          timeoutId.current = null
        }
        const currentTargetScrollLeft = elem.currentTarget.scrollLeft
        timeoutId.current = window.setTimeout(() => {
          onScrollHandler(currentTargetScrollLeft)
        }, 300)
      }}
      data-testid="inventory-calendar-table"
    >
      <BaseInventoryCalendarTable width={180 + 80 * weeks.length}>
        <thead>
          <tr>
            <InventoryCalendarTableHeader></InventoryCalendarTableHeader>
            {weeks.map((week, idx) => (
              <InventoryCalendarTableHeader key={idx} onClick={() => onHighlightedWeekDateChangeHandler(week.date)}>
                {week.date}
              </InventoryCalendarTableHeader>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <InventoryCalendarTableBody
              isbackgroundred={false}
              isboldbottom={false}
              ishighlight={false}
              isYellowBackground={false}
            >
              予測在庫数
            </InventoryCalendarTableBody>
            {weeks.map((week, idx) => (
              <InventoryCalendarTableBody
                key={idx}
                isbackgroundred={inventoryCalendar[week.date]?.isInventoryUnitInventoryDeficient === true}
                isboldbottom={false}
                ishighlight={week.date === highlightedWeekDate}
                isYellowBackground={false}
                onClick={() => onHighlightedWeekDateChangeHandler(week.date)}
              >
                {inventoryCalendar[week.date]?.inventoryUnitInventoryQuantity != null
                  ? inventoryCalendar[week.date].inventoryUnitInventoryQuantity.toLocaleString()
                  : ''}
              </InventoryCalendarTableBody>
            ))}
          </tr>
          <tr>
            <InventoryCalendarTableBody
              isbackgroundred={false}
              isboldbottom={false}
              ishighlight={false}
              isYellowBackground={false}
            >
              予測在庫週数
            </InventoryCalendarTableBody>
            {weeks.map((week, idx) => {
              const numberOfInventoryUnitInventoryWeeks =
                inventoryCalendar[week.date]?.numberOfInventoryUnitInventoryWeeks != null
                  ? inventoryCalendar[week.date]?.numberOfInventoryUnitInventoryWeeks
                  : null
              return (
                <InventoryCalendarTableBody
                  key={idx}
                  isbackgroundred={false}
                  isboldbottom={false}
                  ishighlight={week.date === highlightedWeekDate}
                  isYellowBackground={false}
                  onClick={() => onHighlightedWeekDateChangeHandler(week.date)}
                >
                  {numberOfInventoryUnitInventoryWeeks != null
                    ? numberOfInventoryUnitInventoryWeeks.toLocaleString()
                    : ''}
                </InventoryCalendarTableBody>
              )
            })}
          </tr>
          <tr>
            <InventoryCalendarTableBody
              isbackgroundred={false}
              isboldbottom={false}
              ishighlight={false}
              isYellowBackground={false}
            >
              出荷予定数
            </InventoryCalendarTableBody>
            {weeks.map((week, idx) => {
              const inventoryUnitShipmentQuantity =
                inventoryCalendar[week.date] != null ? inventoryCalendar[week.date].inventoryUnitShipmentQuantity : null
              return (
                <InventoryCalendarTableBody
                  key={idx}
                  isbackgroundred={false}
                  isboldbottom={false}
                  ishighlight={week.date === highlightedWeekDate}
                  isYellowBackground={false}
                  onClick={() => onHighlightedWeekDateChangeHandler(week.date)}
                >
                  {inventoryUnitShipmentQuantity != null ? inventoryUnitShipmentQuantity.toLocaleString() : ''}
                </InventoryCalendarTableBody>
              )
            })}
          </tr>
          <tr>
            <InventoryCalendarTableBody
              isbackgroundred={false}
              isboldbottom={false}
              ishighlight={false}
              isYellowBackground={false}
            >
              P/O No.
            </InventoryCalendarTableBody>
            {weeks.map((week, idx) => {
              const targetWeek = inventoryCalendar[week.date]
              // TODO: 連想配列でキーからの取得失敗時(targetWeek===undefined)は想定しないはずなのでエラーハンドリングを行う
              if (targetWeek == null) {
                return null
              }
              const inventoryUnitReceivings = targetWeek.inventoryUnitReceivings
              const unreceivedOrders = targetWeek.unreceivedOrders

              return (
                <InventoryCalendarTableBody
                  key={idx}
                  isbackgroundred={false}
                  isboldbottom={false}
                  ishighlight={week.date === highlightedWeekDate}
                  isYellowBackground={false}
                  onClick={() => onHighlightedWeekDateChangeHandler(week.date)}
                >
                  {(() => {
                    // 当週
                    if (idx === 0) {
                      return (
                        <>
                          <Link
                            to="#"
                            onClick={() => setIndexOfVisiblePONumberModal(idx)}
                            data-testid="inventory-calendar-po-number-link"
                          >
                            {inventoryUnitReceivings.length + unreceivedOrders.length} P/O
                          </Link>
                          {/* モーダル設定 */}
                          {idx === indexOfVisiblePONumberModal ? (
                            <PurchaseOrdersModalWindow
                              inventoryUnitReceivings={inventoryUnitReceivings}
                              unconfirmedInventoryUnitReceivings={[]}
                              unreceivedOrders={unreceivedOrders}
                              yardQuantity={yardQuantity}
                              onCloseHandler={() => setIndexOfVisiblePONumberModal(-1)}
                              thisWeek={week}
                            />
                          ) : null}
                        </>
                      )
                    }

                    // 次週以降
                    if (inventoryUnitReceivings.length === 0 && unreceivedOrders.length === 0) {
                      return null
                    }
                    if (inventoryUnitReceivings.length === 1 && unreceivedOrders.length === 0) {
                      return <PONoDisplay>{inventoryUnitReceivings[0].purchaseOrderNumber}</PONoDisplay>
                    }
                    if (inventoryUnitReceivings.length === 0 && unreceivedOrders.length === 1) {
                      return (
                        <PONoDisplay>{unreceivedOrders[0].inventoryUnitReceivings.purchaseOrderNumber}</PONoDisplay>
                      )
                    }
                    return (
                      <>
                        <Link
                          to="#"
                          onClick={() => setIndexOfVisiblePONumberModal(idx)}
                          data-testid="inventory-calendar-po-number-link"
                        >
                          {inventoryUnitReceivings.length + unreceivedOrders.length} P/O
                        </Link>
                        {/* モーダル設定 */}
                        {idx === indexOfVisiblePONumberModal ? (
                          <PurchaseOrdersModalWindow
                            inventoryUnitReceivings={inventoryUnitReceivings}
                            unconfirmedInventoryUnitReceivings={[]}
                            unreceivedOrders={unreceivedOrders}
                            yardQuantity={undefined}
                            onCloseHandler={() => setIndexOfVisiblePONumberModal(-1)}
                            thisWeek={week}
                          />
                        ) : null}
                      </>
                    )
                  })()}
                </InventoryCalendarTableBody>
              )
            })}
          </tr>
          <tr>
            <InventoryCalendarTableBody
              isbackgroundred={false}
              isboldbottom={false}
              ishighlight={false}
              isYellowBackground={false}
            >
              入荷予定数
            </InventoryCalendarTableBody>
            {weeks.map((week, idx) => {
              const targetWeek = inventoryCalendar[week.date]
              // TODO: 連想配列でキーからの取得失敗時(targetWeek===undefined)は想定しないはずなのでエラーハンドリングを行う
              if (targetWeek == null) {
                return null
              }
              const inventoryUnitReceivings = targetWeek.inventoryUnitReceivings
              const unreceivedOrders = targetWeek.unreceivedOrders

              // 当週
              if (idx === 0) {
                const quantity =
                  inventoryUnitReceivings.reduce((a, b) => a + b.quantity, 0) +
                  unreceivedOrders.reduce((a, b) => a + b.inventoryUnitReceivings.quantity, 0) +
                  yardQuantity
                const hasYardOrUnreceivedOrder =
                  yardQuantity > 0 || unreceivedOrders.reduce((a, b) => a + b.inventoryUnitReceivings.quantity, 0) > 0

                return (
                  <InventoryCalendarTableBody
                    key={idx}
                    isbackgroundred={false}
                    isboldbottom={false}
                    ishighlight={week.date === highlightedWeekDate}
                    isYellowBackground={hasYardOrUnreceivedOrder}
                    onClick={() => onHighlightedWeekDateChangeHandler(week.date)}
                    data-testid="inventory-calendar-table-body-receiving-quantity"
                  >
                    <span data-testid="inventoryUnit-receiving-count">{quantity.toLocaleString()}</span>
                  </InventoryCalendarTableBody>
                )
              }

              // 次週以降
              const hasReceivings = inventoryUnitReceivings.length > 0
              const quantity = inventoryUnitReceivings.reduce((a, b) => a + b.quantity, 0)
              return (
                <InventoryCalendarTableBody
                  key={idx}
                  isbackgroundred={false}
                  isboldbottom={false}
                  ishighlight={week.date === highlightedWeekDate}
                  isYellowBackground={false}
                  onClick={() => onHighlightedWeekDateChangeHandler(week.date)}
                  data-testid="inventory-calendar-table-body-receiving-quantity"
                >
                  {hasReceivings ? (
                    <span data-testid="inventoryUnit-receiving-count">{quantity.toLocaleString()}</span>
                  ) : null}
                </InventoryCalendarTableBody>
              )
            })}
          </tr>
          <tr>
            <InventoryCalendarTableBody
              isbackgroundred={false}
              isboldbottom={false}
              ishighlight={false}
              isYellowBackground={false}
            >
              予測店舗在庫数
            </InventoryCalendarTableBody>
            {weeks.map((week, idx) => (
              <InventoryCalendarTableBody
                key={idx}
                isbackgroundred={inventoryCalendar[week.date]?.isStoreInventoryStandardUnachieved === true}
                isboldbottom={false}
                ishighlight={week.date === highlightedWeekDate}
                isYellowBackground={false}
                onClick={() => onHighlightedWeekDateChangeHandler(week.date)}
              >
                {inventoryCalendar[week.date]?.storeInventoryQuantity != null
                  ? inventoryCalendar[week.date].storeInventoryQuantity.toLocaleString()
                  : ''}
              </InventoryCalendarTableBody>
            ))}
          </tr>
        </tbody>
      </BaseInventoryCalendarTable>
    </InventoryCalendarTableContainer>
  )
}
