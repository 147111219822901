import React from 'react'
import { Link } from 'react-router-dom'

import { Container, PurchaseOrderDataType, PurchaseOrderType } from '../../../api-client'

import { RadioButton, SquareLabel, SquareLabelProps } from '../../../components/atoms'

import {
  BaseContainersListTable,
  ContainersListTableBodyRow,
  ContainersListTableData,
  ContainersListTableHeader,
  DistributionCenterName,
  RatioRectangleInnerNewData,
  RatioRectangleInnerPastData,
  RatioRectangleOuter,
  UsageContainer,
} from './style'

export interface ContainersListTableProps {
  containers: Array<Container>
  selectedContainerIndex: number
  onContainerSelectedHandler: (index: number) => void
}

export const ContainersListTable: React.FC<ContainersListTableProps> = ({
  containers,
  selectedContainerIndex,
  onContainerSelectedHandler,
}) => {
  const purchaseOrderTypeToSquareLabelOption = (type: PurchaseOrderType): SquareLabelProps => {
    switch (type) {
      case PurchaseOrderType.Normal:
        return {
          color: 'black',
          text: '通常',
          width: 44,
        }
      case PurchaseOrderType.MixedPurchaseOrder:
        return {
          color: 'black',
          text: 'PO混載',
          width: 65,
        }
      case PurchaseOrderType.MultipleWeeks:
        return {
          color: 'black',
          text: '複数週まとめ',
          width: 102,
        }
      case PurchaseOrderType.MixedPurchaseOrderAndmultipleWeeks:
        return {
          color: 'black',
          text: 'PO混載・複数週',
          width: 120,
        }
      case PurchaseOrderType.LandTransportation:
        return {
          color: 'black',
          text: '陸送',
          width: 44,
        }
      case PurchaseOrderType.Lcl:
        return {
          color: 'black',
          text: 'LCL',
          width: 44,
        }
      case PurchaseOrderType.PastDraft:
        return {
          color: 'black',
          text: '発注済み',
          width: 70,
        }
      default:
        return {
          color: 'black',
          text: '',
          width: 0,
        }
    }
  }
  const Accordion = (key: number) => {
    const childLine = document.getElementsByName('parent_' + key)
    const openClose = document.getElementById('open_close_'+ key)

    if (openClose !== null) {
      if (openClose.innerText === "▶") {
        //クローズ時
        childLine.forEach((element) => {
          element.style.display = ''
          // element.setAttribute("style", "display:''")
        })
        openClose.innerText = "▼"
      } else {
        //オープン時
        childLine.forEach((element) => {
          element.style.display = 'none'
          // element.setAttribute("style", "display:'none'")
        })
        openClose.innerText = "▶"
      }
    }

  }

  return (
    <BaseContainersListTable>
      <thead>
        <tr>
          <ContainersListTableHeader width={25}></ContainersListTableHeader>
          <ContainersListTableHeader width={140}>タイプ</ContainersListTableHeader>
          <ContainersListTableHeader width={170}>在庫管理単位</ContainersListTableHeader>
          <ContainersListTableHeader width={65}>SKU数</ContainersListTableHeader>
          <ContainersListTableHeader width={120}>コンテナ</ContainersListTableHeader>
          <ContainersListTableHeader width={170}>入荷日</ContainersListTableHeader>
          <ContainersListTableHeader width={200}>容積</ContainersListTableHeader>
          <ContainersListTableHeader width={200}>重量</ContainersListTableHeader>
          <ContainersListTableHeader width={220}>積地港/揚地港</ContainersListTableHeader>
          <ContainersListTableHeader width={255}></ContainersListTableHeader>
        </tr>
      </thead>
      <tbody>
        {containers.map((container, idx) => {
          const squareLabelOption = purchaseOrderTypeToSquareLabelOption(container.purchaseOrderType)
          const preferredArrivalDate = `${container.preferredArrivalDate.getFullYear()}年${
            container.preferredArrivalDate.getMonth() + 1
          }月${container.preferredArrivalDate.getDate()}日`
          const joinedDistributionCenterNames = container.inventoryUnitNames.join('・')
          const isLCL = container.purchaseOrderType === PurchaseOrderType.Lcl
          const dataType = container.dataType
          const isDetail = container.isDetail
          let display
          let bgColor
          const openClose = "open_close_" + container.serialNumber
          display = (() : string=>{
            if ((dataType === PurchaseOrderDataType.NewData && isDetail) || dataType === PurchaseOrderDataType.PastData) {
              return 'none'
            }
            return ''
          })()
          bgColor = (() : string=>{
            console.log("bgColor called")
            if (display === 'none') {
              return '#F1F7FC'
            } else {
              return '#fff'
            }
          })()
          const trName = "parent_" + String(container.parentSerialNumber)
          return (
            <ContainersListTableBodyRow key={container.id} id={String(container.id)} selected={idx === selectedContainerIndex} backGroundColor={bgColor} name={trName} style={{display:display}}>
              <ContainersListTableData>
                {/* ラジオボタン */}
                <RadioButton
                  id="radio-button"
                  checked={idx === selectedContainerIndex}
                  onChangeHandler={() => onContainerSelectedHandler(idx)}
                />
              </ContainersListTableData>
              <ContainersListTableData>
                {/* タイプ */}
              {(()=> {
                if (dataType === PurchaseOrderDataType.NewData && isDetail) {
                  //newデータ(子)
                  return null
                } else if (dataType === PurchaseOrderDataType.NewData && !isDetail) {
                  //newデータ(親)
                  return <SquareLabel
                            color={squareLabelOption.color}
                            text={squareLabelOption.text}
                            width={squareLabelOption.width}
                         />
                } else if (dataType === PurchaseOrderDataType.PastData) {
                  //oldデータ
                 return <SquareLabel
                            color={squareLabelOption.color}
                            text={squareLabelOption.text}
                            width={squareLabelOption.width}
                        />
                } else if (dataType === PurchaseOrderDataType.SumData) {
                  //totalデータ
                  return <SquareLabel
                            color={squareLabelOption.color}
                            text={squareLabelOption.text}
                            width={squareLabelOption.width}
                         />
                }
              })()}
              </ContainersListTableData>
              <ContainersListTableData>
                {/* 在庫管理単位 */}
              {(()=> {
                if (dataType === PurchaseOrderDataType.NewData && isDetail) {
                  //newデータ(子)
                  return null
                } else if (dataType === PurchaseOrderDataType.NewData && !isDetail) {
                  //newデータ(親)
                  return  <DistributionCenterName>
                            {joinedDistributionCenterNames.length > 15
                            ? `${joinedDistributionCenterNames.substr(0, 15)}…`
                            : joinedDistributionCenterNames}
                          </DistributionCenterName>
                } else if (dataType === PurchaseOrderDataType.PastData) {
                  //oldデータ
                  return null
                } else if (dataType === PurchaseOrderDataType.SumData) {
                  //totalデータ
                  return  <DistributionCenterName>
                            {joinedDistributionCenterNames.length > 15
                            ? `${joinedDistributionCenterNames.substr(0, 15)}…`
                            : joinedDistributionCenterNames}
                          </DistributionCenterName>
                }
              })()}
              </ContainersListTableData>
              <ContainersListTableData>
                {/* SKU数 */}
                {container.numberOfSKUs}
              </ContainersListTableData>
              <ContainersListTableData>
                {/* コンテナ */}
                {isLCL ? (
                  <span data-testid="lcl-type-container-type">-</span>
                ) : (
                  (()=>{
                    if (dataType === PurchaseOrderDataType.PastData) {
                      return null
                    } else {
                      return container.containerType
                    }
                  })()
                )
                }
              </ContainersListTableData>
              <ContainersListTableData>
                {/* 入荷予定日 */}
                {preferredArrivalDate}
              </ContainersListTableData>
              <ContainersListTableData>
                {/* 容積 */}
                {isLCL ? (
                  <UsageContainer data-testid="lcl-type-container-total-m3">
                    {`${Math.round(container.totalM3 * 100) / 100}㎥`}
                  </UsageContainer>
                ) : (
                  <UsageContainer>
                    <RatioRectangleOuter>
                      {dataType === PurchaseOrderDataType.NewData ? (
                        <RatioRectangleInnerNewData width={container.volumeUsage} />
                      ) : dataType === PurchaseOrderDataType.PastData ? (
                        <RatioRectangleInnerPastData width={container.pastVolumeUsage} />
                      ) : dataType === PurchaseOrderDataType.SumData ? (
                        <React.Fragment>
                          <RatioRectangleInnerPastData width={container.pastVolumeUsage} />
                          <RatioRectangleInnerNewData width={container.volumeUsage} />
                        </React.Fragment>
                      ) : null}
                    </RatioRectangleOuter>
                    {(()=> {
                      if (dataType === PurchaseOrderDataType.NewData && isDetail) {
                        //newデータ(子)
                        return null
                      } else if (dataType === PurchaseOrderDataType.NewData && !isDetail) {
                        //newデータ(親)
                        return `${container.volumeUsage.toFixed(1)}%`
                      } else if (dataType === PurchaseOrderDataType.PastData) {
                        //oldデータ
                        return null
                      } else if (dataType === PurchaseOrderDataType.SumData) {
                        //totalデータ
                        var total = (container.volumeUsage + container.pastVolumeUsage)
                        return `${total.toFixed(1)}%`
                      }
                    })()}
                    <br />
                    {(()=> {
                      if (dataType === PurchaseOrderDataType.NewData && isDetail) {
                        //newデータ(子)
                        return `${Math.round(container.totalM3 * 100) / 100}㎥`
                      } else if (dataType === PurchaseOrderDataType.NewData && !isDetail) {
                        //newデータ(親)
                        return `(${Math.round(container.totalM3 * 100) / 100}㎥)`
                      } else if (dataType === PurchaseOrderDataType.PastData) {
                        //oldデータ
                        return `${Math.round(container.pastTotalM3 * 100) / 100}㎥`
                      } else if (dataType === PurchaseOrderDataType.SumData) {
                        //totalデータ
                        var total = (container.totalM3 + container.pastTotalM3)
                        return `(${Math.round(total * 100) / 100}㎥)`
                      }
                    })()}
                  </UsageContainer>
                )}
              </ContainersListTableData>
              <ContainersListTableData>
                {/* 重量 */}
                {isLCL ? (
                  <UsageContainer data-testid="lcl-type-container-total-kg">
                    {`${Math.round(container.totalKg * 10) / 10}kg`}
                  </UsageContainer>
                ) : (
                  <UsageContainer>
                    <RatioRectangleOuter>
                      {dataType === PurchaseOrderDataType.NewData ? (
                        <RatioRectangleInnerNewData width={container.weightUsage} />
                      ) : dataType === PurchaseOrderDataType.PastData ? (
                        <RatioRectangleInnerPastData width={container.pastWeightUsage} />
                      ) : dataType === PurchaseOrderDataType.SumData ? (
                        <React.Fragment>
                          <RatioRectangleInnerPastData width={container.pastWeightUsage} />
                          <RatioRectangleInnerNewData width={container.weightUsage} />
                        </React.Fragment>
                      ) : null}
                    </RatioRectangleOuter>
                    {(()=> {
                      if (dataType === PurchaseOrderDataType.NewData && isDetail) {
                        //newデータ(子)
                        return null
                      } else if (dataType === PurchaseOrderDataType.NewData && !isDetail) {
                        //newデータ(親)
                        return `${container.weightUsage.toFixed(1)}%`
                      } else if (dataType === PurchaseOrderDataType.PastData) {
                        //oldデータ
                        return null
                      } else if (dataType === PurchaseOrderDataType.SumData) {
                        //totalデータ
                        var total = (container.weightUsage + container.pastWeightUsage)
                        return `${total.toFixed(1)}%`
                      }
                    })()}
                    {/* {`${container.weightUsage.toFixed(1)}%`} <br /> */}
                    <br/>
                    {(()=> {
                      if (dataType === PurchaseOrderDataType.NewData && isDetail) {
                        //newデータ(子)
                        return `${Math.round(container.totalKg * 10) / 10}kg`
                      } else if (dataType === PurchaseOrderDataType.NewData && !isDetail) {
                        //newデータ(親)
                        return `(${Math.round(container.totalKg * 10) / 10}kg)`
                      } else if (dataType === PurchaseOrderDataType.PastData) {
                        //oldデータ
                        return `${Math.round(container.pastTotalKg * 10) / 10}kg`
                      } else if (dataType === PurchaseOrderDataType.SumData) {
                        //totalデータ
                        var total = (container.totalKg + container.pastTotalKg)
                        return `(${Math.round(total * 10) / 10}kg)`
                      }
                    })()}
                    
                  </UsageContainer>
                )}
              </ContainersListTableData>
              {/* 積地港/揚地港 */}
              {(()=>{
                
                  if ((dataType === PurchaseOrderDataType.NewData && !isDetail) || (dataType === PurchaseOrderDataType.SumData)) {
                    return  <ContainersListTableData>
                            積：{container.portOfLoading}
                            <br />
                            揚：{container.portOfDischarge}
                            </ContainersListTableData>
                  } else {
                    return  <ContainersListTableData></ContainersListTableData>
                  }
              })()}
              {/* 内訳 */}
              {(()=>{
                if (dataType === PurchaseOrderDataType.SumData) {
                  let serialNumber = 0
                  if (container.serialNumber !== undefined) {
                    serialNumber = container.serialNumber
                  }
                  return  <ContainersListTableData>
                            <Link to={"#"} onClick={() => Accordion(serialNumber)}><span id={`${openClose}`}>▶</span>内訳</Link>
                          </ContainersListTableData>
                } else if (dataType === PurchaseOrderDataType.PastData) {
                  return  <ContainersListTableData>{container.purchaseOrderNumber}</ContainersListTableData>
                } else {
                  return  <ContainersListTableData></ContainersListTableData>
                }
              })()}
            </ContainersListTableBodyRow>
          )
        })}
      </tbody>
    </BaseContainersListTable>
  )
}
