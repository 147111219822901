/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for OMR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ExcelError,
    ExcelErrorFromJSON,
    ExcelErrorToJSON,
    GetContainerPlanResponse,
    GetContainerPlanResponseFromJSON,
    GetContainerPlanResponseToJSON,
    GetContainerPlansResponse,
    GetContainerPlansResponseFromJSON,
    GetContainerPlansResponseToJSON,
    GetDraftPurchaseOrderOrderGroupsResponse,
    GetDraftPurchaseOrderOrderGroupsResponseFromJSON,
    GetDraftPurchaseOrderOrderGroupsResponseToJSON,
    GetInventoryPlanResponse,
    GetInventoryPlanResponseFromJSON,
    GetInventoryPlanResponseToJSON,
    GetInventoryUnitShipmentPlanCollectiveResponse,
    GetInventoryUnitShipmentPlanCollectiveResponseFromJSON,
    GetInventoryUnitShipmentPlanCollectiveResponseToJSON,
    GetInventoryUnitShipmentPlanResponse,
    GetInventoryUnitShipmentPlanResponseFromJSON,
    GetInventoryUnitShipmentPlanResponseToJSON,
    GetLongTermContainerPlanResponse,
    GetLongTermContainerPlanResponseFromJSON,
    GetLongTermContainerPlanResponseToJSON,
    GetLongTermContainerPlansResponse,
    GetLongTermContainerPlansResponseFromJSON,
    GetLongTermContainerPlansResponseToJSON,
    GetSKUsResponse,
    GetSKUsResponseFromJSON,
    GetSKUsResponseToJSON,
    GetShipmentReasonsResponse,
    GetShipmentReasonsResponseFromJSON,
    GetShipmentReasonsResponseToJSON,
    GetUsersResponse,
    GetUsersResponseFromJSON,
    GetUsersResponseToJSON,
    OrderGroupsItem,
    OrderGroupsItemFromJSON,
    OrderGroupsItemToJSON,
    PostUsersRequest,
    PostUsersRequestFromJSON,
    PostUsersRequestToJSON,
    PutInventoryUnitShipmentPlanCollectiveRequest,
    PutInventoryUnitShipmentPlanCollectiveRequestFromJSON,
    PutInventoryUnitShipmentPlanCollectiveRequestToJSON,
    PutInventoryUnitShipmentPlanRequest,
    PutInventoryUnitShipmentPlanRequestFromJSON,
    PutInventoryUnitShipmentPlanRequestToJSON,
    PutSimulationParametersRequest,
    PutSimulationParametersRequestFromJSON,
    PutSimulationParametersRequestToJSON,
    PutSimulationParametersResponse,
    PutSimulationParametersResponseFromJSON,
    PutSimulationParametersResponseToJSON,
    PutUserRequest,
    PutUserRequestFromJSON,
    PutUserRequestToJSON,
    User,
    UserFromJSON,
    UserToJSON,
} from '../models';

export interface DeleteOrderGroupSimulationParametersRequest {
    orderGroupId: number;
}

export interface DeleteUserRequest {
    userId: number;
}

export interface GetContainerPlanRequest {
    containerPlanId: number;
}

export interface GetContainerPlansRequest {
    orderGroupId: number;
}

export interface GetDraftPurchaseOrderExcelRequest {
    orderGroupId: number;
}

export interface GetInventoryPlanRequest {
    orderGroupId: number;
}

export interface GetInventoryUnitShipmentPlanRequest {
    inventoryUnitCode: string;
    janCode: string;
    adjustmentType?: GetInventoryUnitShipmentPlanAdjustmentTypeEnum;
    adjustmentCustomRate?: number;
    adjustmentRange?: GetInventoryUnitShipmentPlanAdjustmentRangeEnum;
    numberOfWeeksToAdjust?: number;
    selectedDemandForecast?: GetInventoryUnitShipmentPlanSelectedDemandForecastEnum;
}

export interface GetInventoryUnitShipmentPlanCollectiveRequest {
    janCode: string;
    adjustmentType?: GetInventoryUnitShipmentPlanCollectiveAdjustmentTypeEnum;
    adjustmentCustomRate?: number;
    adjustmentRange?: GetInventoryUnitShipmentPlanCollectiveAdjustmentRangeEnum;
    numberOfWeeksToAdjust?: number;
    selectedDemandForecast?: GetInventoryUnitShipmentPlanCollectiveSelectedDemandForecastEnum;
}

export interface GetLongTermContainerPlanRequest {
    containerPlanId: number;
}

export interface GetLongTermContainerPlansRequest {
    orderGroupId: number;
}

export interface GetLongTermDraftPurchaseOrderExcelRequest {
    orderGroupId: number;
}

export interface GetOrderGroupCsvFileRequest {
    orderGroupId: number;
}

export interface GetUserByIDRequest {
    userId: number;
}

export interface PostUsersOperationRequest {
    bodyParam: PostUsersRequest;
}

export interface PutDatabaseOrderGroupsCsvRequest {
    uploadedCsvFile: Blob;
}

export interface PutInventoryUnitShipmentPlanOperationRequest {
    inventoryUnitCode: string;
    janCode: string;
    bodyParam: PutInventoryUnitShipmentPlanRequest;
}

export interface PutInventoryUnitShipmentPlanCollectiveOperationRequest {
    janCode: string;
    bodyParam: PutInventoryUnitShipmentPlanCollectiveRequest;
}

export interface PutMasterOfInventoryUnitsExcelRequest {
    uploadedExcelFile: Blob;
}

export interface PutMasterOfSKUsExcelRequest {
    uploadedExcelFile: Blob;
}

export interface PutMasterOfStoresExcelRequest {
    uploadedExcelFile: Blob;
}

export interface PutMoqGroupsCsvRequest {
    uploadedCsvFile: Blob;
}

export interface PutOrderGroupSimulationParametersRequest {
    orderGroupId: number;
    bodyParam: PutSimulationParametersRequest;
}

export interface PutShipmentReasonsExcelRequest {
    uploadedExcelFile: Blob;
}

export interface PutUserOperationRequest {
    userId: number;
    bodyParam: PutUserRequest;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * order_groupに紐づいたシミュレーションを削除する
     */
    async deleteOrderGroupSimulationParametersRaw(requestParameters: DeleteOrderGroupSimulationParametersRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orderGroupId === null || requestParameters.orderGroupId === undefined) {
            throw new runtime.RequiredError('orderGroupId','Required parameter requestParameters.orderGroupId was null or undefined when calling deleteOrderGroupSimulationParameters.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/order-groups/{OrderGroupId}/simulations-parameters`.replace(`{${"OrderGroupId"}}`, encodeURIComponent(String(requestParameters.orderGroupId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * order_groupに紐づいたシミュレーションを削除する
     */
    async deleteOrderGroupSimulationParameters(requestParameters: DeleteOrderGroupSimulationParametersRequest): Promise<void> {
        await this.deleteOrderGroupSimulationParametersRaw(requestParameters);
    }

    /**
     * ユーザーを削除
     */
    async deleteUserRaw(requestParameters: DeleteUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling deleteUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * ユーザーを削除
     */
    async deleteUser(requestParameters: DeleteUserRequest): Promise<void> {
        await this.deleteUserRaw(requestParameters);
    }

    /**
     * コンテナの輸送計画に紐づくPO案やSKUxInventoryUnitの情報
     */
    async getContainerPlanRaw(requestParameters: GetContainerPlanRequest): Promise<runtime.ApiResponse<GetContainerPlanResponse>> {
        if (requestParameters.containerPlanId === null || requestParameters.containerPlanId === undefined) {
            throw new runtime.RequiredError('containerPlanId','Required parameter requestParameters.containerPlanId was null or undefined when calling getContainerPlan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/container-plans/{ContainerPlanId}`.replace(`{${"ContainerPlanId"}}`, encodeURIComponent(String(requestParameters.containerPlanId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetContainerPlanResponseFromJSON(jsonValue));
    }

    /**
     * コンテナの輸送計画に紐づくPO案やSKUxInventoryUnitの情報
     */
    async getContainerPlan(requestParameters: GetContainerPlanRequest): Promise<GetContainerPlanResponse> {
        const response = await this.getContainerPlanRaw(requestParameters);
        return await response.value();
    }

    /**
     * 発注グループに紐づくコンテナの輸送計画一覧を取得する
     */
    async getContainerPlansRaw(requestParameters: GetContainerPlansRequest): Promise<runtime.ApiResponse<GetContainerPlansResponse>> {
        if (requestParameters.orderGroupId === null || requestParameters.orderGroupId === undefined) {
            throw new runtime.RequiredError('orderGroupId','Required parameter requestParameters.orderGroupId was null or undefined when calling getContainerPlans.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/order-groups/{OrderGroupId}/container-plans`.replace(`{${"OrderGroupId"}}`, encodeURIComponent(String(requestParameters.orderGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetContainerPlansResponseFromJSON(jsonValue));
    }

    /**
     * 発注グループに紐づくコンテナの輸送計画一覧を取得する
     */
    async getContainerPlans(requestParameters: GetContainerPlansRequest): Promise<GetContainerPlansResponse> {
        const response = await this.getContainerPlansRaw(requestParameters);
        return await response.value();
    }

    /**
     * 発注グループ一覧のCSV
     */
    async getDatabaseOrderGroupsCsvRaw(): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/database-order-groups/csv`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * 発注グループ一覧のCSV
     */
    async getDatabaseOrderGroupsCsv(): Promise<Blob> {
        const response = await this.getDatabaseOrderGroupsCsvRaw();
        return await response.value();
    }

    /**
     * 発注案のExcelダウンロード
     */
    async getDraftPurchaseOrderExcelRaw(requestParameters: GetDraftPurchaseOrderExcelRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.orderGroupId === null || requestParameters.orderGroupId === undefined) {
            throw new runtime.RequiredError('orderGroupId','Required parameter requestParameters.orderGroupId was null or undefined when calling getDraftPurchaseOrderExcel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/order-groups/{OrderGroupId}/draft-purchase-order/excel`.replace(`{${"OrderGroupId"}}`, encodeURIComponent(String(requestParameters.orderGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * 発注案のExcelダウンロード
     */
    async getDraftPurchaseOrderExcel(requestParameters: GetDraftPurchaseOrderExcelRequest): Promise<Blob> {
        const response = await this.getDraftPurchaseOrderExcelRaw(requestParameters);
        return await response.value();
    }

    /**
     * 発注案がある発注グループの一覧を取得する
     */
    async getDraftPurchaseOrderOrderGroupsRaw(): Promise<runtime.ApiResponse<GetDraftPurchaseOrderOrderGroupsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/draft-purchase-order-order-groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDraftPurchaseOrderOrderGroupsResponseFromJSON(jsonValue));
    }

    /**
     * 発注案がある発注グループの一覧を取得する
     */
    async getDraftPurchaseOrderOrderGroups(): Promise<GetDraftPurchaseOrderOrderGroupsResponse> {
        const response = await this.getDraftPurchaseOrderOrderGroupsRaw();
        return await response.value();
    }

    /**
     * 発注グループに紐づく在庫計画の情報を取得する
     */
    async getInventoryPlanRaw(requestParameters: GetInventoryPlanRequest): Promise<runtime.ApiResponse<GetInventoryPlanResponse>> {
        if (requestParameters.orderGroupId === null || requestParameters.orderGroupId === undefined) {
            throw new runtime.RequiredError('orderGroupId','Required parameter requestParameters.orderGroupId was null or undefined when calling getInventoryPlan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/order-groups/{OrderGroupId}/inventory-plan`.replace(`{${"OrderGroupId"}}`, encodeURIComponent(String(requestParameters.orderGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInventoryPlanResponseFromJSON(jsonValue));
    }

    /**
     * 発注グループに紐づく在庫計画の情報を取得する
     */
    async getInventoryPlan(requestParameters: GetInventoryPlanRequest): Promise<GetInventoryPlanResponse> {
        const response = await this.getInventoryPlanRaw(requestParameters);
        return await response.value();
    }

    /**
     * 出荷予定数の情報
     */
    async getInventoryUnitShipmentPlanRaw(requestParameters: GetInventoryUnitShipmentPlanRequest): Promise<runtime.ApiResponse<GetInventoryUnitShipmentPlanResponse>> {
        if (requestParameters.inventoryUnitCode === null || requestParameters.inventoryUnitCode === undefined) {
            throw new runtime.RequiredError('inventoryUnitCode','Required parameter requestParameters.inventoryUnitCode was null or undefined when calling getInventoryUnitShipmentPlan.');
        }

        if (requestParameters.janCode === null || requestParameters.janCode === undefined) {
            throw new runtime.RequiredError('janCode','Required parameter requestParameters.janCode was null or undefined when calling getInventoryUnitShipmentPlan.');
        }

        const queryParameters: any = {};

        if (requestParameters.adjustmentType !== undefined) {
            queryParameters['adjustmentType'] = requestParameters.adjustmentType;
        }

        if (requestParameters.adjustmentCustomRate !== undefined) {
            queryParameters['adjustmentCustomRate'] = requestParameters.adjustmentCustomRate;
        }

        if (requestParameters.adjustmentRange !== undefined) {
            queryParameters['adjustmentRange'] = requestParameters.adjustmentRange;
        }

        if (requestParameters.numberOfWeeksToAdjust !== undefined) {
            queryParameters['numberOfWeeksToAdjust'] = requestParameters.numberOfWeeksToAdjust;
        }

        if (requestParameters.selectedDemandForecast !== undefined) {
            queryParameters['selectedDemandForecast'] = requestParameters.selectedDemandForecast;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/inventory-units/{inventoryUnitCode}/skus/{janCode}/inventory-unit-shipment-plan`.replace(`{${"inventoryUnitCode"}}`, encodeURIComponent(String(requestParameters.inventoryUnitCode))).replace(`{${"janCode"}}`, encodeURIComponent(String(requestParameters.janCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInventoryUnitShipmentPlanResponseFromJSON(jsonValue));
    }

    /**
     * 出荷予定数の情報
     */
    async getInventoryUnitShipmentPlan(requestParameters: GetInventoryUnitShipmentPlanRequest): Promise<GetInventoryUnitShipmentPlanResponse> {
        const response = await this.getInventoryUnitShipmentPlanRaw(requestParameters);
        return await response.value();
    }

    /**
     * 出荷予定数の情報(一括補正)
     */
    async getInventoryUnitShipmentPlanCollectiveRaw(requestParameters: GetInventoryUnitShipmentPlanCollectiveRequest): Promise<runtime.ApiResponse<GetInventoryUnitShipmentPlanCollectiveResponse>> {
        if (requestParameters.janCode === null || requestParameters.janCode === undefined) {
            throw new runtime.RequiredError('janCode','Required parameter requestParameters.janCode was null or undefined when calling getInventoryUnitShipmentPlanCollective.');
        }

        const queryParameters: any = {};

        if (requestParameters.adjustmentType !== undefined) {
            queryParameters['adjustmentType'] = requestParameters.adjustmentType;
        }

        if (requestParameters.adjustmentCustomRate !== undefined) {
            queryParameters['adjustmentCustomRate'] = requestParameters.adjustmentCustomRate;
        }

        if (requestParameters.adjustmentRange !== undefined) {
            queryParameters['adjustmentRange'] = requestParameters.adjustmentRange;
        }

        if (requestParameters.numberOfWeeksToAdjust !== undefined) {
            queryParameters['numberOfWeeksToAdjust'] = requestParameters.numberOfWeeksToAdjust;
        }

        if (requestParameters.selectedDemandForecast !== undefined) {
            queryParameters['selectedDemandForecast'] = requestParameters.selectedDemandForecast;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/skus/{janCode}/shipment-plan`.replace(`{${"janCode"}}`, encodeURIComponent(String(requestParameters.janCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInventoryUnitShipmentPlanCollectiveResponseFromJSON(jsonValue));
    }

    /**
     * 出荷予定数の情報(一括補正)
     */
    async getInventoryUnitShipmentPlanCollective(requestParameters: GetInventoryUnitShipmentPlanCollectiveRequest): Promise<GetInventoryUnitShipmentPlanCollectiveResponse> {
        const response = await this.getInventoryUnitShipmentPlanCollectiveRaw(requestParameters);
        return await response.value();
    }

    /**
     * コンテナの輸送計画に紐づくPO案やSKUxInventoryUnitの情報(先行発注)
     */
    async getLongTermContainerPlanRaw(requestParameters: GetLongTermContainerPlanRequest): Promise<runtime.ApiResponse<GetLongTermContainerPlanResponse>> {
        if (requestParameters.containerPlanId === null || requestParameters.containerPlanId === undefined) {
            throw new runtime.RequiredError('containerPlanId','Required parameter requestParameters.containerPlanId was null or undefined when calling getLongTermContainerPlan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/long-term-container-plans/{ContainerPlanId}`.replace(`{${"ContainerPlanId"}}`, encodeURIComponent(String(requestParameters.containerPlanId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLongTermContainerPlanResponseFromJSON(jsonValue));
    }

    /**
     * コンテナの輸送計画に紐づくPO案やSKUxInventoryUnitの情報(先行発注)
     */
    async getLongTermContainerPlan(requestParameters: GetLongTermContainerPlanRequest): Promise<GetLongTermContainerPlanResponse> {
        const response = await this.getLongTermContainerPlanRaw(requestParameters);
        return await response.value();
    }

    /**
     * 発注グループに紐づくコンテナの輸送計画一覧を取得する(先行発注)
     */
    async getLongTermContainerPlansRaw(requestParameters: GetLongTermContainerPlansRequest): Promise<runtime.ApiResponse<GetLongTermContainerPlansResponse>> {
        if (requestParameters.orderGroupId === null || requestParameters.orderGroupId === undefined) {
            throw new runtime.RequiredError('orderGroupId','Required parameter requestParameters.orderGroupId was null or undefined when calling getLongTermContainerPlans.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/order-groups/{OrderGroupId}/long-term-container-plans`.replace(`{${"OrderGroupId"}}`, encodeURIComponent(String(requestParameters.orderGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLongTermContainerPlansResponseFromJSON(jsonValue));
    }

    /**
     * 発注グループに紐づくコンテナの輸送計画一覧を取得する(先行発注)
     */
    async getLongTermContainerPlans(requestParameters: GetLongTermContainerPlansRequest): Promise<GetLongTermContainerPlansResponse> {
        const response = await this.getLongTermContainerPlansRaw(requestParameters);
        return await response.value();
    }

    /**
     * 発注案のExcelダウンロード(先行発注)
     */
    async getLongTermDraftPurchaseOrderExcelRaw(requestParameters: GetLongTermDraftPurchaseOrderExcelRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.orderGroupId === null || requestParameters.orderGroupId === undefined) {
            throw new runtime.RequiredError('orderGroupId','Required parameter requestParameters.orderGroupId was null or undefined when calling getLongTermDraftPurchaseOrderExcel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/order-groups/{OrderGroupId}/long-term-draft-purchase-order/excel`.replace(`{${"OrderGroupId"}}`, encodeURIComponent(String(requestParameters.orderGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * 発注案のExcelダウンロード(先行発注)
     */
    async getLongTermDraftPurchaseOrderExcel(requestParameters: GetLongTermDraftPurchaseOrderExcelRequest): Promise<Blob> {
        const response = await this.getLongTermDraftPurchaseOrderExcelRaw(requestParameters);
        return await response.value();
    }

    /**
     * InventoryUnitマスタのExcelダウンロード
     */
    async getMasterOfInventoryUnitsExcelRaw(): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/master-of-inventory-units/excel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * InventoryUnitマスタのExcelダウンロード
     */
    async getMasterOfInventoryUnitsExcel(): Promise<Blob> {
        const response = await this.getMasterOfInventoryUnitsExcelRaw();
        return await response.value();
    }

    /**
     * SKUマスタのExcelダウンロード
     */
    async getMasterOfSKUExcelRaw(): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/skus/excel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * SKUマスタのExcelダウンロード
     */
    async getMasterOfSKUExcel(): Promise<Blob> {
        const response = await this.getMasterOfSKUExcelRaw();
        return await response.value();
    }

    /**
     * 店舗マスタのExcelダウンロード
     */
    async getMasterOfStoresExcelRaw(): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/master-of-stores/excel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * 店舗マスタのExcelダウンロード
     */
    async getMasterOfStoresExcel(): Promise<Blob> {
        const response = await this.getMasterOfStoresExcelRaw();
        return await response.value();
    }

    /**
     * MOQ管理グループ一覧のCSV
     */
    async getMoqGroupsCsvRaw(): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/moq-groups/csv`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * MOQ管理グループ一覧のCSV
     */
    async getMoqGroupsCsv(): Promise<Blob> {
        const response = await this.getMoqGroupsCsvRaw();
        return await response.value();
    }

    /**
     * 在庫計画表ダウンロード
     */
    async getOrderGroupCsvFileRaw(requestParameters: GetOrderGroupCsvFileRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.orderGroupId === null || requestParameters.orderGroupId === undefined) {
            throw new runtime.RequiredError('orderGroupId','Required parameter requestParameters.orderGroupId was null or undefined when calling getOrderGroupCsvFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/order-groups/{OrderGroupId}/csv-file`.replace(`{${"OrderGroupId"}}`, encodeURIComponent(String(requestParameters.orderGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * 在庫計画表ダウンロード
     */
    async getOrderGroupCsvFile(requestParameters: GetOrderGroupCsvFileRequest): Promise<Blob> {
        const response = await this.getOrderGroupCsvFileRaw(requestParameters);
        return await response.value();
    }

    /**
     * 発注グループ一覧を取得する
     */
    async getOrderGroupsRaw(): Promise<runtime.ApiResponse<Array<OrderGroupsItem>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/order-groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderGroupsItemFromJSON));
    }

    /**
     * 発注グループ一覧を取得する
     */
    async getOrderGroups(): Promise<Array<OrderGroupsItem>> {
        const response = await this.getOrderGroupsRaw();
        return await response.value();
    }

    /**
     * 特売・積み増し（追加の出荷予定）情報の一覧
     */
    async getShipmentReasonsRaw(): Promise<runtime.ApiResponse<GetShipmentReasonsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/shipment-reasons`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetShipmentReasonsResponseFromJSON(jsonValue));
    }

    /**
     * 特売・積み増し（追加の出荷予定）情報の一覧
     */
    async getShipmentReasons(): Promise<GetShipmentReasonsResponse> {
        const response = await this.getShipmentReasonsRaw();
        return await response.value();
    }

    /**
     * 特売・積み増し（追加の出荷予定）情報のExcelファイル
     */
    async getShipmentReasonsExcelRaw(): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/shipment-reasons/excel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * 特売・積み増し（追加の出荷予定）情報のExcelファイル
     */
    async getShipmentReasonsExcel(): Promise<Blob> {
        const response = await this.getShipmentReasonsExcelRaw();
        return await response.value();
    }

    /**
     * SKU一覧
     */
    async getSkusRaw(): Promise<runtime.ApiResponse<GetSKUsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/skus`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSKUsResponseFromJSON(jsonValue));
    }

    /**
     * SKU一覧
     */
    async getSkus(): Promise<GetSKUsResponse> {
        const response = await this.getSkusRaw();
        return await response.value();
    }

    /**
     * SKU一覧のExcelダウンロード
     */
    async getSkusExcelRaw(): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/skus/excel-list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * SKU一覧のExcelダウンロード
     */
    async getSkusExcel(): Promise<Blob> {
        const response = await this.getSkusExcelRaw();
        return await response.value();
    }

    /**
     * ログインユーザのアカウント情報を取得する
     */
    async getUserRaw(): Promise<runtime.ApiResponse<User>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * ログインユーザのアカウント情報を取得する
     */
    async getUser(): Promise<User> {
        const response = await this.getUserRaw();
        return await response.value();
    }

    /**
     * ユーザーの情報を取得する
     */
    async getUserByIDRaw(requestParameters: GetUserByIDRequest): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUserByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * ユーザーの情報を取得する
     */
    async getUserByID(requestParameters: GetUserByIDRequest): Promise<User> {
        const response = await this.getUserByIDRaw(requestParameters);
        return await response.value();
    }

    /**
     * ユーザー一覧
     */
    async getUsersRaw(): Promise<runtime.ApiResponse<GetUsersResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUsersResponseFromJSON(jsonValue));
    }

    /**
     * ユーザー一覧
     */
    async getUsers(): Promise<GetUsersResponse> {
        const response = await this.getUsersRaw();
        return await response.value();
    }

    /**
     * ユーザーを新規作成
     */
    async postUsersRaw(requestParameters: PostUsersOperationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.bodyParam === null || requestParameters.bodyParam === undefined) {
            throw new runtime.RequiredError('bodyParam','Required parameter requestParameters.bodyParam was null or undefined when calling postUsers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostUsersRequestToJSON(requestParameters.bodyParam),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * ユーザーを新規作成
     */
    async postUsers(requestParameters: PostUsersOperationRequest): Promise<void> {
        await this.postUsersRaw(requestParameters);
    }

    /**
     * 発注グループ一覧のCSVアップロード
     */
    async putDatabaseOrderGroupsCsvRaw(requestParameters: PutDatabaseOrderGroupsCsvRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uploadedCsvFile === null || requestParameters.uploadedCsvFile === undefined) {
            throw new runtime.RequiredError('uploadedCsvFile','Required parameter requestParameters.uploadedCsvFile was null or undefined when calling putDatabaseOrderGroupsCsv.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.uploadedCsvFile !== undefined) {
            formParams.append('uploadedCsvFile', requestParameters.uploadedCsvFile as any);
        }

        const response = await this.request({
            path: `/database-order-groups/csv`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 発注グループ一覧のCSVアップロード
     */
    async putDatabaseOrderGroupsCsv(requestParameters: PutDatabaseOrderGroupsCsvRequest): Promise<void> {
        await this.putDatabaseOrderGroupsCsvRaw(requestParameters);
    }

    /**
     * 補正登録情報を保存
     */
    async putInventoryUnitShipmentPlanRaw(requestParameters: PutInventoryUnitShipmentPlanOperationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.inventoryUnitCode === null || requestParameters.inventoryUnitCode === undefined) {
            throw new runtime.RequiredError('inventoryUnitCode','Required parameter requestParameters.inventoryUnitCode was null or undefined when calling putInventoryUnitShipmentPlan.');
        }

        if (requestParameters.janCode === null || requestParameters.janCode === undefined) {
            throw new runtime.RequiredError('janCode','Required parameter requestParameters.janCode was null or undefined when calling putInventoryUnitShipmentPlan.');
        }

        if (requestParameters.bodyParam === null || requestParameters.bodyParam === undefined) {
            throw new runtime.RequiredError('bodyParam','Required parameter requestParameters.bodyParam was null or undefined when calling putInventoryUnitShipmentPlan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/inventory-units/{inventoryUnitCode}/skus/{janCode}/inventory-unit-shipment-plan`.replace(`{${"inventoryUnitCode"}}`, encodeURIComponent(String(requestParameters.inventoryUnitCode))).replace(`{${"janCode"}}`, encodeURIComponent(String(requestParameters.janCode))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutInventoryUnitShipmentPlanRequestToJSON(requestParameters.bodyParam),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 補正登録情報を保存
     */
    async putInventoryUnitShipmentPlan(requestParameters: PutInventoryUnitShipmentPlanOperationRequest): Promise<void> {
        await this.putInventoryUnitShipmentPlanRaw(requestParameters);
    }

    /**
     * 補正登録情報を保存
     */
    async putInventoryUnitShipmentPlanCollectiveRaw(requestParameters: PutInventoryUnitShipmentPlanCollectiveOperationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.janCode === null || requestParameters.janCode === undefined) {
            throw new runtime.RequiredError('janCode','Required parameter requestParameters.janCode was null or undefined when calling putInventoryUnitShipmentPlanCollective.');
        }

        if (requestParameters.bodyParam === null || requestParameters.bodyParam === undefined) {
            throw new runtime.RequiredError('bodyParam','Required parameter requestParameters.bodyParam was null or undefined when calling putInventoryUnitShipmentPlanCollective.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/skus/{janCode}/shipment-plan`.replace(`{${"janCode"}}`, encodeURIComponent(String(requestParameters.janCode))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutInventoryUnitShipmentPlanCollectiveRequestToJSON(requestParameters.bodyParam),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 補正登録情報を保存
     */
    async putInventoryUnitShipmentPlanCollective(requestParameters: PutInventoryUnitShipmentPlanCollectiveOperationRequest): Promise<void> {
        await this.putInventoryUnitShipmentPlanCollectiveRaw(requestParameters);
    }

    /**
     * InventoryUnitマスタのExcelアップロード
     */
    async putMasterOfInventoryUnitsExcelRaw(requestParameters: PutMasterOfInventoryUnitsExcelRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uploadedExcelFile === null || requestParameters.uploadedExcelFile === undefined) {
            throw new runtime.RequiredError('uploadedExcelFile','Required parameter requestParameters.uploadedExcelFile was null or undefined when calling putMasterOfInventoryUnitsExcel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.uploadedExcelFile !== undefined) {
            formParams.append('uploadedExcelFile', requestParameters.uploadedExcelFile as any);
        }

        const response = await this.request({
            path: `/master-of-inventory-units/excel`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * InventoryUnitマスタのExcelアップロード
     */
    async putMasterOfInventoryUnitsExcel(requestParameters: PutMasterOfInventoryUnitsExcelRequest): Promise<void> {
        await this.putMasterOfInventoryUnitsExcelRaw(requestParameters);
    }

    /**
     * SKUマスタのExcelアップロード
     */
    async putMasterOfSKUsExcelRaw(requestParameters: PutMasterOfSKUsExcelRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uploadedExcelFile === null || requestParameters.uploadedExcelFile === undefined) {
            throw new runtime.RequiredError('uploadedExcelFile','Required parameter requestParameters.uploadedExcelFile was null or undefined when calling putMasterOfSKUsExcel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.uploadedExcelFile !== undefined) {
            formParams.append('uploadedExcelFile', requestParameters.uploadedExcelFile as any);
        }

        const response = await this.request({
            path: `/skus/excel`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * SKUマスタのExcelアップロード
     */
    async putMasterOfSKUsExcel(requestParameters: PutMasterOfSKUsExcelRequest): Promise<void> {
        await this.putMasterOfSKUsExcelRaw(requestParameters);
    }

    /**
     * 店舗マスタのExcelアップロード
     */
    async putMasterOfStoresExcelRaw(requestParameters: PutMasterOfStoresExcelRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uploadedExcelFile === null || requestParameters.uploadedExcelFile === undefined) {
            throw new runtime.RequiredError('uploadedExcelFile','Required parameter requestParameters.uploadedExcelFile was null or undefined when calling putMasterOfStoresExcel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.uploadedExcelFile !== undefined) {
            formParams.append('uploadedExcelFile', requestParameters.uploadedExcelFile as any);
        }

        const response = await this.request({
            path: `/master-of-stores/excel`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 店舗マスタのExcelアップロード
     */
    async putMasterOfStoresExcel(requestParameters: PutMasterOfStoresExcelRequest): Promise<void> {
        await this.putMasterOfStoresExcelRaw(requestParameters);
    }

    /**
     * MOQ管理グループ一覧のCSVアップロード
     */
    async putMoqGroupsCsvRaw(requestParameters: PutMoqGroupsCsvRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uploadedCsvFile === null || requestParameters.uploadedCsvFile === undefined) {
            throw new runtime.RequiredError('uploadedCsvFile','Required parameter requestParameters.uploadedCsvFile was null or undefined when calling putMoqGroupsCsv.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.uploadedCsvFile !== undefined) {
            formParams.append('uploadedCsvFile', requestParameters.uploadedCsvFile as any);
        }

        const response = await this.request({
            path: `/moq-groups/csv`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * MOQ管理グループ一覧のCSVアップロード
     */
    async putMoqGroupsCsv(requestParameters: PutMoqGroupsCsvRequest): Promise<void> {
        await this.putMoqGroupsCsvRaw(requestParameters);
    }

    /**
     * シミュレーション値更新
     */
    async putOrderGroupSimulationParametersRaw(requestParameters: PutOrderGroupSimulationParametersRequest): Promise<runtime.ApiResponse<PutSimulationParametersResponse>> {
        if (requestParameters.orderGroupId === null || requestParameters.orderGroupId === undefined) {
            throw new runtime.RequiredError('orderGroupId','Required parameter requestParameters.orderGroupId was null or undefined when calling putOrderGroupSimulationParameters.');
        }

        if (requestParameters.bodyParam === null || requestParameters.bodyParam === undefined) {
            throw new runtime.RequiredError('bodyParam','Required parameter requestParameters.bodyParam was null or undefined when calling putOrderGroupSimulationParameters.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/order-groups/{OrderGroupId}/simulation-parameters`.replace(`{${"OrderGroupId"}}`, encodeURIComponent(String(requestParameters.orderGroupId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutSimulationParametersRequestToJSON(requestParameters.bodyParam),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PutSimulationParametersResponseFromJSON(jsonValue));
    }

    /**
     * シミュレーション値更新
     */
    async putOrderGroupSimulationParameters(requestParameters: PutOrderGroupSimulationParametersRequest): Promise<PutSimulationParametersResponse> {
        const response = await this.putOrderGroupSimulationParametersRaw(requestParameters);
        return await response.value();
    }

    /**
     * 特売・積み増し（追加の出荷予定）情報のExcelアップロード
     */
    async putShipmentReasonsExcelRaw(requestParameters: PutShipmentReasonsExcelRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uploadedExcelFile === null || requestParameters.uploadedExcelFile === undefined) {
            throw new runtime.RequiredError('uploadedExcelFile','Required parameter requestParameters.uploadedExcelFile was null or undefined when calling putShipmentReasonsExcel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.uploadedExcelFile !== undefined) {
            formParams.append('uploadedExcelFile', requestParameters.uploadedExcelFile as any);
        }

        const response = await this.request({
            path: `/shipment-reasons/excel`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 特売・積み増し（追加の出荷予定）情報のExcelアップロード
     */
    async putShipmentReasonsExcel(requestParameters: PutShipmentReasonsExcelRequest): Promise<void> {
        await this.putShipmentReasonsExcelRaw(requestParameters);
    }

    /**
     * ユーザーを更新
     */
    async putUserRaw(requestParameters: PutUserOperationRequest): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling putUser.');
        }

        if (requestParameters.bodyParam === null || requestParameters.bodyParam === undefined) {
            throw new runtime.RequiredError('bodyParam','Required parameter requestParameters.bodyParam was null or undefined when calling putUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutUserRequestToJSON(requestParameters.bodyParam),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * ユーザーを更新
     */
    async putUser(requestParameters: PutUserOperationRequest): Promise<User> {
        const response = await this.putUserRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetInventoryUnitShipmentPlanAdjustmentTypeEnum {
    NoAdjustment = 'noAdjustment',
    AchievementRateIn4Weeks = 'achievementRateIn4Weeks',
    AchievementRateIn13Weeks = 'achievementRateIn13Weeks',
    AverageSalesIn4Weeks = 'averageSalesIn4Weeks',
    AverageSalesIn13Weeks = 'averageSalesIn13Weeks',
    YearOverYearIn4Weeks = 'yearOverYearIn4Weeks',
    YearOverYearIn13Weeks = 'yearOverYearIn13Weeks',
    CustomRate = 'customRate'
}
/**
    * @export
    * @enum {string}
    */
export enum GetInventoryUnitShipmentPlanAdjustmentRangeEnum {
    All = 'all',
    InSomeWeeks = 'inSomeWeeks'
}
/**
    * @export
    * @enum {string}
    */
export enum GetInventoryUnitShipmentPlanSelectedDemandForecastEnum {
    DemandInsight = 'demandInsight',
    SalesComposition = 'salesComposition'
}
/**
    * @export
    * @enum {string}
    */
export enum GetInventoryUnitShipmentPlanCollectiveAdjustmentTypeEnum {
    NoAdjustment = 'noAdjustment',
    AchievementRateIn4Weeks = 'achievementRateIn4Weeks',
    AchievementRateIn13Weeks = 'achievementRateIn13Weeks',
    AverageSalesIn4Weeks = 'averageSalesIn4Weeks',
    AverageSalesIn13Weeks = 'averageSalesIn13Weeks',
    YearOverYearIn4Weeks = 'yearOverYearIn4Weeks',
    YearOverYearIn13Weeks = 'yearOverYearIn13Weeks',
    CustomRate = 'customRate'
}
/**
    * @export
    * @enum {string}
    */
export enum GetInventoryUnitShipmentPlanCollectiveAdjustmentRangeEnum {
    All = 'all',
    InSomeWeeks = 'inSomeWeeks'
}
/**
    * @export
    * @enum {string}
    */
export enum GetInventoryUnitShipmentPlanCollectiveSelectedDemandForecastEnum {
    DemandInsight = 'demandInsight',
    SalesComposition = 'salesComposition'
}
