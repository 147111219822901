/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for OMR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SKUTotalInventoryPlanPerWeek,
    SKUTotalInventoryPlanPerWeekFromJSON,
    SKUTotalInventoryPlanPerWeekFromJSONTyped,
    SKUTotalInventoryPlanPerWeekToJSON,
} from './';

/**
 * SKUごとの予測値の合計
 * @export
 * @interface SKUTotal
 */
export interface SKUTotal {
    /**
     * SKUのコード
     * @type {string}
     * @memberof SKUTotal
     */
    janCode: string;
    /**
     * 
     * @type {{ [key: string]: SKUTotalInventoryPlanPerWeek; }}
     * @memberof SKUTotal
     */
    inventoryCalendar: { [key: string]: SKUTotalInventoryPlanPerWeek; };
    /**
     * ヤード残数
     * @type {number}
     * @memberof SKUTotal
     */
    yardQuantity: number;
}

export function SKUTotalFromJSON(json: any): SKUTotal {
    return SKUTotalFromJSONTyped(json, false);
}

export function SKUTotalFromJSONTyped(json: any, ignoreDiscriminator: boolean): SKUTotal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'janCode': json['janCode'],
        'inventoryCalendar': (mapValues(json['inventoryCalendar'], SKUTotalInventoryPlanPerWeekFromJSON)),
        'yardQuantity': json['yardQuantity'],
    };
}

export function SKUTotalToJSON(value?: SKUTotal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'janCode': value.janCode,
        'inventoryCalendar': (mapValues(value.inventoryCalendar, SKUTotalInventoryPlanPerWeekToJSON)),
        'yardQuantity': value.yardQuantity,
    };
}


