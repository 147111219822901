import styled from 'styled-components'

const BaseShipmentReasonsTable = styled.table`
  border: solid 1px var(--di-table-border-color);
  width: 100%;
`

const ShipmentReasonsTableBodyRow = styled.tr`
  background-color: #fff;
  border-top-color: var(--di-table-border-color);
  border-top-style: solid;
  border-top-width: 1px;
  transition: background-color 0.3s 0s ease;

  &:hover {
    background-color: var(--di-table-body-bg-hover-color);
  }
`

const ShipmentReasonsTableData = styled.td`
  height: 40px;
  padding-left: 30px;
`

const ShipmentReasonsTableHeader = styled.th<{width?: number}>`
  border-bottom-color: var(--di-table-border-color);
  border-bottom-style: solid;
  border-bottom-width: 2px;
  height: 40px;
  padding-left: 30px;
  text-align: left;
  width: ${props => props.width != null? `${props.width}px` : 'auto'}
`

export {
  BaseShipmentReasonsTable,
  ShipmentReasonsTableBodyRow,
  ShipmentReasonsTableData,
  ShipmentReasonsTableHeader,
}
