import styled from 'styled-components'

const Container = styled.div`
  background-color: #fff;
`

const Label = styled.div`
  color: #767676;
`

const Property = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 5px;
`

const Value = styled.div`
  color: #767676;
  margin-right: 30px;
`

export {
  Container,
  Label,
  Property,
  Value,
}
