import styled from 'styled-components'

import { ReactComponent as BaseCaretIcon } from '../../../assets/images/caret-down.svg'

const CaretIcon = styled(BaseCaretIcon)<{expanded : string}>`
  margin-left: auto;
  margin-right: 14px;
  transform: ${props => props.expanded === 'true'? 'scaleY(-1)' : 'none' };
  & path {
    fill: none;
    stroke: #707070;
    stroke-width: 2px;
  }
`

const Container = styled.div`
  position: relative;
`

const ErrorMessage = styled.span`
  color: var(--di-text-error-color);
`

const OptionContainer = styled.div<{width: number}>`
  background-color: #fff;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  transition: all 0.3s ease-out;
  width: ${props => `${props.width}px`};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover {
    background-color: #fafafa;
  }
`

const OptionLabel = styled.span`
  margin-left: 20px;
`

const OptionsContainer = styled.div<{width: number}>`
  box-shadow: 0 3px 6px #00000029;
  position: absolute;
  top: 43px;
  width: ${props => `${props.width}px`};
  z-index: 2;
`

const SelectedOptionContainer = styled.div<{width: number, disabled: boolean, hasError: boolean, highlight: boolean}>`
  align-items: center;
  background-color: ${props => props.disabled === true? '#fbfafa' : props.highlight === true? '#fffbd9' : '#fff'};
  border-color: ${props => props.hasError === true? '#ff0000' : '#ccc'};
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: flex;
  height: 40px;
  pointer-events: ${props => props.disabled === true? 'none' : 'auto'};
  transition: all 0.3s ease-out;
  width: ${props => `${props.width}px`};
  &:hover {
    border-color: ${props => props.hasError === true? '#ff0000' : props.disabled === true? '#ccc' : 'var(--di-primary-color)'};
  }
`

const SelectedOptionLabel = styled.span<{width: number}>`
  margin-left: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: ${props => `${props.width}px`};
`

export {
  CaretIcon,
  Container,
  ErrorMessage,
  OptionContainer,
  OptionLabel,
  OptionsContainer,
  SelectedOptionContainer,
  SelectedOptionLabel,
}
