import React from 'react'

import { BaseRoundLabel } from './style'

type RoundLabelColor = 'red' | 'yellow' | 'green'

export interface RoundLabelProps {
  color: RoundLabelColor
  text: string
  width: number
}

export const RoundLabel: React.FC<RoundLabelProps> = ({ color, text, width }) => (
  <BaseRoundLabel color={color} width={width}>
    {text}
  </BaseRoundLabel>
)
