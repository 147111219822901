import styled from 'styled-components'

import { ReactComponent as BaseCancelIcon } from '../../../assets/images/cancel.svg'

const Background = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 30px;
  padding-right: 25px;
  margin-top: 20px;
`

const CancelIcon = styled(BaseCancelIcon)`
  height: 18px;
  width: 18px;
`

const CancelIconContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  padding-right: 25px;
  margin-bottom: 7px;
  margin-top: 25px;
`

const Container = styled.div`
  background-color: #fff;
  min-height: 141px;
  padding-left: 57px;
  text-align: left;
  width: 615px;
`

const ListsContainer = styled.div`
  margin-right: 25px;
`

const Title = styled.div`
  margin-bottom: 20px;
`

const Window = styled.div`
  background-color: #fff;
  position: relative;
`

export {
  Background,
  ButtonContainer,
  CancelIcon,
  CancelIconContainer,
  Container,
  ListsContainer,
  Title,
  Window,
}
