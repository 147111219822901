import styled from 'styled-components'

const BaseContainersListTable = styled.table`
  border: none;
  width: 100%;
`

const ContainersListTableBodyRow = styled.tr<{selected: boolean, backGroundColor: string, name: string}>`
  name: ${props => props.name};
  background-color: ${props => props.selected === true? '#f7f7f7' : props.backGroundColor};
  border-bottom: solid 1px var(--di-table-border-color);
  height: 56px;
  transition: background-color 0.3s ease-out;

  &:hover {
    background-color: ${props => props.selected === true? '#f7f7f7' : 'var(--di-table-body-bg-hover-color)'};
  }
`

const ContainersListTableData = styled.td`
  padding-left: 11px;
`

const ContainersListTableHeader = styled.th<{width: number}>`
  background-color: #f1f1f1;
  height: 47px;
  padding-left: 11px;
  text-align: left;
  width: ${props => `${props.width}px`};
`

const DistributionCenterName = styled.span`
  display: inline-block;
  width: 120px;
  word-break: break-all;
`

const RatioRectangleInnerNewData = styled.span<{width: number}>`
  background-color: #8cb0e1;
  border-radius: 5px;
  display: inline-block;
  height: 37px;
  width: ${props => `${props.width}%`};
`

const RatioRectangleInnerPastData = styled.span<{width: number}>`
  background-color: #C7C7C8;
  border-radius: 5px;
  display: inline-block;
  height: 37px;
  width: ${props => `${props.width}%`};
`

const RatioRectangleOuter = styled.span`
  align-items: center;
  border: 1px solid #cecece;
  border-radius: 5px;
  display: flex;
  height: 40px;
  margin-right: 13px;
  width: 120px;
`

const UsageContainer = styled.div`
  align-items: center;
  display: flex;
  width: 235px;
`


export {
  BaseContainersListTable,
  ContainersListTableBodyRow,
  ContainersListTableData,
  ContainersListTableHeader,
  DistributionCenterName,
  RatioRectangleInnerNewData,
  RatioRectangleInnerPastData,
  RatioRectangleOuter,
  UsageContainer,
}
