import styled from 'styled-components'

const DescriptionContainer = styled.div`
  margin-top: 16px;
  text-align: center;
`

const LogoContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 339px;
`

const Title = styled.h2`
  font-weight: bold;
  margin-top: 39px;
  text-align: center;
`

export {
  DescriptionContainer,
  LogoContainer,
  Title,
}
