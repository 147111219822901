import styled from 'styled-components'

const ButtonContainer = styled.div`
  margin-left: auto;
  margin-right: 0;
`

const ContentsContainer = styled.div`
  margin-left: 32px;
  margin-top: 32px;
`

const Description = styled.div`
  margin-bottom: 24px;
`

const DragAndDropArea = styled.div`
  align-items: center;
  border-color: #8e8e8e;
  border-style: dashed;
  border-width: 1px;
  display: flex;
  height: 185px;
  justify-content: center;
`

const DragAndDropDescription = styled.div`
   text-align: center;
`

const DragAndDropInput = styled.input`
  display: none;
`

const DragAndDropLink = styled.label`
  color: var(--di-text-link-color);
  cursor: pointer;
`

const LoadingSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 28px;
  margin-top: 50px;
`

const NotificationFieldContainer = styled.div`
  margin-top: 32px;
`

const Panel = styled.div`
  background-color: #fff;
  padding-bottom: 32px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 32px;
`

const PanelContainer = styled.div`
  margin: 0 24px 59px 0;
  width: calc(33% - 24px);
`

const PanelTitle = styled.span`
  font-size: 22px;
`

const PanelTitleContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
`

const PanelsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export {
  ButtonContainer,
  ContentsContainer,
  Description,
  DragAndDropArea,
  DragAndDropDescription,
  DragAndDropInput,
  DragAndDropLink,
  LoadingSpinnerContainer,
  NotificationFieldContainer,
  Panel,
  PanelContainer,
  PanelTitle,
  PanelTitleContainer,
  PanelsContainer,
}
