import styled from 'styled-components'

const DetailLinkContainer = styled.div`
  margin-left: 36px;
  margin-top: 16px;
`

const EmailContainer = styled.div`
  margin-left: 36px;
  margin-top: 4px;
`

const LogoutButton = styled.div`
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 36px;
  justify-content: center;
  margin-top: 8px;
  transition: background-color 0.3s 0s ease;
  width: 100%;

  &:hover {
    background-color: #e3e3e3;
  }
`

const LogoutButtonContainer = styled.div`
  border-color: #ededed;
  border-style: solid;
  border-width: 1px 0 0 0;
  letter-spacing: 1.25px;
  margin-top: 22px;
`

const Name = styled.b`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 120px;
`

const NameContainer = styled.div`
  display: flex;
  margin-left: 36px;
`

const UserMenuContainer = styled.div`
  background-color: #fff;
  box-shadow: 0 5px 10px 1px rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%);
  border-radius: 4px;
  height: 158px;
  padding-top: 34px;
  width: 280px;
`

export {
  DetailLinkContainer,
  EmailContainer,
  LogoutButton,
  LogoutButtonContainer,
  Name,
  NameContainer,
  UserMenuContainer,
}
