import styled from 'styled-components'

const BaseInventoryCalendarTable = styled.table`
  border-bottom: solid 1px var(--di-table-border-color);
  border-right: solid 1px var(--di-table-border-color);
  border-top: solid 1px var(--di-table-border-color);
  table-layout: fixed;
  white-space: nowrap;
  width: ${props => `${props.width}px`};
`

const InventoryCalendarTableBody = styled.td<{
  isbackgroundred: boolean,
  isboldbottom: boolean,
  ishighlight: boolean,
  isYellowBackground: boolean
}>`
  background-color: ${props => props.isbackgroundred? '#f8d9d9' : props.isYellowBackground? '#FFFFCC' : props.ishighlight? '#fafafa' : '#fff'};
  border-bottom-color: ${props => props.isboldbottom? '#707070' : 'var(--di-table-border-color)'};
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left: solid 1px var(--di-table-border-color);
  border-right: solid 1px var(--di-table-border-color);
  border-top: solid 1px var(--di-table-border-color);
  height: 25px;
  padding-bottom: 0;
  padding-right: 8px;
  padding-top: 0;
  text-align: right;

  &:first-child {
    background-color: #f1f1f1;
    border-left: none;
    left: 0;
    padding-left: 10px;
    position: sticky;
    text-align: left;
    z-index: 2;
  }

  &:first-child::before {
    border-right: solid 1px var(--di-table-border-color);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`

const InventoryCalendarTableContainer = styled.div`
  border-left: solid 1px var(--di-table-border-color);
  overflow-x: scroll;
`

const InventoryCalendarTableHeader = styled.th`
  background-color: #f1f1f1;
  border-bottom: solid 1px #707070;
  border-left: solid 1px var(--di-table-border-color);
  font-weight: normal;
  height: 30px;
  padding-bottom: 0;
  padding-right: 8px;
  padding-top: 0;
  text-align: right;
  width: 70px;

  &:first-child {
    border-left: none;
    left: 0;
    position: sticky;
    width: 180px;
  }

  &:first-child::before {
    border-right: solid 1px var(--di-table-border-color);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`

const TextBoxContainer = styled.div`
  position: relative;
`

const PONoDisplay = styled.span`
  font-size: 10px;
`

export {
  BaseInventoryCalendarTable,
  InventoryCalendarTableBody,
  InventoryCalendarTableContainer,
  InventoryCalendarTableHeader,
  TextBoxContainer,
  PONoDisplay,
}
