import styled from 'styled-components'

const FilterContainer = styled.div`
  margin-right: 16px;
`

const FilterLabel = styled.div`
  margin-bottom: 4px;
`

const FiltersContainer = styled.div`
  align-items: flex-end;
  background-color: #f1f1f1;
  display: flex;
  height: 82px;
  margin-left: 48px;
  margin-right: 48px;
  margin-top: 32px;
  padding-bottom: 18px;
  padding-left: 20px;
`

const LoadingSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`

const OrderGroupsCountLabel = styled.div`
  margin-bottom: 7px;
`

const OrderGroupsListContainer = styled.div`
  margin-left: 48px;
  margin-right: 48px;
  margin-top: 24px;
`

export {
  FilterContainer,
  FilterLabel,
  FiltersContainer,
  LoadingSpinnerContainer,
  OrderGroupsCountLabel,
  OrderGroupsListContainer,
}
