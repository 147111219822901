import React from 'react'

import { BaseRadioButton } from './style'

export interface RadioButtonProps {
  /**
   * いわゆるID. ユニットテスト時の指定に使う
   */
  id?: string

  /**
   * 選択されているかどうか
   */
  checked: boolean

  /**
   * 無効にするかどうか
   */
  disabled?: boolean

  /**
   * 選択された時に呼び出されるハンドラー
   */
  onChangeHandler: (value: boolean) => void
}

export const RadioButton: React.FC<RadioButtonProps> = ({ id = '', checked, disabled = false, onChangeHandler }) => (
  <BaseRadioButton
    checked={checked}
    disabled={disabled}
    onClick={() => {
      if (disabled === true) {
        // jsdom may not have pointer event feature.
        // Ref: https://github.com/jsdom/jsdom/issues/2527
        return
      }
      onChangeHandler(true)
    }}
    data-testid={id}
  />
)
