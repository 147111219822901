/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for OMR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DraftPurchaseOrderPlanningInventoryUnitReceiving,
    DraftPurchaseOrderPlanningInventoryUnitReceivingFromJSON,
    DraftPurchaseOrderPlanningInventoryUnitReceivingFromJSONTyped,
    DraftPurchaseOrderPlanningInventoryUnitReceivingToJSON,
    DraftPurchaseOrderPlanningUnconfirmedInventoryUnitReceiving,
    DraftPurchaseOrderPlanningUnconfirmedInventoryUnitReceivingFromJSON,
    DraftPurchaseOrderPlanningUnconfirmedInventoryUnitReceivingFromJSONTyped,
    DraftPurchaseOrderPlanningUnconfirmedInventoryUnitReceivingToJSON,
    DraftPurchaseOrderPlanningWeek,
    DraftPurchaseOrderPlanningWeekFromJSON,
    DraftPurchaseOrderPlanningWeekFromJSONTyped,
    DraftPurchaseOrderPlanningWeekToJSON,
    DraftPurchaseOrderUnreceivedOrder,
    DraftPurchaseOrderUnreceivedOrderFromJSON,
    DraftPurchaseOrderUnreceivedOrderFromJSONTyped,
    DraftPurchaseOrderUnreceivedOrderToJSON,
} from './';

/**
 * 
 * @export
 * @interface DraftPurchaseOrderPlanningInventoryPlanPerWeek
 */
export interface DraftPurchaseOrderPlanningInventoryPlanPerWeek {
    /**
     * 
     * @type {DraftPurchaseOrderPlanningWeek}
     * @memberof DraftPurchaseOrderPlanningInventoryPlanPerWeek
     */
    week: DraftPurchaseOrderPlanningWeek;
    /**
     * 予測在庫数
     * @type {number}
     * @memberof DraftPurchaseOrderPlanningInventoryPlanPerWeek
     */
    inventoryUnitInventoryQuantity: number;
    /**
     * InventoryUnit在庫切れか
     * @type {boolean}
     * @memberof DraftPurchaseOrderPlanningInventoryPlanPerWeek
     */
    isInventoryUnitInventoryDeficient: boolean;
    /**
     * 予測在庫週数
     * @type {number}
     * @memberof DraftPurchaseOrderPlanningInventoryPlanPerWeek
     */
    numberOfInventoryUnitInventoryWeeks: number | null;
    /**
     * 出荷予定数
     * @type {number}
     * @memberof DraftPurchaseOrderPlanningInventoryPlanPerWeek
     */
    inventoryUnitShipmentQuantity: number | null;
    /**
     * 入荷予定数
     * @type {Array<DraftPurchaseOrderPlanningInventoryUnitReceiving>}
     * @memberof DraftPurchaseOrderPlanningInventoryPlanPerWeek
     */
    inventoryUnitReceivings: Array<DraftPurchaseOrderPlanningInventoryUnitReceiving>;
    /**
     * 発注が確定していない入荷予定数
     * @type {Array<DraftPurchaseOrderPlanningUnconfirmedInventoryUnitReceiving>}
     * @memberof DraftPurchaseOrderPlanningInventoryPlanPerWeek
     */
    unconfirmedInventoryUnitReceivings: Array<DraftPurchaseOrderPlanningUnconfirmedInventoryUnitReceiving>;
    /**
     * 予測店舗在庫数
     * @type {number}
     * @memberof DraftPurchaseOrderPlanningInventoryPlanPerWeek
     */
    storeInventoryQuantity: number;
    /**
     * 店舗基準在庫未達か
     * @type {boolean}
     * @memberof DraftPurchaseOrderPlanningInventoryPlanPerWeek
     */
    isStoreInventoryStandardUnachieved: boolean;
    /**
     * 入荷残数
     * @type {Array<DraftPurchaseOrderUnreceivedOrder>}
     * @memberof DraftPurchaseOrderPlanningInventoryPlanPerWeek
     */
    unreceivedOrders: Array<DraftPurchaseOrderUnreceivedOrder>;
}

export function DraftPurchaseOrderPlanningInventoryPlanPerWeekFromJSON(json: any): DraftPurchaseOrderPlanningInventoryPlanPerWeek {
    return DraftPurchaseOrderPlanningInventoryPlanPerWeekFromJSONTyped(json, false);
}

export function DraftPurchaseOrderPlanningInventoryPlanPerWeekFromJSONTyped(json: any, ignoreDiscriminator: boolean): DraftPurchaseOrderPlanningInventoryPlanPerWeek {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'week': DraftPurchaseOrderPlanningWeekFromJSON(json['week']),
        'inventoryUnitInventoryQuantity': json['inventoryUnitInventoryQuantity'],
        'isInventoryUnitInventoryDeficient': json['isInventoryUnitInventoryDeficient'],
        'numberOfInventoryUnitInventoryWeeks': json['numberOfInventoryUnitInventoryWeeks'],
        'inventoryUnitShipmentQuantity': json['inventoryUnitShipmentQuantity'],
        'inventoryUnitReceivings': ((json['inventoryUnitReceivings'] as Array<any>).map(DraftPurchaseOrderPlanningInventoryUnitReceivingFromJSON)),
        'unconfirmedInventoryUnitReceivings': ((json['unconfirmedInventoryUnitReceivings'] as Array<any>).map(DraftPurchaseOrderPlanningUnconfirmedInventoryUnitReceivingFromJSON)),
        'storeInventoryQuantity': json['storeInventoryQuantity'],
        'isStoreInventoryStandardUnachieved': json['isStoreInventoryStandardUnachieved'],
        'unreceivedOrders': ((json['unreceivedOrders'] as Array<any>).map(DraftPurchaseOrderUnreceivedOrderFromJSON)),
    };
}

export function DraftPurchaseOrderPlanningInventoryPlanPerWeekToJSON(value?: DraftPurchaseOrderPlanningInventoryPlanPerWeek | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'week': DraftPurchaseOrderPlanningWeekToJSON(value.week),
        'inventoryUnitInventoryQuantity': value.inventoryUnitInventoryQuantity,
        'isInventoryUnitInventoryDeficient': value.isInventoryUnitInventoryDeficient,
        'numberOfInventoryUnitInventoryWeeks': value.numberOfInventoryUnitInventoryWeeks,
        'inventoryUnitShipmentQuantity': value.inventoryUnitShipmentQuantity,
        'inventoryUnitReceivings': ((value.inventoryUnitReceivings as Array<any>).map(DraftPurchaseOrderPlanningInventoryUnitReceivingToJSON)),
        'unconfirmedInventoryUnitReceivings': ((value.unconfirmedInventoryUnitReceivings as Array<any>).map(DraftPurchaseOrderPlanningUnconfirmedInventoryUnitReceivingToJSON)),
        'storeInventoryQuantity': value.storeInventoryQuantity,
        'isStoreInventoryStandardUnachieved': value.isStoreInventoryStandardUnachieved,
        'unreceivedOrders': ((value.unreceivedOrders as Array<any>).map(DraftPurchaseOrderUnreceivedOrderToJSON)),
    };
}


