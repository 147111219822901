import React, { useEffect, useRef, useState } from 'react'

import { AdjustmentRange, AdjustmentTarget, AdjustmentType } from '../../../api-client'

import {
  ADJUSTMENT_CUSTOM_RATE_DEFAULT,
  ADJUSTMENT_CUSTOM_RATE_MAX,
  ADJUSTMENT_CUSTOM_RATE_MIN,
  NUMBER_OF_WEEKS_TO_ADJUST_DEFAULT,
  NUMBER_OF_WEEKS_TO_ADJUST_MAX,
  NUMBER_OF_WEEKS_TO_ADJUST_MIN,
} from '../../../constants'

import { Button, RadioButton, TextBox, Tooltip } from '../../atoms'

import {
  AdjustmentButtonContainer,
  AdjustmentRangeLabel,
  Container,
  RadioButtonContainer,
  RadioButtonLabel,
  TextBoxContainer,
  Title,
} from './style'

export interface CollectiveAdjustmentFormProps {
  /**
   * 達成率直近4週の値
   */
  achievementRateIn4Weeks: number | null

  /**
   * 達成率直近13週の値
   */
  achievementRateIn13Weeks: number | null

  /**
   * 平均週販直近4週の値
   */
  averageSalesIn4Weeks: number | null

  /**
   * 平均週販直近13週の値
   */
  averageSalesIn13Weeks: number | null

  /**
   * 昨年対比直近4週の値
   */
  yearOverYearIn4Weeks: number | null

  /**
   * 昨年対比直近13週の値
   */
  yearOverYearIn13Weeks: number | null

  /**
   * 選択中の補正種別
   */
  currentAdjustmentType: AdjustmentType

  /**
   * 選択可能な補正種別
   * 指定されている場合のみ, 指定されていない補正種別への変更を無効化する.
   */
  selectableAdjustmentType: AdjustmentType | null

  /**
   * 指定中の指定比率
   */
  currentAdjustmentCustomRate: number

  /**
   * 選択中の補正期間
   */
  currentAdjustmentRange: AdjustmentRange | null

  /**
   * 指定中の指定期間
   */
  currentNumberOfWeeksToAdjust: number

  /**
   * 読み取り専用にするかどうか
   */
  readonly: boolean

  /**
   * 達成率直近4週の範囲最小値
   */
  minAchievement4weeksRate: number | null

  /**
   * 達成率直近4週の範囲最大値
   */
  maxAchievement4weeksRate: number | null

  /**
   * 達成率直近13週の範囲最小値
   */
  minAchievement13weeksRate: number | null

  /**
   * 達成率直近13週の範囲最大値
   */
  maxAchievement13weeksRate: number | null

  /**
   * 平均週販直近4週の範囲最小値
   */
  minAverage4weeks: number | null

  /**
   * 平均週販直近4週の範囲最大値
   */
  maxAverage4weeks: number | null

  /**
   * 平均週販直近13週の範囲最小値
   */
  minAverage13weeks: number | null

  /**
   * 平均週販直近13週の範囲最大値
   */
  maxAverage13weeks: number | null

  /**
   * 昨年対比直近4週の範囲最小値
   */
  minLastYear4weeks: number | null

  /**
   * 昨年対比直近4週の範囲最大値
   */
  maxLastYear4weeks: number | null

  /**
   * 昨年対比直近13週の範囲最小値
   */
  minLastYear13weeks: number | null

  /**
   * 昨年対比直近13週の範囲最大値
   */
  maxLastYear13weeks: number | null

  /**
   * 達成率直近4週ラジオボタン制御
   */
  disableAchievement4weeks: boolean

  /**
   * 達成率直近13週ラジオボタン制御
   */
  disableAchievement13weeks: boolean

  /**
   * 平均週販直近4週ラジオボタン制御
   */
  disableAverage4weeks: boolean

  /**
   * 平均週販直近13週ラジオボタン制御
   */
  disableAverage13weeks: boolean

  /**
   * 昨年対比直近4週ラジオボタン制御
   */
  disableLastYear4weeks: boolean

  /**
   * 昨年対比直近13週ラジオボタン制御
   */
  disableLastYear13weeks: boolean

  /**
   * 需要予測の出力切り替え
   */
  selectedDemandForecast: AdjustmentTarget

  /**
   * 値が変更された時に呼び出されるハンドラー
   */
  onChangeHandler: (
    adjustmentType: AdjustmentType,
    adjustmentCustomRate: number,
    adjustmentRange: AdjustmentRange | null,
    numberOfWeeksToAdjust: number,
    selectedDemandForecast: AdjustmentTarget
  ) => void

  /**
   * 登録ボタンが押された時に呼び出されるハンドラー
   */
  onClickSaveButtonHandler: (
    adjustmentType: AdjustmentType,
    adjustmentCustomRate: number,
    adjustmentRange: AdjustmentRange | null,
    numberOfWeeksToAdjust: number,
    selectedDemandForecast: AdjustmentTarget
  ) => void
}

export const CollectiveAdjustmentForm: React.FC<CollectiveAdjustmentFormProps> = ({
  achievementRateIn4Weeks,
  achievementRateIn13Weeks,
  averageSalesIn4Weeks,
  averageSalesIn13Weeks,
  yearOverYearIn4Weeks,
  yearOverYearIn13Weeks,
  currentAdjustmentType,
  selectableAdjustmentType,
  currentAdjustmentCustomRate,
  currentAdjustmentRange,
  currentNumberOfWeeksToAdjust,
  readonly,
  minAchievement4weeksRate,
  maxAchievement4weeksRate,
  minAchievement13weeksRate,
  maxAchievement13weeksRate,
  minLastYear4weeks,
  maxLastYear4weeks,
  minLastYear13weeks,
  maxLastYear13weeks,
  disableAchievement4weeks,
  disableAchievement13weeks,
  disableAverage4weeks,
  disableAverage13weeks,
  disableLastYear4weeks,
  disableLastYear13weeks,
  onChangeHandler,
  onClickSaveButtonHandler,
  selectedDemandForecast,
}) => {
  const forceUpdate = useState(false)[1]

  const adjustmentCustomRate = useRef(currentAdjustmentCustomRate)
  const numberOfWeeksToAdjust = useRef(currentNumberOfWeeksToAdjust)

  useEffect(() => {
    adjustmentCustomRate.current = currentAdjustmentCustomRate
    numberOfWeeksToAdjust.current = currentNumberOfWeeksToAdjust
    forceUpdate((v) => !v)
  }, [
    currentAdjustmentType,
    currentAdjustmentCustomRate,
    currentAdjustmentRange,
    currentNumberOfWeeksToAdjust,
    forceUpdate,
  ])

  return (
    <Container>
      <Title>店舗出荷 需要予測数の補正</Title>
      <RadioButtonContainer disabled={readonly}>
        <RadioButton
          id="adjustment-form-radio-button-no-adjustment"
          checked={currentAdjustmentType === AdjustmentType.NoAdjustment}
          disabled={readonly}
          onChangeHandler={() =>
            onChangeHandler(
              AdjustmentType.NoAdjustment,
              adjustmentCustomRate.current < ADJUSTMENT_CUSTOM_RATE_MIN ||
                adjustmentCustomRate.current > ADJUSTMENT_CUSTOM_RATE_MAX
                ? ADJUSTMENT_CUSTOM_RATE_DEFAULT
                : adjustmentCustomRate.current,
              currentAdjustmentRange,
              numberOfWeeksToAdjust.current < NUMBER_OF_WEEKS_TO_ADJUST_MIN ||
                numberOfWeeksToAdjust.current > NUMBER_OF_WEEKS_TO_ADJUST_MAX
                ? NUMBER_OF_WEEKS_TO_ADJUST_DEFAULT
                : numberOfWeeksToAdjust.current,
              selectedDemandForecast
            )
          }
        />
        <RadioButtonLabel>補正なし</RadioButtonLabel>
      </RadioButtonContainer>
      <RadioButtonContainer
        disabled={
          disableAchievement4weeks ||
          (selectableAdjustmentType != null &&
            selectableAdjustmentType !== AdjustmentType.AchievementRateIn4Weeks &&
            currentAdjustmentType !== AdjustmentType.AchievementRateIn4Weeks)
        }
      >
        <RadioButton
          id="adjustment-form-radio-button-achievement-rate-in-4-weeks"
          checked={currentAdjustmentType === AdjustmentType.AchievementRateIn4Weeks}
          disabled={
            disableAchievement4weeks ||
            (selectableAdjustmentType != null &&
              selectableAdjustmentType !== AdjustmentType.AchievementRateIn4Weeks &&
              currentAdjustmentType !== AdjustmentType.AchievementRateIn4Weeks)
          }
          onChangeHandler={() =>
            onChangeHandler(
              AdjustmentType.AchievementRateIn4Weeks,
              adjustmentCustomRate.current < ADJUSTMENT_CUSTOM_RATE_MIN ||
                adjustmentCustomRate.current > ADJUSTMENT_CUSTOM_RATE_MAX
                ? ADJUSTMENT_CUSTOM_RATE_DEFAULT
                : adjustmentCustomRate.current,
              currentAdjustmentRange,
              numberOfWeeksToAdjust.current < NUMBER_OF_WEEKS_TO_ADJUST_MIN ||
                numberOfWeeksToAdjust.current > NUMBER_OF_WEEKS_TO_ADJUST_MAX
                ? NUMBER_OF_WEEKS_TO_ADJUST_DEFAULT
                : numberOfWeeksToAdjust.current,
              selectedDemandForecast
            )
          }
        />
        <RadioButtonLabel>達成率：直近4週</RadioButtonLabel>
        <b>
          {disableAchievement4weeks === false
            ? `${achievementRateIn4Weeks}%(${minAchievement4weeksRate}%～${maxAchievement4weeksRate}%)`
            : '-'}
        </b>
      </RadioButtonContainer>
      <RadioButtonContainer
        disabled={
          disableAchievement13weeks ||
          (selectableAdjustmentType != null &&
            selectableAdjustmentType !== AdjustmentType.AchievementRateIn13Weeks &&
            currentAdjustmentType !== AdjustmentType.AchievementRateIn13Weeks)
        }
      >
        <RadioButton
          id="adjustment-form-radio-button-achievement-rate-in-13-weeks"
          checked={currentAdjustmentType === AdjustmentType.AchievementRateIn13Weeks}
          disabled={
            disableAchievement13weeks ||
            (selectableAdjustmentType != null &&
              selectableAdjustmentType !== AdjustmentType.AchievementRateIn13Weeks &&
              currentAdjustmentType !== AdjustmentType.AchievementRateIn13Weeks)
          }
          onChangeHandler={() =>
            onChangeHandler(
              AdjustmentType.AchievementRateIn13Weeks,
              adjustmentCustomRate.current < ADJUSTMENT_CUSTOM_RATE_MIN ||
                adjustmentCustomRate.current > ADJUSTMENT_CUSTOM_RATE_MAX
                ? ADJUSTMENT_CUSTOM_RATE_DEFAULT
                : adjustmentCustomRate.current,
              currentAdjustmentRange,
              numberOfWeeksToAdjust.current < NUMBER_OF_WEEKS_TO_ADJUST_MIN ||
                numberOfWeeksToAdjust.current > NUMBER_OF_WEEKS_TO_ADJUST_MAX
                ? NUMBER_OF_WEEKS_TO_ADJUST_DEFAULT
                : numberOfWeeksToAdjust.current,
              selectedDemandForecast
            )
          }
        />
        <RadioButtonLabel>達成率：直近13週</RadioButtonLabel>
        <b>
          {disableAchievement13weeks === false
            ? `${achievementRateIn13Weeks}%(${minAchievement13weeksRate}%～${maxAchievement13weeksRate}%)`
            : '-'}
        </b>
      </RadioButtonContainer>
      <RadioButtonContainer
        disabled={
          disableAverage4weeks ||
          (selectableAdjustmentType != null &&
            selectableAdjustmentType !== AdjustmentType.AverageSalesIn4Weeks &&
            currentAdjustmentType !== AdjustmentType.AverageSalesIn4Weeks)
        }
      >
        <RadioButton
          id="adjustment-form-radio-button-average-sales-in-4-weeks"
          checked={currentAdjustmentType === AdjustmentType.AverageSalesIn4Weeks}
          disabled={
            disableAverage4weeks ||
            (selectableAdjustmentType != null &&
              selectableAdjustmentType !== AdjustmentType.AverageSalesIn4Weeks &&
              currentAdjustmentType !== AdjustmentType.AverageSalesIn4Weeks)
          }
          onChangeHandler={() =>
            onChangeHandler(
              AdjustmentType.AverageSalesIn4Weeks,
              adjustmentCustomRate.current < ADJUSTMENT_CUSTOM_RATE_MIN ||
                adjustmentCustomRate.current > ADJUSTMENT_CUSTOM_RATE_MAX
                ? ADJUSTMENT_CUSTOM_RATE_DEFAULT
                : adjustmentCustomRate.current,
              currentAdjustmentRange,
              numberOfWeeksToAdjust.current < NUMBER_OF_WEEKS_TO_ADJUST_MIN ||
                numberOfWeeksToAdjust.current > NUMBER_OF_WEEKS_TO_ADJUST_MAX
                ? NUMBER_OF_WEEKS_TO_ADJUST_DEFAULT
                : numberOfWeeksToAdjust.current,
              selectedDemandForecast
            )
          }
        />
        <RadioButtonLabel>平均週販：直近4週</RadioButtonLabel>
        <b>{disableAverage4weeks === false ? `${averageSalesIn4Weeks}` : '-'}</b>
      </RadioButtonContainer>
      <RadioButtonContainer
        disabled={
          disableAverage13weeks ||
          (selectableAdjustmentType != null &&
            selectableAdjustmentType !== AdjustmentType.AverageSalesIn13Weeks &&
            currentAdjustmentType !== AdjustmentType.AverageSalesIn13Weeks)
        }
      >
        <RadioButton
          id="adjustment-form-radio-button-average-sales-in-13-weeks"
          checked={currentAdjustmentType === AdjustmentType.AverageSalesIn13Weeks}
          disabled={
            disableAverage13weeks ||
            (selectableAdjustmentType != null &&
              selectableAdjustmentType !== AdjustmentType.AverageSalesIn13Weeks &&
              currentAdjustmentType !== AdjustmentType.AverageSalesIn13Weeks)
          }
          onChangeHandler={() =>
            onChangeHandler(
              AdjustmentType.AverageSalesIn13Weeks,
              adjustmentCustomRate.current < ADJUSTMENT_CUSTOM_RATE_MIN ||
                adjustmentCustomRate.current > ADJUSTMENT_CUSTOM_RATE_MAX
                ? ADJUSTMENT_CUSTOM_RATE_DEFAULT
                : adjustmentCustomRate.current,
              currentAdjustmentRange,
              numberOfWeeksToAdjust.current < NUMBER_OF_WEEKS_TO_ADJUST_MIN ||
                numberOfWeeksToAdjust.current > NUMBER_OF_WEEKS_TO_ADJUST_MAX
                ? NUMBER_OF_WEEKS_TO_ADJUST_DEFAULT
                : numberOfWeeksToAdjust.current,
              selectedDemandForecast
            )
          }
        />
        <RadioButtonLabel>平均週販：直近13週</RadioButtonLabel>
        <b>{disableAverage13weeks === false ? `${averageSalesIn13Weeks}` : '-'}</b>
      </RadioButtonContainer>
      <RadioButtonContainer
        disabled={
          disableLastYear4weeks ||
          (selectableAdjustmentType != null &&
            selectableAdjustmentType !== AdjustmentType.YearOverYearIn4Weeks &&
            currentAdjustmentType !== AdjustmentType.YearOverYearIn4Weeks)
        }
      >
        <RadioButton
          id="adjustment-form-radio-button-year-over-year-in-4-weeks"
          checked={currentAdjustmentType === AdjustmentType.YearOverYearIn4Weeks}
          disabled={
            disableLastYear4weeks ||
            (selectableAdjustmentType != null &&
              selectableAdjustmentType !== AdjustmentType.YearOverYearIn4Weeks &&
              currentAdjustmentType !== AdjustmentType.YearOverYearIn4Weeks)
          }
          onChangeHandler={() =>
            onChangeHandler(
              AdjustmentType.YearOverYearIn4Weeks,
              adjustmentCustomRate.current < ADJUSTMENT_CUSTOM_RATE_MIN ||
                adjustmentCustomRate.current > ADJUSTMENT_CUSTOM_RATE_MAX
                ? ADJUSTMENT_CUSTOM_RATE_DEFAULT
                : adjustmentCustomRate.current,
              currentAdjustmentRange,
              numberOfWeeksToAdjust.current < NUMBER_OF_WEEKS_TO_ADJUST_MIN ||
                numberOfWeeksToAdjust.current > NUMBER_OF_WEEKS_TO_ADJUST_MAX
                ? NUMBER_OF_WEEKS_TO_ADJUST_DEFAULT
                : numberOfWeeksToAdjust.current,
              selectedDemandForecast
            )
          }
        />
        <RadioButtonLabel>昨年対比：直近4週</RadioButtonLabel>
        <b>
          {disableLastYear4weeks === false
            ? `${yearOverYearIn4Weeks}%(${minLastYear4weeks}%～${maxLastYear4weeks}%)`
            : '-'}
        </b>
      </RadioButtonContainer>
      <RadioButtonContainer
        disabled={
          disableLastYear13weeks ||
          (selectableAdjustmentType != null &&
            selectableAdjustmentType !== AdjustmentType.YearOverYearIn13Weeks &&
            currentAdjustmentType !== AdjustmentType.YearOverYearIn13Weeks)
        }
      >
        <RadioButton
          id="adjustment-form-radio-button-year-over-year-in-13-weeks"
          checked={currentAdjustmentType === AdjustmentType.YearOverYearIn13Weeks}
          disabled={
            disableLastYear13weeks ||
            (selectableAdjustmentType != null &&
              selectableAdjustmentType !== AdjustmentType.YearOverYearIn13Weeks &&
              currentAdjustmentType !== AdjustmentType.YearOverYearIn13Weeks)
          }
          onChangeHandler={() =>
            onChangeHandler(
              AdjustmentType.YearOverYearIn13Weeks,
              adjustmentCustomRate.current < ADJUSTMENT_CUSTOM_RATE_MIN ||
                adjustmentCustomRate.current > ADJUSTMENT_CUSTOM_RATE_MAX
                ? ADJUSTMENT_CUSTOM_RATE_DEFAULT
                : adjustmentCustomRate.current,
              currentAdjustmentRange,
              numberOfWeeksToAdjust.current < NUMBER_OF_WEEKS_TO_ADJUST_MIN ||
                numberOfWeeksToAdjust.current > NUMBER_OF_WEEKS_TO_ADJUST_MAX
                ? NUMBER_OF_WEEKS_TO_ADJUST_DEFAULT
                : numberOfWeeksToAdjust.current,
              selectedDemandForecast
            )
          }
        />
        <RadioButtonLabel>昨年対比：直近13週</RadioButtonLabel>
        <b>
          {disableLastYear13weeks === false
            ? `${yearOverYearIn13Weeks}%(${minLastYear13weeks}%～${maxLastYear13weeks}%)`
            : '-'}
        </b>
      </RadioButtonContainer>
      <RadioButtonContainer
        disabled={
          readonly ||
          (selectableAdjustmentType != null &&
            selectableAdjustmentType !== AdjustmentType.CustomRate &&
            currentAdjustmentType !== AdjustmentType.CustomRate)
        }
      >
        <RadioButton
          id="adjustment-form-radio-button-custom-rate"
          checked={currentAdjustmentType === AdjustmentType.CustomRate}
          disabled={
            readonly ||
            (selectableAdjustmentType != null &&
              selectableAdjustmentType !== AdjustmentType.CustomRate &&
              currentAdjustmentType !== AdjustmentType.CustomRate)
          }
          onChangeHandler={() =>
            onChangeHandler(
              AdjustmentType.CustomRate,
              adjustmentCustomRate.current < ADJUSTMENT_CUSTOM_RATE_MIN ||
                adjustmentCustomRate.current > ADJUSTMENT_CUSTOM_RATE_MAX
                ? ADJUSTMENT_CUSTOM_RATE_DEFAULT
                : adjustmentCustomRate.current,
              currentAdjustmentRange,
              numberOfWeeksToAdjust.current < NUMBER_OF_WEEKS_TO_ADJUST_MIN ||
                numberOfWeeksToAdjust.current > NUMBER_OF_WEEKS_TO_ADJUST_MAX
                ? NUMBER_OF_WEEKS_TO_ADJUST_DEFAULT
                : numberOfWeeksToAdjust.current,
              selectedDemandForecast
            )
          }
        />
        <RadioButtonLabel>指定比率</RadioButtonLabel>
        <TextBoxContainer>
          <TextBox
            id="adjustment-form-input-rate"
            type="number"
            width={53}
            height={24}
            defaultValue={adjustmentCustomRate.current.toString()}
            disabled={readonly || currentAdjustmentType !== AdjustmentType.CustomRate}
            min={ADJUSTMENT_CUSTOM_RATE_MIN}
            max={ADJUSTMENT_CUSTOM_RATE_MAX}
            required={true}
            forceValidate={true}
            suppressErrorMessage={true}
            onChangeHandler={(value) => {
              const rate = Number.parseInt(value)
              if (Number.isNaN(rate)) {
                adjustmentCustomRate.current = 0
              } else {
                adjustmentCustomRate.current = rate
              }
              forceUpdate((v) => !v)
            }}
            onBlurHandler={() => {
              if (
                adjustmentCustomRate.current < ADJUSTMENT_CUSTOM_RATE_MIN ||
                adjustmentCustomRate.current > ADJUSTMENT_CUSTOM_RATE_MAX
              ) {
                return
              }

              onChangeHandler(
                AdjustmentType.CustomRate,
                adjustmentCustomRate.current,
                currentAdjustmentRange,
                numberOfWeeksToAdjust.current < NUMBER_OF_WEEKS_TO_ADJUST_MIN ||
                  numberOfWeeksToAdjust.current > NUMBER_OF_WEEKS_TO_ADJUST_MAX
                  ? NUMBER_OF_WEEKS_TO_ADJUST_DEFAULT
                  : numberOfWeeksToAdjust.current,
                selectedDemandForecast
              )
            }}
          />
        </TextBoxContainer>
        <span>%</span>
        {adjustmentCustomRate.current < ADJUSTMENT_CUSTOM_RATE_MIN ||
        adjustmentCustomRate.current > ADJUSTMENT_CUSTOM_RATE_MAX ? (
          <Tooltip
            text={`${ADJUSTMENT_CUSTOM_RATE_MIN}〜${ADJUSTMENT_CUSTOM_RATE_MAX}で入力してください。`}
            top={24}
            width={195}
          />
        ) : null}
      </RadioButtonContainer>
      <AdjustmentRangeLabel>補正範囲</AdjustmentRangeLabel>
      <RadioButtonContainer disabled={readonly || currentAdjustmentType === AdjustmentType.NoAdjustment}>
        <RadioButton
          id="adjustment-form-radio-button-adjustment-range-all"
          checked={
            currentAdjustmentType !== AdjustmentType.NoAdjustment && currentAdjustmentRange === AdjustmentRange.All
          }
          disabled={readonly || currentAdjustmentType === AdjustmentType.NoAdjustment}
          onChangeHandler={() =>
            onChangeHandler(
              currentAdjustmentType,
              adjustmentCustomRate.current < ADJUSTMENT_CUSTOM_RATE_MIN ||
                adjustmentCustomRate.current > ADJUSTMENT_CUSTOM_RATE_MAX
                ? ADJUSTMENT_CUSTOM_RATE_DEFAULT
                : adjustmentCustomRate.current,
              AdjustmentRange.All,
              numberOfWeeksToAdjust.current < NUMBER_OF_WEEKS_TO_ADJUST_MIN ||
                numberOfWeeksToAdjust.current > NUMBER_OF_WEEKS_TO_ADJUST_MAX
                ? NUMBER_OF_WEEKS_TO_ADJUST_DEFAULT
                : numberOfWeeksToAdjust.current,
              selectedDemandForecast
            )
          }
        />
        <RadioButtonLabel>全期間</RadioButtonLabel>
      </RadioButtonContainer>
      <RadioButtonContainer disabled={readonly || currentAdjustmentType === AdjustmentType.NoAdjustment}>
        <RadioButton
          id="adjustment-form-radio-button-adjustment-range-in-some-weeks"
          checked={
            currentAdjustmentType !== AdjustmentType.NoAdjustment &&
            currentAdjustmentRange === AdjustmentRange.InSomeWeeks
          }
          disabled={readonly || currentAdjustmentType === AdjustmentType.NoAdjustment}
          onChangeHandler={() =>
            onChangeHandler(
              currentAdjustmentType,
              adjustmentCustomRate.current < ADJUSTMENT_CUSTOM_RATE_MIN ||
                adjustmentCustomRate.current > ADJUSTMENT_CUSTOM_RATE_MAX
                ? ADJUSTMENT_CUSTOM_RATE_DEFAULT
                : adjustmentCustomRate.current,
              AdjustmentRange.InSomeWeeks,
              numberOfWeeksToAdjust.current < NUMBER_OF_WEEKS_TO_ADJUST_MIN ||
                numberOfWeeksToAdjust.current > NUMBER_OF_WEEKS_TO_ADJUST_MAX
                ? NUMBER_OF_WEEKS_TO_ADJUST_DEFAULT
                : numberOfWeeksToAdjust.current,
              selectedDemandForecast
            )
          }
        />
        <RadioButtonLabel>直近</RadioButtonLabel>
        <TextBoxContainer>
          <TextBox
            id="adjustment-form-input-in-some-weeks"
            type="number"
            width={53}
            height={24}
            defaultValue={numberOfWeeksToAdjust.current.toString()}
            disabled={
              readonly ||
              currentAdjustmentType === AdjustmentType.NoAdjustment ||
              currentAdjustmentRange !== AdjustmentRange.InSomeWeeks
            }
            required={true}
            min={NUMBER_OF_WEEKS_TO_ADJUST_MIN}
            max={NUMBER_OF_WEEKS_TO_ADJUST_MAX}
            forceValidate={true}
            suppressErrorMessage={true}
            onChangeHandler={(value) => {
              const num = Number.parseInt(value)
              if (Number.isNaN(num)) {
                numberOfWeeksToAdjust.current = 0
              } else {
                numberOfWeeksToAdjust.current = num
              }
              forceUpdate((v) => !v)
            }}
            onBlurHandler={() => {
              if (
                numberOfWeeksToAdjust.current < NUMBER_OF_WEEKS_TO_ADJUST_MIN ||
                numberOfWeeksToAdjust.current > NUMBER_OF_WEEKS_TO_ADJUST_MAX
              ) {
                return
              }

              onChangeHandler(
                currentAdjustmentType,
                adjustmentCustomRate.current < ADJUSTMENT_CUSTOM_RATE_MIN ||
                  adjustmentCustomRate.current > ADJUSTMENT_CUSTOM_RATE_MAX
                  ? ADJUSTMENT_CUSTOM_RATE_DEFAULT
                  : adjustmentCustomRate.current,
                AdjustmentRange.InSomeWeeks,
                numberOfWeeksToAdjust.current,
                selectedDemandForecast
              )
            }}
          />
        </TextBoxContainer>
        <span>週</span>
        {numberOfWeeksToAdjust.current < NUMBER_OF_WEEKS_TO_ADJUST_MIN ||
        numberOfWeeksToAdjust.current > NUMBER_OF_WEEKS_TO_ADJUST_MAX ? (
          <Tooltip
            text={`${NUMBER_OF_WEEKS_TO_ADJUST_MIN}〜${NUMBER_OF_WEEKS_TO_ADJUST_MAX}で入力してください。`}
            top={24}
            width={185}
          />
        ) : null}
      </RadioButtonContainer>
      <AdjustmentButtonContainer>
        <Button
          styleType="primary"
          label="補正登録"
          width={100}
          disabled={
            adjustmentCustomRate.current < ADJUSTMENT_CUSTOM_RATE_MIN ||
            adjustmentCustomRate.current > ADJUSTMENT_CUSTOM_RATE_MAX ||
            numberOfWeeksToAdjust.current < NUMBER_OF_WEEKS_TO_ADJUST_MIN ||
            numberOfWeeksToAdjust.current > NUMBER_OF_WEEKS_TO_ADJUST_MAX
          }
          onClickHandler={() => {
            onClickSaveButtonHandler(
              currentAdjustmentType,
              adjustmentCustomRate.current,
              currentAdjustmentRange,
              numberOfWeeksToAdjust.current,
              selectedDemandForecast
            )
          }}
          data-testid="adjustment-form-register-adjustment-button"
        />
      </AdjustmentButtonContainer>
    </Container>
  )
}
