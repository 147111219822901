const ADJUSTMENT_CUSTOM_RATE_DEFAULT = 100
const ADJUSTMENT_CUSTOM_RATE_MAX = 500
const ADJUSTMENT_CUSTOM_RATE_MIN = 1
const NUMBER_OF_WEEKS_TO_ADJUST_DEFAULT = 52
const NUMBER_OF_WEEKS_TO_ADJUST_MAX = 52
const NUMBER_OF_WEEKS_TO_ADJUST_MIN = 1

export {
  ADJUSTMENT_CUSTOM_RATE_DEFAULT,
  ADJUSTMENT_CUSTOM_RATE_MAX,
  ADJUSTMENT_CUSTOM_RATE_MIN,
  NUMBER_OF_WEEKS_TO_ADJUST_DEFAULT,
  NUMBER_OF_WEEKS_TO_ADJUST_MAX,
  NUMBER_OF_WEEKS_TO_ADJUST_MIN,
}
