/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for OMR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdjustmentRange,
    AdjustmentRangeFromJSON,
    AdjustmentRangeFromJSONTyped,
    AdjustmentRangeToJSON,
    AdjustmentTarget,
    AdjustmentTargetFromJSON,
    AdjustmentTargetFromJSONTyped,
    AdjustmentTargetToJSON,
    AdjustmentType,
    AdjustmentTypeFromJSON,
    AdjustmentTypeFromJSONTyped,
    AdjustmentTypeToJSON,
    InventoryUnitShipmentPlanPerWeek,
    InventoryUnitShipmentPlanPerWeekFromJSON,
    InventoryUnitShipmentPlanPerWeekFromJSONTyped,
    InventoryUnitShipmentPlanPerWeekToJSON,
    InventoryUnitShipmentPlanningWeek,
    InventoryUnitShipmentPlanningWeekFromJSON,
    InventoryUnitShipmentPlanningWeekFromJSONTyped,
    InventoryUnitShipmentPlanningWeekToJSON,
} from './';

/**
 * 出荷予定数の情報
 * @export
 * @interface GetInventoryUnitShipmentPlanCollectiveResponse
 */
export interface GetInventoryUnitShipmentPlanCollectiveResponse {
    /**
     * 在庫管理単位名
     * @type {string}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    inventoryUnitName: string;
    /**
     * JANコード
     * @type {string}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    janCode: string;
    /**
     * 商品名
     * @type {string}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    skuName: string | null;
    /**
     * 規格名
     * @type {string}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    skuSpecName: string | null;
    /**
     * ETA 週の日付
     * @type {string}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    etaWeekDate: string | null;
    /**
     * SKUxInventoryUnitが紐づく発注グループの担当者のユーザーID
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    operatorId: number;
    /**
     * SKUxInventoryUnitが紐づく発注グループのID
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    orderGroupId: number;
    /**
     * 直近4週誤差
     * @type {string}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    errorLevelInLast4Weeks: GetInventoryUnitShipmentPlanCollectiveResponseErrorLevelInLast4WeeksEnum;
    /**
     * 直近13週誤差
     * @type {string}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    errorLevelInLast13Weeks: GetInventoryUnitShipmentPlanCollectiveResponseErrorLevelInLast13WeeksEnum;
    /**
     * 直近4週の達成率の平均
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    averageAchievementRateIn4Weeks: number | null;
    /**
     * 直近13週の達成率の平均
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    averageAchievementRateIn13Weeks: number | null;
    /**
     * 直近4週の平均週販
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    averageSalesIn4Weeks: number | null;
    /**
     * 直近13週の平均週販
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    averageSalesIn13Weeks: number | null;
    /**
     * 直近4週の昨年対比の平均
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    averageYearOverYearIn4Weeks: number | null;
    /**
     * 直近13週の昨年対比の平均
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    averageYearOverYearIn13Weeks: number | null;
    /**
     * 売上構成比直近4週の達成率の平均
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    averageSalesCompositionAchievementRateIn4Weeks: number | null;
    /**
     * 売上構成比直近13週の達成率の平均
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    averageSalesCompositionAchievementRateIn13Weeks: number | null;
    /**
     * 売上構成比直近4週の平均週販
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    salesCompositionAverageSalesIn4Weeks: number | null;
    /**
     * 売上構成比直近13週の平均週販
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    salesCompositionAverageSalesIn13Weeks: number | null;
    /**
     * 売上構成比直近4週の昨年対比の平均
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    averageSalesCompositionYearOverYearIn4Weeks: number | null;
    /**
     * 売上構成比直近13週の昨年対比の平均
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    averageSalesCompositionYearOverYearIn13Weeks: number | null;
    /**
     * 
     * @type {AdjustmentType}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    defaultAdjustmentType: AdjustmentType | null;
    /**
     * 補正条件の指定比率(初期値)
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    defaultAdjustmentCustomRate: number;
    /**
     * 
     * @type {AdjustmentRange}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    defaultAdjustmentRange: AdjustmentRange | null;
    /**
     * 補正条件の指定期間(初期値)
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    defaultNumberOfWeeksToAdjust: number;
    /**
     * 
     * @type {AdjustmentType}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    temporaryAdjustmentType: AdjustmentType | null;
    /**
     * 一時的に指定された補正条件の指定比率
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    temporaryAdjustmentCustomRate: number | null;
    /**
     * 
     * @type {AdjustmentRange}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    temporaryAdjustmentRange: AdjustmentRange | null;
    /**
     * 一時的に指定された補正条件のの指定期間
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    temporaryNumberOfWeeksToAdjust: number | null;
    /**
     * 実績（過去）週
     * @type {Array<InventoryUnitShipmentPlanningWeek>}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    pastWeeks: Array<InventoryUnitShipmentPlanningWeek>;
    /**
     * 計画（現在および未来）週
     * @type {Array<InventoryUnitShipmentPlanningWeek>}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    presentAndFutureWeeks: Array<InventoryUnitShipmentPlanningWeek>;
    /**
     * 過去の出荷予定
     * @type {{ [key: string]: InventoryUnitShipmentPlanPerWeek; }}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    pastShipmentCalendar: { [key: string]: InventoryUnitShipmentPlanPerWeek; };
    /**
     * 現在および未来のInventoryUnit出荷予定
     * @type {{ [key: string]: InventoryUnitShipmentPlanPerWeek; }}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    presentAndFutureShipmentCalendar: { [key: string]: InventoryUnitShipmentPlanPerWeek; };
    /**
     * 一括補正対象外となったInvetoryUnitを警鐘するメッセージ
     * @type {string}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    outOfBulkMessage: string;
    /**
     * 達成率直近4週の最小レート値
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    minAchievement4weeksRate?: number | null;
    /**
     * 達成率直近4週の最大レート値
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    maxAchievement4weeksRate?: number | null;
    /**
     * 達成率直近13週の最小レート値
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    minAchievement13weeksRate?: number | null;
    /**
     * 達成率直近13週の最大レート値
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    maxAchievement13weeksRate?: number | null;
    /**
     * 平均週販4週の最小値
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    minAverage4weeks?: number | null;
    /**
     * 平均週販4週の最大値
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    maxAverage4weeks?: number | null;
    /**
     * 平均週販13週の最小値
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    minAverage13weeks?: number | null;
    /**
     * 平均週販13週の最大値
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    maxAverage13weeks?: number | null;
    /**
     * 昨年対比4週の最小レート値
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    minLastYear4weeks?: number | null;
    /**
     * 昨年対比4週の最大レート値
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    maxLastYear4weeks?: number | null;
    /**
     * 昨年対比13週の最小レート値
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    minLastYear13weeks?: number | null;
    /**
     * 昨年対比13週の最大レート値
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    maxLastYear13weeks?: number | null;
    /**
     * 達成率直近4週ラジオボタン制御
     * @type {boolean}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    disableAchievement4weeks?: boolean;
    /**
     * 達成率直近13週ラジオボタン制御
     * @type {boolean}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    disableAchievement13weeks?: boolean;
    /**
     * 平均週販直近4週ラジオボタン制御
     * @type {boolean}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    disableAverage4weeks?: boolean;
    /**
     * 平均週販直近13週ラジオボタン制御
     * @type {boolean}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    disableAverage13weeks?: boolean;
    /**
     * 昨年対比直近4週ラジオボタン制御
     * @type {boolean}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    disableLastYear4weeks?: boolean;
    /**
     * 昨年対比直近13週ラジオボタン制御
     * @type {boolean}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    disableLastYear13weeks?: boolean;
    /**
     * 売上構成比達成率直近4週の最小レート値
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    minSaleCompositionAchievement4weeksRate?: number | null;
    /**
     * 売上構成比達成率直近4週の最大レート値
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    maxSaleCompositionAchievement4weeksRate?: number | null;
    /**
     * 売上構成比達成率直近13週の最小レート値
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    minSaleCompositionAchievement13weeksRate?: number | null;
    /**
     * 売上構成比達成率直近13週の最大レート値
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    maxSaleCompositionAchievement13weeksRate?: number | null;
    /**
     * 売上構成比平均週販4週の最小値
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    minSaleCompositionAverage4weeks?: number | null;
    /**
     * 売上構成比平均週販4週の最大値
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    maxSaleCompositionAverage4weeks?: number | null;
    /**
     * 売上構成比平均週販13週の最小値
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    minSaleCompositionAverage13weeks?: number | null;
    /**
     * 売上構成比平均週販13週の最大値
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    maxSaleCompositionAverage13weeks?: number | null;
    /**
     * 売上構成比昨年対比4週の最小レート値
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    minSaleCompositionLastYear4weeks?: number | null;
    /**
     * 売上構成比昨年対比4週の最大レート値
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    maxSaleCompositionLastYear4weeks?: number | null;
    /**
     * 売上構成比昨年対比13週の最小レート値
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    minSaleCompositionLastYear13weeks?: number | null;
    /**
     * 売上構成比昨年対比13週の最大レート値
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    maxSaleCompositionLastYear13weeks?: number | null;
    /**
     * 売上構成比達成率直近4週ラジオボタン制御
     * @type {boolean}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    disableSaleCompositionAchievement4weeks?: boolean;
    /**
     * 売上構成比達成率直近13週ラジオボタン制御
     * @type {boolean}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    disableSaleCompositionAchievement13weeks?: boolean;
    /**
     * 売上構成比平均週販直近4週ラジオボタン制御
     * @type {boolean}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    disableSaleCompositionAverage4weeks?: boolean;
    /**
     * 売上構成比平均週販直近13週ラジオボタン制御
     * @type {boolean}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    disableSaleCompositionAverage13weeks?: boolean;
    /**
     * 売上構成比昨年対比直近4週ラジオボタン制御
     * @type {boolean}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    disableSaleCompositionLastYear4weeks?: boolean;
    /**
     * 売上構成比昨年対比直近13週ラジオボタン制御
     * @type {boolean}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    disableSaleCompositionLastYear13weeks?: boolean;
    /**
     * 
     * @type {AdjustmentTarget}
     * @memberof GetInventoryUnitShipmentPlanCollectiveResponse
     */
    selectedDemandForecast: AdjustmentTarget;
}

/**
* @export
* @enum {string}
*/
export enum GetInventoryUnitShipmentPlanCollectiveResponseErrorLevelInLast4WeeksEnum {
    High = 'high',
    Middle = 'middle',
    Low = 'low',
    Unavailable = 'unavailable'
}/**
* @export
* @enum {string}
*/
export enum GetInventoryUnitShipmentPlanCollectiveResponseErrorLevelInLast13WeeksEnum {
    High = 'high',
    Middle = 'middle',
    Low = 'low',
    Unavailable = 'unavailable'
}

export function GetInventoryUnitShipmentPlanCollectiveResponseFromJSON(json: any): GetInventoryUnitShipmentPlanCollectiveResponse {
    return GetInventoryUnitShipmentPlanCollectiveResponseFromJSONTyped(json, false);
}

export function GetInventoryUnitShipmentPlanCollectiveResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetInventoryUnitShipmentPlanCollectiveResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inventoryUnitName': json['inventoryUnitName'],
        'janCode': json['janCode'],
        'skuName': json['skuName'],
        'skuSpecName': json['skuSpecName'],
        'etaWeekDate': json['etaWeekDate'],
        'operatorId': json['operatorId'],
        'orderGroupId': json['orderGroupId'],
        'errorLevelInLast4Weeks': json['errorLevelInLast4Weeks'],
        'errorLevelInLast13Weeks': json['errorLevelInLast13Weeks'],
        'averageAchievementRateIn4Weeks': json['averageAchievementRateIn4Weeks'],
        'averageAchievementRateIn13Weeks': json['averageAchievementRateIn13Weeks'],
        'averageSalesIn4Weeks': json['averageSalesIn4Weeks'],
        'averageSalesIn13Weeks': json['averageSalesIn13Weeks'],
        'averageYearOverYearIn4Weeks': json['averageYearOverYearIn4Weeks'],
        'averageYearOverYearIn13Weeks': json['averageYearOverYearIn13Weeks'],
        'averageSalesCompositionAchievementRateIn4Weeks': json['averageSalesCompositionAchievementRateIn4Weeks'],
        'averageSalesCompositionAchievementRateIn13Weeks': json['averageSalesCompositionAchievementRateIn13Weeks'],
        'salesCompositionAverageSalesIn4Weeks': json['salesCompositionAverageSalesIn4Weeks'],
        'salesCompositionAverageSalesIn13Weeks': json['salesCompositionAverageSalesIn13Weeks'],
        'averageSalesCompositionYearOverYearIn4Weeks': json['averageSalesCompositionYearOverYearIn4Weeks'],
        'averageSalesCompositionYearOverYearIn13Weeks': json['averageSalesCompositionYearOverYearIn13Weeks'],
        'defaultAdjustmentType': AdjustmentTypeFromJSON(json['defaultAdjustmentType']),
        'defaultAdjustmentCustomRate': json['defaultAdjustmentCustomRate'],
        'defaultAdjustmentRange': AdjustmentRangeFromJSON(json['defaultAdjustmentRange']),
        'defaultNumberOfWeeksToAdjust': json['defaultNumberOfWeeksToAdjust'],
        'temporaryAdjustmentType': AdjustmentTypeFromJSON(json['temporaryAdjustmentType']),
        'temporaryAdjustmentCustomRate': json['temporaryAdjustmentCustomRate'],
        'temporaryAdjustmentRange': AdjustmentRangeFromJSON(json['temporaryAdjustmentRange']),
        'temporaryNumberOfWeeksToAdjust': json['temporaryNumberOfWeeksToAdjust'],
        'pastWeeks': ((json['pastWeeks'] as Array<any>).map(InventoryUnitShipmentPlanningWeekFromJSON)),
        'presentAndFutureWeeks': ((json['presentAndFutureWeeks'] as Array<any>).map(InventoryUnitShipmentPlanningWeekFromJSON)),
        'pastShipmentCalendar': (mapValues(json['pastShipmentCalendar'], InventoryUnitShipmentPlanPerWeekFromJSON)),
        'presentAndFutureShipmentCalendar': (mapValues(json['presentAndFutureShipmentCalendar'], InventoryUnitShipmentPlanPerWeekFromJSON)),
        'outOfBulkMessage': json['outOfBulkMessage'],
        'minAchievement4weeksRate': !exists(json, 'minAchievement4weeksRate') ? undefined : json['minAchievement4weeksRate'],
        'maxAchievement4weeksRate': !exists(json, 'maxAchievement4weeksRate') ? undefined : json['maxAchievement4weeksRate'],
        'minAchievement13weeksRate': !exists(json, 'minAchievement13weeksRate') ? undefined : json['minAchievement13weeksRate'],
        'maxAchievement13weeksRate': !exists(json, 'maxAchievement13weeksRate') ? undefined : json['maxAchievement13weeksRate'],
        'minAverage4weeks': !exists(json, 'minAverage4weeks') ? undefined : json['minAverage4weeks'],
        'maxAverage4weeks': !exists(json, 'maxAverage4weeks') ? undefined : json['maxAverage4weeks'],
        'minAverage13weeks': !exists(json, 'minAverage13weeks') ? undefined : json['minAverage13weeks'],
        'maxAverage13weeks': !exists(json, 'maxAverage13weeks') ? undefined : json['maxAverage13weeks'],
        'minLastYear4weeks': !exists(json, 'minLastYear4weeks') ? undefined : json['minLastYear4weeks'],
        'maxLastYear4weeks': !exists(json, 'maxLastYear4weeks') ? undefined : json['maxLastYear4weeks'],
        'minLastYear13weeks': !exists(json, 'minLastYear13weeks') ? undefined : json['minLastYear13weeks'],
        'maxLastYear13weeks': !exists(json, 'maxLastYear13weeks') ? undefined : json['maxLastYear13weeks'],
        'disableAchievement4weeks': !exists(json, 'disableAchievement4weeks') ? undefined : json['disableAchievement4weeks'],
        'disableAchievement13weeks': !exists(json, 'disableAchievement13weeks') ? undefined : json['disableAchievement13weeks'],
        'disableAverage4weeks': !exists(json, 'disableAverage4weeks') ? undefined : json['disableAverage4weeks'],
        'disableAverage13weeks': !exists(json, 'disableAverage13weeks') ? undefined : json['disableAverage13weeks'],
        'disableLastYear4weeks': !exists(json, 'disableLastYear4weeks') ? undefined : json['disableLastYear4weeks'],
        'disableLastYear13weeks': !exists(json, 'disableLastYear13weeks') ? undefined : json['disableLastYear13weeks'],
        'minSaleCompositionAchievement4weeksRate': !exists(json, 'minSaleCompositionAchievement4weeksRate') ? undefined : json['minSaleCompositionAchievement4weeksRate'],
        'maxSaleCompositionAchievement4weeksRate': !exists(json, 'maxSaleCompositionAchievement4weeksRate') ? undefined : json['maxSaleCompositionAchievement4weeksRate'],
        'minSaleCompositionAchievement13weeksRate': !exists(json, 'minSaleCompositionAchievement13weeksRate') ? undefined : json['minSaleCompositionAchievement13weeksRate'],
        'maxSaleCompositionAchievement13weeksRate': !exists(json, 'maxSaleCompositionAchievement13weeksRate') ? undefined : json['maxSaleCompositionAchievement13weeksRate'],
        'minSaleCompositionAverage4weeks': !exists(json, 'minSaleCompositionAverage4weeks') ? undefined : json['minSaleCompositionAverage4weeks'],
        'maxSaleCompositionAverage4weeks': !exists(json, 'maxSaleCompositionAverage4weeks') ? undefined : json['maxSaleCompositionAverage4weeks'],
        'minSaleCompositionAverage13weeks': !exists(json, 'minSaleCompositionAverage13weeks') ? undefined : json['minSaleCompositionAverage13weeks'],
        'maxSaleCompositionAverage13weeks': !exists(json, 'maxSaleCompositionAverage13weeks') ? undefined : json['maxSaleCompositionAverage13weeks'],
        'minSaleCompositionLastYear4weeks': !exists(json, 'minSaleCompositionLastYear4weeks') ? undefined : json['minSaleCompositionLastYear4weeks'],
        'maxSaleCompositionLastYear4weeks': !exists(json, 'maxSaleCompositionLastYear4weeks') ? undefined : json['maxSaleCompositionLastYear4weeks'],
        'minSaleCompositionLastYear13weeks': !exists(json, 'minSaleCompositionLastYear13weeks') ? undefined : json['minSaleCompositionLastYear13weeks'],
        'maxSaleCompositionLastYear13weeks': !exists(json, 'maxSaleCompositionLastYear13weeks') ? undefined : json['maxSaleCompositionLastYear13weeks'],
        'disableSaleCompositionAchievement4weeks': !exists(json, 'disableSaleCompositionAchievement4weeks') ? undefined : json['disableSaleCompositionAchievement4weeks'],
        'disableSaleCompositionAchievement13weeks': !exists(json, 'disableSaleCompositionAchievement13weeks') ? undefined : json['disableSaleCompositionAchievement13weeks'],
        'disableSaleCompositionAverage4weeks': !exists(json, 'disableSaleCompositionAverage4weeks') ? undefined : json['disableSaleCompositionAverage4weeks'],
        'disableSaleCompositionAverage13weeks': !exists(json, 'disableSaleCompositionAverage13weeks') ? undefined : json['disableSaleCompositionAverage13weeks'],
        'disableSaleCompositionLastYear4weeks': !exists(json, 'disableSaleCompositionLastYear4weeks') ? undefined : json['disableSaleCompositionLastYear4weeks'],
        'disableSaleCompositionLastYear13weeks': !exists(json, 'disableSaleCompositionLastYear13weeks') ? undefined : json['disableSaleCompositionLastYear13weeks'],
        'selectedDemandForecast': AdjustmentTargetFromJSON(json['selectedDemandForecast']),
    };
}

export function GetInventoryUnitShipmentPlanCollectiveResponseToJSON(value?: GetInventoryUnitShipmentPlanCollectiveResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inventoryUnitName': value.inventoryUnitName,
        'janCode': value.janCode,
        'skuName': value.skuName,
        'skuSpecName': value.skuSpecName,
        'etaWeekDate': value.etaWeekDate,
        'operatorId': value.operatorId,
        'orderGroupId': value.orderGroupId,
        'errorLevelInLast4Weeks': value.errorLevelInLast4Weeks,
        'errorLevelInLast13Weeks': value.errorLevelInLast13Weeks,
        'averageAchievementRateIn4Weeks': value.averageAchievementRateIn4Weeks,
        'averageAchievementRateIn13Weeks': value.averageAchievementRateIn13Weeks,
        'averageSalesIn4Weeks': value.averageSalesIn4Weeks,
        'averageSalesIn13Weeks': value.averageSalesIn13Weeks,
        'averageYearOverYearIn4Weeks': value.averageYearOverYearIn4Weeks,
        'averageYearOverYearIn13Weeks': value.averageYearOverYearIn13Weeks,
        'averageSalesCompositionAchievementRateIn4Weeks': value.averageSalesCompositionAchievementRateIn4Weeks,
        'averageSalesCompositionAchievementRateIn13Weeks': value.averageSalesCompositionAchievementRateIn13Weeks,
        'salesCompositionAverageSalesIn4Weeks': value.salesCompositionAverageSalesIn4Weeks,
        'salesCompositionAverageSalesIn13Weeks': value.salesCompositionAverageSalesIn13Weeks,
        'averageSalesCompositionYearOverYearIn4Weeks': value.averageSalesCompositionYearOverYearIn4Weeks,
        'averageSalesCompositionYearOverYearIn13Weeks': value.averageSalesCompositionYearOverYearIn13Weeks,
        'defaultAdjustmentType': AdjustmentTypeToJSON(value.defaultAdjustmentType),
        'defaultAdjustmentCustomRate': value.defaultAdjustmentCustomRate,
        'defaultAdjustmentRange': AdjustmentRangeToJSON(value.defaultAdjustmentRange),
        'defaultNumberOfWeeksToAdjust': value.defaultNumberOfWeeksToAdjust,
        'temporaryAdjustmentType': AdjustmentTypeToJSON(value.temporaryAdjustmentType),
        'temporaryAdjustmentCustomRate': value.temporaryAdjustmentCustomRate,
        'temporaryAdjustmentRange': AdjustmentRangeToJSON(value.temporaryAdjustmentRange),
        'temporaryNumberOfWeeksToAdjust': value.temporaryNumberOfWeeksToAdjust,
        'pastWeeks': ((value.pastWeeks as Array<any>).map(InventoryUnitShipmentPlanningWeekToJSON)),
        'presentAndFutureWeeks': ((value.presentAndFutureWeeks as Array<any>).map(InventoryUnitShipmentPlanningWeekToJSON)),
        'pastShipmentCalendar': (mapValues(value.pastShipmentCalendar, InventoryUnitShipmentPlanPerWeekToJSON)),
        'presentAndFutureShipmentCalendar': (mapValues(value.presentAndFutureShipmentCalendar, InventoryUnitShipmentPlanPerWeekToJSON)),
        'outOfBulkMessage': value.outOfBulkMessage,
        'minAchievement4weeksRate': value.minAchievement4weeksRate,
        'maxAchievement4weeksRate': value.maxAchievement4weeksRate,
        'minAchievement13weeksRate': value.minAchievement13weeksRate,
        'maxAchievement13weeksRate': value.maxAchievement13weeksRate,
        'minAverage4weeks': value.minAverage4weeks,
        'maxAverage4weeks': value.maxAverage4weeks,
        'minAverage13weeks': value.minAverage13weeks,
        'maxAverage13weeks': value.maxAverage13weeks,
        'minLastYear4weeks': value.minLastYear4weeks,
        'maxLastYear4weeks': value.maxLastYear4weeks,
        'minLastYear13weeks': value.minLastYear13weeks,
        'maxLastYear13weeks': value.maxLastYear13weeks,
        'disableAchievement4weeks': value.disableAchievement4weeks,
        'disableAchievement13weeks': value.disableAchievement13weeks,
        'disableAverage4weeks': value.disableAverage4weeks,
        'disableAverage13weeks': value.disableAverage13weeks,
        'disableLastYear4weeks': value.disableLastYear4weeks,
        'disableLastYear13weeks': value.disableLastYear13weeks,
        'minSaleCompositionAchievement4weeksRate': value.minSaleCompositionAchievement4weeksRate,
        'maxSaleCompositionAchievement4weeksRate': value.maxSaleCompositionAchievement4weeksRate,
        'minSaleCompositionAchievement13weeksRate': value.minSaleCompositionAchievement13weeksRate,
        'maxSaleCompositionAchievement13weeksRate': value.maxSaleCompositionAchievement13weeksRate,
        'minSaleCompositionAverage4weeks': value.minSaleCompositionAverage4weeks,
        'maxSaleCompositionAverage4weeks': value.maxSaleCompositionAverage4weeks,
        'minSaleCompositionAverage13weeks': value.minSaleCompositionAverage13weeks,
        'maxSaleCompositionAverage13weeks': value.maxSaleCompositionAverage13weeks,
        'minSaleCompositionLastYear4weeks': value.minSaleCompositionLastYear4weeks,
        'maxSaleCompositionLastYear4weeks': value.maxSaleCompositionLastYear4weeks,
        'minSaleCompositionLastYear13weeks': value.minSaleCompositionLastYear13weeks,
        'maxSaleCompositionLastYear13weeks': value.maxSaleCompositionLastYear13weeks,
        'disableSaleCompositionAchievement4weeks': value.disableSaleCompositionAchievement4weeks,
        'disableSaleCompositionAchievement13weeks': value.disableSaleCompositionAchievement13weeks,
        'disableSaleCompositionAverage4weeks': value.disableSaleCompositionAverage4weeks,
        'disableSaleCompositionAverage13weeks': value.disableSaleCompositionAverage13weeks,
        'disableSaleCompositionLastYear4weeks': value.disableSaleCompositionLastYear4weeks,
        'disableSaleCompositionLastYear13weeks': value.disableSaleCompositionLastYear13weeks,
        'selectedDemandForecast': AdjustmentTargetToJSON(value.selectedDemandForecast),
    };
}


