import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { User, UserRole } from '../../../api-client'

import { Button, Dropdown, TextBox } from '../../atoms'

import {
  ButtonsContainer,
  CancelButtonContainer,
  Email,
  IdLabel,
  InputFormContainer,
  InputFormContainerInner,
  InputFormLabel,
  InputFormSubLabel,
  Role,
} from './style'

export interface UserFormProps {
  /**
   * ユーザー情報. nullの場合は新規作成であることを想定
   */
  user: User | null

  /**
   * メールアドレスが編集可能かどうか
   */
  emailEditable: boolean

  /**
   * ロールが編集可能かどうか
   */
  roleEditable: boolean

  /**
   * 保存ボタンがクリックされた時に呼び出されるハンドラー
   */
  onSaveUserClick: (name: string, password: string | null, email: string, role: UserRole) => void
}

export const UserForm: React.FC<UserFormProps> = ({ user, emailEditable, roleEditable, onSaveUserClick }) => {
  const roleToLabel = (role: UserRole | null) => {
    if (role != null && role === UserRole.Manager) {
      return '部門管理者'
    } else if (role != null && role === UserRole.Operator) {
      return '一般ユーザー'
    }

    return ''
  }

  // Ref: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#basic_validation
  const emailValidationRegex = new RegExp(
    "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$",
    ''
  )
  const isInValidInput = () =>
    name === '' ||
    name.length > 1000 ||
    (user == null && password == null) || // 新規作成はパスワード必須
    (password != null && (password.length < 8 || password.length > 200)) ||
    password !== passwordConfirmation ||
    (emailEditable && (email === '' || emailValidationRegex.test(email) === false))

  const history = useHistory()

  const [name, setName] = useState(user != null ? user.name : '')
  const [password, setPassword] = useState<string | null>(null)
  const [passwordConfirmation, setPasswordConfirmation] = useState<string | null>(null)
  const [email, setEmail] = useState(user != null ? user.email : '')
  const [role, setRole] = useState(user != null ? user.role : UserRole.Operator)

  const [forceValidate, setForceValidate] = useState(false)

  return (
    <>
      <ButtonsContainer>
        <Button
          styleType="primary"
          label={user != null ? '保存' : '登録'}
          width={76}
          disabled={forceValidate && isInValidInput()}
          onClickHandler={() => {
            setForceValidate(true)
            if (isInValidInput()) {
              return
            }
            onSaveUserClick(name, password, email, role)
          }}
          data-testid="user-form-submit-button"
        />
        <CancelButtonContainer>
          <Button
            styleType="tertiary"
            label="キャンセル"
            width={120}
            onClickHandler={() => history.goBack()}
            data-testid="user-form-cancel-button"
          />
        </CancelButtonContainer>
      </ButtonsContainer>
      {user != null ? (
        <InputFormContainer>
          <InputFormLabel>ユーザーID</InputFormLabel>
          <InputFormContainerInner>
            <IdLabel>{user.id}</IdLabel>
          </InputFormContainerInner>
        </InputFormContainer>
      ) : null}
      <InputFormContainer>
        <InputFormLabel>ユーザー名</InputFormLabel>
        <InputFormContainerInner>
          <TextBox
            id="user-form-name-input"
            label=""
            type="text"
            width={200}
            height={40}
            defaultValue={name}
            required={true}
            maxLength={1000}
            forceValidate={forceValidate}
            onChangeHandler={(value: string) => {
              setName(value)
            }}
          />
        </InputFormContainerInner>
      </InputFormContainer>
      <InputFormContainer>
        <InputFormLabel>パスワード</InputFormLabel>
        <InputFormContainerInner>
          <TextBox
            id="user-form-password-input"
            label=""
            type="password"
            width={200}
            height={40}
            defaultValue={password != null ? password : ''}
            placeholder="********"
            required={user == null} // 新規作成ならパスワード必須
            minLength={(user != null && password != null && password.length > 0) || user == null ? 8 : undefined}
            maxLength={(user != null && password != null && password.length > 0) || user == null ? 200 : undefined}
            forceValidate={forceValidate}
            onChangeHandler={(value: string) => {
              if (value === '') {
                setPassword(null)
              } else {
                setPassword(value)
              }
            }}
          />
          <InputFormSubLabel>(8文字以上)</InputFormSubLabel>
        </InputFormContainerInner>
      </InputFormContainer>
      <InputFormContainer>
        <InputFormLabel>パスワードの確認</InputFormLabel>
        <InputFormContainerInner>
          <TextBox
            id="user-form-password-confirmation-input"
            label=""
            type="password"
            width={200}
            height={40}
            defaultValue={passwordConfirmation != null ? passwordConfirmation : ''}
            customErrorMessage={
              passwordConfirmation != null && passwordConfirmation !== '' && passwordConfirmation !== password
                ? 'パスワードが一致しません。'
                : ''
            }
            placeholder="********"
            required={(user != null && password != null && password.length > 0) || user == null} // 新規作成ならパスワード必須
            forceValidate={forceValidate}
            onChangeHandler={(value: string) => {
              if (value === '') {
                setPasswordConfirmation(null)
              } else {
                setPasswordConfirmation(value)
              }
            }}
          />
        </InputFormContainerInner>
      </InputFormContainer>
      <InputFormContainer>
        <InputFormLabel>メールアドレス</InputFormLabel>
        <InputFormContainerInner>
          {emailEditable === true ? (
            <TextBox
              id="user-form-email-input"
              label=""
              type="email"
              width={200}
              height={40}
              defaultValue={email}
              required={true}
              forceValidate={forceValidate}
              onChangeHandler={(value: string) => {
                setEmail(value)
              }}
            />
          ) : (
            <Email data-testid="user-form-email-text">{user != null ? user.email : ''}</Email>
          )}
        </InputFormContainerInner>
      </InputFormContainer>
      <InputFormContainer>
        <InputFormLabel>ユーザー種別</InputFormLabel>
        <InputFormContainerInner>
          {roleEditable === true ? (
            <Dropdown
              options={[
                {
                  label: roleToLabel(UserRole.Operator),
                  value: UserRole.Operator,
                },
                {
                  label: roleToLabel(UserRole.Manager),
                  value: UserRole.Manager,
                },
              ]}
              defaultValue={role}
              width={200}
              onChangeHandler={(value) =>
                value === UserRole.Manager ? setRole(UserRole.Manager) : setRole(UserRole.Operator)
              }
            />
          ) : (
            <Role data-testid="user-form-role-text">{roleToLabel(user != null ? user.role : null)}</Role>
          )}
        </InputFormContainerInner>
      </InputFormContainer>
    </>
  )
}
