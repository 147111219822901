/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for OMR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderGroupsItem
 */
export interface OrderGroupsItem {
    /**
     * 発注グループID
     * @type {number}
     * @memberof OrderGroupsItem
     */
    id: number;
    /**
     * 発注グループ名称
     * @type {string}
     * @memberof OrderGroupsItem
     */
    name: string;
    /**
     * 発注案があるか
     * @type {boolean}
     * @memberof OrderGroupsItem
     */
    hasDraftPurchaseOrder: boolean;
    /**
     * 発注担当者ID
     * @type {number}
     * @memberof OrderGroupsItem
     */
    operatorId: number;
    /**
     * 発注担当者名
     * @type {string}
     * @memberof OrderGroupsItem
     */
    operatorName: string;
    /**
     * 部門コードの配列
     * @type {Array<string>}
     * @memberof OrderGroupsItem
     */
    departmentCodes: Array<string>;
    /**
     * 需要予測確認SKU数
     * @type {number}
     * @memberof OrderGroupsItem
     */
    numberOfLargePredictionErrorSkus: number;
    /**
     * 登録SKU数
     * @type {number}
     * @memberof OrderGroupsItem
     */
    numberOfSkus: number;
}

export function OrderGroupsItemFromJSON(json: any): OrderGroupsItem {
    return OrderGroupsItemFromJSONTyped(json, false);
}

export function OrderGroupsItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderGroupsItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'hasDraftPurchaseOrder': json['hasDraftPurchaseOrder'],
        'operatorId': json['operatorId'],
        'operatorName': json['operatorName'],
        'departmentCodes': json['departmentCodes'],
        'numberOfLargePredictionErrorSkus': json['numberOfLargePredictionErrorSkus'],
        'numberOfSkus': json['numberOfSkus'],
    };
}

export function OrderGroupsItemToJSON(value?: OrderGroupsItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'hasDraftPurchaseOrder': value.hasDraftPurchaseOrder,
        'operatorId': value.operatorId,
        'operatorName': value.operatorName,
        'departmentCodes': value.departmentCodes,
        'numberOfLargePredictionErrorSkus': value.numberOfLargePredictionErrorSkus,
        'numberOfSkus': value.numberOfSkus,
    };
}


