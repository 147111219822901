import styled from 'styled-components'

const BaseTooltip = styled.div<{top: number, width: number}>`
  background-color: #333;
  color: #fff;
  height: 20px;
  letter-spacing: 0.46px;
  line-height: 20px;
  opacity: 0.75;
  padding: 5px 10px;
  position: absolute;
  top: ${props => `${props.top}px`};
  width: ${props => `${props.width}px`};
`

export {
  BaseTooltip,
}
