import React, { Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState } from "react"
import { InventoryUnitSKU, GetInventoryPlanResponse, SKUTotal } from "../../api-client"
import cloneDeep from 'lodash/cloneDeep'
import {ErrorLevel} from '../../api-client'
import { useReload } from "./useReload.hooks"


export type TabType = 'sku' | 'distribution-center'
export interface UseReloadProps {
  inventoryPlan: GetInventoryPlanResponse | undefined
}

interface CommonSearch {
  selectedTab: TabType
  setSelectedTab: Dispatch<SetStateAction<TabType>>
}

interface SKUSearch {
  shouldInventoryPlanVisibleForSKUTab: (inventoryUnitSKU: InventoryUnitSKU) => boolean
  shouldTotalInventoryPlanVisibleForSKUTab: (totalInventoryPlan: SKUTotal) => boolean
  selectedSKUIndex: number
  filterByErrorLebelLowInLast4WeeksForSKUTab: boolean
  filterByErrorLebelMiddleInLast4WeeksForSKUTab: boolean
  filterByErrorLebelHighInLast4WeeksForSKUTab: boolean
  filterByErrorLebelLowInLast13WeeksForSKUTab: boolean
  filterByErrorLebelMiddleInLast13WeeksForSKUTab: boolean
  filterByErrorLebelHighInLast13WeeksForSKUTab: boolean
  filterByAdjustmentTargetForSKUTab: boolean
  filterBySimulatedValueForSKUTab: boolean
  setSelectedSKUIndex: Dispatch<SetStateAction<number>>
  setFilterByErrorLebelLowInLast4WeeksForSKUTab: Dispatch<SetStateAction<boolean>>
  setFilterByErrorLebelMiddleInLast4WeeksForSKUTab: Dispatch<SetStateAction<boolean>>
  setFilterByErrorLebelHighInLast4WeeksForSKUTab: Dispatch<SetStateAction<boolean>>
  setFilterByErrorLebelLowInLast13WeeksForSKUTab: Dispatch<SetStateAction<boolean>>
  setFilterByErrorLebelMiddleInLast13WeeksForSKUTab: Dispatch<SetStateAction<boolean>>
  setFilterByErrorLebelHighInLast13WeeksForSKUTab: Dispatch<SetStateAction<boolean>>
  setFilterByAdjustmentTargetForSKUTab: Dispatch<SetStateAction<boolean>>
  setFilterBySimulatedValueForSKUTab: Dispatch<SetStateAction<boolean>>
}

interface InventoryUnitSearch {
  isErrorLevelIncludedForInventoryUnitTab: (errorLevelIn4Weeks: ErrorLevel, errorLevelIn13Weeks: ErrorLevel) => boolean
  allDistributionCentersSelected:  React.MutableRefObject<boolean>
  selectedDistributionCenterIndex: React.MutableRefObject<number>
  filterByErrorLebelLowInLast4WeeksForInventoryUnitTab: boolean
  filterByErrorLebelMiddleInLast4WeeksForInventoryUnitTab: boolean
  filterByErrorLebelHighInLast4WeeksForInventoryUnitTab: boolean
  filterByErrorLebelLowInLast13WeeksForInventoryUnitTab: boolean
  filterByErrorLebelMiddleInLast13WeeksForInventoryUnitTab: boolean
  filterByErrorLebelHighInLast13WeeksForInventoryUnitTab: boolean
  filterByAdjustmentTargetForInventoryUnitTab: boolean
  filterBySimulatedValueForInventoryUnitTab: boolean
  filterByTextForInventoryUnitTab: string
  setFilterByErrorLebelLowInLast4WeeksForInventoryUnitTab: Dispatch<SetStateAction<boolean>>
  setFilterByErrorLebelMiddleInLast4WeeksForInventoryUnitTab: Dispatch<SetStateAction<boolean>>
  setFilterByErrorLebelHighInLast4WeeksForInventoryUnitTab: Dispatch<SetStateAction<boolean>>
  setFilterByErrorLebelLowInLast13WeeksForInventoryUnitTab: Dispatch<SetStateAction<boolean>>
  setFilterByErrorLebelMiddleInLast13WeeksForInventoryUnitTab: Dispatch<SetStateAction<boolean>>
  setFilterByErrorLebelHighInLast13WeeksForInventoryUnitTab: Dispatch<SetStateAction<boolean>>
  setFilterByAdjustmentTargetForInventoryUnitTab: Dispatch<SetStateAction<boolean>>
  setFilterBySimulatedValueForInventoryUnitTab: Dispatch<SetStateAction<boolean>>
  setFilterByTextForInventoryUnitTab: Dispatch<SetStateAction<string>>
}

export const useSearch = (
  inventoryPlan: GetInventoryPlanResponse | undefined,
  inventoryPlanRef: MutableRefObject<GetInventoryPlanResponse | undefined>,
  forceUpdate: React.Dispatch<React.SetStateAction<boolean>>
): [CommonSearch, SKUSearch, InventoryUnitSearch] => {
  // common
  const [selectedTab, setSelectedTab] = useState<TabType>('sku')

  // SKU tab states
  const [selectedSKUIndex, setSelectedSKUIndex] = useState(0)
  const [filterByErrorLebelLowInLast4WeeksForSKUTab, setFilterByErrorLebelLowInLast4WeeksForSKUTab] = useState(false)
  const [filterByErrorLebelMiddleInLast4WeeksForSKUTab, setFilterByErrorLebelMiddleInLast4WeeksForSKUTab] = useState(false)
  const [filterByErrorLebelHighInLast4WeeksForSKUTab, setFilterByErrorLebelHighInLast4WeeksForSKUTab] = useState(false)
  const [filterByErrorLebelLowInLast13WeeksForSKUTab, setFilterByErrorLebelLowInLast13WeeksForSKUTab] = useState(false)
  const [filterByErrorLebelMiddleInLast13WeeksForSKUTab, setFilterByErrorLebelMiddleInLast13WeeksForSKUTab] = useState(false)
  const [filterByErrorLebelHighInLast13WeeksForSKUTab, setFilterByErrorLebelHighInLast13WeeksForSKUTab] = useState(false)
  const [filterByAdjustmentTargetForSKUTab, setFilterByAdjustmentTargetForSKUTab] = useState(false)
  const [filterBySimulatedValueForSKUTab, setFilterBySimulatedValueForSKUTab] = useState(false)

  const isErrorLevelIncludedForSKUTab = (errorLevelIn4Weeks: ErrorLevel, errorLevelIn13Weeks: ErrorLevel) =>
    (
      filterByErrorLebelLowInLast4WeeksForSKUTab === false &&
      filterByErrorLebelMiddleInLast4WeeksForSKUTab === false &&
      filterByErrorLebelHighInLast4WeeksForSKUTab === false &&
      filterByErrorLebelLowInLast13WeeksForSKUTab === false &&
      filterByErrorLebelMiddleInLast13WeeksForSKUTab === false &&
      filterByErrorLebelHighInLast13WeeksForSKUTab === false
    ) ||
    (filterByErrorLebelLowInLast4WeeksForSKUTab === true && errorLevelIn4Weeks === ErrorLevel.Low) ||
    (filterByErrorLebelMiddleInLast4WeeksForSKUTab === true && errorLevelIn4Weeks === ErrorLevel.Middle) ||
    (filterByErrorLebelHighInLast4WeeksForSKUTab === true && errorLevelIn4Weeks === ErrorLevel.High) ||
    (filterByErrorLebelLowInLast13WeeksForSKUTab === true && errorLevelIn13Weeks === ErrorLevel.Low) ||
    (filterByErrorLebelMiddleInLast13WeeksForSKUTab === true && errorLevelIn13Weeks === ErrorLevel.Middle) ||
    (filterByErrorLebelHighInLast13WeeksForSKUTab === true && errorLevelIn13Weeks === ErrorLevel.High)

  const isAnyFilterForSKUTabChecked = () =>
    filterByErrorLebelLowInLast4WeeksForSKUTab === true ||
    filterByErrorLebelMiddleInLast4WeeksForSKUTab === true ||
    filterByErrorLebelHighInLast4WeeksForSKUTab === true ||
    filterByErrorLebelLowInLast13WeeksForSKUTab === true ||
    filterByErrorLebelMiddleInLast13WeeksForSKUTab === true ||
    filterByErrorLebelHighInLast13WeeksForSKUTab === true ||
    filterBySimulatedValueForSKUTab === true

  const shouldInventoryPlanVisibleForSKUTab = (inventoryUnitSKU: InventoryUnitSKU) => {
    if (inventoryPlanRef.current == null) {
      return false
    }

    if (inventoryUnitSKU.janCode !== inventoryPlanRef.current.skus[selectedSKUIndex].code) {
      return false
    }

    if (isErrorLevelIncludedForSKUTab(inventoryUnitSKU.errorLevelInLast4Weeks, inventoryUnitSKU.errorLevelInLast13Weeks) === false) {
      // 誤差の値でフィルタリング
      return false
    }

    if (filterByAdjustmentTargetForSKUTab === true && inventoryUnitSKU.isAdjusted === true) {
      // 補正なしかどうかでフィルタリング
      return false
    }

    if (filterBySimulatedValueForSKUTab === true && Object.values(inventoryUnitSKU.inventoryCalendar).some((inventoryPlan) => inventoryPlan.receivingQuantityForSimulation != null) === false) {
      // シミュレーション値があるかどうかでフィルタリング
      return false
    }

    return true
  }

  const shouldTotalInventoryPlanVisibleForSKUTab = (totalInventoryPlan: SKUTotal) => {
    if (inventoryPlanRef.current == null) {
      return false
    }
    if (totalInventoryPlan.janCode !== inventoryPlanRef.current.skus[selectedSKUIndex].code) {
      return false
    }
    if (isAnyFilterForSKUTabChecked() === true) {
      // SKU毎の合算値のテーブルはいずれのフィルタリング項目にもヒットしないので, フィルタリングがされていたら表示させない
      return false
    }
    return true
  }

  // Inventory tab states
  const allDistributionCentersSelected = useRef(true)
  const selectedDistributionCenterIndex = useRef(-1)
  const [filterByErrorLebelLowInLast4WeeksForInventoryUnitTab, setFilterByErrorLebelLowInLast4WeeksForInventoryUnitTab] = useState(false)
  const [filterByErrorLebelMiddleInLast4WeeksForInventoryUnitTab, setFilterByErrorLebelMiddleInLast4WeeksForInventoryUnitTab] = useState(false)
  const [filterByErrorLebelHighInLast4WeeksForInventoryUnitTab, setFilterByErrorLebelHighInLast4WeeksForInventoryUnitTab] = useState(false)
  const [filterByErrorLebelLowInLast13WeeksForInventoryUnitTab, setFilterByErrorLebelLowInLast13WeeksForInventoryUnitTab] = useState(false)
  const [filterByErrorLebelMiddleInLast13WeeksForInventoryUnitTab, setFilterByErrorLebelMiddleInLast13WeeksForInventoryUnitTab] = useState(false)
  const [filterByErrorLebelHighInLast13WeeksForInventoryUnitTab, setFilterByErrorLebelHighInLast13WeeksForInventoryUnitTab] = useState(false)
  const [filterByAdjustmentTargetForInventoryUnitTab, setFilterByAdjustmentTargetForInventoryUnitTab] = useState(false)
  const [filterBySimulatedValueForInventoryUnitTab, setFilterBySimulatedValueForInventoryUnitTab] = useState(false)
  const [filterByTextForInventoryUnitTab, setFilterByTextForInventoryUnitTab] = useState('')

  const isErrorLevelIncludedForInventoryUnitTab = (errorLevelIn4Weeks: ErrorLevel, errorLevelIn13Weeks: ErrorLevel) =>
    (
      filterByErrorLebelLowInLast4WeeksForInventoryUnitTab === false &&
      filterByErrorLebelMiddleInLast4WeeksForInventoryUnitTab === false &&
      filterByErrorLebelHighInLast4WeeksForInventoryUnitTab === false &&
      filterByErrorLebelLowInLast13WeeksForInventoryUnitTab === false &&
      filterByErrorLebelMiddleInLast13WeeksForInventoryUnitTab === false &&
      filterByErrorLebelHighInLast13WeeksForInventoryUnitTab === false
    ) ||
    (filterByErrorLebelLowInLast4WeeksForInventoryUnitTab === true && errorLevelIn4Weeks === ErrorLevel.Low) ||
    (filterByErrorLebelMiddleInLast4WeeksForInventoryUnitTab === true && errorLevelIn4Weeks === ErrorLevel.Middle) ||
    (filterByErrorLebelHighInLast4WeeksForInventoryUnitTab === true && errorLevelIn4Weeks === ErrorLevel.High) ||
    (filterByErrorLebelLowInLast13WeeksForInventoryUnitTab === true && errorLevelIn13Weeks === ErrorLevel.Low) ||
    (filterByErrorLebelMiddleInLast13WeeksForInventoryUnitTab === true && errorLevelIn13Weeks === ErrorLevel.Middle) ||
    (filterByErrorLebelHighInLast13WeeksForInventoryUnitTab === true && errorLevelIn13Weeks === ErrorLevel.High)

  // reload時に値を変えるhooks(内部でsetStateしている)
  const [, setReload] = useState(false)
  useReload(
    setReload,
    selectedTab,
    setSelectedTab,
    selectedSKUIndex,
    filterByErrorLebelLowInLast4WeeksForSKUTab,
    filterByErrorLebelMiddleInLast4WeeksForSKUTab,
    filterByErrorLebelHighInLast4WeeksForSKUTab,
    filterByErrorLebelLowInLast13WeeksForSKUTab,
    filterByErrorLebelMiddleInLast13WeeksForSKUTab,
    filterByErrorLebelHighInLast13WeeksForSKUTab,
    filterByAdjustmentTargetForSKUTab,
    filterBySimulatedValueForSKUTab,
    setSelectedSKUIndex,
    setFilterByErrorLebelLowInLast4WeeksForSKUTab,
    setFilterByErrorLebelMiddleInLast4WeeksForSKUTab,
    setFilterByErrorLebelHighInLast4WeeksForSKUTab,
    setFilterByErrorLebelLowInLast13WeeksForSKUTab,
    setFilterByErrorLebelMiddleInLast13WeeksForSKUTab,
    setFilterByErrorLebelHighInLast13WeeksForSKUTab,
    setFilterByAdjustmentTargetForSKUTab,
    setFilterBySimulatedValueForSKUTab,
    allDistributionCentersSelected,
    selectedDistributionCenterIndex,
    filterByErrorLebelLowInLast4WeeksForInventoryUnitTab,
    filterByErrorLebelMiddleInLast4WeeksForInventoryUnitTab,
    filterByErrorLebelHighInLast4WeeksForInventoryUnitTab,
    filterByErrorLebelLowInLast13WeeksForInventoryUnitTab,
    filterByErrorLebelMiddleInLast13WeeksForInventoryUnitTab,
    filterByErrorLebelHighInLast13WeeksForInventoryUnitTab,
    filterByAdjustmentTargetForInventoryUnitTab,
    filterBySimulatedValueForInventoryUnitTab,
    filterByTextForInventoryUnitTab,
    setFilterByErrorLebelLowInLast4WeeksForInventoryUnitTab,
    setFilterByErrorLebelMiddleInLast4WeeksForInventoryUnitTab,
    setFilterByErrorLebelHighInLast4WeeksForInventoryUnitTab,
    setFilterByErrorLebelLowInLast13WeeksForInventoryUnitTab,
    setFilterByErrorLebelMiddleInLast13WeeksForInventoryUnitTab,
    setFilterByErrorLebelHighInLast13WeeksForInventoryUnitTab,
    setFilterByAdjustmentTargetForInventoryUnitTab,
    setFilterBySimulatedValueForInventoryUnitTab,
    setFilterByTextForInventoryUnitTab,
  )

  useEffect(() => {
    inventoryPlanRef.current = cloneDeep(inventoryPlan)
    if (inventoryPlanRef.current === undefined) {
      return  forceUpdate(v => !v)
    }

    forceUpdate(v => !v)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryPlan, forceUpdate])

  return [
    {
      selectedTab,
      setSelectedTab,
    },
    {
      shouldInventoryPlanVisibleForSKUTab,
      shouldTotalInventoryPlanVisibleForSKUTab,
      selectedSKUIndex,
      filterByErrorLebelLowInLast4WeeksForSKUTab,
      filterByErrorLebelMiddleInLast4WeeksForSKUTab,
      filterByErrorLebelHighInLast4WeeksForSKUTab,
      filterByErrorLebelLowInLast13WeeksForSKUTab,
      filterByErrorLebelMiddleInLast13WeeksForSKUTab,
      filterByErrorLebelHighInLast13WeeksForSKUTab,
      filterByAdjustmentTargetForSKUTab,
      filterBySimulatedValueForSKUTab,
      setSelectedSKUIndex,
      setFilterByErrorLebelLowInLast4WeeksForSKUTab,
      setFilterByErrorLebelMiddleInLast4WeeksForSKUTab,
      setFilterByErrorLebelHighInLast4WeeksForSKUTab,
      setFilterByErrorLebelLowInLast13WeeksForSKUTab,
      setFilterByErrorLebelMiddleInLast13WeeksForSKUTab,
      setFilterByErrorLebelHighInLast13WeeksForSKUTab,
      setFilterByAdjustmentTargetForSKUTab,
      setFilterBySimulatedValueForSKUTab,
    },
    {
      isErrorLevelIncludedForInventoryUnitTab,
      allDistributionCentersSelected,
      selectedDistributionCenterIndex,
      filterByErrorLebelLowInLast4WeeksForInventoryUnitTab,
      filterByErrorLebelMiddleInLast4WeeksForInventoryUnitTab,
      filterByErrorLebelHighInLast4WeeksForInventoryUnitTab,
      filterByErrorLebelLowInLast13WeeksForInventoryUnitTab,
      filterByErrorLebelMiddleInLast13WeeksForInventoryUnitTab,
      filterByErrorLebelHighInLast13WeeksForInventoryUnitTab,
      filterByAdjustmentTargetForInventoryUnitTab,
      filterBySimulatedValueForInventoryUnitTab,
      filterByTextForInventoryUnitTab,
      setFilterByErrorLebelLowInLast4WeeksForInventoryUnitTab,
      setFilterByErrorLebelMiddleInLast4WeeksForInventoryUnitTab,
      setFilterByErrorLebelHighInLast4WeeksForInventoryUnitTab,
      setFilterByErrorLebelLowInLast13WeeksForInventoryUnitTab,
      setFilterByErrorLebelMiddleInLast13WeeksForInventoryUnitTab,
      setFilterByErrorLebelHighInLast13WeeksForInventoryUnitTab,
      setFilterByAdjustmentTargetForInventoryUnitTab,
      setFilterBySimulatedValueForInventoryUnitTab,
      setFilterByTextForInventoryUnitTab,
    }
  ]
}
