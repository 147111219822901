import { useHistory, useLocation } from 'react-router-dom'

export const useUrlParams = () => {
  const location = useLocation()
  const history = useHistory()
  const urlParams = new URLSearchParams(location.search)

  const get = (key: string) => {
    return urlParams.get(key)
  }

  const set = (key: string, value?: string) => {
    if (!value) {
      deleteParam(key)
      return
    }

    if (urlParams.has(key)) {
      urlParams.set(key, value)
    } else {
      urlParams.append(key, value)
    }

    history.replace({
      ...location,
      search: decodeURIComponent(urlParams.toString()),
    })

    return urlParams
  }

  const deleteParam = (key: string) => {
    urlParams.delete(key)
    updateUrl()
  }

  const updateUrl = () => {
    history.replace({
      ...location,
      search: decodeURIComponent(urlParams.toString()),
    })
  }

  return { get, set }
}
