import React from 'react'

import { Button } from '../../atoms'

import {
  Background,
  ButtonsContainer,
  CancelIcon,
  CancelIconContainer,
  Container,
  Description,
  SubmitButtonContainer,
  Window,
} from './style'

export interface DeleteConfirmationModalProps {
  /**
   * 文言
   */
  message: string

  /**
   * 無効化するかどうか
   */
  disabled: boolean

  /**
   * 閉じるボタンや背景がクリックされた時に呼び出されるハンドラー
   */
  onCloseHandler: () => void

  /**
   * 実行ボタンがクリックされた時に呼び出されるハンドラー
   */
  onSubmitHandler: () => void
}

export const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({
  message,
  disabled,
  onCloseHandler,
  onSubmitHandler,
}) => (
  <Background onClick={onCloseHandler}>
    <Window>
      <CancelIconContainer onClick={onCloseHandler} data-testid="close">
        <CancelIcon />
      </CancelIconContainer>
      <Container onClick={(e) => e.stopPropagation()}>
        <Description>{message}</Description>
        <ButtonsContainer>
          <Button
            styleType="tertiary"
            label="キャンセル"
            width={120}
            onClickHandler={onCloseHandler}
            data-testid="cancel-delete-confirmation-button"
          />
          <SubmitButtonContainer>
            <Button
              styleType="primary"
              label="削除"
              width={76}
              disabled={disabled}
              onClickHandler={onSubmitHandler}
              data-testid="submit-delete-confirmation-button"
            />
          </SubmitButtonContainer>
        </ButtonsContainer>
      </Container>
    </Window>
  </Background>
)
