import styled from 'styled-components'

const Container = styled.div`
  background-color: #fff;
  height: calc(100% - 100px);
  padding-left: 92px;
  padding-top: 100px;
`

const ErrorCodeLabel = styled.div`
  color: #999;
`

const ErrorCodeLabelContainer = styled.div`
  margin-bottom: 20px;
`

const ErrorTitle = styled.h1`
  font-weight: bold;
`

const ErrorTitleContainer = styled.div`
  margin-bottom: 19px;
`

export {
  Container,
  ErrorCodeLabel,
  ErrorCodeLabelContainer,
  ErrorTitle,
  ErrorTitleContainer
}
