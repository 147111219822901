import React, { useEffect, useState } from 'react'

import { CheckBox } from '../../atoms'

import { CaretIcon, CaretIconContainer, CheckBoxContainer, Container, Option, OptionsContainer, Select } from './style'

export interface ErrorLevelDropdownProps {
  disabled: boolean
  defaultValueForErrorLebelLowInLast4Weeks: boolean
  defaultValueForErrorLebelMiddleInLast4Weeks: boolean
  defaultValueForErrorLebelHighInLast4Weeks: boolean
  defaultValueForrrorLebelLowInLast13Weeks: boolean
  defaultValueForErrorLebelMiddleInLast13Weeks: boolean
  defaultValueForErrorLebelHighInLast13Weeks: boolean
  onErrorLebelLowInLast4WeeksChangeHandler: (value: boolean) => void
  onErrorLebelMiddleInLast4WeeksChangeHandler: (value: boolean) => void
  onErrorLebelHighInLast4WeeksChangeHandler: (value: boolean) => void
  onErrorLebelLowInLast13WeeksChangeHandler: (value: boolean) => void
  onErrorLebelMiddleInLast13WeeksChangeHandler: (value: boolean) => void
  onErrorLebelHighInLast13WeeksChangeHandler: (value: boolean) => void
}

export const ErrorLevelDropdown: React.FC<ErrorLevelDropdownProps> = ({
  disabled,
  defaultValueForErrorLebelLowInLast4Weeks,
  defaultValueForErrorLebelMiddleInLast4Weeks,
  defaultValueForErrorLebelHighInLast4Weeks,
  defaultValueForrrorLebelLowInLast13Weeks,
  defaultValueForErrorLebelMiddleInLast13Weeks,
  defaultValueForErrorLebelHighInLast13Weeks,
  onErrorLebelLowInLast4WeeksChangeHandler,
  onErrorLebelMiddleInLast4WeeksChangeHandler,
  onErrorLebelHighInLast4WeeksChangeHandler,
  onErrorLebelLowInLast13WeeksChangeHandler,
  onErrorLebelMiddleInLast13WeeksChangeHandler,
  onErrorLebelHighInLast13WeeksChangeHandler,
}) => {
  const [expanded, setExpanded] = useState(false)

  const closeDropdown = () => setExpanded(false)

  useEffect(() => {
    document.addEventListener('click', closeDropdown)
    return function cleanup() {
      document.removeEventListener('click', closeDropdown)
    }
  }, [])

  return (
    <Container>
      <Select
        disabled={disabled}
        onClick={(event) => {
          setExpanded(!expanded)
          event.stopPropagation()
        }}
        data-testid="error-level-dropdown-select"
      >
        <span>誤差率の絞り込み</span>
        <CaretIconContainer>
          <CaretIcon expanded={expanded.toString()} />
        </CaretIconContainer>
      </Select>
      <OptionsContainer visible={expanded}>
        <Option>
          <CheckBoxContainer onClick={(event) => event.stopPropagation()}>
            <CheckBox
              id="error-level-low-in-last-4-weeks-checkbox"
              label="直近4週誤差率　20％未満"
              defaultValue={defaultValueForErrorLebelLowInLast4Weeks}
              disabled={false}
              onChangeHandler={onErrorLebelLowInLast4WeeksChangeHandler}
            />
          </CheckBoxContainer>
        </Option>
        <Option>
          <CheckBoxContainer onClick={(event) => event.stopPropagation()}>
            <CheckBox
              id="error-level-middle-in-last-4-weeks-checkbox"
              label="直近4週誤差率　20％以上"
              defaultValue={defaultValueForErrorLebelMiddleInLast4Weeks}
              disabled={false}
              onChangeHandler={onErrorLebelMiddleInLast4WeeksChangeHandler}
            />
          </CheckBoxContainer>
        </Option>
        <Option>
          <CheckBoxContainer onClick={(event) => event.stopPropagation()}>
            <CheckBox
              id="error-level-high-in-last-4-weeks-checkbox"
              label="直近4週誤差率　50％以上"
              defaultValue={defaultValueForErrorLebelHighInLast4Weeks}
              disabled={false}
              onChangeHandler={onErrorLebelHighInLast4WeeksChangeHandler}
            />
          </CheckBoxContainer>
        </Option>
        <Option>
          <CheckBoxContainer onClick={(event) => event.stopPropagation()}>
            <CheckBox
              id="error-level-low-in-last-13-weeks-checkbox"
              label="直近13週誤差率　20％未満"
              defaultValue={defaultValueForrrorLebelLowInLast13Weeks}
              disabled={false}
              onChangeHandler={onErrorLebelLowInLast13WeeksChangeHandler}
            />
          </CheckBoxContainer>
        </Option>
        <Option>
          <CheckBoxContainer onClick={(event) => event.stopPropagation()}>
            <CheckBox
              id="error-level-middle-in-last-13-weeks-checkbox"
              label="直近13週誤差率　20％以上"
              defaultValue={defaultValueForErrorLebelMiddleInLast13Weeks}
              disabled={false}
              onChangeHandler={onErrorLebelMiddleInLast13WeeksChangeHandler}
            />
          </CheckBoxContainer>
        </Option>
        <Option>
          <CheckBoxContainer onClick={(event) => event.stopPropagation()}>
            <CheckBox
              id="error-level-high-in-last-13-weeks-checkbox"
              label="直近13週誤差率　50％以上"
              defaultValue={defaultValueForErrorLebelHighInLast13Weeks}
              disabled={false}
              onChangeHandler={onErrorLebelHighInLast13WeeksChangeHandler}
            />
          </CheckBoxContainer>
        </Option>
      </OptionsContainer>
    </Container>
  )
}
