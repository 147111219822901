import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import {
  InventoryUnitSKUInventoryPlanPerWeek,
  DraftPurchaseOrderPlanningInventoryPlanPerWeek,
  Week,
} from '../../../api-client'

import { PurchaseOrdersModalWindow, TextBox, Tooltip } from '../../atoms'

import {
  BaseInventoryCalendarTable,
  InventoryCalendarTableBody,
  InventoryCalendarTableContainer,
  InventoryCalendarTableHeader,
  TextBoxContainer,
  PONoDisplay,
} from './style'

export interface InventoryCalendarTableProps {
  /**
   * 在庫計画
   */
  inventoryCalendar: {
    [key: string]: InventoryUnitSKUInventoryPlanPerWeek | DraftPurchaseOrderPlanningInventoryPlanPerWeek
  }

  /**
   * 元々の在庫計画. 変更を検知するために使う
   */
  inventoryCalendarOrigin: {
    [key: string]: InventoryUnitSKUInventoryPlanPerWeek | DraftPurchaseOrderPlanningInventoryPlanPerWeek
  }

  /**
   * ヤード残数
   */
  yardQuantity: number

  /**
   * 週
   */
  weeks: Week[]

  /**
   * ハイライト対象の週
   */
  highlightedWeekDate: string

  /**
   * スクロール位置
   */
  scrollLeft: number

  /**
   * シミュレーション行を表示するかどうか
   */
  simulationAvailable: boolean

  /**
   * 読み取り専用かどうか
   */
  readonly: boolean

  /**
   * 列がクリックされた時に呼び出されるハンドラー
   * クリックに連動して列をハイライトする挙動を制御するために使う
   * 入力フォームなどがあるセルがクリックされた場合は呼び出されない
   */
  onClickColumnHandler: (weekDate: string) => void

  /**
   * テーブルがスクロールされた時に呼び出されるハンドラー
   */
  onScrollHandler: (scrollLeft: number) => void

  /**
   * シミュレーション値が変更された時に呼び出されるハンドラー
   */
  onReceivingQuantityForSimulationChangeHandler: (weekDate: string, quantity: number | null) => void
}

export const InventoryCalendarTable: React.FC<InventoryCalendarTableProps> = ({
  inventoryCalendar,
  inventoryCalendarOrigin,
  yardQuantity,
  weeks,
  highlightedWeekDate,
  scrollLeft,
  simulationAvailable,
  readonly,
  onClickColumnHandler,
  onScrollHandler,
  onReceivingQuantityForSimulationChangeHandler,
}) => {
  const timeoutId = useRef<number | null>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    // jsdom may not have scrollTo method. Ref: https://github.com/jsdom/jsdom/issues/1422
    if (containerRef.current != null && containerRef.current.scrollTo != null) {
      containerRef.current.scrollTo(scrollLeft, 0)
    }
  }, [scrollLeft])

  const [indexOfVisiblePONumberModal, setIndexOfVisiblePONumberModal] = useState(-1)

  return (
    <InventoryCalendarTableContainer
      ref={containerRef}
      onScroll={(elem) => {
        if (timeoutId.current != null) {
          clearTimeout(timeoutId.current)
          timeoutId.current = null
        }
        const currentTargetScrollLeft = elem.currentTarget.scrollLeft
        timeoutId.current = window.setTimeout(() => {
          onScrollHandler(currentTargetScrollLeft)
        }, 300)
      }}
      data-testid="inventory-calendar-table"
    >
      <BaseInventoryCalendarTable width={180 + 80 * weeks.length}>
        <thead>
          <tr>
            <InventoryCalendarTableHeader></InventoryCalendarTableHeader>
            {weeks.map((week, idx) => (
              <InventoryCalendarTableHeader
                key={idx}
                onClick={() => onClickColumnHandler(week.date)}
                data-testid="inventory-calendar-table-header"
              >
                {week.date}
              </InventoryCalendarTableHeader>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <InventoryCalendarTableBody
              isbackgroundred={false}
              isboldbottom={false}
              ishighlight={false}
              isYellowBackground={false}
            >
              予測在庫数
            </InventoryCalendarTableBody>
            {weeks.map((week, idx) => {
              const isSimulated = (inventoryCalendar[week.date] as InventoryUnitSKUInventoryPlanPerWeek)?.isSimulated
              return (
                <InventoryCalendarTableBody
                  key={idx}
                  isbackgroundred={inventoryCalendar[week.date]?.isInventoryUnitInventoryDeficient === true}
                  isboldbottom={false}
                  ishighlight={week.date === highlightedWeekDate}
                  isYellowBackground={false}
                  onClick={() => onClickColumnHandler(week.date)}
                >
                  {isSimulated === true ? '(' : ''}
                  {inventoryCalendar[week.date]?.inventoryUnitInventoryQuantity != null
                    ? inventoryCalendar[week.date].inventoryUnitInventoryQuantity.toLocaleString()
                    : ''}
                  {isSimulated === true ? ')' : ''}
                </InventoryCalendarTableBody>
              )
            })}
          </tr>
          <tr>
            <InventoryCalendarTableBody
              isbackgroundred={false}
              isboldbottom={false}
              ishighlight={false}
              isYellowBackground={false}
            >
              予測在庫週数
            </InventoryCalendarTableBody>
            {weeks.map((week, idx) => {
              const isSimulated = (inventoryCalendar[week.date] as InventoryUnitSKUInventoryPlanPerWeek)?.isSimulated
              const numberOfInventoryUnitInventoryWeeks =
                inventoryCalendar[week.date]?.numberOfInventoryUnitInventoryWeeks != null
                  ? inventoryCalendar[week.date]?.numberOfInventoryUnitInventoryWeeks
                  : null
              return (
                <InventoryCalendarTableBody
                  key={idx}
                  isbackgroundred={false}
                  isboldbottom={false}
                  ishighlight={week.date === highlightedWeekDate}
                  isYellowBackground={false}
                  onClick={() => onClickColumnHandler(week.date)}
                  data-testid="inventory-calendar-table-cell-number-of-inventoryUnit-inventory-weeks"
                >
                  {numberOfInventoryUnitInventoryWeeks != null && isSimulated === true ? '(' : ''}
                  {numberOfInventoryUnitInventoryWeeks != null
                    ? numberOfInventoryUnitInventoryWeeks.toLocaleString()
                    : ''}
                  {numberOfInventoryUnitInventoryWeeks != null && isSimulated === true ? ')' : ''}
                </InventoryCalendarTableBody>
              )
            })}
          </tr>
          <tr>
            <InventoryCalendarTableBody
              isbackgroundred={false}
              isboldbottom={false}
              ishighlight={false}
              isYellowBackground={false}
            >
              出荷予定数
            </InventoryCalendarTableBody>
            {weeks.map((week, idx) => {
              const inventoryUnitShipmentQuantity =
                inventoryCalendar[week.date] != null ? inventoryCalendar[week.date].inventoryUnitShipmentQuantity : null
              return (
                <InventoryCalendarTableBody
                  key={idx}
                  isbackgroundred={false}
                  isboldbottom={false}
                  ishighlight={week.date === highlightedWeekDate}
                  isYellowBackground={false}
                  onClick={() => onClickColumnHandler(week.date)}
                >
                  {inventoryUnitShipmentQuantity != null ? inventoryUnitShipmentQuantity.toLocaleString() : ''}
                </InventoryCalendarTableBody>
              )
            })}
          </tr>
          <tr>
            <InventoryCalendarTableBody
              isbackgroundred={false}
              isboldbottom={false}
              ishighlight={false}
              isYellowBackground={false}
            >
              P/O No.
            </InventoryCalendarTableBody>
            {weeks.map((week, idx) => {
              const targetWeek = inventoryCalendar[week.date]
              // TODO: 連想配列でキーからの取得失敗時(targetWeek===undefined)は想定しないはずなのでエラーハンドリングを行う
              if (targetWeek == null) {
                return null
              }
              const inventoryUnitReceivings = targetWeek.inventoryUnitReceivings
              const unreceivedOrders = targetWeek.unreceivedOrders
              const unconfirmedInventoryUnitReceivings =
                'unconfirmedInventoryUnitReceivings' in targetWeek ? targetWeek.unconfirmedInventoryUnitReceivings : []

              return (
                <InventoryCalendarTableBody
                  key={idx}
                  isbackgroundred={false}
                  isboldbottom={false}
                  ishighlight={week.date === highlightedWeekDate}
                  isYellowBackground={false}
                  onClick={() => onClickColumnHandler(week.date)}
                >
                  {(() => {
                    const quantity =
                      inventoryUnitReceivings.length +
                      unconfirmedInventoryUnitReceivings.length +
                      unreceivedOrders.length
                    const displayString =
                      unconfirmedInventoryUnitReceivings.length > 0 ? `(${quantity} P/O)` : `${quantity} P/O`

                    // 当週
                    if (idx === 0) {
                      return (
                        <>
                          <Link
                            to="#"
                            onClick={() => setIndexOfVisiblePONumberModal(idx)}
                            data-testid="inventory-calendar-purchase-orders-modal-window-link"
                          >
                            {displayString}
                          </Link>
                          {/* モーダル設定 */}
                          {idx === indexOfVisiblePONumberModal ? (
                            <PurchaseOrdersModalWindow
                              unconfirmedInventoryUnitReceivings={unconfirmedInventoryUnitReceivings}
                              inventoryUnitReceivings={inventoryUnitReceivings}
                              unreceivedOrders={unreceivedOrders}
                              yardQuantity={yardQuantity}
                              onCloseHandler={() => setIndexOfVisiblePONumberModal(-1)}
                              thisWeek={week}
                            />
                          ) : null}
                        </>
                      )
                    }

                    // 次週以降
                    if (
                      inventoryUnitReceivings.length === 0 &&
                      unconfirmedInventoryUnitReceivings.length === 0 &&
                      unreceivedOrders.length === 0
                    ) {
                      return null
                    }
                    if (
                      inventoryUnitReceivings.length === 1 &&
                      unreceivedOrders.length === 0 &&
                      unconfirmedInventoryUnitReceivings.length === 0
                    ) {
                      return <PONoDisplay>{inventoryUnitReceivings[0].purchaseOrderNumber}</PONoDisplay>
                    }
                    if (
                      inventoryUnitReceivings.length === 0 &&
                      unreceivedOrders.length === 1 &&
                      unconfirmedInventoryUnitReceivings.length === 0
                    ) {
                      return (
                        <PONoDisplay>{unreceivedOrders[0].inventoryUnitReceivings.purchaseOrderNumber}</PONoDisplay>
                      )
                    }

                    return (
                      <>
                        <Link
                          to="#"
                          onClick={() => setIndexOfVisiblePONumberModal(idx)}
                          data-testid="inventory-calendar-purchase-orders-modal-window-link"
                        >
                          {displayString}
                        </Link>
                        {/* モーダル設定 */}
                        {idx === indexOfVisiblePONumberModal ? (
                          <PurchaseOrdersModalWindow
                            unconfirmedInventoryUnitReceivings={unconfirmedInventoryUnitReceivings}
                            inventoryUnitReceivings={inventoryUnitReceivings}
                            unreceivedOrders={unreceivedOrders}
                            yardQuantity={undefined}
                            onCloseHandler={() => setIndexOfVisiblePONumberModal(-1)}
                            thisWeek={week}
                          />
                        ) : null}
                      </>
                    )
                  })()}
                </InventoryCalendarTableBody>
              )
            })}
          </tr>
          <tr>
            <InventoryCalendarTableBody
              isbackgroundred={false}
              isboldbottom={false}
              ishighlight={false}
              isYellowBackground={false}
            >
              入荷予定数
            </InventoryCalendarTableBody>
            {weeks.map((week, idx) => {
              const targetWeek = inventoryCalendar[week.date]
              // TODO: 連想配列でキーからの取得失敗時(targetWeek===undefined)は想定しないはずなのでエラーハンドリングを行う
              if (targetWeek == null) {
                return null
              }

              const inventoryUnitReceivings = targetWeek.inventoryUnitReceivings
              const unreceivedOrders = targetWeek.unreceivedOrders
              const unconfirmedInventoryUnitReceivings =
                'unconfirmedInventoryUnitReceivings' in targetWeek ? targetWeek.unconfirmedInventoryUnitReceivings : []

              // 当週
              if (idx === 0) {
                const quantity =
                  inventoryUnitReceivings.reduce((a, b) => a + b.quantity, 0) +
                  unconfirmedInventoryUnitReceivings.reduce((a, b) => a + b.quantity, 0) +
                  unreceivedOrders.reduce((a, b) => a + b.inventoryUnitReceivings.quantity, 0) +
                  yardQuantity
                const hasYardOrUnreceivedOrder =
                  yardQuantity > 0 || unreceivedOrders.reduce((a, b) => a + b.inventoryUnitReceivings.quantity, 0) > 0

                return (
                  <InventoryCalendarTableBody
                    key={idx}
                    isbackgroundred={false}
                    isboldbottom={false}
                    ishighlight={week.date === highlightedWeekDate}
                    isYellowBackground={hasYardOrUnreceivedOrder}
                    onClick={() => onClickColumnHandler(week.date)}
                    data-testid="inventory-calendar-table-body-receiving-quantity"
                  >
                    <span data-testid="inventoryUnit-receiving-count">{quantity.toLocaleString()}</span>
                  </InventoryCalendarTableBody>
                )
              }

              // 次週以降
              const hasReceivings = inventoryUnitReceivings.length > 0 || unconfirmedInventoryUnitReceivings.length > 0
              const quantity =
                inventoryUnitReceivings.reduce((a, b) => a + b.quantity, 0) +
                unconfirmedInventoryUnitReceivings.reduce((a, b) => a + b.quantity, 0)

              return (
                <InventoryCalendarTableBody
                  key={idx}
                  isbackgroundred={false}
                  isboldbottom={false}
                  ishighlight={week.date === highlightedWeekDate}
                  isYellowBackground={false}
                  onClick={() => onClickColumnHandler(week.date)}
                  data-testid="inventory-calendar-table-body-receiving-quantity"
                >
                  {hasReceivings ? (
                    <span data-testid="inventoryUnit-receiving-count">{quantity.toLocaleString()}</span>
                  ) : null}
                </InventoryCalendarTableBody>
              )
            })}
          </tr>
          {simulationAvailable === true ? (
            <tr>
              <InventoryCalendarTableBody
                isbackgroundred={false}
                isboldbottom={true}
                ishighlight={false}
                isYellowBackground={false}
              >
                入出荷シミュレーション
              </InventoryCalendarTableBody>
              {weeks.map((week, idx) => {
                const receivingQuantityForSimulation =
                  (inventoryCalendar[week.date] as InventoryUnitSKUInventoryPlanPerWeek)
                    ?.receivingQuantityForSimulation != null
                    ? (inventoryCalendar[week.date] as InventoryUnitSKUInventoryPlanPerWeek)
                        .receivingQuantityForSimulation
                    : null
                const receivingQuantityForSimulationOrigin =
                  (inventoryCalendarOrigin[week.date] as InventoryUnitSKUInventoryPlanPerWeek)
                    ?.receivingQuantityForSimulation != null
                    ? (inventoryCalendarOrigin[week.date] as InventoryUnitSKUInventoryPlanPerWeek)
                        .receivingQuantityForSimulation
                    : null
                return (
                  <InventoryCalendarTableBody
                    key={idx}
                    isbackgroundred={false}
                    isboldbottom={true}
                    ishighlight={week.date === highlightedWeekDate}
                    isYellowBackground={false}
                  >
                    <TextBoxContainer>
                      <TextBox
                        id="receiving-quantity-for-simulation-input"
                        type="number"
                        width={77}
                        height={22}
                        padding={6}
                        defaultValue={
                          receivingQuantityForSimulation != null ? receivingQuantityForSimulation.toString() : ''
                        }
                        disabled={readonly}
                        min={-9999999}
                        max={99999999}
                        forceValidate={true}
                        suppressErrorMessage={true}
                        highlightOnChange={true}
                        forceHighlight={receivingQuantityForSimulation !== receivingQuantityForSimulationOrigin}
                        onChangeHandler={(value) => {
                          let quantity: number | null = Number.parseInt(value)
                          if (Number.isNaN(quantity)) {
                            quantity = null
                          }
                          if (quantity !== receivingQuantityForSimulation) {
                            onReceivingQuantityForSimulationChangeHandler(week.date, quantity)
                          }
                        }}
                      />
                      {receivingQuantityForSimulation != null && receivingQuantityForSimulation > 99999999 ? (
                        <Tooltip text={'99,999,999以内の半角数字で入力して下さい。'} width={300} top={22} />
                      ) : null}
                      {receivingQuantityForSimulation != null && receivingQuantityForSimulation < -9999999 ? (
                        <Tooltip text={'-9,999,999以上の半角数字で入力して下さい。'} width={300} top={22} />
                      ) : null}
                    </TextBoxContainer>
                  </InventoryCalendarTableBody>
                )
              })}
            </tr>
          ) : null}
          <tr>
            <InventoryCalendarTableBody
              isbackgroundred={false}
              isboldbottom={false}
              ishighlight={false}
              isYellowBackground={false}
            >
              予測店舗在庫数
            </InventoryCalendarTableBody>
            {weeks.map((week, idx) => {
              const isSimulated = (inventoryCalendar[week.date] as InventoryUnitSKUInventoryPlanPerWeek)?.isSimulated
              return (
                <InventoryCalendarTableBody
                  key={idx}
                  isbackgroundred={inventoryCalendar[week.date]?.isStoreInventoryStandardUnachieved === true}
                  isboldbottom={false}
                  ishighlight={week.date === highlightedWeekDate}
                  isYellowBackground={false}
                  onClick={() => onClickColumnHandler(week.date)}
                >
                  {isSimulated === true ? '(' : ''}
                  {inventoryCalendar[week.date]?.storeInventoryQuantity != null
                    ? inventoryCalendar[week.date].storeInventoryQuantity.toLocaleString()
                    : ''}
                  {isSimulated === true ? ')' : ''}
                </InventoryCalendarTableBody>
              )
            })}
          </tr>
        </tbody>
      </BaseInventoryCalendarTable>
    </InventoryCalendarTableContainer>
  )
}
