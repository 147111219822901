import styled from 'styled-components'

const BaseOrderGroupsListTable = styled.table`
  border: 1px solid var(--di-table-border-color);
  width: 100%;
`

const FilledRoundLabelContainer = styled.div`
  display: flex;
  justify-content: center;
`

const OrderGroupsListTableBodyRow = styled.tr<{pointer: boolean}>`
  background-color: #fff;
  border-top: 1px solid var(--di-table-border-color);
  cursor: ${props => props.pointer === true? 'pointer' : 'auto'};
  height: 40px;

  &:hover {
    background-color: var(--di-table-body-bg-hover-color);
  }
`

const OrdreGroupsListTableData = styled.td<{
  textalign: string
}>`
  padding-left: 40px;
  text-align: ${props => props.textalign};
`

const OrderGroupsListTableHeader = styled.th<{
  textalign: string
  width?: number
}>`
  background-color: #f1f1f1;
  border-bottom: 2px solid var(--di-table-border-color);
  height: 39px;
  padding-left: 40px;
  text-align: ${props => props.textalign};
  width: ${props => props.width != null? `${props.width}px` : 'auto'};
`

export {
  BaseOrderGroupsListTable,
  FilledRoundLabelContainer,
  OrderGroupsListTableBodyRow,
  OrdreGroupsListTableData,
  OrderGroupsListTableHeader,
}
