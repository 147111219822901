import React from 'react'

import { InventoryUnit, InventoryUnitSKU, SKU } from '../../../api-client'

import { Button } from '../../atoms'

import {
  Background,
  ButtonContainer,
  CancelIcon,
  CancelIconContainer,
  Container,
  ListsContainer,
  Title,
  Window,
} from './style'

export interface SimulationResetConfirmationModalProps {
  /**
   * InventoryUnitの一覧
   */
  inventoryUnits: Array<InventoryUnit>

  /**
   * SKUの一覧
   */
  skus: Array<SKU>

  /**
   * SKUxInventoryUnitの一覧
   */
  inventoryUnitSKUs: Array<InventoryUnitSKU>

  /**
   * 実行ボタンが押された時に呼び出されるハンドラー
   */
  onSubmitHandler: () => void

  /**
   * 閉じるボタンが押された時に呼び出されるハンドラー
   * 透過背景がクリックされたときにも呼び出される
   */
  onCloseHandler: () => void
}

export const SimulationResetConfirmationModal: React.FC<SimulationResetConfirmationModalProps> = ({
  inventoryUnits,
  skus,
  inventoryUnitSKUs,
  onSubmitHandler,
  onCloseHandler,
}) => {
  return (
    <Background onClick={() => onCloseHandler()}>
      <Window>
        <CancelIconContainer
          onClick={() => onCloseHandler()}
          data-testid="simulation-reset-confirmation-modal-close-icon"
        >
          <CancelIcon />
        </CancelIconContainer>
        <Container onClick={(e) => e.stopPropagation()} data-testid="simulation-reset-confirmation-modal-body">
          <Title>以下の入出荷シミュレーションを削除します。</Title>
          <ListsContainer>
            <ul>
              {inventoryUnitSKUs.map((inventoryUnitSKU) => {
                const inventoryUnit = inventoryUnits.find(
                  (inventoryUnit) => inventoryUnit.code === inventoryUnitSKU.inventoryUnitCode
                )
                const sku = skus.find((sku) => sku.code === inventoryUnitSKU.janCode)
                return inventoryUnit != null && sku != null ? (
                  <li
                    key={`${inventoryUnitSKU.inventoryUnitCode}:${inventoryUnitSKU.janCode}`}
                  >{`【${inventoryUnit.name}(${inventoryUnit.logisticPatternName})】 ${sku.name}・${sku.specName}`}</li>
                ) : null
              })}
            </ul>
          </ListsContainer>
          <ButtonContainer>
            <Button
              styleType="primary"
              label="OK"
              width={68}
              onClickHandler={onSubmitHandler}
              data-testid="simulation-reset-confirmation-modal-submit-button"
            />
          </ButtonContainer>
        </Container>
      </Window>
    </Background>
  )
}
