import { Dispatch, SetStateAction, useEffect, useLayoutEffect } from "react"
import { useLocation } from "react-router-dom"
import { useReloadState } from "../../hooks/useReloadState"
import { useTypeConverter } from "../../hooks/useTypeConverter"
import { TabType } from "./useSearch.hooks"

export const useReload = (
  setReload: Dispatch<SetStateAction<boolean>>,
  selectedTab: TabType,
  setSelectedTab: Dispatch<SetStateAction<TabType>>,
  selectedSKUIndex: number,
  filterByErrorLebelLowInLast4WeeksForSKUTab: boolean,
  filterByErrorLebelMiddleInLast4WeeksForSKUTab: boolean,
  filterByErrorLebelHighInLast4WeeksForSKUTab: boolean,
  filterByErrorLebelLowInLast13WeeksForSKUTab: boolean,
  filterByErrorLebelMiddleInLast13WeeksForSKUTab: boolean,
  filterByErrorLebelHighInLast13WeeksForSKUTab: boolean,
  filterByAdjustmentTargetForSKUTab: boolean,
  filterBySimulatedValueForSKUTab: boolean,
  setSelectedSKUIndex: Dispatch<SetStateAction<number>>,
  setFilterByErrorLebelLowInLast4WeeksForSKUTab: Dispatch<SetStateAction<boolean>>,
  setFilterByErrorLebelMiddleInLast4WeeksForSKUTab: Dispatch<SetStateAction<boolean>>,
  setFilterByErrorLebelHighInLast4WeeksForSKUTab: Dispatch<SetStateAction<boolean>>,
  setFilterByErrorLebelLowInLast13WeeksForSKUTab: Dispatch<SetStateAction<boolean>>,
  setFilterByErrorLebelMiddleInLast13WeeksForSKUTab: Dispatch<SetStateAction<boolean>>,
  setFilterByErrorLebelHighInLast13WeeksForSKUTab: Dispatch<SetStateAction<boolean>>,
  setFilterByAdjustmentTargetForSKUTab: Dispatch<SetStateAction<boolean>>,
  setFilterBySimulatedValueForSKUTab: Dispatch<SetStateAction<boolean>>,
  allDistributionCentersSelected:  React.MutableRefObject<boolean>,
  selectedDistributionCenterIndex: React.MutableRefObject<number>,
  filterByErrorLebelLowInLast4WeeksForInventoryUnitTab: boolean,
  filterByErrorLebelMiddleInLast4WeeksForInventoryUnitTab: boolean,
  filterByErrorLebelHighInLast4WeeksForInventoryUnitTab: boolean,
  filterByErrorLebelLowInLast13WeeksForInventoryUnitTab: boolean,
  filterByErrorLebelMiddleInLast13WeeksForInventoryUnitTab: boolean,
  filterByErrorLebelHighInLast13WeeksForInventoryUnitTab: boolean,
  filterByAdjustmentTargetForInventoryUnitTab: boolean,
  filterBySimulatedValueForInventoryUnitTab: boolean,
  filterByTextForInventoryUnitTab: string,
  setFilterByErrorLebelLowInLast4WeeksForInventoryUnitTab: Dispatch<SetStateAction<boolean>>,
  setFilterByErrorLebelMiddleInLast4WeeksForInventoryUnitTab: Dispatch<SetStateAction<boolean>>,
  setFilterByErrorLebelHighInLast4WeeksForInventoryUnitTab: Dispatch<SetStateAction<boolean>>,
  setFilterByErrorLebelLowInLast13WeeksForInventoryUnitTab: Dispatch<SetStateAction<boolean>>,
  setFilterByErrorLebelMiddleInLast13WeeksForInventoryUnitTab: Dispatch<SetStateAction<boolean>>,
  setFilterByErrorLebelHighInLast13WeeksForInventoryUnitTab: Dispatch<SetStateAction<boolean>>,
  setFilterByAdjustmentTargetForInventoryUnitTab: Dispatch<SetStateAction<boolean>>,
  setFilterBySimulatedValueForInventoryUnitTab: Dispatch<SetStateAction<boolean>>,
  setFilterByTextForInventoryUnitTab: Dispatch<SetStateAction<string>>,
) => {
  const location = useLocation()
  const reloadStatusKey = 'reload-inventory-plan'
  const reloadLocationKey = 'reload-inventory-plan-location'

  const reloadHooks = useReloadState(reloadStatusKey, reloadLocationKey, location.pathname)

  // reload時のみ、状態を復元する
  const typeConverter = useTypeConverter()
  useLayoutEffect(() => {
    // 別ページ → URL直打ち されるとreload状態になってしまうのでreloadかどうかだけでなくどこから来たかを確認している
    if (sessionStorage.getItem(reloadStatusKey) === "reload" && sessionStorage.getItem(reloadLocationKey) === location.pathname) {
      // common
      setSelectedTab(JSON.parse(sessionStorage.getItem('selectedTab') as string))
      // SKU
      setSelectedSKUIndex(typeConverter.stringToNumber(JSON.parse(sessionStorage.getItem('selectedSKUIndex') as string)))
      setFilterByErrorLebelLowInLast4WeeksForSKUTab(typeConverter.stringToBoolean(JSON.parse(sessionStorage.getItem('filterByErrorLebelLowInLast4WeeksForSKUTab') as string)))
      setFilterByErrorLebelMiddleInLast4WeeksForSKUTab(typeConverter.stringToBoolean(JSON.parse(sessionStorage.getItem('filterByErrorLebelMiddleInLast4WeeksForSKUTab') as string)))
      setFilterByErrorLebelHighInLast4WeeksForSKUTab(typeConverter.stringToBoolean(JSON.parse(sessionStorage.getItem('filterByErrorLebelHighInLast4WeeksForSKUTab') as string)))
      setFilterByErrorLebelLowInLast13WeeksForSKUTab(typeConverter.stringToBoolean(JSON.parse(sessionStorage.getItem('filterByErrorLebelLowInLast13WeeksForSKUTab') as string)))
      setFilterByErrorLebelMiddleInLast13WeeksForSKUTab(typeConverter.stringToBoolean(JSON.parse(sessionStorage.getItem('filterByErrorLebelMiddleInLast13WeeksForSKUTab') as string)))
      setFilterByErrorLebelHighInLast13WeeksForSKUTab(typeConverter.stringToBoolean(JSON.parse(sessionStorage.getItem('filterByErrorLebelHighInLast13WeeksForSKUTab') as string)))
      setFilterByAdjustmentTargetForSKUTab(typeConverter.stringToBoolean(JSON.parse(sessionStorage.getItem('filterByAdjustmentTargetForSKUTab') as string)))
      setFilterBySimulatedValueForSKUTab(typeConverter.stringToBoolean(JSON.parse(sessionStorage.getItem('filterBySimulatedValueForSKUTab') as string)))
      // Inventory
      selectedDistributionCenterIndex.current = typeConverter.stringToNumber(JSON.parse(sessionStorage.getItem('selectedDistributionCenterIndex') as string))
      allDistributionCentersSelected.current = typeConverter.stringToBoolean(JSON.parse(sessionStorage.getItem('allDistributionCentersSelected') as string))
      setFilterByErrorLebelLowInLast4WeeksForInventoryUnitTab(typeConverter.stringToBoolean(JSON.parse(sessionStorage.getItem('filterByErrorLebelLowInLast4WeeksForInventoryUnitTab') as string)))
      setFilterByErrorLebelMiddleInLast4WeeksForInventoryUnitTab(typeConverter.stringToBoolean(JSON.parse(sessionStorage.getItem('filterByErrorLebelMiddleInLast4WeeksForInventoryUnitTab') as string)))
      setFilterByErrorLebelHighInLast4WeeksForInventoryUnitTab(typeConverter.stringToBoolean(JSON.parse(sessionStorage.getItem('filterByErrorLebelHighInLast4WeeksForInventoryUnitTab') as string)))
      setFilterByErrorLebelLowInLast13WeeksForInventoryUnitTab(typeConverter.stringToBoolean(JSON.parse(sessionStorage.getItem('filterByErrorLebelLowInLast13WeeksForInventoryUnitTab') as string)))
      setFilterByErrorLebelMiddleInLast13WeeksForInventoryUnitTab(typeConverter.stringToBoolean(JSON.parse(sessionStorage.getItem('filterByErrorLebelMiddleInLast13WeeksForInventoryUnitTab') as string)))
      setFilterByErrorLebelHighInLast13WeeksForInventoryUnitTab(typeConverter.stringToBoolean(JSON.parse(sessionStorage.getItem('filterByErrorLebelHighInLast13WeeksForInventoryUnitTab') as string)))
      setFilterByAdjustmentTargetForInventoryUnitTab(typeConverter.stringToBoolean(JSON.parse(sessionStorage.getItem('filterByAdjustmentTargetForInventoryUnitTab') as string)))
      setFilterBySimulatedValueForInventoryUnitTab(typeConverter.stringToBoolean(JSON.parse(sessionStorage.getItem('filterBySimulatedValueForInventoryUnitTab') as string)))
      setFilterByTextForInventoryUnitTab(sessionStorage.getItem('filterByTextForInventoryUnitTab') as string)

      setReload(true) // リロード時かつ海外InventoryUnitの場合、初期値を入れる処理をスキップするために用意
    }

    reloadHooks.resetReloadState()
    // eslint-disable-next-line
  }, []);

  // 選択状態を保持する
  useEffect(() => {
    sessionStorage.setItem('selectedTab', JSON.stringify(selectedTab))
    // SKU
    sessionStorage.setItem('selectedSKUIndex', JSON.stringify(selectedSKUIndex))
    sessionStorage.setItem('filterByErrorLebelLowInLast4WeeksForSKUTab', JSON.stringify(filterByErrorLebelLowInLast4WeeksForSKUTab))
    sessionStorage.setItem('filterByErrorLebelMiddleInLast4WeeksForSKUTab', JSON.stringify(filterByErrorLebelMiddleInLast4WeeksForSKUTab))
    sessionStorage.setItem('filterByErrorLebelHighInLast4WeeksForSKUTab', JSON.stringify(filterByErrorLebelHighInLast4WeeksForSKUTab))
    sessionStorage.setItem('filterByErrorLebelLowInLast13WeeksForSKUTab', JSON.stringify(filterByErrorLebelLowInLast13WeeksForSKUTab))
    sessionStorage.setItem('filterByErrorLebelMiddleInLast13WeeksForSKUTab', JSON.stringify(filterByErrorLebelMiddleInLast13WeeksForSKUTab))
    sessionStorage.setItem('filterByErrorLebelHighInLast13WeeksForSKUTab', JSON.stringify(filterByErrorLebelHighInLast13WeeksForSKUTab))
    sessionStorage.setItem('filterByAdjustmentTargetForSKUTab', JSON.stringify(filterByAdjustmentTargetForSKUTab))
    sessionStorage.setItem('filterBySimulatedValueForSKUTab', JSON.stringify(filterBySimulatedValueForSKUTab))
    // Inventory
    sessionStorage.setItem('filterByErrorLebelLowInLast4WeeksForInventoryUnitTab', JSON.stringify(filterByErrorLebelLowInLast4WeeksForInventoryUnitTab))
    sessionStorage.setItem('filterByErrorLebelMiddleInLast4WeeksForInventoryUnitTab', JSON.stringify(filterByErrorLebelMiddleInLast4WeeksForInventoryUnitTab))
    sessionStorage.setItem('filterByErrorLebelHighInLast4WeeksForInventoryUnitTab', JSON.stringify(filterByErrorLebelHighInLast4WeeksForInventoryUnitTab))
    sessionStorage.setItem('filterByErrorLebelLowInLast13WeeksForInventoryUnitTab', JSON.stringify(filterByErrorLebelLowInLast13WeeksForInventoryUnitTab))
    sessionStorage.setItem('filterByErrorLebelMiddleInLast13WeeksForInventoryUnitTab', JSON.stringify(filterByErrorLebelMiddleInLast13WeeksForInventoryUnitTab))
    sessionStorage.setItem('filterByErrorLebelHighInLast13WeeksForInventoryUnitTab', JSON.stringify(filterByErrorLebelHighInLast13WeeksForInventoryUnitTab))
    sessionStorage.setItem('filterByAdjustmentTargetForInventoryUnitTab', JSON.stringify(filterByAdjustmentTargetForInventoryUnitTab))
    sessionStorage.setItem('filterBySimulatedValueForInventoryUnitTab', JSON.stringify(filterBySimulatedValueForInventoryUnitTab))
    sessionStorage.setItem('filterByTextForInventoryUnitTab', filterByTextForInventoryUnitTab)
    // eslint-disable-next-line
  },[
    selectedTab,
    selectedSKUIndex,
    filterByErrorLebelLowInLast4WeeksForSKUTab,
    filterByErrorLebelMiddleInLast4WeeksForSKUTab,
    filterByErrorLebelHighInLast4WeeksForSKUTab,
    filterByErrorLebelLowInLast13WeeksForSKUTab,
    filterByErrorLebelMiddleInLast13WeeksForSKUTab,
    filterByErrorLebelHighInLast13WeeksForSKUTab,
    filterByAdjustmentTargetForSKUTab,
    filterBySimulatedValueForSKUTab,
    filterByErrorLebelLowInLast4WeeksForInventoryUnitTab,
    filterByErrorLebelMiddleInLast4WeeksForInventoryUnitTab,
    filterByErrorLebelHighInLast4WeeksForInventoryUnitTab,
    filterByErrorLebelLowInLast13WeeksForInventoryUnitTab,
    filterByErrorLebelMiddleInLast13WeeksForInventoryUnitTab,
    filterByErrorLebelHighInLast13WeeksForInventoryUnitTab,
    filterByAdjustmentTargetForInventoryUnitTab,
    filterBySimulatedValueForInventoryUnitTab,
    filterByTextForInventoryUnitTab,
  ])

  // リロード直前のみ発火するイベント(useStateはreload直前では破棄されるのでここで格納していない)
  window.addEventListener('beforeunload', () => {
    sessionStorage.setItem(reloadStatusKey, "reload")
    sessionStorage.setItem(reloadLocationKey,  location.pathname)

    // refはuseStateで監視できないのでreload直前に格納する
    sessionStorage.setItem('selectedDistributionCenterIndex', JSON.stringify(selectedDistributionCenterIndex.current))
    sessionStorage.setItem('allDistributionCentersSelected', JSON.stringify(allDistributionCentersSelected.current))
  })

  // 他のページに影響が出る可能性があるのでページ離脱の時は中身を削除
  window.addEventListener('popstate', () => {
    reloadHooks.resetReloadEvent()

    // common
    sessionStorage.removeItem('selectedTab')
    // SKU
    sessionStorage.removeItem('selectedSKUIndex')
    sessionStorage.removeItem('filterByErrorLebelLowInLast4WeeksForSKUTab')
    sessionStorage.removeItem('filterByErrorLebelMiddleInLast4WeeksForSKUTab')
    sessionStorage.removeItem('filterByErrorLebelHighInLast4WeeksForSKUTab')
    sessionStorage.removeItem('filterByErrorLebelLowInLast13WeeksForSKUTab')
    sessionStorage.removeItem('filterByErrorLebelMiddleInLast13WeeksForSKUTab')
    sessionStorage.removeItem('filterByErrorLebelHighInLast13WeeksForSKUTab')
    sessionStorage.removeItem('filterByAdjustmentTargetForSKUTab')
    sessionStorage.removeItem('filterBySimulatedValueForSKUTab')
    // Inventory
    sessionStorage.removeItem('selectedDistributionCenterIndex')
    sessionStorage.removeItem('allDistributionCentersSelected')
    sessionStorage.removeItem('filterByErrorLebelLowInLast4WeeksForInventoryUnitTab')
    sessionStorage.removeItem('filterByErrorLebelMiddleInLast4WeeksForInventoryUnitTab')
    sessionStorage.removeItem('filterByErrorLebelHighInLast4WeeksForInventoryUnitTab')
    sessionStorage.removeItem('filterByErrorLebelLowInLast13WeeksForInventoryUnitTab')
    sessionStorage.removeItem('filterByErrorLebelMiddleInLast13WeeksForInventoryUnitTab')
    sessionStorage.removeItem('filterByErrorLebelHighInLast13WeeksForInventoryUnitTab')
    sessionStorage.removeItem('filterByAdjustmentTargetForInventoryUnitTab')
    sessionStorage.removeItem('filterBySimulatedValueForInventoryUnitTab')
    sessionStorage.removeItem('filterByTextForInventoryUnitTab')
  },{'once': true})
}
