import React, { useState } from 'react'

import { Button, TextBox } from '../../atoms'

import { EmailInputContainer, PasswordInputContainer } from './style'

export interface LoginFormProps {
  /**
   * ログインボタンが押された時に呼び出されるハンドラー
   */
  onLoginClick: (email: string, password: string) => void
}

export const LoginForm: React.FC<LoginFormProps> = ({ onLoginClick }) => {
  // Ref: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#basic_validation
  const emailValidationRegex = new RegExp(
    "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$",
    ''
  )

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [forceValidate, setForceValidate] = useState(false)

  return (
    <div>
      <EmailInputContainer>
        <TextBox
          id="login-form-email-input"
          label="メールアドレス"
          width={427}
          height={40}
          type="email"
          required={true}
          defaultValue={email}
          forceValidate={forceValidate}
          onChangeHandler={(value: string) => setEmail(value)}
        />
      </EmailInputContainer>
      <PasswordInputContainer>
        <TextBox
          id="login-form-password-input"
          label="パスワード"
          width={427}
          height={40}
          type="password"
          required={true}
          defaultValue={password}
          forceValidate={forceValidate}
          onChangeHandler={(value: string) => setPassword(value)}
        />
      </PasswordInputContainer>
      <Button
        styleType="primary"
        label="ログイン"
        width={427}
        onClickHandler={() => {
          setForceValidate(true)
          if (email === '' || emailValidationRegex.test(email) === false) {
            return
          }
          if (password === '') {
            return
          }
          onLoginClick(email, password)
        }}
        data-testid="login-form-submit-button"
      />
    </div>
  )
}
