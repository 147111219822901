import React from 'react'
import { useHistory } from 'react-router-dom'

import { ContainerPlanningOrderGroup } from '../../../api-client'

import { OrderTimingLabel } from '../../molecules'

import {
  BaseOrderGroupsWithDraftPOListTable,
  OrderGroupsWithDraftPOListTableBodyRow,
  OrderGroupsWithDraftPOListTableData,
  OrderGroupsWithDraftPOListTableHeader,
  OrderGroupName,
} from './style'

import './OrderGroupsWithDraftPOListTable.css'

export interface OrderGroupsWithDraftPOListTableProps {
  /**
   * 発注グループ一覧
   */
  orderGroups: Array<ContainerPlanningOrderGroup>
}

export const OrderGroupsWithDraftPOListTable: React.FC<OrderGroupsWithDraftPOListTableProps> = ({ orderGroups }) => {
  const history = useHistory()

  return (
    <BaseOrderGroupsWithDraftPOListTable>
      <thead>
        <tr>
          <OrderGroupsWithDraftPOListTableHeader width={140}>発注グループ</OrderGroupsWithDraftPOListTableHeader>
          <OrderGroupsWithDraftPOListTableHeader width={60}>部門</OrderGroupsWithDraftPOListTableHeader>
          <OrderGroupsWithDraftPOListTableHeader width={160}>最終計算日時</OrderGroupsWithDraftPOListTableHeader>
          <OrderGroupsWithDraftPOListTableHeader width={120}>担当者</OrderGroupsWithDraftPOListTableHeader>
          <OrderGroupsWithDraftPOListTableHeader></OrderGroupsWithDraftPOListTableHeader>
        </tr>
      </thead>
      <tbody>
        {orderGroups.map((orderGroup) => (
          <OrderGroupsWithDraftPOListTableBodyRow
            key={orderGroup.orderGroupId}
            onClick={() => {
              history.push(`/order-groups/${orderGroup.orderGroupId}/draft-purchase-order`)
            }}
          >
            <OrderGroupsWithDraftPOListTableData>
              <OrderGroupName>{orderGroup.orderGroupName}</OrderGroupName>
            </OrderGroupsWithDraftPOListTableData>
            <OrderGroupsWithDraftPOListTableData>
              {orderGroup.departmentCodes.length === 0
                ? '-'
                : orderGroup.departmentCodes.length === 1
                ? orderGroup.departmentCodes[0]
                : '複数部門'}
            </OrderGroupsWithDraftPOListTableData>
            <OrderGroupsWithDraftPOListTableData>{`${orderGroup.calculatedAt.getFullYear()}年${
              orderGroup.calculatedAt.getMonth() + 1
            }月${orderGroup.calculatedAt.getDate()}日 ${orderGroup.calculatedAt.getHours()}:${(
              '00' + orderGroup.calculatedAt.getMinutes()
            ).slice(-2)}`}</OrderGroupsWithDraftPOListTableData>
            <OrderGroupsWithDraftPOListTableData>{orderGroup.operatorName}</OrderGroupsWithDraftPOListTableData>
            <td className="td-supplier">
              <span className="dashboard-order-timing-label-container">
                <OrderTimingLabel orderTiming={orderGroup.orderTiming} />
              </span>
            </td>
          </OrderGroupsWithDraftPOListTableBodyRow>
        ))}
      </tbody>
    </BaseOrderGroupsWithDraftPOListTable>
  )
}
