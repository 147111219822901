import styled from 'styled-components'

import { ReactComponent as BaseCaretIcon } from '../../../assets/images/caret-down.svg'

const CaretIcon = styled(BaseCaretIcon)<{expanded: string}>`
  transform: ${props => props.expanded === 'true'? 'scaleY(-1)' : 'none' };

  & path {
    fill: none;
    stroke: #707070;
    stroke-width: 2px;
  }
`

const CaretIconContainer = styled.span`
  display: inline-block;
  margin-left: auto;
  margin-right: 15px;
`

const CheckBoxContainer = styled.div`
  margin-left: 15px;
  margin-right: 8px;
`

const Container = styled.div`
  position: relative;
`

const Option = styled.div`
  align-items: center;
  display: flex;
  height: 38px;
  transition: background-color 0.3s ease-out;
  width: 245px;

  &:hover {
    background-color: #fafafa;
  }
`

const OptionsContainer = styled.div<{ visible: boolean }>`
  background-color: #fff;
  box-shadow: 0 3px 6px #00000029;
  display: ${props => props.visible === true? 'block' : 'none'};
  position: absolute;
  width: 245px;
  z-index: 299;
`

const Select = styled.div<{disabled: boolean}>`
  align-items: center;
  background-color: ${props => props.disabled === true? '#fbfafa' : '#fff'};
  border: 1px solid #ccc;
  border-radius: 3px;
  color: #999;
  cursor: pointer;
  display: flex;
  height: 38px;
  padding-left: 20px;
  pointer-events: ${props => props.disabled === true? 'none' : 'auto'};
  transition: border-color 0.3s ease-out;
  width: 225px;

  &:hover {
    border-color: #036ce1;
  }
`

export {
  CaretIcon,
  CaretIconContainer,
  CheckBoxContainer,
  Container,
  Option,
  OptionsContainer,
  Select,
}
