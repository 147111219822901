/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for OMR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderGroup
 */
export interface OrderGroup {
    /**
     * 発注グループ名称
     * @type {string}
     * @memberof OrderGroup
     */
    name: string;
    /**
     * 発注案があるか
     * @type {boolean}
     * @memberof OrderGroup
     */
    hasDraftPurchaseOrder: boolean;
    /**
     * 発注担当者ID
     * @type {number}
     * @memberof OrderGroup
     */
    operatorId: number;
    /**
     * 発注担当者名
     * @type {string}
     * @memberof OrderGroup
     */
    operatorName: string;
}

export function OrderGroupFromJSON(json: any): OrderGroup {
    return OrderGroupFromJSONTyped(json, false);
}

export function OrderGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'hasDraftPurchaseOrder': json['hasDraftPurchaseOrder'],
        'operatorId': json['operatorId'],
        'operatorName': json['operatorName'],
    };
}

export function OrderGroupToJSON(value?: OrderGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'hasDraftPurchaseOrder': value.hasDraftPurchaseOrder,
        'operatorId': value.operatorId,
        'operatorName': value.operatorName,
    };
}


