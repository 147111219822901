import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import firebase from 'firebase/app'

import {
  Configuration,
  DefaultApi,
  GetContainerPlanRequest,
  GetContainerPlanResponse,
  GetContainerPlansRequest,
  GetContainerPlansResponse,
} from '../api-client'
import { useGetDraftPurchaseOrderParams } from './useDraftPurchaseOrderParamControl'

export const useDraftPurchaseOrder = (orderGroupId: number) => {
  const [draftPurchaseOrder, setDraftPurchaseOrder] = useState<GetContainerPlansResponse | undefined>(undefined)
  const [containerPlanDetail, setContainerPlanDetail] = useState<GetContainerPlanResponse | undefined>(undefined)
  const { getSelectedContainerId } = useGetDraftPurchaseOrderParams()
  const selectedContainerId = getSelectedContainerId()

  useEffect(() => {
    firebase
      .auth()
      .currentUser?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        const param: GetContainerPlansRequest = {
          orderGroupId,
        }
        return api.getContainerPlans(param).then((response) => {
          setDraftPurchaseOrder(response)
          if (response.containers.length === 0) {
            return
          }

          const containerId = selectedContainerId === 0 ? response.containers[0].id : selectedContainerId
          const param: GetContainerPlanRequest = {
            containerPlanId: containerId,
          }
          return api.getContainerPlan(param).then(setContainerPlanDetail)
        })
      })
      .catch((err) => {
        Sentry.captureException(err)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderGroupId])

  const getContainerPlan = (containerPlanId: number) => {
    setContainerPlanDetail(undefined)
    return firebase
      .auth()
      .currentUser?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        const param: GetContainerPlanRequest = {
          containerPlanId: containerPlanId,
        }
        return api.getContainerPlan(param).then(setContainerPlanDetail)
      })
      .catch((err) => {
        Sentry.captureException(err)
      })
  }

  return {
    draftPurchaseOrder,
    containerPlanDetail,
    getContainerPlan,
  }
}
