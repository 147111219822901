/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for OMR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DraftPurchaseOrderPlanningWeek
 */
export interface DraftPurchaseOrderPlanningWeek {
    /**
     * 
     * @type {number}
     * @memberof DraftPurchaseOrderPlanningWeek
     */
    year: number;
    /**
     * 
     * @type {string}
     * @memberof DraftPurchaseOrderPlanningWeek
     */
    date: string;
}

export function DraftPurchaseOrderPlanningWeekFromJSON(json: any): DraftPurchaseOrderPlanningWeek {
    return DraftPurchaseOrderPlanningWeekFromJSONTyped(json, false);
}

export function DraftPurchaseOrderPlanningWeekFromJSONTyped(json: any, ignoreDiscriminator: boolean): DraftPurchaseOrderPlanningWeek {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'year': json['year'],
        'date': json['date'],
    };
}

export function DraftPurchaseOrderPlanningWeekToJSON(value?: DraftPurchaseOrderPlanningWeek | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'year': value.year,
        'date': value.date,
    };
}


