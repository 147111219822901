import styled from 'styled-components'

const CalculatedAtLabel = styled.div`
  margin-left: 24px;
`

const DownloadButtonContainer = styled.div`
  margin-left: auto;
  margin-right: 0;
`

const CheckBoxContainer = styled.div`
  margin-left: auto;
  margin-right: 0;
`

const ContainerListTableContainer = styled.div`
  margin-bottom: 60px;
`

const DetailContainer = styled.div`
  align-items: center;
  border-bottom: 1px solid var(--di-table-border-color);
  display: flex;
  padding-bottom: 8px;
`

const DistributionCenterName = styled.div`
  font-weight: bold;
`

const DistributionCenterSKUContainer = styled.div`
  border-bottom: 1px solid var(--di-table-border-color);
  margin-top: 17px;
  padding-bottom: 15px;
  padding-left: 14px;
`

const InventoryCalendarTableContainer = styled.div`
  margin-bottom: 10px;
`

const LoadingSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`

const MainContents = styled.div`
  background-color: #fff;
  margin-left: 16px;
  margin-right: 16px;
  padding-bottom: 76px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 10px;
`

const NoSearchHitMessage = styled.div`
  margin-top: 25px;
`

const NumberOfContainerLabel = styled.div``

const OperatorName = styled.div`
  color: #999;
  margin-bottom: 7px;
  margin-top: 10px;
  padding-right: 16px;
  text-align: right;
`

const SearchFormContainer = styled.div`
  margin-left: 57px;
`

const SearchHitCount = styled.div`
  color: #999;
  margin-left: 15px;
`

const SectionTitle = styled.span`
  font-weight: bold;
`

const SKUCode = styled.div`
  font-weight: bold;
  margin-right: 16px;
`

const SKUHeader = styled.div`
  display: flex;
  margin-bottom: 9px;
`

const SKUName = styled.div`
  font-weight: bold;
  margin-right: 14px;
`

const SKUSpec = styled.div`
  font-weight: bold;
`

const SquareLabelContainer = styled.div`
  margin-right: 12px;
`

const SummaryContainer = styled.div`
  align-items: flex-end;
  display: flex;
  margin-bottom: 9px;
  margin-top: -3px;
`

const ToastContainer = styled.div`
  position: absolute;
  right: 18px;
  top: 107px;
  z-index: 399;
`

const Caution = styled.span`
  font-size: 12px;
`

const PageTitle = styled.h2`
  padding-bottom: 10px;
  font-weight: bold;
`

export {
  CalculatedAtLabel,
  DownloadButtonContainer,
  CheckBoxContainer,
  ContainerListTableContainer,
  DetailContainer,
  DistributionCenterName,
  DistributionCenterSKUContainer,
  InventoryCalendarTableContainer,
  LoadingSpinnerContainer,
  MainContents,
  NoSearchHitMessage,
  NumberOfContainerLabel,
  OperatorName,
  SearchFormContainer,
  SearchHitCount,
  SectionTitle,
  SKUCode,
  SKUHeader,
  SKUName,
  SKUSpec,
  SquareLabelContainer,
  SummaryContainer,
  ToastContainer,
  Caution,
  PageTitle,
}
