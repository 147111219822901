import React from 'react'

import { BaseTooltip } from './style'

export interface TooltipProps {
  text: string
  top: number
  width: number
}

export const Tooltip: React.FC<TooltipProps> = ({ text, top, width }) => (
  <BaseTooltip top={top} width={width}>
    {text}
  </BaseTooltip>
)
