import React, { useState } from 'react'
import * as Sentry from '@sentry/react'
import firebase from 'firebase/app'

import {
  Configuration,
  DefaultApi,
  ExcelErrorInvalidExcelRows,
  ErrorTitle,
  ExcelError,
  PutShipmentReasonsExcelRequest,
} from '../../api-client'

import { useShipmentReasons } from '../../hooks'

import {
  Button,
  LoadingSpinner,
  NotificationField,
  PageHeader,
  ShipmentReasonsTable,
  Toast,
} from '../../components/atoms'

import {
  CountLabel,
  DragAndDropArea,
  DragAndDropDescription,
  DragAndDropInput,
  DragAndDropLink,
  LoadingSpinnerContainer,
  NotificationFieldContainer,
  Panel,
  PanelDescription,
  PanelTitle,
  ShipmentReasonsTableContainer,
  ShipmentReasonsTableTitleContainer,
  ToastContainer,
} from './style'

export interface ShipmentReasonProps {}

export const ShipmentReason: React.FC<ShipmentReasonProps> = () => {
  const { shipmentReasons } = useShipmentReasons()

  const [isExcelUploading, setIsExcelUploading] = useState(false)
  const [excelUplodingErrorMessage, setExcelUplodingErrorMessage] = useState('')
  const [excelInvalidRows, setExcelInvalidRows] = useState<Array<ExcelErrorInvalidExcelRows>>([])

  const [toastVisible, setToastVisible] = useState(false)

  const sortInvalidExcelRowsHandler = (a: ExcelErrorInvalidExcelRows, b: ExcelErrorInvalidExcelRows) =>
    a.rowNumber > b.rowNumber ? 1 : a.rowNumber < b.rowNumber ? -1 : 0

  return (
    <>
      <PageHeader pageTitle="出荷予定数管理" backLink={false} />
      {toastVisible === true ? (
        <ToastContainer>
          <Toast
            message="登録しました。"
            onCloseHandler={() => {
              setTimeout(() => setToastVisible(false), 300)
            }}
          />
        </ToastContainer>
      ) : null}
      <PanelTitle>データ更新</PanelTitle>
      <Panel>
        <Button
          styleType="tertiary"
          label="登録済データダウンロード"
          width={222}
          onClickHandler={() => {
            firebase
              .auth()
              .currentUser?.getIdToken(true)
              .then((token) => {
                const conf = new Configuration({
                  basePath: process.env.REACT_APP_API_PATH,
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                })
                const api = new DefaultApi(conf)
                return api.getShipmentReasonsExcelRaw().then((response) => {
                  return response.value().then((blob) => {
                    const disposition = response.raw.headers.get('content-disposition')
                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
                    const matches = filenameRegex.exec(disposition != null ? disposition : '')

                    let filename
                    if (matches != null && matches[1]) {
                      filename = matches[1].replace(/['"]/g, '')
                      filename = decodeURI(filename)
                    }

                    const url = window.URL.createObjectURL(new Blob([blob]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', filename != null ? filename : '')
                    document.body.appendChild(link)
                    link.click()
                    link.parentNode?.removeChild(link)
                  })
                })
              })
              .catch((err) => {
                Sentry.captureException(err)
              })
          }}
          data-testid="download-excel"
        />
        <PanelDescription>アップロードされたデータで上書きします。</PanelDescription>
        <DragAndDropArea
          onDragOver={(event) => {
            // onDrop を発火させるために onDragOver イベントを制御する。
            // Ref: https://developer.mozilla.org/en-US/docs/Web/API/GlobalEventHandlers/ondrop
            event.stopPropagation()
            event.preventDefault()
          }}
          onDrop={(event) => {
            event.stopPropagation()
            event.preventDefault()
            const files = event.dataTransfer.files
            setIsExcelUploading(true)
            setExcelUplodingErrorMessage('')
            setExcelInvalidRows([])
            firebase
              .auth()
              .currentUser?.getIdToken(true)
              .then((token) => {
                const conf = new Configuration({
                  basePath: process.env.REACT_APP_API_PATH,
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                })
                const api = new DefaultApi(conf)
                const params: PutShipmentReasonsExcelRequest = {
                  uploadedExcelFile: files[0],
                }
                return api
                  .putShipmentReasonsExcel(params)
                  .then(() => {
                    setIsExcelUploading(false)
                    setToastVisible(true)
                  })
                  .catch((error: Response) => {
                    setIsExcelUploading(false)
                    if (error.status === 400) {
                      error.json().then((e: ExcelError) => {
                        if (e.title === ErrorTitle._400BadRequestInvalidExcelRows) {
                          if (e.invalidExcelRows != null) {
                            e.invalidExcelRows.sort(sortInvalidExcelRowsHandler)
                            setExcelInvalidRows(e.invalidExcelRows)
                          } else {
                            setExcelUplodingErrorMessage('不明なエラーです。システム管理者にお問い合わせ下さい。')
                          }
                        } else if (e.title === ErrorTitle._400BadRequestInvalidExcelFile) {
                          if (e.invalidExcelRows != null) {
                            e.invalidExcelRows.sort(sortInvalidExcelRowsHandler)
                            setExcelInvalidRows(e.invalidExcelRows)
                          } else {
                            setExcelUplodingErrorMessage('フォーマットに誤りがあります')
                          }
                        }
                      })
                    } else {
                      setExcelUplodingErrorMessage('不明なエラーです。システム管理者にお問い合わせ下さい。')
                    }
                  })
              })
          }}
        >
          <DragAndDropDescription>
            <div>ここにファイルをドラッグするか、</div>
            <DragAndDropLink htmlFor="shipment-reason-excel-input">ファイルを選択</DragAndDropLink>
            <span>して下さい。</span>
            <DragAndDropInput
              type="file"
              id="shipment-reason-excel-input"
              accept=".xlsx"
              onChange={(event) => {
                const files = event.target.files
                if (files == null) {
                  return
                }
                setIsExcelUploading(true)
                setExcelUplodingErrorMessage('')
                setExcelInvalidRows([])
                firebase
                  .auth()
                  .currentUser?.getIdToken(true)
                  .then((token) => {
                    const conf = new Configuration({
                      basePath: process.env.REACT_APP_API_PATH,
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    })
                    const api = new DefaultApi(conf)
                    const params: PutShipmentReasonsExcelRequest = {
                      uploadedExcelFile: files[0],
                    }
                    return api
                      .putShipmentReasonsExcel(params)
                      .then(() => {
                        // 即アップロードしてしまうので、入力状態をクリアする.
                        event.target.value = ''
                        setIsExcelUploading(false)
                        setToastVisible(true)
                      })
                      .catch((error: Response) => {
                        // 即アップロードしてしまうので、入力状態をクリアする.
                        event.target.value = ''
                        setIsExcelUploading(false)
                        if (error.status === 400) {
                          error.json().then((e: ExcelError) => {
                            if (e.title === ErrorTitle._400BadRequestInvalidExcelRows) {
                              if (e.invalidExcelRows != null) {
                                e.invalidExcelRows.sort(sortInvalidExcelRowsHandler)
                                setExcelInvalidRows(e.invalidExcelRows)
                              } else {
                                setExcelUplodingErrorMessage('不明なエラーです。システム管理者にお問い合わせ下さい。')
                              }
                            } else if (e.title === ErrorTitle._400BadRequestInvalidExcelFile) {
                              if (e.invalidExcelRows != null) {
                                e.invalidExcelRows.sort(sortInvalidExcelRowsHandler)
                                setExcelInvalidRows(e.invalidExcelRows)
                              } else {
                                setExcelUplodingErrorMessage('フォーマットに誤りがあります')
                              }
                            }
                          })
                        } else {
                          setExcelUplodingErrorMessage('不明なエラーです。システム管理者にお問い合わせ下さい。')
                        }
                      })
                  })
              }}
              data-testid="shipment-reason-excel-input"
            />
          </DragAndDropDescription>
        </DragAndDropArea>
        {isExcelUploading === true ? (
          <LoadingSpinnerContainer>
            <LoadingSpinner />
          </LoadingSpinnerContainer>
        ) : null}
      </Panel>
      {excelInvalidRows.length > 0 ? (
        <NotificationFieldContainer>
          <NotificationField
            type="error"
            title="登録エラー"
            // 行番号が0だったらエラー詳細のみ表示する. 特定の行に依存しないエラー（SKUが紐づくので削除できない, など）が入る想定.
            bodyMessages={excelInvalidRows.map((row) => {
                if (row.sheetName === undefined) {
                    return row.rowNumber === 0
                        ? row.detail
                        : `${row.rowNumber}行目：${row.detail}`
                }

                const rowDescription = row.rowNumber === 0
                    ? `${row.sheetName}シート`
                    : `${row.sheetName}シート・${row.rowNumber}行目`

                return `${rowDescription}：${row.detail}`
            })}
          />
        </NotificationFieldContainer>
      ) : null}
      {excelUplodingErrorMessage !== '' ? (
        <NotificationFieldContainer>
          <NotificationField type="error" title="登録エラー" bodyMessage={excelUplodingErrorMessage} />
        </NotificationFieldContainer>
      ) : null}
      <ShipmentReasonsTableTitleContainer>
        <h3>現在反映されているデータ</h3>
        {shipmentReasons != null ? <CountLabel>{shipmentReasons.length}件</CountLabel> : null}
      </ShipmentReasonsTableTitleContainer>
      {shipmentReasons != null ? (
        <ShipmentReasonsTableContainer>
          <ShipmentReasonsTable shipmentReasons={shipmentReasons} />
        </ShipmentReasonsTableContainer>
      ) : (
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      )}
    </>
  )
}
