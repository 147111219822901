import styled from 'styled-components'

import { ReactComponent as BlackLogoImage } from '../../../assets/images/logo-black.svg'
import { ReactComponent as WhiteLogoImage } from '../../../assets/images/logo-white.svg'

const BlackLogo = styled(BlackLogoImage)`
  width: 100%;
`

const WhiteLogo = styled(WhiteLogoImage)`
  width: 100%;
`

export {
  BlackLogo,
  WhiteLogo,
}
