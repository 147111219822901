import React, { useEffect, useRef } from 'react'
import { forwardRef, useImperativeHandle } from 'react'

import { InventoryUnitShipmentPlanningWeek, InventoryUnitShipmentPlanPerWeek } from '../../../api-client'

import {
  BaseInventoryUnitShipmentCalendarTable,
  InventoryUnitShipmentCalendarTableCol,
  InventoryUnitShipmentCalendarTableContainer,
  InventoryUnitShipmentCalendarTableData,
  InventoryUnitShipmentCalendarTableDataHeader,
  InventoryUnitShipmentCalendarTableHeader,
} from './style'

export interface InventoryUnitShipmentCalendarTableProps {
  /**
   * 実績週
   */
  pastWeeks: Array<InventoryUnitShipmentPlanningWeek>

  /**
   * 計画週
   */
  presentAndFutureWeeks: Array<InventoryUnitShipmentPlanningWeek>

  /**
   * 実績週のInventoryUnit出荷予定
   */
  pastInventoryUnitShipmentCalendar: { [key: string]: InventoryUnitShipmentPlanPerWeek }

  /**
   * 計画週のInventoryUnit出荷予定
   */
  presentAndFutureInventoryUnitShipmentCalendar: { [key: string]: InventoryUnitShipmentPlanPerWeek }

  /**
   * 需要予測の表示切替
   */
  selectedDemandForecast: string

  /**
   * 需要予測の設定値が初期値かどうか
   */
  isInitialParams: boolean
}

// 出荷予定表の行切り替え関数公開用
export interface ChangeLineMethod {
  changeLine: (selectedValue: string) => void
}
// 出荷予定表行切り替えの関数
export const ShipmentCalenderTable = forwardRef<ChangeLineMethod>((props, ref) => {
  useImperativeHandle(ref, () => ({
    // pages/InventoryUnitShipmentPlanのラジオボタンクリックで呼び出される行表示／非表示
    changeLine(selectedValue) {
      const demandInsightLine = document.getElementById('demand_insight_line')
      const salesCompositionLine = document.getElementById('sales_composition_line')
      if (demandInsightLine !== null && salesCompositionLine !== null) {
        if (selectedValue === 'demandInsight') {
          demandInsightLine.style.display = ''
          salesCompositionLine.style.display = 'none'
        } else if (selectedValue === 'salesComposition') {
          demandInsightLine.style.display = 'none'
          salesCompositionLine.style.display = ''
        } else {
          console.log('想定しないラジオボタン選択です。')
        }
      }
    },
  }))
  return null
})

export const InventoryUnitShipmentCalendarTable: React.FC<InventoryUnitShipmentCalendarTableProps> = ({
  pastWeeks,
  presentAndFutureWeeks,
  pastInventoryUnitShipmentCalendar,
  presentAndFutureInventoryUnitShipmentCalendar,
  selectedDemandForecast,
  isInitialParams,
}) => {
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // jsdom may not have scrollTo method. Ref: https://github.com/jsdom/jsdom/issues/1422
    if (containerRef.current != null && containerRef.current.scrollTo != null) {
      // 初期表示状態では、過去直近3週見える位置で表示する
      containerRef.current.scrollTo((pastWeeks.length - 3) * 80, 0)
    }
  }, [pastWeeks.length])

  const savedRadioSelected: string = selectedDemandForecast
  // 画面表示時の行切り替えスタイル判定
  const displayLineSelects = ((): Array<string> => {
    if (savedRadioSelected === 'demandInsight') {
      return ['', 'none']
    } else if (savedRadioSelected === 'salesComposition') {
      return ['none', '']
    }
    return ['', 'none']
  })()

  return (
    <InventoryUnitShipmentCalendarTableContainer ref={containerRef}>
      <BaseInventoryUnitShipmentCalendarTable
        width={90 + 175 + pastWeeks.length * 80 + presentAndFutureWeeks.length * 80}
      >
        <colgroup>
          <InventoryUnitShipmentCalendarTableCol width={90}></InventoryUnitShipmentCalendarTableCol>
          <InventoryUnitShipmentCalendarTableCol width={175}></InventoryUnitShipmentCalendarTableCol>
          {pastWeeks.map((week, idx) => (
            <InventoryUnitShipmentCalendarTableCol key={idx} width={80}></InventoryUnitShipmentCalendarTableCol>
          ))}
          {presentAndFutureWeeks.map((week, idx) => (
            <InventoryUnitShipmentCalendarTableCol key={idx} width={80}></InventoryUnitShipmentCalendarTableCol>
          ))}
        </colgroup>
        <thead>
          <tr>
            <InventoryUnitShipmentCalendarTableHeader
              boldbottom={false}
              colSpan={2}
              textalign="center"
            ></InventoryUnitShipmentCalendarTableHeader>
            {pastWeeks.length > 0 ? (
              <InventoryUnitShipmentCalendarTableHeader boldbottom={false} colSpan={pastWeeks.length} textalign="left">
                実績
              </InventoryUnitShipmentCalendarTableHeader>
            ) : null}
            {presentAndFutureWeeks.length > 0 ? (
              <InventoryUnitShipmentCalendarTableHeader
                boldbottom={false}
                colSpan={presentAndFutureWeeks.length}
                textalign="left"
              >
                計画
              </InventoryUnitShipmentCalendarTableHeader>
            ) : null}
          </tr>
          <tr>
            <InventoryUnitShipmentCalendarTableHeader
              boldbottom={true}
              colSpan={2}
              textalign="center"
            ></InventoryUnitShipmentCalendarTableHeader>
            {pastWeeks.map((week, idx) => (
              <InventoryUnitShipmentCalendarTableHeader key={idx} textalign="right" boldbottom={true}>
                {week.date}
              </InventoryUnitShipmentCalendarTableHeader>
            ))}
            {presentAndFutureWeeks.map((week, idx) => (
              <InventoryUnitShipmentCalendarTableHeader key={idx} textalign="right" boldbottom={true}>
                {week.date}
              </InventoryUnitShipmentCalendarTableHeader>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr id="demand_insight_line" style={{ display: displayLineSelects[0] }}>
            <InventoryUnitShipmentCalendarTableDataHeader rowSpan={4} boldbottom={false} left={0}>
              <p>店舗</p>
              <p>出荷</p>
            </InventoryUnitShipmentCalendarTableDataHeader>
            <InventoryUnitShipmentCalendarTableDataHeader boldbottom={false} left={90}>
              DI需要予測数
            </InventoryUnitShipmentCalendarTableDataHeader>
            {pastWeeks.map((week, idx) => {
              const plannedSalesOverwriteQuantity =
                pastInventoryUnitShipmentCalendar[week.date] != null
                  ? pastInventoryUnitShipmentCalendar[week.date].plannedSalesOverwriteQuantity
                  : null
              return (
                <InventoryUnitShipmentCalendarTableData
                  key={idx}
                  boldbottom={false}
                  backgroundgrey={plannedSalesOverwriteQuantity != null}
                >
                  {pastInventoryUnitShipmentCalendar[week.date] != null
                    ? pastInventoryUnitShipmentCalendar[week.date].temporarilyPlannedSalesQuantity != null
                      ? `(${pastInventoryUnitShipmentCalendar[week.date].temporarilyPlannedSalesQuantity})`
                      : pastInventoryUnitShipmentCalendar[week.date].permanentlyPlannedSalesQuantity != null
                      ? pastInventoryUnitShipmentCalendar[week.date].permanentlyPlannedSalesQuantity
                      : ''
                    : ''}
                </InventoryUnitShipmentCalendarTableData>
              )
            })}
            {presentAndFutureWeeks.map((week, idx) => {
              const plannedSalesOverwriteQuantity =
                presentAndFutureInventoryUnitShipmentCalendar[week.date] != null
                  ? presentAndFutureInventoryUnitShipmentCalendar[week.date].plannedSalesOverwriteQuantity
                  : null
              return (
                <InventoryUnitShipmentCalendarTableData
                  key={idx}
                  boldbottom={false}
                  backgroundgrey={plannedSalesOverwriteQuantity != null}
                >
                  {presentAndFutureInventoryUnitShipmentCalendar[week.date] != null
                    ? !isInitialParams &&
                      presentAndFutureInventoryUnitShipmentCalendar[week.date].temporarilyPlannedSalesQuantity != null
                      ? `(${presentAndFutureInventoryUnitShipmentCalendar[week.date].temporarilyPlannedSalesQuantity})`
                      : isInitialParams &&
                        presentAndFutureInventoryUnitShipmentCalendar[week.date].permanentlyPlannedSalesQuantity != null
                      ? presentAndFutureInventoryUnitShipmentCalendar[week.date].permanentlyPlannedSalesQuantity
                      : ''
                    : ''}
                </InventoryUnitShipmentCalendarTableData>
              )
            })}
          </tr>
          <tr id="sales_composition_line" style={{ display: displayLineSelects[1] }}>
            <InventoryUnitShipmentCalendarTableDataHeader rowSpan={4} boldbottom={false} left={0}>
              <p>店舗</p>
              <p>出荷</p>
            </InventoryUnitShipmentCalendarTableDataHeader>
            <InventoryUnitShipmentCalendarTableDataHeader boldbottom={false} left={90}>
              売上構成比による予測数
            </InventoryUnitShipmentCalendarTableDataHeader>
            {pastWeeks.map((week, idx) => {
              const plannedSalesOverwriteQuantity =
                pastInventoryUnitShipmentCalendar[week.date] != null
                  ? pastInventoryUnitShipmentCalendar[week.date].plannedSalesOverwriteQuantity
                  : null
              return (
                <InventoryUnitShipmentCalendarTableData
                  key={idx}
                  boldbottom={false}
                  backgroundgrey={plannedSalesOverwriteQuantity != null}
                >
                  {' '}
                  {/* （本編）backgroundgrey={true}はfalseにする。＊モックでは切り替わったことを分かり易くするため。 */}
                  {pastInventoryUnitShipmentCalendar[week.date] != null
                    ? pastInventoryUnitShipmentCalendar[week.date].temporarilyPlannedSalesQuantity != null
                      ? `(${pastInventoryUnitShipmentCalendar[week.date].temporarilyPlannedSalesQuantity})`
                      : pastInventoryUnitShipmentCalendar[week.date].permanentlySalesCompositionQuantity != null
                      ? pastInventoryUnitShipmentCalendar[week.date].permanentlySalesCompositionQuantity
                      : ''
                    : ''}
                </InventoryUnitShipmentCalendarTableData>
              )
            })}
            {presentAndFutureWeeks.map((week, idx) => {
              const plannedSalesOverwriteQuantity =
                presentAndFutureInventoryUnitShipmentCalendar[week.date] != null
                  ? presentAndFutureInventoryUnitShipmentCalendar[week.date].plannedSalesOverwriteQuantity
                  : null
              return (
                <InventoryUnitShipmentCalendarTableData
                  key={idx}
                  boldbottom={false}
                  backgroundgrey={plannedSalesOverwriteQuantity != null}
                  id="sample_sales_composition_data_future"
                >
                  {presentAndFutureInventoryUnitShipmentCalendar[week.date] != null
                    ? presentAndFutureInventoryUnitShipmentCalendar[week.date].temporarilyPlannedSalesQuantity != null
                      ? `(${presentAndFutureInventoryUnitShipmentCalendar[week.date].temporarilyPlannedSalesQuantity})`
                      : presentAndFutureInventoryUnitShipmentCalendar[week.date].permanentlySalesCompositionQuantity !=
                        null
                      ? presentAndFutureInventoryUnitShipmentCalendar[week.date].permanentlySalesCompositionQuantity
                      : ''
                    : ''}
                </InventoryUnitShipmentCalendarTableData>
              )
            })}
          </tr>
          <tr>
            <InventoryUnitShipmentCalendarTableDataHeader boldbottom={false} left={90}>
              販売実績数
            </InventoryUnitShipmentCalendarTableDataHeader>
            {pastWeeks.map((week, idx) => {
              const actualSalesQuantity =
                pastInventoryUnitShipmentCalendar[week.date] != null
                  ? pastInventoryUnitShipmentCalendar[week.date].actualSalesQuantity
                  : null
              return (
                <InventoryUnitShipmentCalendarTableData key={idx} boldbottom={false} backgroundgrey={false}>
                  {actualSalesQuantity != null ? actualSalesQuantity : ''}
                </InventoryUnitShipmentCalendarTableData>
              )
            })}
            {presentAndFutureWeeks.map((week, idx) => (
              <InventoryUnitShipmentCalendarTableData key={idx} boldbottom={false} backgroundgrey={false}>
                {/* 未来の販売実績は存在しないはず */}
              </InventoryUnitShipmentCalendarTableData>
            ))}
          </tr>
          <tr>
            <InventoryUnitShipmentCalendarTableDataHeader boldbottom={false} left={90}>
              昨年販売実績
            </InventoryUnitShipmentCalendarTableDataHeader>
            {pastWeeks.map((week, idx) => {
              const lastYearActualSalesQuantity =
                pastInventoryUnitShipmentCalendar[week.date] != null
                  ? pastInventoryUnitShipmentCalendar[week.date].lastYearActualSalesQuantity
                  : null
              return (
                <InventoryUnitShipmentCalendarTableData key={idx} boldbottom={false} backgroundgrey={false}>
                  {lastYearActualSalesQuantity != null ? lastYearActualSalesQuantity : ''}
                </InventoryUnitShipmentCalendarTableData>
              )
            })}
            {presentAndFutureWeeks.map((week, idx) => {
              const lastYearActualSalesQuantity =
                presentAndFutureInventoryUnitShipmentCalendar[week.date] != null
                  ? presentAndFutureInventoryUnitShipmentCalendar[week.date].lastYearActualSalesQuantity
                  : null
              return (
                <InventoryUnitShipmentCalendarTableData key={idx} boldbottom={false} backgroundgrey={false}>
                  {lastYearActualSalesQuantity != null ? lastYearActualSalesQuantity : ''}
                </InventoryUnitShipmentCalendarTableData>
              )
            })}
          </tr>
          <tr>
            <InventoryUnitShipmentCalendarTableDataHeader boldbottom={false} left={90}>
              上書修正登録
            </InventoryUnitShipmentCalendarTableDataHeader>
            {pastWeeks.map((week, idx) => {
              const plannedSalesOverwriteQuantity =
                pastInventoryUnitShipmentCalendar[week.date] != null
                  ? pastInventoryUnitShipmentCalendar[week.date].plannedSalesOverwriteQuantity
                  : null
              return (
                <InventoryUnitShipmentCalendarTableData key={idx} boldbottom={false} backgroundgrey={false}>
                  {plannedSalesOverwriteQuantity ?? ''}
                </InventoryUnitShipmentCalendarTableData>
              )
            })}
            {presentAndFutureWeeks.map((week, idx) => {
              const plannedSalesOverwriteQuantity =
                presentAndFutureInventoryUnitShipmentCalendar[week.date] != null
                  ? presentAndFutureInventoryUnitShipmentCalendar[week.date].plannedSalesOverwriteQuantity
                  : null
              return (
                <InventoryUnitShipmentCalendarTableData key={idx} boldbottom={false} backgroundgrey={false}>
                  {plannedSalesOverwriteQuantity ?? ''}
                </InventoryUnitShipmentCalendarTableData>
              )
            })}
          </tr>
          <tr>
            <InventoryUnitShipmentCalendarTableDataHeader rowSpan={2} boldbottom={false} left={0}>
              卸出荷
            </InventoryUnitShipmentCalendarTableDataHeader>
            <InventoryUnitShipmentCalendarTableDataHeader boldbottom={false} left={90}>
              需要予測数
            </InventoryUnitShipmentCalendarTableDataHeader>
            {pastWeeks.map((week, idx) => {
              const plannedWholesalesShipmentQuantity =
                pastInventoryUnitShipmentCalendar[week.date] != null
                  ? pastInventoryUnitShipmentCalendar[week.date].plannedWholesalesShipmentQuantity
                  : null
              return (
                <InventoryUnitShipmentCalendarTableData key={idx} boldbottom={false} backgroundgrey={false}>
                  {plannedWholesalesShipmentQuantity ?? ''}
                </InventoryUnitShipmentCalendarTableData>
              )
            })}
            {presentAndFutureWeeks.map((week, idx) => {
              const plannedWholesalesShipmentQuantity =
                presentAndFutureInventoryUnitShipmentCalendar[week.date] != null
                  ? presentAndFutureInventoryUnitShipmentCalendar[week.date].plannedWholesalesShipmentQuantity
                  : null
              return (
                <InventoryUnitShipmentCalendarTableData key={idx} boldbottom={false} backgroundgrey={false}>
                  {plannedWholesalesShipmentQuantity ?? ''}
                </InventoryUnitShipmentCalendarTableData>
              )
            })}
          </tr>
          <tr>
            <InventoryUnitShipmentCalendarTableDataHeader boldbottom={false} left={90}>
              出荷実績数
            </InventoryUnitShipmentCalendarTableDataHeader>
            {pastWeeks.map((week, idx) => {
              const actualWholesaleShipmentQuantity =
                pastInventoryUnitShipmentCalendar[week.date] != null
                  ? pastInventoryUnitShipmentCalendar[week.date].actualWholesaleShipmentQuantity
                  : null
              return (
                <InventoryUnitShipmentCalendarTableData key={idx} boldbottom={false} backgroundgrey={false}>
                  {actualWholesaleShipmentQuantity ?? ''}
                </InventoryUnitShipmentCalendarTableData>
              )
            })}
            {presentAndFutureWeeks.map((_, idx) => {
              return <InventoryUnitShipmentCalendarTableData key={idx} boldbottom={false} backgroundgrey={false} />
            })}
          </tr>
          <tr>
            <InventoryUnitShipmentCalendarTableDataHeader colSpan={2} boldbottom={true} left={0}>
              積み増し登録
            </InventoryUnitShipmentCalendarTableDataHeader>
            {pastWeeks.map((week, idx) => {
              const inventoryAccumulationQuantity =
                pastInventoryUnitShipmentCalendar[week.date] != null
                  ? pastInventoryUnitShipmentCalendar[week.date].inventoryAccumulationQuantity
                  : null
              return (
                <InventoryUnitShipmentCalendarTableData key={idx} boldbottom={false} backgroundgrey={false}>
                  {inventoryAccumulationQuantity != null ? inventoryAccumulationQuantity : ''}
                </InventoryUnitShipmentCalendarTableData>
              )
            })}
            {presentAndFutureWeeks.map((week, idx) => {
              const inventoryAccumulationQuantity =
                presentAndFutureInventoryUnitShipmentCalendar[week.date] != null
                  ? presentAndFutureInventoryUnitShipmentCalendar[week.date].inventoryAccumulationQuantity
                  : null
              return (
                <InventoryUnitShipmentCalendarTableData key={idx} boldbottom={false} backgroundgrey={false}>
                  {inventoryAccumulationQuantity != null ? inventoryAccumulationQuantity : ''}
                </InventoryUnitShipmentCalendarTableData>
              )
            })}
          </tr>
          <tr>
            <InventoryUnitShipmentCalendarTableDataHeader colSpan={2} boldbottom={false} left={0}>
              出荷予定数
            </InventoryUnitShipmentCalendarTableDataHeader>
            {pastWeeks.map((week, idx) => {
              const inventoryUnitShipmentQuantity =
                pastInventoryUnitShipmentCalendar[week.date] != null
                  ? pastInventoryUnitShipmentCalendar[week.date].inventoryUnitShipmentQuantity
                  : null
              return (
                <InventoryUnitShipmentCalendarTableData key={idx} boldbottom={false} backgroundgrey={false}>
                  {inventoryUnitShipmentQuantity != null ? inventoryUnitShipmentQuantity : ''}
                </InventoryUnitShipmentCalendarTableData>
              )
            })}
            {presentAndFutureWeeks.map((week, idx) => {
              return (
                <InventoryUnitShipmentCalendarTableData key={idx} boldbottom={false} backgroundgrey={false}>
                  {presentAndFutureInventoryUnitShipmentCalendar[week.date] != null
                    ? presentAndFutureInventoryUnitShipmentCalendar[week.date].inventoryUnitShipmentQuantity
                    : ''}
                </InventoryUnitShipmentCalendarTableData>
              )
            })}
          </tr>
        </tbody>
      </BaseInventoryUnitShipmentCalendarTable>
    </InventoryUnitShipmentCalendarTableContainer>
  )
}
