import styled from 'styled-components'

const backgroundcolors = (color: string): string => {
  switch (color) {
    case 'red':
      return '#ffe6e6'
    default:
      return 'transparent'
  }
}

const colors = (color: string): string => {
  switch (color) {
    case 'red':
      return '#b72727'
    default:
      return 'transparent'
  }
}

const BaseFilledRoundLabel = styled.div<{
  color: string
  width: number
}>`
  background-color: ${props => backgroundcolors(props.color)};
  border-radius: 10px;
  color: ${props => colors(props.color)};
  height: 22px;
  text-align: center;
  width: ${props => `${props.width}px`};
`

export {
  BaseFilledRoundLabel,
}
