import styled from 'styled-components'

import cancelIcon from '../../../assets/images/cancel-search.svg'

const BaseInput = styled.input<{padding: number, disabled: boolean, hasErr: boolean, isBackgroundYellow: boolean}>`
  background-color: ${props => props.isBackgroundYellow? '#fffbd9' : ''};
  border-color: ${props => props.hasErr? '#ff0000' : '#ccc' };
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  font-size: 14px;
  height: ${props => `${props.height}px`};
  padding-left: ${props => `${props.padding}px`};
  padding-right: ${props => `${props.padding}px`};
  pointer-events: ${props => props.disabled === true? 'none' : 'auto'};
  text-align: ${props => props.type && props.type === 'number'? 'right' : 'left'};
  transition: border-color 0.3s ease-out;
  width: ${props => `${props.width}px`};

  /** カレンダーのアイコンんは出さずにフォームをクリックしたら日付選択が出るようにする */
  &::-webkit-calendar-picker-indicator {
    background: transparent;
    color: transparent;
    cursor: pointer;
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
  }

  /** 数値増減のボタンは出さない */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus-visible {
    outline: ${props => props.hasErr? '#ff0000' : 'var(--di-primary-color)' };
  }

  &:hover {
    border-color: ${props => props.hasErr? '#ff0000' : 'var(--di-primary-color)' };
  }

  &[type="date"] {
    position: relative;
  }

  &[type='search']::placeholder {
    color: #999;
  }

  &[type='search']::-webkit-search-cancel-button {
    -webkit-appearance: none;
    background-image: url(${cancelIcon});
    height: 14px;
    width: 14px;
    background-repeat: no-repeat;
    background-size: cover;
  }
`

const BaseLabel = styled.label`
  display: block;
  margin-bottom: 5px;
  margin-left: 5px;
`

const BaseSubLabel = styled.span`
  color: var(--di-text-disabled-color);
`

const ErrorMessage = styled.div`
  color: var(--di-text-error-color);
`

export {
  BaseInput,
  BaseLabel,
  BaseSubLabel,
  ErrorMessage,
}
