interface ReloadState {
  addReloadEvent: () => void
  resetReloadState: () => void
  resetReloadEvent: () => void
}

/**
 * reload時に共通使用するhooks
 */
export const useReloadState = (reloadStatusKey: string, reloadLocationKey: string, location: string): ReloadState => {
  const setReloadStatus = () => {
    sessionStorage.setItem(reloadStatusKey, 'reload')
    sessionStorage.setItem(reloadLocationKey, location)
  }

  const addReloadEvent = () => {
    window.addEventListener('beforeunload', setReloadStatus)
  }

  const resetReloadState = () => {
    sessionStorage.removeItem(reloadStatusKey)
    sessionStorage.removeItem(reloadLocationKey)
  }

  const resetReloadEvent = () => {
    resetReloadState()

    window.removeEventListener('beforeunload', setReloadStatus)
  }

  return {
    addReloadEvent,
    resetReloadState,
    resetReloadEvent,
  }
}
