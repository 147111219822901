import { MutableRefObject } from "react"
import { GetInventoryPlanResponse } from "../../api-client"

interface simulation {
  simulationSubmitAvailable: () => boolean
  simulationResetAvailable: () => boolean
}

export const useSimulation = (
  inventoryPlan: GetInventoryPlanResponse | undefined,
  inventoryPlanRef: MutableRefObject<GetInventoryPlanResponse | undefined>,
): [simulation] => {
  const simulationSubmitAvailable = () =>
    inventoryPlan != null &&
    inventoryPlanRef.current != null &&
    // 変更点がない場合はシミュレーション実行不可
    JSON.stringify(inventoryPlan.inventoryUnitSKUs) !== JSON.stringify(inventoryPlanRef.current.inventoryUnitSKUs) &&
    // バリデーションエラーがある場合はシミュレーション実行不可
    inventoryPlanRef.current.inventoryUnitSKUs.some((inventoryUnitSKU) =>
      Object.values(inventoryUnitSKU.inventoryCalendar).some((inventoryPlan) =>
        inventoryPlan.receivingQuantityForSimulation != null && (inventoryPlan.receivingQuantityForSimulation > 99999999 || inventoryPlan.receivingQuantityForSimulation < -9999999)
      )) === false

  const simulationResetAvailable = () =>
    inventoryPlan != null &&
    // 保存されたシミュレーション値がなければシミュレーションリセット不可
    inventoryPlan.inventoryUnitSKUs.some((inventoryUnitSKU) =>
      Object.values(inventoryUnitSKU.inventoryCalendar).some((inventoryPlan) =>
        inventoryPlan.receivingQuantityForSimulation != null))


  return [
    {
      simulationSubmitAvailable,
      simulationResetAvailable,
    }
  ]
}
