import styled from 'styled-components'

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`

const CancelButtonContainer = styled.div`
  margin-left: 16.5px;
`

const Email = styled.span`
  align-items: center;
  display: flex;
  height: 40px;
`

const IdLabel = styled.span`
  align-items: center;
  display: flex;
  height: 40px;
`

const InputFormContainer = styled.div`
  background-color: #fff;
  border: 1px solid var(--di-table-border-color);
  display: flex;
  width: 100%;
  & + div {
    border-top: none;
  }
`

const InputFormContainerInner = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;
`

const InputFormLabel = styled.span`
  font-weight: bold;
  letter-spacing: 0;
  margin-left: 68px;
  margin-right: 26px;
  margin-top: 20px;
  text-align: right;
  width: 120px;
`

const InputFormSubLabel = styled.span`
  color: #999;
  margin-left: 14px;
`

const Role = styled.span`
  align-items: center;
  display: flex;
  height: 40px;
`

export {
  ButtonsContainer,
  CancelButtonContainer,
  Email,
  IdLabel,
  InputFormContainer,
  InputFormContainerInner,
  InputFormLabel,
  InputFormSubLabel,
  Role,
}
