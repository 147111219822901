import styled from 'styled-components'

const LinkContainer = styled.div`
  margin-top: 20px;
`

const LoginFormContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 65px;
  width: 427px;
`

const LogoContainer = styled.div`
  margin: auto;
  width: 339px;
`

const NotificationFieldContainer = styled.div`
  margin-bottom: 20px;
`

export {
  LinkContainer,
  LoginFormContainer,
  LogoContainer,
  NotificationFieldContainer,
}
