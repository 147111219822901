/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for OMR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DraftPurchaseOrderPlanningInventoryUnitReceiving,
    DraftPurchaseOrderPlanningInventoryUnitReceivingFromJSON,
    DraftPurchaseOrderPlanningInventoryUnitReceivingFromJSONTyped,
    DraftPurchaseOrderPlanningInventoryUnitReceivingToJSON,
} from './';

/**
 * 入荷残数
 * @export
 * @interface DraftPurchaseOrderUnreceivedOrder
 */
export interface DraftPurchaseOrderUnreceivedOrder {
    /**
     * 
     * @type {DraftPurchaseOrderPlanningInventoryUnitReceiving}
     * @memberof DraftPurchaseOrderUnreceivedOrder
     */
    inventoryUnitReceivings: DraftPurchaseOrderPlanningInventoryUnitReceiving;
    /**
     * 日付
     * @type {Date}
     * @memberof DraftPurchaseOrderUnreceivedOrder
     */
    date: Date;
}

export function DraftPurchaseOrderUnreceivedOrderFromJSON(json: any): DraftPurchaseOrderUnreceivedOrder {
    return DraftPurchaseOrderUnreceivedOrderFromJSONTyped(json, false);
}

export function DraftPurchaseOrderUnreceivedOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): DraftPurchaseOrderUnreceivedOrder {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inventoryUnitReceivings': DraftPurchaseOrderPlanningInventoryUnitReceivingFromJSON(json['inventoryUnitReceivings']),
        'date': (new Date(json['date'])),
    };
}

export function DraftPurchaseOrderUnreceivedOrderToJSON(value?: DraftPurchaseOrderUnreceivedOrder | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inventoryUnitReceivings': DraftPurchaseOrderPlanningInventoryUnitReceivingToJSON(value.inventoryUnitReceivings),
        'date': (value.date.toISOString().substr(0,10)),
    };
}


