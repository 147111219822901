import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import firebase from 'firebase/app'

import {
  AdjustmentRange,
  AdjustmentType,
  DefaultApi,
  Configuration,
  PutInventoryUnitShipmentPlanCollectiveOperationRequest,
  PutInventoryUnitShipmentPlanCollectiveRequest,
  GetInventoryUnitShipmentPlanCollectiveRequest,
  GetInventoryUnitShipmentPlanCollectiveResponse,
  GetInventoryUnitShipmentPlanCollectiveAdjustmentTypeEnum,
  GetInventoryUnitShipmentPlanCollectiveAdjustmentRangeEnum,
  GetInventoryUnitShipmentPlanCollectiveSelectedDemandForecastEnum,
  AdjustmentTarget,
} from '../api-client'
import { NUMBER_OF_WEEKS_TO_ADJUST_DEFAULT } from '../constants'
import {
  InventoryUnitShipmentPlanParamsBySelectedDemandForecast,
  useInventoryUnitShipmentPlanParams,
} from './useInventoryUnitShipmentPlanParams'

export const useInventoryUnitShipmentPlanCollective = (janCode: string) => {
  const [shipmentPlanCollective, setShipmentPlanCollective] = useState<
    GetInventoryUnitShipmentPlanCollectiveResponse | undefined
  >(undefined)

  const {
    convAdjustmentType,
    convAdjustmentRange,
    convDemandForecast,
    saveParams,
    onChangeSelectedForecast,
    inventoryUnitShipmentPlanParams,
    setInventoryUnitShipmentPlanParams,
  } = useInventoryUnitShipmentPlanParams()

  useEffect(() => {
    firebase
      .auth()
      .currentUser?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        const params: GetInventoryUnitShipmentPlanCollectiveRequest = {
          janCode,
          numberOfWeeksToAdjust: NUMBER_OF_WEEKS_TO_ADJUST_DEFAULT,
          adjustmentRange: GetInventoryUnitShipmentPlanCollectiveAdjustmentRangeEnum.InSomeWeeks,
        }
        return api.getInventoryUnitShipmentPlanCollective(params)
      })
      .then((data) => {
        if (shipmentPlanCollective?.selectedDemandForecast) {
          setInventoryUnitShipmentPlanParams((prev) => {
            const defaultSettingPramas = {
              adjustmentType:
                shipmentPlanCollective.temporaryAdjustmentType != null
                  ? shipmentPlanCollective.temporaryAdjustmentType
                  : // 本来は not null であるべきだが, 一時的な指定の場合のプロパティと enum の型と共通化するために nullable となっている.
                  shipmentPlanCollective.defaultAdjustmentType != null
                  ? shipmentPlanCollective.defaultAdjustmentType
                  : AdjustmentType.NoAdjustment,
              adjustmentCustomRate:
                shipmentPlanCollective.temporaryAdjustmentCustomRate != null
                  ? shipmentPlanCollective.temporaryAdjustmentCustomRate
                  : shipmentPlanCollective.defaultAdjustmentCustomRate,
              adjustmentRange:
                shipmentPlanCollective.temporaryAdjustmentRange != null
                  ? shipmentPlanCollective.temporaryAdjustmentRange
                  : shipmentPlanCollective.defaultAdjustmentRange,
              numberOfWeeksToAdjust:
                shipmentPlanCollective.temporaryNumberOfWeeksToAdjust != null
                  ? shipmentPlanCollective.temporaryNumberOfWeeksToAdjust
                  : shipmentPlanCollective.defaultNumberOfWeeksToAdjust,
              selectedDemandForecast: shipmentPlanCollective.selectedDemandForecast,
            }

            let settingPramas: InventoryUnitShipmentPlanParamsBySelectedDemandForecast = prev
            if (shipmentPlanCollective.selectedDemandForecast === AdjustmentTarget.DemandInsight) {
              settingPramas = {
                ...settingPramas,
                demandInsightParams: defaultSettingPramas,
              }
            } else if (shipmentPlanCollective.selectedDemandForecast === AdjustmentTarget.SalesComposition) {
              settingPramas = {
                ...settingPramas,
                salesCompositionParams: defaultSettingPramas,
              }
            }
            return { ...settingPramas }
          })
        }
        setShipmentPlanCollective(data)
      })
      .catch((err) => {
        Sentry.captureException(err)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [janCode])

  const getInventoryUnitShipmentPlanCollective = (
    adjustmentType?: AdjustmentType,
    adjustmentCustomRate?: number,
    adjustmentRange?: AdjustmentRange,
    numberOfWeeksToAdjust?: number,
    selectedDemandForecast?: string
  ): Promise<void> | undefined => {
    return firebase
      .auth()
      .currentUser?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        const params: GetInventoryUnitShipmentPlanCollectiveRequest = {
          janCode,
          adjustmentType: convAdjustmentType(
            adjustmentType
          ) as GetInventoryUnitShipmentPlanCollectiveAdjustmentTypeEnum,
          adjustmentCustomRate,
          adjustmentRange: convAdjustmentRange(
            adjustmentRange
          ) as GetInventoryUnitShipmentPlanCollectiveAdjustmentRangeEnum,
          numberOfWeeksToAdjust,
          selectedDemandForecast: convDemandForecast(
            selectedDemandForecast
          ) as GetInventoryUnitShipmentPlanCollectiveSelectedDemandForecastEnum,
        }
        return api.getInventoryUnitShipmentPlanCollective(params)
      })
      .then(setShipmentPlanCollective)
      .catch((err) => {
        Sentry.captureException(err)
      })
  }

  //初期Prams設定
  useEffect(() => {
    if (shipmentPlanCollective?.selectedDemandForecast) {
      setInventoryUnitShipmentPlanParams((prev) => {
        const defaultSettingPramas = {
          adjustmentType:
            shipmentPlanCollective.temporaryAdjustmentType != null
              ? shipmentPlanCollective.temporaryAdjustmentType
              : // 本来は not null であるべきだが, 一時的な指定の場合のプロパティと enum の型と共通化するために nullable となっている.
              shipmentPlanCollective.defaultAdjustmentType != null
              ? shipmentPlanCollective.defaultAdjustmentType
              : AdjustmentType.NoAdjustment,
          adjustmentCustomRate:
            shipmentPlanCollective.temporaryAdjustmentCustomRate != null
              ? shipmentPlanCollective.temporaryAdjustmentCustomRate
              : shipmentPlanCollective.defaultAdjustmentCustomRate,
          adjustmentRange:
            shipmentPlanCollective.temporaryAdjustmentRange != null
              ? shipmentPlanCollective.temporaryAdjustmentRange
              : shipmentPlanCollective.defaultAdjustmentRange,
          numberOfWeeksToAdjust:
            shipmentPlanCollective.temporaryNumberOfWeeksToAdjust != null
              ? shipmentPlanCollective.temporaryNumberOfWeeksToAdjust
              : shipmentPlanCollective.defaultNumberOfWeeksToAdjust,
          selectedDemandForecast: shipmentPlanCollective.selectedDemandForecast,
        }

        let settingPramas: InventoryUnitShipmentPlanParamsBySelectedDemandForecast = prev
        if (shipmentPlanCollective.selectedDemandForecast === AdjustmentTarget.DemandInsight) {
          settingPramas = {
            ...settingPramas,
            demandInsightParams: defaultSettingPramas,
          }
        } else if (shipmentPlanCollective.selectedDemandForecast === AdjustmentTarget.SalesComposition) {
          settingPramas = {
            ...settingPramas,
            salesCompositionParams: defaultSettingPramas,
          }
        }

        return { ...settingPramas }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipmentPlanCollective?.selectedDemandForecast])

  const putInventoryUnitShipmentPlanCollective = (
    adjustmentType: AdjustmentType,
    adjustmentCustomRate: number,
    adjustmentRange: AdjustmentRange | null,
    numberOfWeeksToAdjust: number,
    selectedDemandForecast: AdjustmentTarget
  ) => {
    return firebase
      .auth()
      .currentUser?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        const bodyParam: PutInventoryUnitShipmentPlanCollectiveRequest = {
          adjustmentType,
          adjustmentCustomRate,
          adjustmentRange,
          numberOfWeeksToAdjust,
          selectedDemandForecast,
        }
        const params: PutInventoryUnitShipmentPlanCollectiveOperationRequest = {
          janCode,
          bodyParam,
        }
        return api.putInventoryUnitShipmentPlanCollective(params)
      })
      .catch((err) => {
        Sentry.captureException(err)
      })
  }

  return {
    shipmentPlanCollective,
    getInventoryUnitShipmentPlanCollective,
    putInventoryUnitShipmentPlanCollective,
    saveParams,
    onChangeSelectedForecast: (selecteForecast: AdjustmentTarget) =>
      onChangeSelectedForecast(selecteForecast, getInventoryUnitShipmentPlanCollective),
    inventoryUnitShipmentPlanParams,
    setInventoryUnitShipmentPlanParams,
  }
}
