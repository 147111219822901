/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for OMR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PutSimulationParametersRequestInventoryCalendar,
    PutSimulationParametersRequestInventoryCalendarFromJSON,
    PutSimulationParametersRequestInventoryCalendarFromJSONTyped,
    PutSimulationParametersRequestInventoryCalendarToJSON,
} from './';

/**
 * 
 * @export
 * @interface PutSimulationParametersRequestInventoryUnitSKUs
 */
export interface PutSimulationParametersRequestInventoryUnitSKUs {
    /**
     * 
     * @type {string}
     * @memberof PutSimulationParametersRequestInventoryUnitSKUs
     */
    inventoryUnitCode: string;
    /**
     * 
     * @type {string}
     * @memberof PutSimulationParametersRequestInventoryUnitSKUs
     */
    janCode: string;
    /**
     * 
     * @type {{ [key: string]: PutSimulationParametersRequestInventoryCalendar; }}
     * @memberof PutSimulationParametersRequestInventoryUnitSKUs
     */
    inventoryCalendar: { [key: string]: PutSimulationParametersRequestInventoryCalendar; };
}

export function PutSimulationParametersRequestInventoryUnitSKUsFromJSON(json: any): PutSimulationParametersRequestInventoryUnitSKUs {
    return PutSimulationParametersRequestInventoryUnitSKUsFromJSONTyped(json, false);
}

export function PutSimulationParametersRequestInventoryUnitSKUsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutSimulationParametersRequestInventoryUnitSKUs {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inventoryUnitCode': json['inventoryUnitCode'],
        'janCode': json['janCode'],
        'inventoryCalendar': (mapValues(json['inventoryCalendar'], PutSimulationParametersRequestInventoryCalendarFromJSON)),
    };
}

export function PutSimulationParametersRequestInventoryUnitSKUsToJSON(value?: PutSimulationParametersRequestInventoryUnitSKUs | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inventoryUnitCode': value.inventoryUnitCode,
        'janCode': value.janCode,
        'inventoryCalendar': (mapValues(value.inventoryCalendar, PutSimulationParametersRequestInventoryCalendarToJSON)),
    };
}


