import React from 'react'
import { useHistory } from 'react-router-dom'

import { OrderGroupsItem } from '../../../api-client'

import { FilledRoundLabel } from '../../atoms'

import {
  BaseOrderGroupsListTable,
  FilledRoundLabelContainer,
  OrderGroupsListTableBodyRow,
  OrdreGroupsListTableData,
  OrderGroupsListTableHeader,
} from './style'

export interface OrderGroupsListTableProps {
  orderGroups: Array<OrderGroupsItem>
}

export const OrderGroupsListTable: React.FC<OrderGroupsListTableProps> = ({ orderGroups }) => {
  const history = useHistory()

  return (
    <BaseOrderGroupsListTable>
      <thead>
        <tr>
          <OrderGroupsListTableHeader textalign="left" width={230}>
            発注グループ
          </OrderGroupsListTableHeader>
          <OrderGroupsListTableHeader textalign="left" width={110}>
            部門
          </OrderGroupsListTableHeader>
          <OrderGroupsListTableHeader textalign="center" width={156}>
            需要予測確認SKU
          </OrderGroupsListTableHeader>
          <OrderGroupsListTableHeader textalign="center" width={133}>
            発注案
          </OrderGroupsListTableHeader>
          <OrderGroupsListTableHeader textalign="center" width={100}>
            登録SKU
          </OrderGroupsListTableHeader>
          <OrderGroupsListTableHeader textalign="left" width={110}>
            担当者
          </OrderGroupsListTableHeader>
          <OrderGroupsListTableHeader textalign="center"></OrderGroupsListTableHeader>
        </tr>
      </thead>
      <tbody>
        {orderGroups.map((orderGroup) => (
          <OrderGroupsListTableBodyRow
            pointer={orderGroup.numberOfSkus > 0}
            key={orderGroup.id}
            onClick={() => {
              if (orderGroup.numberOfSkus === 0) {
                return
              }
              history.push(`/order-groups/${orderGroup.id}/inventory-plan`)
            }}
          >
            <OrdreGroupsListTableData textalign="left">{orderGroup.name}</OrdreGroupsListTableData>
            <OrdreGroupsListTableData textalign="left">
              {orderGroup.departmentCodes.length === 0
                ? '-'
                : orderGroup.departmentCodes.length === 1
                ? orderGroup.departmentCodes[0]
                : '複数部門'}
            </OrdreGroupsListTableData>
            <OrdreGroupsListTableData textalign="center">
              {orderGroup.numberOfLargePredictionErrorSkus > 0 ? (
                <FilledRoundLabelContainer>
                  <FilledRoundLabel
                    color="red"
                    text={orderGroup.numberOfLargePredictionErrorSkus.toString()}
                    width={40}
                  />
                </FilledRoundLabelContainer>
              ) : (
                '-'
              )}
            </OrdreGroupsListTableData>
            <OrdreGroupsListTableData textalign="center">
              {orderGroup.hasDraftPurchaseOrder === true ? 'あり' : '-'}
            </OrdreGroupsListTableData>
            <OrdreGroupsListTableData textalign="center">{orderGroup.numberOfSkus}</OrdreGroupsListTableData>
            <OrdreGroupsListTableData textalign="left">{orderGroup.operatorName}</OrdreGroupsListTableData>
            <OrdreGroupsListTableData textalign="left"></OrdreGroupsListTableData>
          </OrderGroupsListTableBodyRow>
        ))}
      </tbody>
    </BaseOrderGroupsListTable>
  )
}
