/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for OMR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DraftPurchaseOrderPlanningUnconfirmedInventoryUnitReceiving
 */
export interface DraftPurchaseOrderPlanningUnconfirmedInventoryUnitReceiving {
    /**
     * コンテナの種類
     * @type {string}
     * @memberof DraftPurchaseOrderPlanningUnconfirmedInventoryUnitReceiving
     */
    containerType: string;
    /**
     * 発注起案の種類
     * @type {string}
     * @memberof DraftPurchaseOrderPlanningUnconfirmedInventoryUnitReceiving
     */
    purchaseOrderType: DraftPurchaseOrderPlanningUnconfirmedInventoryUnitReceivingPurchaseOrderTypeEnum;
    /**
     * 入荷予定数
     * @type {number}
     * @memberof DraftPurchaseOrderPlanningUnconfirmedInventoryUnitReceiving
     */
    quantity: number;
}

/**
* @export
* @enum {string}
*/
export enum DraftPurchaseOrderPlanningUnconfirmedInventoryUnitReceivingPurchaseOrderTypeEnum {
    Normal = 'normal',
    MixedPurchaseOrder = 'mixedPurchaseOrder',
    MultipleWeeks = 'multipleWeeks',
    MixedPurchaseOrderAndmultipleWeeks = 'mixedPurchaseOrderAndmultipleWeeks',
    LandTransportation = 'landTransportation'
}

export function DraftPurchaseOrderPlanningUnconfirmedInventoryUnitReceivingFromJSON(json: any): DraftPurchaseOrderPlanningUnconfirmedInventoryUnitReceiving {
    return DraftPurchaseOrderPlanningUnconfirmedInventoryUnitReceivingFromJSONTyped(json, false);
}

export function DraftPurchaseOrderPlanningUnconfirmedInventoryUnitReceivingFromJSONTyped(json: any, ignoreDiscriminator: boolean): DraftPurchaseOrderPlanningUnconfirmedInventoryUnitReceiving {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'containerType': json['containerType'],
        'purchaseOrderType': json['purchaseOrderType'],
        'quantity': json['quantity'],
    };
}

export function DraftPurchaseOrderPlanningUnconfirmedInventoryUnitReceivingToJSON(value?: DraftPurchaseOrderPlanningUnconfirmedInventoryUnitReceiving | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'containerType': value.containerType,
        'purchaseOrderType': value.purchaseOrderType,
        'quantity': value.quantity,
    };
}


