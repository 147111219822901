/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for OMR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InventoryPlanPerWeek,
    InventoryPlanPerWeekFromJSON,
    InventoryPlanPerWeekFromJSONTyped,
    InventoryPlanPerWeekToJSON,
    InventoryUnitReceiving,
    InventoryUnitReceivingFromJSON,
    InventoryUnitReceivingFromJSONTyped,
    InventoryUnitReceivingToJSON,
    InventoryUnitSKUInventoryPlanPerWeekAllOf,
    InventoryUnitSKUInventoryPlanPerWeekAllOfFromJSON,
    InventoryUnitSKUInventoryPlanPerWeekAllOfFromJSONTyped,
    InventoryUnitSKUInventoryPlanPerWeekAllOfToJSON,
    UnreceivedOrder,
    UnreceivedOrderFromJSON,
    UnreceivedOrderFromJSONTyped,
    UnreceivedOrderToJSON,
    Week,
    WeekFromJSON,
    WeekFromJSONTyped,
    WeekToJSON,
} from './';

/**
 * InventoryUnit×SKUごとのInventoryPlanPerWeek
 * @export
 * @interface InventoryUnitSKUInventoryPlanPerWeek
 */
export interface InventoryUnitSKUInventoryPlanPerWeek {
    /**
     * 
     * @type {Week}
     * @memberof InventoryUnitSKUInventoryPlanPerWeek
     */
    week: Week;
    /**
     * 予測在庫数
     * @type {number}
     * @memberof InventoryUnitSKUInventoryPlanPerWeek
     */
    inventoryUnitInventoryQuantity: number;
    /**
     * 在庫切れか
     * @type {boolean}
     * @memberof InventoryUnitSKUInventoryPlanPerWeek
     */
    isInventoryUnitInventoryDeficient: boolean;
    /**
     * 予測在庫週数
     * @type {number}
     * @memberof InventoryUnitSKUInventoryPlanPerWeek
     */
    numberOfInventoryUnitInventoryWeeks: number | null;
    /**
     * 出荷予定数
     * @type {number}
     * @memberof InventoryUnitSKUInventoryPlanPerWeek
     */
    inventoryUnitShipmentQuantity: number | null;
    /**
     * 入荷予定数
     * @type {Array<InventoryUnitReceiving>}
     * @memberof InventoryUnitSKUInventoryPlanPerWeek
     */
    inventoryUnitReceivings: Array<InventoryUnitReceiving>;
    /**
     * 予測店舗在庫数
     * @type {number}
     * @memberof InventoryUnitSKUInventoryPlanPerWeek
     */
    storeInventoryQuantity: number;
    /**
     * 店舗基準在庫未達か
     * @type {boolean}
     * @memberof InventoryUnitSKUInventoryPlanPerWeek
     */
    isStoreInventoryStandardUnachieved: boolean;
    /**
     * 入荷残数
     * @type {Array<UnreceivedOrder>}
     * @memberof InventoryUnitSKUInventoryPlanPerWeek
     */
    unreceivedOrders: Array<UnreceivedOrder>;
    /**
     * シミュレーション反映中か
     * @type {boolean}
     * @memberof InventoryUnitSKUInventoryPlanPerWeek
     */
    isSimulated: boolean;
    /**
     * シミュレーション（入庫数）
     * @type {number}
     * @memberof InventoryUnitSKUInventoryPlanPerWeek
     */
    receivingQuantityForSimulation: number | null;
    /**
     * 補正対象の需要予測
     * @type {string}
     * @memberof InventoryUnitSKUInventoryPlanPerWeek
     */
    adjustmentTarget?: string;
}

export function InventoryUnitSKUInventoryPlanPerWeekFromJSON(json: any): InventoryUnitSKUInventoryPlanPerWeek {
    return InventoryUnitSKUInventoryPlanPerWeekFromJSONTyped(json, false);
}

export function InventoryUnitSKUInventoryPlanPerWeekFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryUnitSKUInventoryPlanPerWeek {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'week': WeekFromJSON(json['week']),
        'inventoryUnitInventoryQuantity': json['inventoryUnitInventoryQuantity'],
        'isInventoryUnitInventoryDeficient': json['isInventoryUnitInventoryDeficient'],
        'numberOfInventoryUnitInventoryWeeks': json['numberOfInventoryUnitInventoryWeeks'],
        'inventoryUnitShipmentQuantity': json['inventoryUnitShipmentQuantity'],
        'inventoryUnitReceivings': ((json['inventoryUnitReceivings'] as Array<any>).map(InventoryUnitReceivingFromJSON)),
        'storeInventoryQuantity': json['storeInventoryQuantity'],
        'isStoreInventoryStandardUnachieved': json['isStoreInventoryStandardUnachieved'],
        'unreceivedOrders': ((json['unreceivedOrders'] as Array<any>).map(UnreceivedOrderFromJSON)),
        'isSimulated': json['isSimulated'],
        'receivingQuantityForSimulation': json['receivingQuantityForSimulation'],
        'adjustmentTarget': !exists(json, 'adjustmentTarget') ? undefined : json['adjustmentTarget'],
    };
}

export function InventoryUnitSKUInventoryPlanPerWeekToJSON(value?: InventoryUnitSKUInventoryPlanPerWeek | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'week': WeekToJSON(value.week),
        'inventoryUnitInventoryQuantity': value.inventoryUnitInventoryQuantity,
        'isInventoryUnitInventoryDeficient': value.isInventoryUnitInventoryDeficient,
        'numberOfInventoryUnitInventoryWeeks': value.numberOfInventoryUnitInventoryWeeks,
        'inventoryUnitShipmentQuantity': value.inventoryUnitShipmentQuantity,
        'inventoryUnitReceivings': ((value.inventoryUnitReceivings as Array<any>).map(InventoryUnitReceivingToJSON)),
        'storeInventoryQuantity': value.storeInventoryQuantity,
        'isStoreInventoryStandardUnachieved': value.isStoreInventoryStandardUnachieved,
        'unreceivedOrders': ((value.unreceivedOrders as Array<any>).map(UnreceivedOrderToJSON)),
        'isSimulated': value.isSimulated,
        'receivingQuantityForSimulation': value.receivingQuantityForSimulation,
        'adjustmentTarget': value.adjustmentTarget,
    };
}


