import { useState } from 'react'

import {
  AdjustmentRange,
  AdjustmentType,
  GetInventoryUnitShipmentPlanAdjustmentRangeEnum,
  GetInventoryUnitShipmentPlanAdjustmentTypeEnum,
  GetInventoryUnitShipmentPlanSelectedDemandForecastEnum,
  AdjustmentTarget,
  GetInventoryUnitShipmentPlanCollectiveAdjustmentTypeEnum,
  GetInventoryUnitShipmentPlanCollectiveAdjustmentRangeEnum,
  GetInventoryUnitShipmentPlanCollectiveSelectedDemandForecastEnum,
} from '../api-client'
import { ADJUSTMENT_CUSTOM_RATE_DEFAULT, NUMBER_OF_WEEKS_TO_ADJUST_DEFAULT } from '../constants'

type InventoryUnitShipmentPlanParamsProps = {
  adjustmentType: AdjustmentType
  adjustmentCustomRate: number
  adjustmentRange?: AdjustmentRange | null
  numberOfWeeksToAdjust: number
  selectedDemandForecast: string
}

export type InventoryUnitShipmentPlanParamsBySelectedDemandForecast = {
  demandInsightParams: InventoryUnitShipmentPlanParamsProps
  salesCompositionParams: InventoryUnitShipmentPlanParamsProps
  selectedDemandForecast: AdjustmentTarget
}

const defaultInventoryUnitShipmentPlanDemandInsightParams = {
  adjustmentType: AdjustmentType.NoAdjustment,
  adjustmentCustomRate: ADJUSTMENT_CUSTOM_RATE_DEFAULT,
  adjustmentRange: AdjustmentRange.InSomeWeeks,
  numberOfWeeksToAdjust: NUMBER_OF_WEEKS_TO_ADJUST_DEFAULT,
  selectedDemandForecast: AdjustmentTarget.DemandInsight,
}

const defaultInventoryUnitShipmentPlanSalesCompositionParams = {
  ...defaultInventoryUnitShipmentPlanDemandInsightParams,
  selectedDemandForecast: AdjustmentTarget.SalesComposition,
}

const defaultInventoryUnitShipmentPlanParamsBySelectedDemandForecast = {
  demandInsightParams: defaultInventoryUnitShipmentPlanDemandInsightParams,
  salesCompositionParams: defaultInventoryUnitShipmentPlanSalesCompositionParams,
  selectedDemandForecast: AdjustmentTarget.DemandInsight,
}

export const useInventoryUnitShipmentPlanParams = () => {
  const [inventoryUnitShipmentPlanParams, setInventoryUnitShipmentPlanParams] =
    useState<InventoryUnitShipmentPlanParamsBySelectedDemandForecast>(
      defaultInventoryUnitShipmentPlanParamsBySelectedDemandForecast
    )

  const [InitialInventoryUnitShipmentPlanParams, setInitialInventoryUnitShipmentPlanParams] =
    useState<InventoryUnitShipmentPlanParamsBySelectedDemandForecast>(
      defaultInventoryUnitShipmentPlanParamsBySelectedDemandForecast
    )

  const saveParams = (props: InventoryUnitShipmentPlanParamsProps) => {
    setInventoryUnitShipmentPlanParams((prev) => {
      let settingPramas: InventoryUnitShipmentPlanParamsBySelectedDemandForecast = prev
      if (inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight) {
        const settingProps =
          // adjustmentTypeがデータ初期値と同じかつNoAdjustmentの場合はadjustmentCustomRate, adjustmentRangeをデータ初期値に設定
          InitialInventoryUnitShipmentPlanParams.demandInsightParams.adjustmentType === AdjustmentType.NoAdjustment &&
          props.adjustmentType === AdjustmentType.NoAdjustment
            ? {
                ...props,
                adjustmentCustomRate: InitialInventoryUnitShipmentPlanParams.demandInsightParams.adjustmentCustomRate,
                adjustmentRange: InitialInventoryUnitShipmentPlanParams.demandInsightParams.adjustmentRange,
              }
            : props
        settingPramas = { ...settingPramas, demandInsightParams: { ...settingProps } }
      } else if (inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.SalesComposition) {
        const settingProps =
          // adjustmentTypeがデータ初期値と同じかつNoAdjustmentの場合はadjustmentCustomRate, adjustmentRangeをデータ初期値に設定
          InitialInventoryUnitShipmentPlanParams.salesCompositionParams.adjustmentType ===
            AdjustmentType.NoAdjustment && props.adjustmentType === AdjustmentType.NoAdjustment
            ? {
                ...props,
                adjustmentCustomRate: InitialInventoryUnitShipmentPlanParams.demandInsightParams.adjustmentCustomRate,
                adjustmentRange: InitialInventoryUnitShipmentPlanParams.demandInsightParams.adjustmentRange,
              }
            : props
        settingPramas = { ...settingPramas, salesCompositionParams: { ...settingProps } }
      }
      return { ...settingPramas }
    })
  }

  const onChangeSelectedForecast = (
    selecteForecast: AdjustmentTarget,
    getInventoryUnitShipmentPlan: (
      adjustmentType?: AdjustmentType,
      adjustmentCustomRate?: number,
      adjustmentRange?: AdjustmentRange,
      numberOfWeeksToAdjust?: number,
      selectedDemandForecast?: string
    ) => Promise<void> | undefined
  ) => {
    const params =
      selecteForecast === AdjustmentTarget.DemandInsight
        ? inventoryUnitShipmentPlanParams.demandInsightParams
        : inventoryUnitShipmentPlanParams.salesCompositionParams

    if (!params) return
    getInventoryUnitShipmentPlan(
      params.adjustmentType,
      params.adjustmentCustomRate,
      params.adjustmentRange ?? undefined,
      params.numberOfWeeksToAdjust,
      params.selectedDemandForecast
    )
  }

  return {
    convAdjustmentType,
    convAdjustmentRange,
    convDemandForecast,
    saveParams,
    onChangeSelectedForecast,
    inventoryUnitShipmentPlanParams,
    setInventoryUnitShipmentPlanParams,
    setInitialInventoryUnitShipmentPlanParams,
    isInitialParams: isInitialParams(InitialInventoryUnitShipmentPlanParams, inventoryUnitShipmentPlanParams),
  }
}

const isInitialParams = (
  initialPrams: InventoryUnitShipmentPlanParamsBySelectedDemandForecast,
  currentPrams: InventoryUnitShipmentPlanParamsBySelectedDemandForecast
): boolean => {
  if (initialPrams.selectedDemandForecast === currentPrams.selectedDemandForecast) {
    if (initialPrams.selectedDemandForecast === AdjustmentTarget.DemandInsight) {
      return comparisonParams(initialPrams.demandInsightParams, currentPrams.demandInsightParams)
    } else if (initialPrams.selectedDemandForecast === AdjustmentTarget.SalesComposition) {
      return comparisonParams(initialPrams.salesCompositionParams, currentPrams.salesCompositionParams)
    }
  }
  return false
}

const comparisonParams = (
  initialPrams: InventoryUnitShipmentPlanParamsProps,
  currentPrams: InventoryUnitShipmentPlanParamsProps
): boolean => {
  return (
    initialPrams.adjustmentCustomRate === currentPrams.adjustmentCustomRate &&
    initialPrams.adjustmentRange === currentPrams.adjustmentRange &&
    initialPrams.adjustmentType === currentPrams.adjustmentType &&
    initialPrams.numberOfWeeksToAdjust === currentPrams.numberOfWeeksToAdjust &&
    initialPrams.selectedDemandForecast === currentPrams.selectedDemandForecast
  )
}

const convAdjustmentType = (
  adjustmentType?: AdjustmentType
): GetInventoryUnitShipmentPlanAdjustmentTypeEnum | GetInventoryUnitShipmentPlanCollectiveAdjustmentTypeEnum => {
  switch (adjustmentType) {
    case AdjustmentType.NoAdjustment:
      return GetInventoryUnitShipmentPlanAdjustmentTypeEnum.NoAdjustment
    case AdjustmentType.AchievementRateIn4Weeks:
      return GetInventoryUnitShipmentPlanAdjustmentTypeEnum.AchievementRateIn4Weeks
    case AdjustmentType.AchievementRateIn13Weeks:
      return GetInventoryUnitShipmentPlanAdjustmentTypeEnum.AchievementRateIn13Weeks
    case AdjustmentType.AverageSalesIn4Weeks:
      return GetInventoryUnitShipmentPlanAdjustmentTypeEnum.AverageSalesIn4Weeks
    case AdjustmentType.AverageSalesIn13Weeks:
      return GetInventoryUnitShipmentPlanAdjustmentTypeEnum.AverageSalesIn13Weeks
    case AdjustmentType.YearOverYearIn4Weeks:
      return GetInventoryUnitShipmentPlanAdjustmentTypeEnum.YearOverYearIn4Weeks
    case AdjustmentType.YearOverYearIn13Weeks:
      return GetInventoryUnitShipmentPlanAdjustmentTypeEnum.YearOverYearIn13Weeks
    case AdjustmentType.CustomRate:
      return GetInventoryUnitShipmentPlanAdjustmentTypeEnum.CustomRate
    default:
      return GetInventoryUnitShipmentPlanAdjustmentTypeEnum.NoAdjustment
  }
}
const convAdjustmentRange = (
  adjustmentRange?: AdjustmentRange
):
  | GetInventoryUnitShipmentPlanAdjustmentRangeEnum
  | GetInventoryUnitShipmentPlanCollectiveAdjustmentRangeEnum
  | undefined => {
  switch (adjustmentRange) {
    case AdjustmentRange.All:
      return GetInventoryUnitShipmentPlanAdjustmentRangeEnum.All
    case AdjustmentRange.InSomeWeeks:
      return GetInventoryUnitShipmentPlanAdjustmentRangeEnum.InSomeWeeks
    default:
      return undefined
  }
}

const convDemandForecast = (
  demandForecast?: string
):
  | GetInventoryUnitShipmentPlanSelectedDemandForecastEnum
  | GetInventoryUnitShipmentPlanCollectiveSelectedDemandForecastEnum
  | undefined => {
  switch (demandForecast) {
    case 'demandInsight':
      return GetInventoryUnitShipmentPlanSelectedDemandForecastEnum.DemandInsight
    case 'salesComposition':
      return GetInventoryUnitShipmentPlanSelectedDemandForecastEnum.SalesComposition
    default:
      throw Error('Invalid DemandForecast input value')
  }
}
