/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for OMR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * コンテナのタイプ
 * @export
 * @enum {string}
 */
export enum ContainerType {
    _20F = '20F',
    _40F = '40F',
    _40Hc = '40HC',
    ContainerTypeLcl = 'ContainerTypeLCL'
}

export function ContainerTypeFromJSON(json: any): ContainerType {
    return ContainerTypeFromJSONTyped(json, false);
}

export function ContainerTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContainerType {
    return json as ContainerType;
}

export function ContainerTypeToJSON(value?: ContainerType | null): any {
    return value as any;
}

