import styled from 'styled-components'

import { ReactComponent as BasePlusIcon } from '../../../assets/images/plus.svg'

const PlusIcon = styled(BasePlusIcon)`
  height: 14px;
  width: 14px;
`

const PlusIconContainer = styled.span`
  margin-right: 7px;
  vertical-align: middle;
`

interface BaseButtonProps {
  disabled: boolean
  width: number
}

const BaseButton = styled.button<BaseButtonProps>`
  border-radius: 3px;
  cursor: ${props => props.disabled? 'not-allowed' : 'pointer'};
  height: 40px;
  letter-spacing: 0.49px;
  width: ${props => props.width}px;
`

const PrimaryButton = styled(BaseButton)`
  background-color: ${props => props.disabled? '#9bb9e7' : 'var(--di-primary-color)'};
  border-width: 0;
  color: #fff;
  transition: background-color 0.3s ease-out;

  &:hover {
    background-color: ${props => props.disabled? '#9bb9e7' : 'var(--di-primary-hover-color)'};
  }
`

const SecondaryButton = styled(BaseButton)`
  background-color: #fff;
  border: 1px solid ${props => props.disabled? '#cecece' : 'var(--di-primary-color)'};
  color: ${props => props.disabled? '#999' : 'var(--di-primary-color)'};
  transition: all 0.3s ease-out;

  &:hover {
    background-color: ${props => props.disabled? '#fff' : 'var(--di-primary-color)'};
    color: ${props => props.disabled? '#999' : '#fff'};
  }

  &:hover svg {
    stroke: #fff;
  }
`

const SecondaryPlusIcon = styled(PlusIcon)`
  stroke: var(--di-primary-color);
  transition: all 0.3s ease-out;
`

const TertiaryButton = styled(BaseButton)`
  background-color: #f8f4f4;
  border: 1px solid #cecece;
  color: ${props => props.disabled? '#999' : 'var(--di-text-primary-color)'};
  transition: all 0.3s ease-out;

  &:hover {
    background-color: ${props => props.disabled? '#f8f4f4' : '#fcf7f7'};
  }
`

export {
  PlusIconContainer,
  PrimaryButton,
  SecondaryButton,
  SecondaryPlusIcon,
  TertiaryButton,
}
