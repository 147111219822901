import styled from 'styled-components'

const colors = (color: string): string => {
  switch (color) {
    case 'green':
      return '#1d721f'
    case 'red':
      return '#b72727'
    case 'yellow':
      return '#db7521'
    case 'black':
      return '#505050'
    case 'blue':
      return '#140B9B'
    default: // color は必須なのと型で有効値が決まっているのででこの条件は通らない想定
      return 'inherit'
  }
}

const BaseSquareLabel = styled.div<{
  color: string,
  width: number,
}>`
  border-color: ${props => colors(props.color)};
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  color: ${props => colors(props.color)};
  height: 20px;
  text-align: center;
  width: ${props => `${props.width}px`};
`

export {
  BaseSquareLabel,
}
