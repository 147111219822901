import styled from 'styled-components'

const Container = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 8px;
  white-space: nowrap;
`

const DownloadButtonContainer = styled.div`
  margin-left: 5px;
`

const DropdownContainer = styled.div`
  margin-right: 24px;
`

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 45px;
  justify-content: space-between;
  margin-right: 24px;
`

const NumberOfHitsLabel = styled.div`
  color: #999;
  margin-left: auto;
  margin-right: 32px;
`

const SearchFormContainer = styled.div`
  margin-right: 24px;
`

export {
  Container,
  DownloadButtonContainer,
  DropdownContainer,
  FilterContainer,
  NumberOfHitsLabel,
  SearchFormContainer,
}
