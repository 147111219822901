import styled from 'styled-components'

import { ReactComponent as BaseTriangleIcon } from '../../../assets/images/triangle.svg'

const BaseSimulationButton = styled.div<{ disabled: boolean }>`
  align-items: center;
  background-color: #f8f4f4;
  border: 1px solid #cecece;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  height: 37px;
  padding-left: 20px;
  pointer-events: ${props => props.disabled === true? 'none' : 'auto'};
  transition: background-color 0.3s ease-out;
  width: 217px;

  &:hover {
    background-color: #fcf7f7;
  }
`

const Container = styled.div`
  position: relative;
`

const DropdownContainer = styled.div<{ visible: string }>`
  box-shadow: 0 3px 6px #00000029;
  opacity: ${props => props.visible === 'true'? 1 : 0};
  position: absolute;
  transition: all 0.3s ease-out;
  visibility: ${props => props.visible === 'true'? 'visible' : 'hidden'};
  width: 239px;
`

const DropdownOption = styled.div`
  align-items: center;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  height: 40px;
  padding-left: 17px;
  transition: background-color 0.3s ease-out;

  &:hover {
    background-color: #fafafa;
  }
`

const Label = styled.span<{ disabled: boolean }>`
  color: ${props => props.disabled === true? '#999' : 'inherit'};
`

const Remark = styled.span`
  color: #999;
`

const TriangleIcon = styled(BaseTriangleIcon)<{
  expanded: string
  disabled: boolean
}>`
  transform: ${props => props.expanded === 'true'? 'none' : 'scaleY(-1)'};

  & path {
    fill: ${props => props.disabled === true? '#999' : '#707070'};
  }
`

const TrianbleIconContainer = styled.div`
  align-items: center;
  border-left: 1px solid #ccc;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: 0;
  height: 30px;
  width: 40px;
`

export {
  BaseSimulationButton,
  Container,
  DropdownContainer,
  DropdownOption,
  Label,
  Remark,
  TriangleIcon,
  TrianbleIconContainer,
}
