/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for OMR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 出荷予定数の情報
 * @export
 * @interface InventoryUnitShipmentPlanPerWeek
 */
export interface InventoryUnitShipmentPlanPerWeek {
    /**
     * 永続化された補正条件で計算された需要予測数
     * @type {number}
     * @memberof InventoryUnitShipmentPlanPerWeek
     */
    permanentlyPlannedSalesQuantity: number | null;
    /**
     * 一時的に指定された補正条件で計算された需要予測数
     * @type {number}
     * @memberof InventoryUnitShipmentPlanPerWeek
     */
    temporarilyPlannedSalesQuantity: number | null;
    /**
     * 補正なしの需要予測数
     * @type {number}
     * @memberof InventoryUnitShipmentPlanPerWeek
     */
    noAdjustmentPlannedSalesQuantity: number | null;
    /**
     * 販売実績数
     * @type {number}
     * @memberof InventoryUnitShipmentPlanPerWeek
     */
    actualSalesQuantity: number | null;
    /**
     * 昨年販売実績
     * @type {number}
     * @memberof InventoryUnitShipmentPlanPerWeek
     */
    lastYearActualSalesQuantity: number | null;
    /**
     * 積み増し登録
     * @type {number}
     * @memberof InventoryUnitShipmentPlanPerWeek
     */
    inventoryAccumulationQuantity: number | null;
    /**
     * 上書修正登録
     * @type {number}
     * @memberof InventoryUnitShipmentPlanPerWeek
     */
    plannedSalesOverwriteQuantity: number | null;
    /**
     * 出荷予定数
     * @type {number}
     * @memberof InventoryUnitShipmentPlanPerWeek
     */
    inventoryUnitShipmentQuantity: number | null;
    /**
     * 卸出荷(販売実績数)
     * @type {number}
     * @memberof InventoryUnitShipmentPlanPerWeek
     */
    actualWholesaleShipmentQuantity: number | null;
    /**
     * 卸出荷(需要予測数)
     * @type {number}
     * @memberof InventoryUnitShipmentPlanPerWeek
     */
    plannedWholesalesShipmentQuantity: number | null;
    /**
     * 永続化された補正条件で計算された売上構成比による需要予測数
     * @type {number}
     * @memberof InventoryUnitShipmentPlanPerWeek
     */
    permanentlySalesCompositionQuantity: number | null;
}

export function InventoryUnitShipmentPlanPerWeekFromJSON(json: any): InventoryUnitShipmentPlanPerWeek {
    return InventoryUnitShipmentPlanPerWeekFromJSONTyped(json, false);
}

export function InventoryUnitShipmentPlanPerWeekFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryUnitShipmentPlanPerWeek {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'permanentlyPlannedSalesQuantity': json['permanentlyPlannedSalesQuantity'],
        'temporarilyPlannedSalesQuantity': json['temporarilyPlannedSalesQuantity'],
        'noAdjustmentPlannedSalesQuantity': json['noAdjustmentPlannedSalesQuantity'],
        'actualSalesQuantity': json['actualSalesQuantity'],
        'lastYearActualSalesQuantity': json['lastYearActualSalesQuantity'],
        'inventoryAccumulationQuantity': json['inventoryAccumulationQuantity'],
        'plannedSalesOverwriteQuantity': json['plannedSalesOverwriteQuantity'],
        'inventoryUnitShipmentQuantity': json['inventoryUnitShipmentQuantity'],
        'actualWholesaleShipmentQuantity': json['actualWholesaleShipmentQuantity'],
        'plannedWholesalesShipmentQuantity': json['plannedWholesalesShipmentQuantity'],
        'permanentlySalesCompositionQuantity': json['permanentlySalesCompositionQuantity'],
    };
}

export function InventoryUnitShipmentPlanPerWeekToJSON(value?: InventoryUnitShipmentPlanPerWeek | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'permanentlyPlannedSalesQuantity': value.permanentlyPlannedSalesQuantity,
        'temporarilyPlannedSalesQuantity': value.temporarilyPlannedSalesQuantity,
        'noAdjustmentPlannedSalesQuantity': value.noAdjustmentPlannedSalesQuantity,
        'actualSalesQuantity': value.actualSalesQuantity,
        'lastYearActualSalesQuantity': value.lastYearActualSalesQuantity,
        'inventoryAccumulationQuantity': value.inventoryAccumulationQuantity,
        'plannedSalesOverwriteQuantity': value.plannedSalesOverwriteQuantity,
        'inventoryUnitShipmentQuantity': value.inventoryUnitShipmentQuantity,
        'actualWholesaleShipmentQuantity': value.actualWholesaleShipmentQuantity,
        'plannedWholesalesShipmentQuantity': value.plannedWholesalesShipmentQuantity,
        'permanentlySalesCompositionQuantity': value.permanentlySalesCompositionQuantity,
    };
}


