import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import firebase from 'firebase/app'

import {
  DefaultApi,
  Configuration,
  GetInventoryPlanRequest,
  GetInventoryPlanResponse,
  GetOrderGroupCsvFileRequest,
  PutSimulationParametersRequestInventoryCalendar,
  PutSimulationParametersRequest,
  PutSimulationParametersResponse,
  PutOrderGroupSimulationParametersRequest,
  DeleteOrderGroupSimulationParametersRequest,
} from '../api-client'

export const useInventoryPlan = (orderGroupId: number) => {
  const [inventoryPlan, setInventoryPlan] = useState<GetInventoryPlanResponse | undefined>(undefined)

  useEffect(() => {
    firebase
      .auth()
      .currentUser?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        const param: GetInventoryPlanRequest = {
          orderGroupId,
        }
        return api.getInventoryPlan(param)
      })
      .then(setInventoryPlan)
      .catch((err) => {
        Sentry.captureException(err)
      })
  }, [orderGroupId])

  const getCSVFile = (orderGroupId: number) => {
    return firebase
      .auth()
      .currentUser?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        const param: GetOrderGroupCsvFileRequest = {
          orderGroupId,
        }
        return api.getOrderGroupCsvFileRaw(param).then((response) => {
          return response.value().then((blob) => {
            const disposition = response.raw.headers.get('content-disposition')
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
            const matches = filenameRegex.exec(disposition != null ? disposition : '')

            let filename
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, '')
              filename = decodeURI(filename)
            }

            return { filename, blob }
          })
        })
      })
  }

  const putSimulationParameters = (orderGroupId: number, orderGroupDetail: PutSimulationParametersResponse) => {
    setInventoryPlan(undefined)

    firebase
      .auth()
      .currentUser?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        const bodyParam: PutSimulationParametersRequest = {
          inventoryUnitSKUs: orderGroupDetail.inventoryUnitSKUs.map((inventoryUnitSKU) => {
            const inventoryCalendar: {
              [key: string]: PutSimulationParametersRequestInventoryCalendar
            } = {}
            Object.keys(inventoryUnitSKU.inventoryCalendar).forEach((weekDate) => {
              inventoryCalendar[weekDate] = {
                week: inventoryUnitSKU.inventoryCalendar[weekDate].week,
                receivingQuantityForSimulation:
                  inventoryUnitSKU.inventoryCalendar[weekDate].receivingQuantityForSimulation,
              }
            })
            return {
              inventoryUnitCode: inventoryUnitSKU.inventoryUnitCode,
              janCode: inventoryUnitSKU.janCode,
              inventoryCalendar,
            }
          }),
        }
        const param: PutOrderGroupSimulationParametersRequest = {
          orderGroupId,
          bodyParam,
        }
        return api
          .putOrderGroupSimulationParameters(param)
          .then(setInventoryPlan)
          .catch((err) => {
            Sentry.captureException(err)
          })
      })
  }

  const deleteSimulationParameters = (orderGroupId: number) => {
    // レスポンス来るまでは初期状態で待機.
    setInventoryPlan(undefined)

    firebase
      .auth()
      .currentUser?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        const param: DeleteOrderGroupSimulationParametersRequest = {
          orderGroupId,
        }
        return api.deleteOrderGroupSimulationParameters(param).then(() => {
          const conf = new Configuration({
            basePath: process.env.REACT_APP_API_PATH,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          const api = new DefaultApi(conf)
          const param: GetInventoryPlanRequest = {
            orderGroupId,
          }
          return api.getInventoryPlan(param).then(setInventoryPlan)
        })
      })
      .catch((err) => {
        Sentry.captureException(err)
      })
  }

  return {
    inventoryPlan,
    setInventoryPlan,
    getCSVFile,
    putSimulationParameters,
    deleteSimulationParameters,
  }
}
