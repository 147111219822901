import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import firebase from 'firebase/app'

import { FirebaseAuthErrorCode } from '../../constants'

import { Logo, NotificationField } from '../../components/atoms'

import { LoginForm } from '../../components/molecules'

import { Panel as PanelTemplate } from '../../templates'

import { LinkContainer, LoginFormContainer, LogoContainer, NotificationFieldContainer } from './style'

export interface LoginProps {}

export const Login: React.FC<LoginProps> = () => {
  const history = useHistory()

  const [errorMessage, setErrorMessage] = useState('')

  return (
    <PanelTemplate>
      <LogoContainer>
        <Logo logoColor="black" />
      </LogoContainer>
      <LoginFormContainer>
        {errorMessage !== '' ? (
          <NotificationFieldContainer>
            <NotificationField type="error" bodyMessage={errorMessage} />
          </NotificationFieldContainer>
        ) : null}
        <LoginForm
          onLoginClick={(email, password) => {
            firebase
              .auth()
              .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
              .then(() => {
                firebase
                  .auth()
                  .signInWithEmailAndPassword(email, password)
                  .then(() => {
                    history.push('/')
                  })
                  .catch((error) => {
                    if (
                      error.code === FirebaseAuthErrorCode.USER_DELETED ||
                      error.code === FirebaseAuthErrorCode.INVALID_PASSWORD
                    ) {
                      // ユーザーの存在有無を知られないようにする.
                      // コンソールを見れば知ることができてしまうが, そこは許容する仕様.
                      setErrorMessage('メールアドレスまたはパスワードが異なります。')
                    } else if (error.code === FirebaseAuthErrorCode.TOO_MANY_ATTEMPTS_TRY_LATER) {
                      setErrorMessage('アカウントがロックされました。\nしばらくしてから再度ログインして下さい。')
                    } else {
                      setErrorMessage('ユーザー認証に失敗しました。\n管理者にお問い合わせ下さい。')
                    }
                  })
              })
          }}
        />
        <LinkContainer>
          <Link to="/password-reset">パスワードを忘れた場合</Link>
        </LinkContainer>
      </LoginFormContainer>
    </PanelTemplate>
  )
}
