/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for OMR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdjustmentTarget,
    AdjustmentTargetFromJSON,
    AdjustmentTargetFromJSONTyped,
    AdjustmentTargetToJSON,
    ErrorLevel,
    ErrorLevelFromJSON,
    ErrorLevelFromJSONTyped,
    ErrorLevelToJSON,
    InventoryUnitSKUInventoryPlanPerWeek,
    InventoryUnitSKUInventoryPlanPerWeekFromJSON,
    InventoryUnitSKUInventoryPlanPerWeekFromJSONTyped,
    InventoryUnitSKUInventoryPlanPerWeekToJSON,
} from './';

/**
 * 
 * @export
 * @interface InventoryUnitSKU
 */
export interface InventoryUnitSKU {
    /**
     * 在庫管理コード
     * @type {string}
     * @memberof InventoryUnitSKU
     */
    inventoryUnitCode: string;
    /**
     * SKUコード
     * @type {string}
     * @memberof InventoryUnitSKU
     */
    janCode: string;
    /**
     * 
     * @type {ErrorLevel}
     * @memberof InventoryUnitSKU
     */
    errorLevelInLast4Weeks: ErrorLevel;
    /**
     * 
     * @type {ErrorLevel}
     * @memberof InventoryUnitSKU
     */
    errorLevelInLast13Weeks: ErrorLevel;
    /**
     * 補正対象かどうか
     * @type {boolean}
     * @memberof InventoryUnitSKU
     */
    isAdjusted: boolean;
    /**
     * 
     * @type {{ [key: string]: InventoryUnitSKUInventoryPlanPerWeek; }}
     * @memberof InventoryUnitSKU
     */
    inventoryCalendar: { [key: string]: InventoryUnitSKUInventoryPlanPerWeek; };
    /**
     * ヤード残数
     * @type {number}
     * @memberof InventoryUnitSKU
     */
    yardQuantity: number;
    /**
     * 
     * @type {AdjustmentTarget}
     * @memberof InventoryUnitSKU
     */
    adjustmentTarget?: AdjustmentTarget;
}

export function InventoryUnitSKUFromJSON(json: any): InventoryUnitSKU {
    return InventoryUnitSKUFromJSONTyped(json, false);
}

export function InventoryUnitSKUFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryUnitSKU {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inventoryUnitCode': json['inventoryUnitCode'],
        'janCode': json['janCode'],
        'errorLevelInLast4Weeks': ErrorLevelFromJSON(json['errorLevelInLast4Weeks']),
        'errorLevelInLast13Weeks': ErrorLevelFromJSON(json['errorLevelInLast13Weeks']),
        'isAdjusted': json['isAdjusted'],
        'inventoryCalendar': (mapValues(json['inventoryCalendar'], InventoryUnitSKUInventoryPlanPerWeekFromJSON)),
        'yardQuantity': json['yardQuantity'],
        'adjustmentTarget': !exists(json, 'adjustmentTarget') ? undefined : AdjustmentTargetFromJSON(json['adjustmentTarget']),
    };
}

export function InventoryUnitSKUToJSON(value?: InventoryUnitSKU | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inventoryUnitCode': value.inventoryUnitCode,
        'janCode': value.janCode,
        'errorLevelInLast4Weeks': ErrorLevelToJSON(value.errorLevelInLast4Weeks),
        'errorLevelInLast13Weeks': ErrorLevelToJSON(value.errorLevelInLast13Weeks),
        'isAdjusted': value.isAdjusted,
        'inventoryCalendar': (mapValues(value.inventoryCalendar, InventoryUnitSKUInventoryPlanPerWeekToJSON)),
        'yardQuantity': value.yardQuantity,
        'adjustmentTarget': AdjustmentTargetToJSON(value.adjustmentTarget),
    };
}


