import React from 'react'
import { FilledSquareLabelProps } from '../FilledSquareLabel/FilledSquareLabel'

import { BaseFilledRoundLabel } from './style'

type FilledRoundLabelColor = 'red'

export interface FilledRoundLabelProps {
  color: FilledRoundLabelColor
  text: string
  width: number
}

export const FilledRoundLabel: React.FC<FilledSquareLabelProps> = ({ color, text, width }) => (
  <BaseFilledRoundLabel color={color} width={width}>
    {text}
  </BaseFilledRoundLabel>
)
