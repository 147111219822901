import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import firebase from 'firebase/app'

import { Configuration, DefaultApi, OrderGroupsItem } from '../api-client'

export const useOrderGroups = () => {
  const [orderGroups, setOrderGroups] = useState<Array<OrderGroupsItem> | undefined>(undefined)

  useEffect(() => {
    firebase
      .auth()
      .currentUser?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        return api.getOrderGroups()
      })
      .then(setOrderGroups)
      .catch((err) => {
        Sentry.captureException(err)
      })
  }, [])

  return {
    orderGroups,
  }
}
