import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import {
  BaseSidebar,
  BaseSidebarCard,
  CaretIcon,
  ChildSidebarCardLabel,
  SidebarCardLabel,
  SidebarCardIcon,
} from './style'

export interface SidebarProps {
  currentPath: string
}

export const Sidebar: React.FC<SidebarProps> = ({ currentPath }) => {
  const [isMasterDataDropdownExpanded, setIsMasterDataDropdownExpanded] = useState(
    currentPath.startsWith('/master-data')
  )

  return (
    <BaseSidebar>
      <Link to="/dashboard">
        <BaseSidebarCard selected={currentPath === '/' || currentPath.startsWith('/dashboard')}>
          <SidebarCardIcon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              style={{ transform: 'rotate(360deg)' }}
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 24 24"
            >
              <path d="M13 3v6h8V3m-8 18h8V11h-8M3 21h8v-6H3m0-2h8V3H3v10z" fill="#717171" />
            </svg>
          </SidebarCardIcon>
          <SidebarCardLabel>ダッシュボード</SidebarCardLabel>
        </BaseSidebarCard>
      </Link>
      <Link to="/order-groups">
        <BaseSidebarCard selected={currentPath.startsWith('/order-groups')}>
          <SidebarCardIcon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              style={{ transform: 'rotate(360deg)' }}
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 32 32"
            >
              <g fill="none" stroke="#717171" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                <path d="M4 8h24M4 16h24M4 24h24" />
              </g>
            </svg>
          </SidebarCardIcon>
          <SidebarCardLabel>発注グループ</SidebarCardLabel>
        </BaseSidebarCard>
      </Link>
      <BaseSidebarCard
        selected={false}
        onClick={() => setIsMasterDataDropdownExpanded(!isMasterDataDropdownExpanded)}
        data-testid="master-data-card"
      >
        <SidebarCardIcon>
          <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
            <path id="guide_24px_" data-name="guide(24px)" d="M0,0H24V24H0Z" fill="none" />
            <path
              id="pass"
              d="M14,10H2v2H14Zm0-4H2V8H14Zm4,8V10H16v4H12v2h4v4h2V16h4V14ZM2,16h8V14H2Z"
              fill="#717171"
            />
          </svg>
        </SidebarCardIcon>
        <SidebarCardLabel>マスター管理</SidebarCardLabel>
        <CaretIcon expanded={isMasterDataDropdownExpanded.toString()} />
      </BaseSidebarCard>
      {isMasterDataDropdownExpanded === true ? (
        <>
          <Link to="/master-data/register">
            <BaseSidebarCard
              selected={currentPath.startsWith('/master-data') && !currentPath.startsWith('/master-data/skus')}
            >
              <ChildSidebarCardLabel>マスター登録</ChildSidebarCardLabel>
            </BaseSidebarCard>
          </Link>
          <Link to="/master-data/skus">
            <BaseSidebarCard selected={currentPath.startsWith('/master-data/skus')}>
              <ChildSidebarCardLabel>SKU一覧</ChildSidebarCardLabel>
            </BaseSidebarCard>
          </Link>
        </>
      ) : null}
      <Link to="/shipment-reasons">
        <BaseSidebarCard selected={currentPath.startsWith('/shipment-reasons')}>
          <SidebarCardIcon>
            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
              <path id="guide_24px_" data-name="guide(24px)" d="M0,0H24V24H0Z" fill="none" />
              <path
                id="pass"
                d="M14,10H2v2H14Zm0-4H2V8H14Zm4,8V10H16v4H12v2h4v4h2V16h4V14ZM2,16h8V14H2Z"
                fill="#717171"
              />
            </svg>
          </SidebarCardIcon>
          <SidebarCardLabel>出荷予定管理</SidebarCardLabel>
        </BaseSidebarCard>
      </Link>
      <Link to="/users">
        <BaseSidebarCard selected={currentPath.startsWith('/users')}>
          <SidebarCardIcon>
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" width="24px" fill="#717171" viewBox="0 0 24 24">
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z" />
            </svg>
          </SidebarCardIcon>
          <SidebarCardLabel>ユーザー管理</SidebarCardLabel>
        </BaseSidebarCard>
      </Link>
    </BaseSidebar>
  )
}
