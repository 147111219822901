import React from 'react'

import { BaseSquareLabel } from './style'

type SquareLabelColor = 'green' | 'red' | 'yellow' | 'black' | 'blue'

export interface SquareLabelProps {
  color: SquareLabelColor
  text: string
  width: number
}

export const SquareLabel: React.FC<SquareLabelProps> = ({ color, text, width }) => (
  <BaseSquareLabel color={color} width={width} data-testid="square-label">
    {text}
  </BaseSquareLabel>
)
