import React, { useEffect, useState } from 'react'

import { CancelIcon, CancelIconContainer, Container } from './style'

export interface ToastProps {
  message: string
  onCloseHandler: () => void
}

export const Toast: React.FC<ToastProps> = ({ message, onCloseHandler }) => {
  const [visible, setVisible] = useState(true)
  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(false)
      onCloseHandler()
    }, 2000)
    return () => {
      clearTimeout(timeout)
    }
  }, [onCloseHandler])

  return (
    <Container visible={visible.toString()}>
      <span>{message}</span>
      <CancelIconContainer>
        <CancelIcon
          onClick={() => {
            setVisible(false)
            onCloseHandler()
          }}
          data-testid="toast-cancel-icon"
        />
      </CancelIconContainer>
    </Container>
  )
}
