import styled from 'styled-components'

const BaseRadioButton = styled.div<{checked: boolean, disabled: boolean}>`
  align-items: center;
  background-color: ${props => props.disabled === true? '#fbfafa' : '#fff'};
  border-color: ${props => props.disabled === true? '#ccc' : props.checked === true? 'var(--di-primary-color)' : '#c5c5c5'};
  border-radius: 50%;
  border-style: solid;
  border-width: ${props => props.checked === true? '5px' : '1px'};
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 16px;
  justify-content: center;
  pointer-events: ${props => props.disabled === true? 'none' : 'auto'};
  transition: border-color 0.3s ease-out;
  width: 16px;

  &:hover {
    border-color: var(--di-primary-color);
  }
`

export {
  BaseRadioButton,
}
