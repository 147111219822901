import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import firebase from 'firebase/app'

import { Configuration, DefaultApi, User } from '../api-client'

export const useUsers = () => {
  const [users, setUsers] = useState<Array<User> | undefined>(undefined)

  useEffect(() => {
    firebase
      .auth()
      .currentUser?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        return api.getUsers()
      })
      .then((response) => setUsers(response.users))
      .catch((err) => {
        Sentry.captureException(err)
      })
  }, [])

  return {
    users,
  }
}
