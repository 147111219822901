import React from 'react'

import { BasePanel, Container } from './style'

export interface PanelProps {}

export const Panel: React.FC<PanelProps> = ({ ...props }) => (
  <Container>
    <BasePanel data-testid="panel" {...props} />
  </Container>
)
