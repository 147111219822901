import React, { useCallback, useEffect, useRef, useState } from 'react'

import { BaseInput, BaseLabel, BaseSubLabel, ErrorMessage } from './style'

/**
 * input 要素の種類. 命名はネイティブのものと合わせる.
 * スタイルの調整が必要なので用意ができている種類だけ有効値として型に定義する.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input}
 */
type TextBoxType = 'date' | 'email' | 'number' | 'password' | 'search' | 'text'

export interface TextBoxProps {
  /**
   * いわゆる ID だが、以下の複数の用途として使われる:
   * - HTML 要素の id として使用
   * - label と対応する id として使用. id がなければラベルも表示されない
   * - react-testing-library 用の id として使用
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Element/id}
   */
  id?: string

  /**
   * ラベル. label だけ値を入れても id に値がなければ表示されない.
   */
  label?: string

  /**
   * 注記ラベル（入力値の制限など）
   */
  subLabel?: string

  /**
   * input 要素の種類.
   */
  type: TextBoxType

  /**
   * 入力フォームの幅
   */
  width: number

  /**
   * 入力フォームの高さ
   */
  height: number

  /**
   * 入力フォームの内側の余白の幅
   */
  padding?: number

  /**
   * フォームの初期値
   */
  defaultValue?: string

  /**
   * プレースホルダ
   */
  placeholder?: string

  /**
   * オートコンプリート
   */
  autoComplete?: string

  /**
   * 無効かどうか
   */
  disabled?: boolean

  /**
   * 必須かどうか
   */
  required?: boolean

  /**
   * 許容される最小文字数
   * text か password のみ適用される
   */
  minLength?: number

  /**
   * 許容される最大文字数
   * text か password のみ適用される
   */
  maxLength?: number

  /**
   * 許容される最小値
   * number か date のみ適用される
   */
  min?: number | string

  /**
   * 許容される最大値
   * number のみ適用される
   */
  max?: number

  /**
   * 初期表示にバリデーションを発火させるかどうか
   * 通常は入力フォームからフォーカスを外した場合にバリデーションが発火する
   */
  forceValidate?: boolean

  /**
   * カスタムのエラーメッセージ
   * このコンポーネントで用意されていないエラーメッセージやバリデーションが必要な場合に使う
   */
  customErrorMessage?: string

  /**
   * エラーメッセージを非表示にするかどうか
   * このコンポーネントで用意されているスタイルとは別にエラーメッセージを表示したい時に使う
   */
  suppressErrorMessage?: boolean

  /**
   * 値が変更されたら背景色を変えるかどうか
   */
  highlightOnChange?: boolean

  /**
   * 初期表示でハイライトさせるかどうか
   */
  forceHighlight?: boolean

  /**
   * 値が変更された時に呼び出されるハンドラー
   */
  onChangeHandler: (value: string, errorMessage: string) => void

  /**
   * フォーカスが外れた時に呼び出されるハンドラー
   */
  onBlurHandler?: (errorMessage: string) => void
}

export const TextBox: React.FC<TextBoxProps> = ({
  id = '',
  label = '',
  subLabel = '',
  type,
  width,
  height,
  padding = 10,
  defaultValue = '',
  placeholder = '',
  disabled = false,
  required = false,
  minLength = 0,
  maxLength,
  min,
  max,
  forceValidate = false,
  customErrorMessage = '',
  suppressErrorMessage = false,
  highlightOnChange = false,
  forceHighlight = false,
  onChangeHandler,
  onBlurHandler = () => {},
  ...props
}) => {
  // 再レンダリングを行いたい場合に使う.
  // 全ての state を更新していると再レンダリングの負荷がかかりすぎるので、必要な時にのみ実行すること.
  const forceUpdate = useState(false)[1]

  const defaultValueRef = useRef(defaultValue)
  const valueRef = useRef(defaultValue)
  const errorMessageRef = useRef('')

  const emailValidation = useCallback((value: string) => {
    // Ref: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#basic_validation
    const emailValidationRegex = new RegExp(
      "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$",
      ''
    )
    return !emailValidationRegex.test(value)
  }, [])

  const validate = useCallback(
    (value: string) => {
      if (required && value === '') {
        errorMessageRef.current = '入力して下さい。'
      } else if (type === 'email' && emailValidation(value)) {
        errorMessageRef.current = '不正な形式のメールアドレスです。'
      } else if ((type === 'password' || type === 'text') && minLength && value.length < minLength) {
        errorMessageRef.current = `${minLength}文字以上で入力してください。`
      } else if ((type === 'password' || type === 'text') && maxLength && value.length > maxLength) {
        errorMessageRef.current = `${maxLength}文字以内で入力してください。`
      } else if (type === 'number' && min != null && Number.parseInt(value) < min) {
        errorMessageRef.current = `${min}以上で入力してください。`
      } else if (type === 'number' && max != null && Number.parseInt(value) > max) {
        errorMessageRef.current = `${max}以内で入力してください。`
      } else {
        errorMessageRef.current = ''
      }
    },
    [required, type, minLength, maxLength, min, max, emailValidation]
  )

  useEffect(() => {
    valueRef.current = defaultValue
    if (forceValidate) {
      validate(defaultValue)
    }
    forceUpdate((v) => !v)
  }, [defaultValue, forceValidate, validate, forceUpdate])

  return (
    <div>
      {id !== '' && (label !== '' || subLabel !== '') ? (
        <BaseLabel htmlFor={id}>
          {label}
          {subLabel !== '' ? <BaseSubLabel>{subLabel}</BaseSubLabel> : null}
        </BaseLabel>
      ) : null}
      <BaseInput
        id={id}
        type={type}
        width={width}
        height={height}
        padding={padding}
        required={required}
        min={type === 'date' ? min : undefined}
        value={valueRef.current}
        placeholder={placeholder}
        disabled={disabled}
        hasErr={customErrorMessage !== '' || errorMessageRef.current !== ''}
        isBackgroundYellow={
          forceHighlight === true || (highlightOnChange === true && valueRef.current !== defaultValueRef.current)
        }
        onChange={(event) => {
          if (disabled === true) {
            // jsdom may not have pointer event feature.
            // Ref: https://github.com/jsdom/jsdom/issues/2527
            return
          }
          valueRef.current = event.target.value
          if (forceValidate === true || errorMessageRef.current !== '') {
            validate(valueRef.current)
          }
          onChangeHandler(valueRef.current, errorMessageRef.current)
          forceUpdate((v) => !v)
        }}
        onBlur={(event) => {
          validate(event.target.value)
          onBlurHandler(errorMessageRef.current)
          forceUpdate((v) => !v)
        }}
        data-testid={id}
        {...props}
      />
      {suppressErrorMessage === false ? (
        customErrorMessage !== '' ? (
          <ErrorMessage>{customErrorMessage}</ErrorMessage>
        ) : (
          <ErrorMessage>{errorMessageRef.current}</ErrorMessage>
        )
      ) : null}
    </div>
  )
}
