import styled from 'styled-components'

const AdjustmentButtonContainer = styled.div`
  margin-left: auto;
  margin-right: 0;
  margin-top: 50px;
  width: 100px;
`

const AdjustmentRangeLabel = styled.div`
  color: #999;
  margin-bottom: 8px;
  margin-top: 20px;
`

const Container = styled.div`
  background-color: #fff;
  width: 220px;
`

const RadioButtonContainer = styled.div<{disabled: boolean}>`
  align-items: center;
  color: ${props => props.disabled === true? '#999' : 'inherit'};
  display: flex;
  margin-bottom: 10px;
  position: relative;
`

const RadioButtonLabel = styled.span`
  margin-left: 7px;
  margin-right: auto;
`

const TextBoxContainer = styled.div`
  margin-right: 6px;
`

const Title = styled.div`
  font-weight: bold;
  margin-bottom: 18px;
`

export {
  AdjustmentButtonContainer,
  AdjustmentRangeLabel,
  Container,
  RadioButtonContainer,
  RadioButtonLabel,
  TextBoxContainer,
  Title,
}
