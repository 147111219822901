import React from 'react'

import { Button } from '../../atoms'

import { Background, ButtonContainer, CancelIcon, CancelIconContainer, Description, Window } from './style'

export interface MaintenanceAnnouncementModalProps {
  /**
   * 閉じるボタンや背景がクリックされた時に呼び出されるハンドラー
   */
  onCloseHandler: () => void
}

export const MaintenanceAnnouncementModal: React.FC<MaintenanceAnnouncementModalProps> = ({ onCloseHandler }) => {
  return (
    <Background onClick={onCloseHandler}>
      <Window>
        <CancelIconContainer onClick={onCloseHandler} data-testid="close">
          <CancelIcon />
        </CancelIconContainer>
        <div onClick={(e) => e.stopPropagation()}>
          <Description>18時にシステムがメンテナンス状態に切り替わります。</Description>
          <ButtonContainer>
            <Button
              styleType="primary"
              label="OK"
              width={68}
              onClickHandler={onCloseHandler}
              data-testid="maintenance-announcement-modal-submit-button"
            />
          </ButtonContainer>
        </div>
      </Window>
    </Background>
  )
}
