/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for OMR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrderTiming,
    OrderTimingFromJSON,
    OrderTimingFromJSONTyped,
    OrderTimingToJSON,
} from './';

/**
 * 発注グループ
 * @export
 * @interface ContainerPlanningOrderGroup
 */
export interface ContainerPlanningOrderGroup {
    /**
     * 発注グループのID
     * @type {number}
     * @memberof ContainerPlanningOrderGroup
     */
    orderGroupId: number;
    /**
     * 発注グループ名
     * @type {string}
     * @memberof ContainerPlanningOrderGroup
     */
    orderGroupName: string;
    /**
     * 部門コードの一覧
     * @type {Array<string>}
     * @memberof ContainerPlanningOrderGroup
     */
    departmentCodes: Array<string>;
    /**
     * 最終計算日時
     * @type {Date}
     * @memberof ContainerPlanningOrderGroup
     */
    calculatedAt: Date;
    /**
     * 
     * @type {OrderTiming}
     * @memberof ContainerPlanningOrderGroup
     */
    orderTiming: OrderTiming;
    /**
     * 担当者のユーザーID
     * @type {number}
     * @memberof ContainerPlanningOrderGroup
     */
    operatorId: number;
    /**
     * 担当者名
     * @type {string}
     * @memberof ContainerPlanningOrderGroup
     */
    operatorName: string;
}

export function ContainerPlanningOrderGroupFromJSON(json: any): ContainerPlanningOrderGroup {
    return ContainerPlanningOrderGroupFromJSONTyped(json, false);
}

export function ContainerPlanningOrderGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContainerPlanningOrderGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderGroupId': json['orderGroupId'],
        'orderGroupName': json['orderGroupName'],
        'departmentCodes': json['departmentCodes'],
        'calculatedAt': (new Date(json['calculatedAt'])),
        'orderTiming': OrderTimingFromJSON(json['orderTiming']),
        'operatorId': json['operatorId'],
        'operatorName': json['operatorName'],
    };
}

export function ContainerPlanningOrderGroupToJSON(value?: ContainerPlanningOrderGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderGroupId': value.orderGroupId,
        'orderGroupName': value.orderGroupName,
        'departmentCodes': value.departmentCodes,
        'calculatedAt': (value.calculatedAt.toISOString()),
        'orderTiming': OrderTimingToJSON(value.orderTiming),
        'operatorId': value.operatorId,
        'operatorName': value.operatorName,
    };
}


