import styled from 'styled-components'

const BaseOrderGroupsWithDraftPOListTable = styled.table`
  border: solid 1px var(--di-table-border-color);
  width: 100%;
`

const OrderGroupsWithDraftPOListTableBodyRow = styled.tr`
  background-color: #fff;
  border-top: solid 1px var(--di-table-border-color);
  cursor: pointer;

  &:hover {
    background-color: var(--di-table-body-bg-hover-color);
  }
`

const OrderGroupsWithDraftPOListTableData = styled.td`
  height: 40px;
  padding-left: 25px;
`

const OrderGroupsWithDraftPOListTableHeader = styled.th<{width?: number}>`
  background-color: #f1f1f1;
  border-bottom: solid 3px var(--di-table-border-color);
  height: 40px;
  padding-left: 25px;
  text-align: left;
  width: ${props => props.width != null? `${props.width}px` : 'auto'};
`

const OrderGroupName = styled.span`
  overflow-wrap: anywhere;
  width: 160px;
`

export {
  BaseOrderGroupsWithDraftPOListTable,
  OrderGroupsWithDraftPOListTableBodyRow,
  OrderGroupsWithDraftPOListTableData,
  OrderGroupsWithDraftPOListTableHeader,
  OrderGroupName,
}
