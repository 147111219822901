import React from 'react'
import { Link } from 'react-router-dom'

import { InventoryUnitSKU, ErrorLevel, Week, AdjustmentTarget } from '../../../api-client'

import { RoundLabel, RoundLabelProps } from '../../atoms'

import { InventoryCalendarTable } from '../../molecules'

import { DistributionCenterSKUPanelContainer, Label, Property, Value } from './style'

export interface DistributionCenterSKUPanelProps {
  /**
   * SKUxInventoryUnit
   */
  inventoryUnitSKU: InventoryUnitSKU

  /**
   * 元々の SKUxInventoryUnit. 変更があるかどうかを検知するために使う
   */
  inventoryUnitSKUOrigin: InventoryUnitSKU

  /**
   * 週
   */
  weeks: Week[]

  /**
   * ハイライト対象の週
   */
  highlightedWeekDate: string

  /**
   * 在庫計画のテーブルのスクロール位置
   */
  inventoryCalendarTableScrollLeft: number

  /**
   * 読み取り専用かどうか
   */
  readonly: boolean

  /**
   * ハイライト対象の週が変更された時に呼び出されるハンドラー
   */
  onHighlightedWeekDateChangeHandler: (week: string) => void

  /**
   * 在庫計画のテーブルがスクロールされた時に呼び出されるハンドラー
   */
  onInventoryCalendarTableScrollLeftHandler: (scrollLeft: number) => void

  /**
   * シミュレーション値が変更された時に呼び出されるハンドラー
   */
  onReceivingQuantityForSimulationChangeHandler: (weekDate: string, quantity: number | null) => void
}

export const DistributionCenterSKUPanel: React.FC<DistributionCenterSKUPanelProps> = ({
  inventoryUnitSKU,
  inventoryUnitSKUOrigin,
  weeks,
  highlightedWeekDate,
  inventoryCalendarTableScrollLeft,
  readonly,
  onHighlightedWeekDateChangeHandler,
  onInventoryCalendarTableScrollLeftHandler,
  onReceivingQuantityForSimulationChangeHandler,
}) => {
  const errorLevelToRoundLabelProps = (errorLevel: ErrorLevel): RoundLabelProps => {
    switch (errorLevel) {
      case ErrorLevel.High:
        return {
          color: 'red',
          text: '50%以上',
          width: 80,
        }
      case ErrorLevel.Middle:
        return {
          color: 'yellow',
          text: '20%以上',
          width: 80,
        }
      case ErrorLevel.Low:
        return {
          color: 'green',
          text: '20%未満',
          width: 80,
        }
      default: // このケースは通らない想定
        return {
          color: 'green',
          text: '',
          width: 0,
        }
    }
  }

  return (
    <DistributionCenterSKUPanelContainer data-testid="inventory-plan-panel">
      <Property>
        <Label>直近4週誤差：</Label>
        <Value>
          {inventoryUnitSKU.errorLevelInLast4Weeks === ErrorLevel.Unavailable ? (
            <span>-</span>
          ) : (
            <RoundLabel {...errorLevelToRoundLabelProps(inventoryUnitSKU.errorLevelInLast4Weeks)} />
          )}
        </Value>
        <Label>直近13週誤差：</Label>
        <Value>
          {inventoryUnitSKU.errorLevelInLast13Weeks === ErrorLevel.Unavailable ? (
            <span>-</span>
          ) : (
            <RoundLabel {...errorLevelToRoundLabelProps(inventoryUnitSKU.errorLevelInLast13Weeks)} />
          )}
        </Value>
        <Label>補正：</Label>
        <Value>
          <b>{inventoryUnitSKU.isAdjusted === true ? 'あり' : 'なし'}</b>
        </Value>
        <Label>補正対象の需要予測：</Label>
        <Value>
          {inventoryUnitSKU.adjustmentTarget === AdjustmentTarget.DemandInsight ? (
            <span>DI需要予測</span>
          ) : (
            <span>売上構成比による需要予測</span>
          )}
        </Value>
        <Link
          to="#"
          onClick={(event) => {
            event.preventDefault()
            window.open(
              `/inventory-units/${inventoryUnitSKU.inventoryUnitCode}/skus/${inventoryUnitSKU.janCode}/inventory-unit-shipment-plan?selectedDemandForecast=${inventoryUnitSKU.adjustmentTarget}`
            )
          }}
        >
          出荷予定数の補正
        </Link>
      </Property>
      <InventoryCalendarTable
        inventoryCalendar={inventoryUnitSKU.inventoryCalendar}
        inventoryCalendarOrigin={inventoryUnitSKUOrigin.inventoryCalendar}
        yardQuantity={inventoryUnitSKU.yardQuantity}
        weeks={weeks}
        highlightedWeekDate={highlightedWeekDate}
        scrollLeft={inventoryCalendarTableScrollLeft}
        simulationAvailable={true}
        readonly={readonly}
        onClickColumnHandler={onHighlightedWeekDateChangeHandler}
        onScrollHandler={onInventoryCalendarTableScrollLeftHandler}
        onReceivingQuantityForSimulationChangeHandler={onReceivingQuantityForSimulationChangeHandler}
      />
    </DistributionCenterSKUPanelContainer>
  )
}
