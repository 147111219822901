import React, { useCallback, useEffect, useState } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import firebase from 'firebase/app'

import { AfterLogin } from './templates'

import { Login, NonBusinessHours, PasswordReset, PasswordResetMail } from './pages'

interface PrivateRouteProps {
  /**
   * 営業時間外にメンテナンス画面を出す機能を有効にするかどうか
   */
  nonBusinessHoursPageEnabled: boolean

  /**
   * URLのパス
   */
  path: string
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ nonBusinessHoursPageEnabled, children, ...rest }) => {
  const isBusinessHours = useCallback(() => {
    const today = new Date()

    // Ref: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getDay
    const SUNDAY = 0
    const SATURDAY = 6
    const weekday = today.getDay()
    if (weekday === SUNDAY || weekday === SATURDAY) {
      return false
    }

    const hours = today.getHours()
    if (hours < 12 || hours > 17) {
      return false
    }

    return true
  }, [])

  const [nonBusinessHoursPageVisible, setNonBusinessHoursPageVisible] = useState(
    nonBusinessHoursPageEnabled === true && isBusinessHours() === false
  )

  useEffect(() => {
    setInterval(() => {
      setNonBusinessHoursPageVisible(nonBusinessHoursPageEnabled === true && isBusinessHours() === false)
    }, 1000)
  }, [nonBusinessHoursPageEnabled, isBusinessHours])

  return (
    <Route
      {...rest}
      render={({ location }) =>
        firebase.auth().currentUser == null ? (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        ) : nonBusinessHoursPageVisible === true ? (
          <NonBusinessHours />
        ) : (
          children
        )
      }
    />
  )
}

export interface AppProps {
  /**
   * 営業時間外にメンテナンス画面を出す機能を有効にするかどうか
   */
  nonBusinessHoursPageEnabled: boolean
}

export const App: React.FC<AppProps> = ({ nonBusinessHoursPageEnabled }) => {
  return (
    <Router>
      <Switch>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/password-reset">
          <PasswordResetMail />
        </Route>
        <Route exact path="/email-auth">
          <PasswordReset />
        </Route>
        <PrivateRoute nonBusinessHoursPageEnabled={nonBusinessHoursPageEnabled} path="/">
          <AfterLogin nonBusinessHoursPageEnabled={nonBusinessHoursPageEnabled} />
        </PrivateRoute>
      </Switch>
    </Router>
  )
}

export default App
