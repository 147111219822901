import { useEffect, useState } from 'react'
import { useUrlParams } from './useUrlParams'

const urlParamsConst = {
  selectedContainerIndex: 'selectedContainerIndex',
  selectedContainerId: 'selectedContainerId',
  searchText: 'searchText',
  inventoryCalendarVisible: 'inventoryCalendarVisible',
} as const

enum InventoryCalendarVisibleEnum {
  TRUE = 'true',
  FALSE = 'false',
}

export const useDraftPurchaseOrderParamControl = () => {
  const urlParams = useUrlParams()
  const { getSelectedContainerIndex, getSearchText, getInventoryCalendarVisible, getSelectedContainerId } =
    useGetDraftPurchaseOrderParams()

  const [selectedContainerIndex, setSelectedContainerIndex] = useState(0)
  const [selectedContainerId, setSelectedContainerId] = useState(0)
  const [searchText, setSearchText] = useState('')
  const [inventoryCalendarVisible, setInventoryCalendarVisible] = useState(true)

  useEffect(() => {
    updateContainerInfo(getSelectedContainerIndex(), getSelectedContainerId())
    updateInventoryCalendarVisible(getInventoryCalendarVisible() === InventoryCalendarVisibleEnum.TRUE)
    updateSearchText(getSearchText())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateContainerInfo = (updateContainerIndex: number, updateContainerId: number) => {
    setSelectedContainerIndex(updateContainerIndex)
    setSelectedContainerId(updateContainerId)
    urlParams.set(urlParamsConst.selectedContainerIndex, updateContainerIndex.toString())
    urlParams.set(urlParamsConst.selectedContainerId, updateContainerId.toString())
  }

  const updateInventoryCalendarVisible = (inventoryCalendarVisible: boolean) => {
    setInventoryCalendarVisible(inventoryCalendarVisible)
    urlParams.set(
      urlParamsConst.inventoryCalendarVisible,
      inventoryCalendarVisible ? InventoryCalendarVisibleEnum.TRUE : InventoryCalendarVisibleEnum.FALSE
    )
  }

  const updateSearchText = (updateText: string) => {
    setSearchText(updateText)
    urlParams.set(urlParamsConst.searchText, updateText)
  }

  return {
    selectedContainerIndex,
    selectedContainerId,
    searchText,
    inventoryCalendarVisible,
    updateContainerInfo,
    updateSearchText,
    updateInventoryCalendarVisible,
  }
}

export const useGetDraftPurchaseOrderParams = () => {
  const urlParams = useUrlParams()

  const getSelectedContainerIndex = () => {
    const selectedContainerIndex = Number(urlParams.get(urlParamsConst.selectedContainerIndex) ?? '0')
    return !isNaN(selectedContainerIndex) ? selectedContainerIndex : 0
  }

  const getSelectedContainerId = () => {
    const containerId = Number(urlParams.get(urlParamsConst.selectedContainerId) ?? '0')
    return !isNaN(containerId) ? containerId : 0
  }

  const getSearchText = () => {
    return urlParams.get(urlParamsConst.searchText) ?? ''
  }
  const getInventoryCalendarVisible = () => {
    return urlParams.get(urlParamsConst.inventoryCalendarVisible) ?? InventoryCalendarVisibleEnum.TRUE
  }

  return { getSelectedContainerIndex, getSearchText, getInventoryCalendarVisible, getSelectedContainerId }
}
