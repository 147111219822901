import React from 'react'

import { Logo } from '../../components/atoms'

import { Panel as PanelTemplate } from '../../templates'

import { DescriptionContainer, LogoContainer, Title } from './style'

export interface NonBusinessHoursProps {}

export const NonBusinessHours: React.FC<NonBusinessHoursProps> = () => {
  return (
    <PanelTemplate>
      <LogoContainer>
        <Logo logoColor="black" />
        <Title>ただいまメンテナンス中です。</Title>
        <DescriptionContainer>
          <p>大変ご迷惑おかけし申し訳ございませんが、</p>
          <p>メンテナンス終了までしばらくお待ち下さい。</p>
        </DescriptionContainer>
      </LogoContainer>
    </PanelTemplate>
  )
}
