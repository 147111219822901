/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for OMR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DatabaseSKULogisticPattern,
    DatabaseSKULogisticPatternFromJSON,
    DatabaseSKULogisticPatternFromJSONTyped,
    DatabaseSKULogisticPatternToJSON,
    TypesOfMerchandise,
    TypesOfMerchandiseFromJSON,
    TypesOfMerchandiseFromJSONTyped,
    TypesOfMerchandiseToJSON,
} from './';

/**
 * SKU
 * @export
 * @interface DatabaseSKU
 */
export interface DatabaseSKU {
    /**
     * JANコード
     * @type {string}
     * @memberof DatabaseSKU
     */
    code: string;
    /**
     * 商品名
     * @type {string}
     * @memberof DatabaseSKU
     */
    name: string;
    /**
     * 規格名
     * @type {string}
     * @memberof DatabaseSKU
     */
    specName: string;
    /**
     * MOQ管理グループ名
     * @type {string}
     * @memberof DatabaseSKU
     */
    moqGroupName: string;
    /**
     * 発注グループ名
     * @type {string}
     * @memberof DatabaseSKU
     */
    orderGroupName: string;
    /**
     * 部門コード
     * @type {string}
     * @memberof DatabaseSKU
     */
    departmentCode: string | null;
    /**
     * 
     * @type {TypesOfMerchandise}
     * @memberof DatabaseSKU
     */
    typeOfMerchandise: TypesOfMerchandise | null;
    /**
     * リードタイム
     * @type {number}
     * @memberof DatabaseSKU
     */
    leadtime: number;
    /**
     * 1カートンあたりの積載量(Kg)
     * @type {number}
     * @memberof DatabaseSKU
     */
    loadCapacityPerCarton: number;
    /**
     * 1カートンあたりの容積(M3)
     * @type {number}
     * @memberof DatabaseSKU
     */
    capacityPerCarton: number;
    /**
     * 入庫停止日
     * @type {Date}
     * @memberof DatabaseSKU
     */
    inventoryUnitReceivingsStopDate: Date | null;
    /**
     * 積地港
     * @type {string}
     * @memberof DatabaseSKU
     */
    portOfLoading: string;
    /**
     * 
     * @type {DatabaseSKULogisticPattern}
     * @memberof DatabaseSKU
     */
    logisticPattern: DatabaseSKULogisticPattern;
    /**
     * ケース入数
     * @type {number}
     * @memberof DatabaseSKU
     */
    quantityPerCase?: number;
    /**
     * 新規商品／既存商品区分
     * @type {string}
     * @memberof DatabaseSKU
     */
    typeOfItem?: string;
    /**
     * 廃番
     * @type {string}
     * @memberof DatabaseSKU
     */
    discontinued?: string;
    /**
     * MOQ
     * @type {string}
     * @memberof DatabaseSKU
     */
    moq?: string;
    /**
     * 取引先コード
     * @type {string}
     * @memberof DatabaseSKU
     */
    supplierCode?: string;
    /**
     * FCL/LCL
     * @type {string}
     * @memberof DatabaseSKU
     */
    fclLcl?: string;
    /**
     * 担当者
     * @type {string}
     * @memberof DatabaseSKU
     */
    inCharge?: string;
    /**
     * 安全在庫週数
     * @type {number}
     * @memberof DatabaseSKU
     */
    safetyInventoryWeeks?: number;
}

export function DatabaseSKUFromJSON(json: any): DatabaseSKU {
    return DatabaseSKUFromJSONTyped(json, false);
}

export function DatabaseSKUFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatabaseSKU {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
        'name': json['name'],
        'specName': json['specName'],
        'moqGroupName': json['moqGroupName'],
        'orderGroupName': json['orderGroupName'],
        'departmentCode': json['departmentCode'],
        'typeOfMerchandise': TypesOfMerchandiseFromJSON(json['typeOfMerchandise']),
        'leadtime': json['leadtime'],
        'loadCapacityPerCarton': json['loadCapacityPerCarton'],
        'capacityPerCarton': json['capacityPerCarton'],
        'inventoryUnitReceivingsStopDate': (json['inventoryUnitReceivingsStopDate'] === null ? null : new Date(json['inventoryUnitReceivingsStopDate'])),
        'portOfLoading': json['portOfLoading'],
        'logisticPattern': DatabaseSKULogisticPatternFromJSON(json['logisticPattern']),
        'quantityPerCase': !exists(json, 'quantityPerCase') ? undefined : json['quantityPerCase'],
        'typeOfItem': !exists(json, 'typeOfItem') ? undefined : json['typeOfItem'],
        'discontinued': !exists(json, 'discontinued') ? undefined : json['discontinued'],
        'moq': !exists(json, 'moq') ? undefined : json['moq'],
        'supplierCode': !exists(json, 'supplierCode') ? undefined : json['supplierCode'],
        'fclLcl': !exists(json, 'fclLcl') ? undefined : json['fclLcl'],
        'inCharge': !exists(json, 'inCharge') ? undefined : json['inCharge'],
        'safetyInventoryWeeks': !exists(json, 'safetyInventoryWeeks') ? undefined : json['safetyInventoryWeeks'],
    };
}

export function DatabaseSKUToJSON(value?: DatabaseSKU | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'name': value.name,
        'specName': value.specName,
        'moqGroupName': value.moqGroupName,
        'orderGroupName': value.orderGroupName,
        'departmentCode': value.departmentCode,
        'typeOfMerchandise': TypesOfMerchandiseToJSON(value.typeOfMerchandise),
        'leadtime': value.leadtime,
        'loadCapacityPerCarton': value.loadCapacityPerCarton,
        'capacityPerCarton': value.capacityPerCarton,
        'inventoryUnitReceivingsStopDate': (value.inventoryUnitReceivingsStopDate === null ? null : value.inventoryUnitReceivingsStopDate.toISOString().substr(0,10)),
        'portOfLoading': value.portOfLoading,
        'logisticPattern': DatabaseSKULogisticPatternToJSON(value.logisticPattern),
        'quantityPerCase': value.quantityPerCase,
        'typeOfItem': value.typeOfItem,
        'discontinued': value.discontinued,
        'moq': value.moq,
        'supplierCode': value.supplierCode,
        'fclLcl': value.fclLcl,
        'inCharge': value.inCharge,
        'safetyInventoryWeeks': value.safetyInventoryWeeks,
    };
}


