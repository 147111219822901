import React from 'react'

import { Button } from '../../atoms'

import {
  Background,
  ButtonsContainer,
  CancelIcon,
  CancelIconContainer,
  Container,
  Description,
  SubmitButtonContainer,
  Window,
} from './style'

export interface DeleteUserErrorModalProps {
  /**
   * 閉じるボタンや背景がクリックされた時に呼び出されるハンドラー
   */
  onCloseHandler: () => void
}

export const DeleteUserErrorModal: React.FC<DeleteUserErrorModalProps> = ({ onCloseHandler }) => (
  <Background onClick={onCloseHandler}>
    <Window>
      <CancelIconContainer onClick={onCloseHandler} data-testid="close">
        <CancelIcon />
      </CancelIconContainer>
      <Container onClick={(e) => e.stopPropagation()}>
        <Description>
          このユーザーに紐づく発注グループがある為
          <br />
          削除出来ません。
        </Description>
        <ButtonsContainer>
          <SubmitButtonContainer>
            <Button
              styleType="primary"
              label="OK"
              width={76}
              onClickHandler={onCloseHandler}
              data-testid="delete-user-error-modal-close-button"
            />
          </SubmitButtonContainer>
        </ButtonsContainer>
      </Container>
    </Window>
  </Background>
)
