import React from 'react'
import { Button, CheckBox, SimulationButton, TextBox } from '../../atoms'

import { ErrorLevelDropdown } from '../../molecules'

import {
  Container,
  DownloadButtonContainer,
  DropdownContainer,
  FilterContainer,
  NumberOfHitsLabel,
  SearchFormContainer,
} from './style'

export interface SearchInventoryUnitSKUFormProps {
  /**
   * JANコード・商品名の検索フォームの初期値
   */
  searchText?: string

  /**
   * JANコード・商品名の検索フォームを無効化するかどうか
   */
  searchTextBoxDisabled?: boolean

  /**
   * JANコード・商品名の検索フォームを表示するかどうか
   */
  searchTextBoxVisible?: boolean

  /**
   * 直近4週誤差率20％未満のチェックボックスの初期値
   */
  defaultValueForErrorLebelLowInLast4Weeks: boolean

  /**
   * 直近4週誤差率20％以上のチェックボックスの初期値
   */
  defaultValueForErrorLebelMiddleInLast4Weeks: boolean

  /**
   * 直近4週誤差率50％以上のチェックボックスの初期値
   */
  defaultValueForErrorLebelHighInLast4Weeks: boolean

  /**
   * 直近13週誤差率20％未満のチェックボックスの初期値
   */
  defaultValueForrrorLebelLowInLast13Weeks: boolean

  /**
   * 直近13週誤差率20％以上のチェックボックスの初期値
   */
  defaultValueForErrorLebelMiddleInLast13Weeks: boolean

  /**
   * 直近13週誤差率50％以上のチェックボックスの初期値
   */
  defaultValueForErrorLebelHighInLast13Weeks: boolean

  /**
   * ドロップダウンを無効化するかどうか
   */
  dropdownDisabled?: boolean

  /**
   * 補正なしのチェックボックスの初期値
   */
  filterByAdjustmentTarget: boolean

  /**
   * 入出荷シミュレーションありのチェックボックスの初期値
   */
  filterBySimulatedValue: boolean

  /**
   * 補正登録対象などのチェックボックスを無効化するかどうか
   */
  checkBoxDisabled?: boolean

  /**
   * 検索対象にヒットした件数
   */
  numberOfHits: number

  /**
   * シミュレーションボタンを無効化するかどうか
   */
  simulationButtonDisabled: boolean

  /**
   * シミュレーション実行ボタンを表示するかどうか
   */
  simulationSubmitButtonVisible: boolean

  /**
   * シミュレーションリセットボタンを表示するかどうか
   */
  simulationResetButtonVisible: boolean

  /**
   * JANコード・商品名の検索文字列が変更された時に呼び出されるハンドラー
   */
  onSearchTextChangeHandler: (value: string) => void

  /**
   * 直近4週誤差率20％未満のチェックボックスの値が変わった時に呼び出されるハンドラー
   */
  onErrorLebelLowInLast4WeeksChangeHandler: (value: boolean) => void

  /**
   * 直近4週誤差率20％以上のチェックボックスの値が変わった時に呼び出されるハンドラー
   */
  onErrorLebelMiddleInLast4WeeksChangeHandler: (value: boolean) => void

  /**
   * 直近4週誤差率50％以上のチェックボックスの値が変わった時に呼び出されるハンドラー
   */
  onErrorLebelHighInLast4WeeksChangeHandler: (value: boolean) => void

  /**
   * 直近13週誤差率20％未満のチェックボックスの値が変わった時に呼び出されるハンドラー
   */
  onErrorLebelLowInLast13WeeksChangeHandler: (value: boolean) => void

  /**
   * 直近13週誤差率20％以上のチェックボックスの値が変わった時に呼び出されるハンドラー
   */
  onErrorLebelMiddleInLast13WeeksChangeHandler: (value: boolean) => void

  /**
   * 直近13週誤差率50％以上のチェックボックスの値が変わった時に呼び出されるハンドラー
   */
  onErrorLebelHighInLast13WeeksChangeHandler: (value: boolean) => void

  /**
   * 補正なしのチェックボックスの値が変わった時に呼び出されるハンドラー
   */
  onFilterByAdjustmentTargetChangeHandler: (value: boolean) => void

  /**
   * 入出荷シミュレーションありのチェックボックスの値が変わった時に呼び出されるハンドラー
   */
  onFilterBySimulatedValueChangeHandler: (value: boolean) => void

  /**
   * シミュレーション実行ボタンがクリックされた時に呼び出されるハンドラー
   */
  onSimulationSubmitHandler: () => void

  /**
   * シミュレーションリセットボタンがクリックされた時に呼び出されるハンドラー
   */
  onSimulationResetHandler: () => void

  /**
   * ダウンロードボタンがクリックされた時に呼び出されるハンドラー
   */
  onDownloadButtonClickHandler: () => void

}

export const SearchInventoryUnitSKUForm: React.FC<SearchInventoryUnitSKUFormProps> = ({
  searchText = '',
  searchTextBoxDisabled = false,
  searchTextBoxVisible = true,
  defaultValueForErrorLebelLowInLast4Weeks,
  defaultValueForErrorLebelMiddleInLast4Weeks,
  defaultValueForErrorLebelHighInLast4Weeks,
  defaultValueForrrorLebelLowInLast13Weeks,
  defaultValueForErrorLebelMiddleInLast13Weeks,
  defaultValueForErrorLebelHighInLast13Weeks,
  dropdownDisabled = false,
  filterByAdjustmentTarget,
  filterBySimulatedValue,
  checkBoxDisabled = false,
  numberOfHits,
  simulationButtonDisabled,
  simulationSubmitButtonVisible,
  simulationResetButtonVisible,
  onSearchTextChangeHandler,
  onErrorLebelLowInLast4WeeksChangeHandler,
  onErrorLebelMiddleInLast4WeeksChangeHandler,
  onErrorLebelHighInLast4WeeksChangeHandler,
  onErrorLebelLowInLast13WeeksChangeHandler,
  onErrorLebelMiddleInLast13WeeksChangeHandler,
  onErrorLebelHighInLast13WeeksChangeHandler,
  onFilterByAdjustmentTargetChangeHandler,
  onFilterBySimulatedValueChangeHandler,
  onSimulationSubmitHandler,
  onSimulationResetHandler,
  onDownloadButtonClickHandler,
}) => (
  <Container>
    {searchTextBoxVisible === true ? (
      <SearchFormContainer>
        <TextBox
          id="search-inventoryUnit-sku-form-search-text-form"
          type="search"
          height={40}
          width={350}
          placeholder="JANコード・商品名の検索"
          defaultValue={searchText}
          disabled={searchTextBoxDisabled}
          onChangeHandler={onSearchTextChangeHandler}
        />
      </SearchFormContainer>
    ) : null}
    <DropdownContainer>
      <ErrorLevelDropdown
        defaultValueForErrorLebelLowInLast4Weeks={defaultValueForErrorLebelLowInLast4Weeks}
        defaultValueForErrorLebelMiddleInLast4Weeks={defaultValueForErrorLebelMiddleInLast4Weeks}
        defaultValueForErrorLebelHighInLast4Weeks={defaultValueForErrorLebelHighInLast4Weeks}
        defaultValueForrrorLebelLowInLast13Weeks={defaultValueForrrorLebelLowInLast13Weeks}
        defaultValueForErrorLebelMiddleInLast13Weeks={defaultValueForErrorLebelMiddleInLast13Weeks}
        defaultValueForErrorLebelHighInLast13Weeks={defaultValueForErrorLebelHighInLast13Weeks}
        disabled={dropdownDisabled}
        onErrorLebelLowInLast4WeeksChangeHandler={onErrorLebelLowInLast4WeeksChangeHandler}
        onErrorLebelMiddleInLast4WeeksChangeHandler={onErrorLebelMiddleInLast4WeeksChangeHandler}
        onErrorLebelHighInLast4WeeksChangeHandler={onErrorLebelHighInLast4WeeksChangeHandler}
        onErrorLebelLowInLast13WeeksChangeHandler={onErrorLebelLowInLast13WeeksChangeHandler}
        onErrorLebelMiddleInLast13WeeksChangeHandler={onErrorLebelMiddleInLast13WeeksChangeHandler}
        onErrorLebelHighInLast13WeeksChangeHandler={onErrorLebelHighInLast13WeeksChangeHandler}
      />
    </DropdownContainer>
    <FilterContainer>
      <CheckBox
        id="search-inventoryUnit-sku-form-filter-by-adjustment-target"
        label="補正なし"
        defaultValue={filterByAdjustmentTarget}
        disabled={checkBoxDisabled}
        onChangeHandler={onFilterByAdjustmentTargetChangeHandler}
      />
    </FilterContainer>
    <FilterContainer>
      <CheckBox
        id="search-inventoryUnit-sku-form-filter-by-simulated-value"
        label="入出荷シミュレーションあり"
        defaultValue={filterBySimulatedValue}
        disabled={checkBoxDisabled}
        onChangeHandler={onFilterBySimulatedValueChangeHandler}
      />
    </FilterContainer>
    <NumberOfHitsLabel>{numberOfHits}件</NumberOfHitsLabel>
    <SimulationButton
      disabled={simulationButtonDisabled}
      submitButtonVisible={simulationSubmitButtonVisible}
      resetButtonVisible={simulationResetButtonVisible}
      onSubmitHandler={onSimulationSubmitHandler}
      onResetHandler={onSimulationResetHandler}
    />
    <DownloadButtonContainer>
      <Button
        styleType="tertiary"
        label="ダウンロード"
        width={134}
        onClickHandler={onDownloadButtonClickHandler}
        data-testid="search-inventoryUnit-sku-form-download-csv-file-button"
      />
    </DownloadButtonContainer>
  </Container>
)
