/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for OMR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InventoryUnitReceiving,
    InventoryUnitReceivingFromJSON,
    InventoryUnitReceivingFromJSONTyped,
    InventoryUnitReceivingToJSON,
} from './';

/**
 * 入荷残数
 * @export
 * @interface UnreceivedOrder
 */
export interface UnreceivedOrder {
    /**
     * 
     * @type {InventoryUnitReceiving}
     * @memberof UnreceivedOrder
     */
    inventoryUnitReceivings: InventoryUnitReceiving;
    /**
     * 日付
     * @type {Date}
     * @memberof UnreceivedOrder
     */
    date: Date;
}

export function UnreceivedOrderFromJSON(json: any): UnreceivedOrder {
    return UnreceivedOrderFromJSONTyped(json, false);
}

export function UnreceivedOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnreceivedOrder {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inventoryUnitReceivings': InventoryUnitReceivingFromJSON(json['inventoryUnitReceivings']),
        'date': (new Date(json['date'])),
    };
}

export function UnreceivedOrderToJSON(value?: UnreceivedOrder | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inventoryUnitReceivings': InventoryUnitReceivingToJSON(value.inventoryUnitReceivings),
        'date': (value.date.toISOString().substr(0,10)),
    };
}


