/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for OMR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ContainerType,
    ContainerTypeFromJSON,
    ContainerTypeFromJSONTyped,
    ContainerTypeToJSON,
    PurchaseOrderDataType,
    PurchaseOrderDataTypeFromJSON,
    PurchaseOrderDataTypeFromJSONTyped,
    PurchaseOrderDataTypeToJSON,
    PurchaseOrderType,
    PurchaseOrderTypeFromJSON,
    PurchaseOrderTypeFromJSONTyped,
    PurchaseOrderTypeToJSON,
} from './';

/**
 * コンテナの輸送計画
 * @export
 * @interface Container
 */
export interface Container {
    /**
     * コンテナの輸送計画のID
     * @type {number}
     * @memberof Container
     */
    id: number;
    /**
     * 
     * @type {PurchaseOrderType}
     * @memberof Container
     */
    purchaseOrderType: PurchaseOrderType;
    /**
     * 在庫管理単位の名前
     * @type {Array<string>}
     * @memberof Container
     */
    inventoryUnitNames: Array<string>;
    /**
     * 
     * @type {ContainerType}
     * @memberof Container
     */
    containerType: ContainerType;
    /**
     * 入荷日
     * @type {Date}
     * @memberof Container
     */
    preferredArrivalDate: Date;
    /**
     * 容量(M3)
     * @type {number}
     * @memberof Container
     */
    totalM3: number;
    /**
     * 重量(Kg)
     * @type {number}
     * @memberof Container
     */
    totalKg: number;
    /**
     * 容積(M3)の使用率
     * @type {number}
     * @memberof Container
     */
    volumeUsage: number;
    /**
     * SKU数
     * @type {number}
     * @memberof Container
     */
    numberOfSKUs: number;
    /**
     * 重量(Kg)の使用率
     * @type {number}
     * @memberof Container
     */
    weightUsage: number;
    /**
     * 積地港
     * @type {string}
     * @memberof Container
     */
    portOfLoading: string;
    /**
     * 揚地港
     * @type {string}
     * @memberof Container
     */
    portOfDischarge: string;
    /**
     * 過去分容積(M3)
     * @type {number}
     * @memberof Container
     */
    pastTotalM3: number;
    /**
     * 過去分重量(Kg)
     * @type {number}
     * @memberof Container
     */
    pastTotalKg: number;
    /**
     * 過去分容積(M3)の使用率
     * @type {number}
     * @memberof Container
     */
    pastVolumeUsage: number;
    /**
     * 過去分重量(Kg)の使用率
     * @type {number}
     * @memberof Container
     */
    pastWeightUsage: number;
    /**
     * 
     * @type {PurchaseOrderDataType}
     * @memberof Container
     */
    dataType: PurchaseOrderDataType;
    /**
     * 詳細データフラグ
     * @type {boolean}
     * @memberof Container
     */
    isDetail: boolean;
    /**
     * シリアルナンバー
     * @type {number}
     * @memberof Container
     */
    serialNumber?: number;
    /**
     * 自分の親に当たるシリアルナンバー
     * @type {number}
     * @memberof Container
     */
    parentSerialNumber?: number;
    /**
     * 過去発注のPONo
     * @type {string}
     * @memberof Container
     */
    purchaseOrderNumber: string;
}

export function ContainerFromJSON(json: any): Container {
    return ContainerFromJSONTyped(json, false);
}

export function ContainerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Container {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'purchaseOrderType': PurchaseOrderTypeFromJSON(json['purchaseOrderType']),
        'inventoryUnitNames': json['inventoryUnitNames'],
        'containerType': ContainerTypeFromJSON(json['containerType']),
        'preferredArrivalDate': (new Date(json['preferredArrivalDate'])),
        'totalM3': json['totalM3'],
        'totalKg': json['totalKg'],
        'volumeUsage': json['volumeUsage'],
        'numberOfSKUs': json['numberOfSKUs'],
        'weightUsage': json['weightUsage'],
        'portOfLoading': json['portOfLoading'],
        'portOfDischarge': json['portOfDischarge'],
        'pastTotalM3': json['pastTotalM3'],
        'pastTotalKg': json['pastTotalKg'],
        'pastVolumeUsage': json['pastVolumeUsage'],
        'pastWeightUsage': json['pastWeightUsage'],
        'dataType': PurchaseOrderDataTypeFromJSON(json['dataType']),
        'isDetail': json['isDetail'],
        'serialNumber': !exists(json, 'serialNumber') ? undefined : json['serialNumber'],
        'parentSerialNumber': !exists(json, 'parentSerialNumber') ? undefined : json['parentSerialNumber'],
        'purchaseOrderNumber': json['purchaseOrderNumber'],
    };
}

export function ContainerToJSON(value?: Container | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'purchaseOrderType': PurchaseOrderTypeToJSON(value.purchaseOrderType),
        'inventoryUnitNames': value.inventoryUnitNames,
        'containerType': ContainerTypeToJSON(value.containerType),
        'preferredArrivalDate': (value.preferredArrivalDate.toISOString().substr(0,10)),
        'totalM3': value.totalM3,
        'totalKg': value.totalKg,
        'volumeUsage': value.volumeUsage,
        'numberOfSKUs': value.numberOfSKUs,
        'weightUsage': value.weightUsage,
        'portOfLoading': value.portOfLoading,
        'portOfDischarge': value.portOfDischarge,
        'pastTotalM3': value.pastTotalM3,
        'pastTotalKg': value.pastTotalKg,
        'pastVolumeUsage': value.pastVolumeUsage,
        'pastWeightUsage': value.pastWeightUsage,
        'dataType': PurchaseOrderDataTypeToJSON(value.dataType),
        'isDetail': value.isDetail,
        'serialNumber': value.serialNumber,
        'parentSerialNumber': value.parentSerialNumber,
        'purchaseOrderNumber': value.purchaseOrderNumber,
    };
}


