import styled from 'styled-components'

const BaseCheckBox = styled.input<{disabled: boolean}>`
  appearance: none;
  background-color: ${props => props.disabled === true? '#fbfafa' : '#fff'};
  border-color: #ccc;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  display: block;
  height: 16px;
  margin: 0;
  -moz-appearance: none;
  cursor: pointer;
  transition: border-color 0.3s ease-out;
  -webkit-appearance: none;
  width: 16px;

  &:hover {
    border-color: var(--di-primary-color);
  }

  &:checked {
    background-color: var(--di-primary-color);
    border-color: var(--di-primary-color);
  }

  &:checked::before {
    color: #fff;
    content: '✔';
    display: block;
    font-size: 12px;
    text-align: center;
  }
`

const Container = styled.div<{disabled: boolean}>`
  align-items: center;
  display: flex;
  pointer-events: ${props => props.disabled === true? 'none' : 'auto'};
`

const Label = styled.label<{disabled: boolean, margin: number}>`
  color: ${props => props.disabled === true? '#999' : 'inherit'};
  cursor: pointer;
  margin-left: ${props => `${props.margin}px`};
`

export {
  BaseCheckBox,
  Container,
  Label,
}
