import React, { useCallback, useEffect, useState } from 'react'
import { Switch, Route, useHistory, useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import firebase from 'firebase/app'

import { useCurrentUser } from '../../hooks'

import { Sidebar } from '../../components/atoms'

import { MaintenanceAnnouncementModal, GlobalHeader } from '../../components/molecules'

import {
  CreateUser,
  Dashboard,
  InventoryUnitShipmentPlan,
  DraftPurchaseOrder,
  EditUser,
  InventoryPlan,
  OrderGroups,
  RegisterMasterData,
  ShipmentReason,
  SKUs,
  Users,
  InventoryUnitShipmentPlanCollective,
  DraftPurchaseOrderLongTerm,
} from '../../pages'

import { Container, MainContents } from './style'

export interface AfterLoginProps {
  /**
   * 営業時間外にメンテナンス画面を出す機能を有効にするかどうか
   */
  nonBusinessHoursPageEnabled: boolean
}

export const AfterLogin: React.FC<AfterLoginProps> = ({ nonBusinessHoursPageEnabled }) => {
  const businessHoursWillEndIn10Minutes = useCallback(() => {
    const today = new Date()
    const hours = today.getHours()
    if (hours !== 17) {
      return false
    }
    const minutes = today.getMinutes()
    if (minutes < 50) {
      return false
    }
    return true
  }, [])

  const [maintenanceAnnouncementModalVisible, setMaintenanceAnnouncementModalVisible] = useState(false)

  useEffect(() => {
    setInterval(() => {
      if (nonBusinessHoursPageEnabled === false) {
        return
      }

      if (businessHoursWillEndIn10Minutes() === true) {
        const KEY = 'maintenance-announcement-modal-dismissed-at'
        const dismissedAtStr = localStorage.getItem(KEY)
        if (dismissedAtStr == null) {
          setMaintenanceAnnouncementModalVisible(true)
          return
        }

        const dismissedAt = new Date(dismissedAtStr)
        const today = new Date()
        if (
          dismissedAt.getFullYear() < today.getFullYear() ||
          dismissedAt.getMonth() < today.getMonth() ||
          dismissedAt.getDate() < today.getDate()
        ) {
          setMaintenanceAnnouncementModalVisible(true)
        }
      }
    }, 1000)
  }, [nonBusinessHoursPageEnabled, businessHoursWillEndIn10Minutes])

  const history = useHistory()
  const location = useLocation()

  const { currentUser, setCurrentUser } = useCurrentUser()

  return currentUser ? (
    <Container id="page-top">
      <GlobalHeader
        user={currentUser}
        logout={() => {
          firebase
            .auth()
            .signOut()
            .then(() => history.push('/'))
            .catch((err) => Sentry.captureException(err))
        }}
      />
      <Sidebar currentPath={location.pathname} />
      <MainContents>
        <Switch>
          <Route exact path="/">
            <Dashboard />
          </Route>
          <Route exact path="/dashboard">
            <Dashboard />
          </Route>
          <Route exact path="/order-groups">
            <OrderGroups />
          </Route>
          <Route exact path="/order-groups/:orderGroupId/inventory-plan">
            <InventoryPlan currentUser={currentUser} />
          </Route>
          <Route exact path="/order-groups/:orderGroupId/draft-purchase-order">
            <DraftPurchaseOrder currentUser={currentUser} />
          </Route>
          <Route exact path="/inventory-units/:inventoryUnitCode/skus/:janCode/inventory-unit-shipment-plan">
            <InventoryUnitShipmentPlan currentUser={currentUser} />
          </Route>
          <Route exact path="/master-data/register">
            <RegisterMasterData />
          </Route>
          <Route exact path="/master-data/skus">
            <SKUs />
          </Route>
          <Route exact path="/shipment-reasons">
            <ShipmentReason />
          </Route>
          <Route exact path="/users">
            <Users currentUser={currentUser} />
          </Route>
          <Route exact path="/users/new">
            <CreateUser currentUser={currentUser} />
          </Route>
          <Route exact path="/users/:userId">
            <EditUser
              currentUser={currentUser}
              onEditUserHandler={(u) => {
                if (u.id === currentUser.id) {
                  // 自ユーザーの更新時は state を更新.
                  setCurrentUser(u)
                }
              }}
            />
          </Route>
          <Route exact path="/skus/:janCode/shipment-plan">
            <InventoryUnitShipmentPlanCollective currentUser={currentUser} />
          </Route>
          <Route exact path="/order-groups/:orderGroupId/draft-purchase-order-long-term">
            <DraftPurchaseOrderLongTerm currentUser={currentUser} />
          </Route>
        </Switch>
      </MainContents>
      {maintenanceAnnouncementModalVisible === true ? (
        <MaintenanceAnnouncementModal
          onCloseHandler={() => {
            const today = new Date()
            localStorage.setItem('maintenance-announcement-modal-dismissed-at', today.toDateString())
            setMaintenanceAnnouncementModalVisible(false)
          }}
        />
      ) : null}
    </Container>
  ) : (
    // TODO: Provide loading UI
    <div />
  )
}
