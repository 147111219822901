import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import firebase from 'firebase/app'

import { Configuration, DatabaseSKU, DefaultApi } from '../api-client'

export const useSKUs = () => {
  const [skus, setSKUs] = useState<Array<DatabaseSKU> | undefined>(undefined)

  useEffect(() => {
    firebase
      .auth()
      .currentUser?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        return api.getSkus()
      })
      .then((response) => setSKUs(response.skus))
      .catch((err) => {
        Sentry.captureException(err)
      })
  }, [])

  return {
    skus,
  }
}
