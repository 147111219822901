import styled from 'styled-components'

const CountLabel = styled.div`
  margin-left: 24px;
`

const LoadingSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 35px;
`

const NewUserButtonContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  height: 40px;
  margin-bottom: 15px;
  margin-top: 29px;
`

const ToastContainer = styled.div`
  position: absolute;
  right: 22px;
  top: 107px;
  z-index: 399;
`

const UsersTableContainer = styled.div`
  margin-left: 40px;
  margin-right: 40px;
`

export {
  CountLabel,
  LoadingSpinnerContainer,
  NewUserButtonContainer,
  ToastContainer,
  UsersTableContainer,
}
