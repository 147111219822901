import styled from 'styled-components'

const DistributionCenterSKUPanelContainer = styled.div`
  background-color: #fff;
`

const Label = styled.div`
  color: #767676;
`

const Property = styled.div`
  display: flex;
  margin-bottom: 5px;
`

const Value = styled.div`
  margin-right: 20px;
`

export {
  DistributionCenterSKUPanelContainer,
  Label,
  Property,
  Value,
}
