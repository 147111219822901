import React from 'react'
import { Link } from 'react-router-dom'

import {
  DraftPurchaseOrderPlanningInventoryUnitSKU,
  DraftPurchaseOrderPlanningInventoryUnitSKUErrorLevelInLast4WeeksEnum,
  DraftPurchaseOrderPlanningInventoryUnitSKUErrorLevelInLast13WeeksEnum,
  AdjustmentTarget,
} from '../../../api-client'

import { RoundLabel, RoundLabelProps } from '../../atoms'

import { Container, Label, Property, Value } from './style'

export interface ContainerDetailPanelProps {
  inventoryUnitSKU: DraftPurchaseOrderPlanningInventoryUnitSKU
}

export const ContainerDetailPanel: React.FC<ContainerDetailPanelProps> = ({ inventoryUnitSKU }) => {
  const errorLevelToRoundLabelProps = (
    errorLevel:
      | DraftPurchaseOrderPlanningInventoryUnitSKUErrorLevelInLast4WeeksEnum
      | DraftPurchaseOrderPlanningInventoryUnitSKUErrorLevelInLast13WeeksEnum
  ): RoundLabelProps => {
    switch (errorLevel) {
      case DraftPurchaseOrderPlanningInventoryUnitSKUErrorLevelInLast4WeeksEnum.High:
        return {
          color: 'red',
          text: '50%以上',
          width: 80,
        }
      // FIXME: 4週と13週のEnumは同じ値だが、Go 側で定数として宣言されてしまい重複によるエラーが起きてしまう。
      //       そのためこのパスは通らない。
      // case DraftPurchaseOrderPlanningInventoryUnitSKUErrorLevelInLast13WeeksEnum.High:
      //   return {
      //     color: 'red',
      //     text: '50%以上',
      //     width: 80,
      //   };
      case DraftPurchaseOrderPlanningInventoryUnitSKUErrorLevelInLast4WeeksEnum.Middle:
        return {
          color: 'yellow',
          text: '20%以上',
          width: 80,
        }
      // FIXME: 4週と13週のEnumは同じ値だが、Go 側で定数として宣言されてしまい重複によるエラーが起きてしまう。
      //       そのためこのパスは通らない。
      // case DraftPurchaseOrderPlanningInventoryUnitSKUErrorLevelInLast13WeeksEnum.Middle:
      //   return {
      //     color: 'yellow',
      //     text: '20%以上',
      //     width: 80,
      //   };
      case DraftPurchaseOrderPlanningInventoryUnitSKUErrorLevelInLast4WeeksEnum.Low:
        return {
          color: 'green',
          text: '20%未満',
          width: 80,
        }
      // FIXME: 4週と13週のEnumは同じ値だが、Go 側で定数として宣言されてしまい重複によるエラーが起きてしまう。
      //       そのためこのパスは通らない。
      // case DraftPurchaseOrderPlanningInventoryUnitSKUErrorLevelInLast13WeeksEnum.Low:
      //   return {
      //     color: 'green',
      //     text: '20%未満',
      //     width: 80,
      //   };
      default: // Enumで制約があるためこのケースは通らない想定
        return {
          color: 'green',
          text: '',
          width: 0,
        }
    }
  }

  return (
    <Container>
      <Property>
        <Label>入荷後在庫週数：</Label>
        <Value>
          {inventoryUnitSKU.numberOfInventoryWeeksAfterShipment != null
            ? `${inventoryUnitSKU.numberOfInventoryWeeksAfterShipment}週`
            : inventoryUnitSKU.minNumberOfInventoryWeeksAfterShipment != null
            ? `${inventoryUnitSKU.minNumberOfInventoryWeeksAfterShipment}週以上`
            : ''}
        </Value>
        <Label>直近4週平均週販：</Label>
        <Value>
          {inventoryUnitSKU.salesVolumeAverageInLast4Weeks != null
            ? inventoryUnitSKU.salesVolumeAverageInLast4Weeks.toLocaleString()
            : '-'}
        </Value>
        <Label>MOQ調整：</Label>
        <Value>{inventoryUnitSKU.isMOQAdjustmentApplied ? 'あり' : 'なし'}</Value>
        <Label>補正：</Label>
        <Value>{inventoryUnitSKU.isAdjusted ? 'あり' : 'なし'}</Value>
        <Label>直近4週誤差：</Label>
        <Value>
          {inventoryUnitSKU.errorLevelInLast4Weeks ===
          DraftPurchaseOrderPlanningInventoryUnitSKUErrorLevelInLast4WeeksEnum.Unavailable ? (
            <span>-</span>
          ) : (
            <RoundLabel {...errorLevelToRoundLabelProps(inventoryUnitSKU.errorLevelInLast4Weeks)} />
          )}
        </Value>
        <Label>直近13週誤差：</Label>
        <Value>
          {inventoryUnitSKU.errorLevelInLast13Weeks ===
          DraftPurchaseOrderPlanningInventoryUnitSKUErrorLevelInLast13WeeksEnum.Unavailable ? (
            <span>-</span>
          ) : (
            <RoundLabel {...errorLevelToRoundLabelProps(inventoryUnitSKU.errorLevelInLast13Weeks)} />
          )}
        </Value>
        <Label>補正対象の需要予測：</Label>
        <Value>
          {inventoryUnitSKU.adjustmentTarget === AdjustmentTarget.DemandInsight ? (
            <span>DI需要予測</span>
          ) : (
            <span>売上構成比による需要予測</span>
          )}
        </Value>
        <Link
          to="#"
          onClick={(event) => {
            event.preventDefault()
            window.open(
              `/inventory-units/${inventoryUnitSKU.inventoryUnitCode}/skus/${inventoryUnitSKU.janCode}/inventory-unit-shipment-plan?selectedDemandForecast=${inventoryUnitSKU.adjustmentTarget}`
            )
          }}
        >
          出荷予定数の補正
        </Link>
      </Property>
    </Container>
  )
}
