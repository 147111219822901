/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for OMR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DraftPurchaseOrderPlanningInventoryUnitReceiving
 */
export interface DraftPurchaseOrderPlanningInventoryUnitReceiving {
    /**
     * P/O No.
     * @type {string}
     * @memberof DraftPurchaseOrderPlanningInventoryUnitReceiving
     */
    purchaseOrderNumber: string;
    /**
     * 入荷予定数
     * @type {number}
     * @memberof DraftPurchaseOrderPlanningInventoryUnitReceiving
     */
    quantity: number;
}

export function DraftPurchaseOrderPlanningInventoryUnitReceivingFromJSON(json: any): DraftPurchaseOrderPlanningInventoryUnitReceiving {
    return DraftPurchaseOrderPlanningInventoryUnitReceivingFromJSONTyped(json, false);
}

export function DraftPurchaseOrderPlanningInventoryUnitReceivingFromJSONTyped(json: any, ignoreDiscriminator: boolean): DraftPurchaseOrderPlanningInventoryUnitReceiving {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'purchaseOrderNumber': json['purchaseOrderNumber'],
        'quantity': json['quantity'],
    };
}

export function DraftPurchaseOrderPlanningInventoryUnitReceivingToJSON(value?: DraftPurchaseOrderPlanningInventoryUnitReceiving | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'purchaseOrderNumber': value.purchaseOrderNumber,
        'quantity': value.quantity,
    };
}


