/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for OMR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 発注起案の種類
 * @export
 * @enum {string}
 */
export enum PurchaseOrderType {
    Normal = 'normal',
    MixedPurchaseOrder = 'mixedPurchaseOrder',
    MultipleWeeks = 'multipleWeeks',
    MixedPurchaseOrderAndmultipleWeeks = 'mixedPurchaseOrderAndmultipleWeeks',
    LandTransportation = 'landTransportation',
    Lcl = 'lcl',
    PastDraft = 'pastDraft'
}

export function PurchaseOrderTypeFromJSON(json: any): PurchaseOrderType {
    return PurchaseOrderTypeFromJSONTyped(json, false);
}

export function PurchaseOrderTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PurchaseOrderType {
    return json as PurchaseOrderType;
}

export function PurchaseOrderTypeToJSON(value?: PurchaseOrderType | null): any {
    return value as any;
}

