import React, { useState } from 'react'

import { BaseCheckBox, Container, Label } from './style'

export interface CheckBoxProps {
  /**
   * いわゆるid. 以下の用途で使用される
   * - HTML要素のid
   * - テスト用のid
   */
  id?: string

  /**
   * ラベル
   */
  label?: string

  /**
   * ラベルとチェックボックスの間の幅
   */
  labelMargin?: number

  /**
   * 初期値
   */
  defaultValue: boolean

  /**
   * 無効化するかどうか
   */
  disabled?: boolean

  /**
   * 値が変更された時に呼び出されるハンドラー
   */
  onChangeHandler: (checked: boolean) => void
}

export const CheckBox: React.FC<CheckBoxProps> = ({
  id = '',
  label = '',
  labelMargin = 6,
  defaultValue,
  disabled = false,
  onChangeHandler,
}) => {
  const [value, setValue] = useState(defaultValue)

  return (
    <Container disabled={disabled}>
      <BaseCheckBox
        id={id}
        type="checkbox"
        checked={value}
        disabled={disabled}
        value={value.toString()}
        onChange={(event) => {
          if (disabled === true) {
            // jsdom may not have pointer event feature.
            // Ref: https://github.com/jsdom/jsdom/issues/2527
            return
          }
          setValue(event.target.checked)
          onChangeHandler(event.target.checked)
        }}
        data-testid={id}
      />
      {id !== '' && label !== '' ? (
        <Label htmlFor={id} disabled={disabled} margin={labelMargin}>
          {label}
        </Label>
      ) : null}
    </Container>
  )
}
