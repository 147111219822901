/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for OMR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 発注起案のデータタイプ
 * @export
 * @enum {string}
 */
export enum PurchaseOrderDataType {
    NewData = 'newData',
    PastData = 'pastData',
    SumData = 'sumData'
}

export function PurchaseOrderDataTypeFromJSON(json: any): PurchaseOrderDataType {
    return PurchaseOrderDataTypeFromJSONTyped(json, false);
}

export function PurchaseOrderDataTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PurchaseOrderDataType {
    return json as PurchaseOrderDataType;
}

export function PurchaseOrderDataTypeToJSON(value?: PurchaseOrderDataType | null): any {
    return value as any;
}

