import React, { useEffect, useState } from 'react'

import {
  BaseSimulationButton,
  Container,
  DropdownContainer,
  DropdownOption,
  Label,
  Remark,
  TriangleIcon,
  TrianbleIconContainer,
} from './style'

export interface SimulationButtonProps {
  /**
   * 無効化するかどうか
   */
  disabled: boolean

  /**
   * 実行ボタンを表示するかどうか
   */
  submitButtonVisible: boolean

  /**
   * リセットボタンを表示するかどうか
   */
  resetButtonVisible: boolean

  /**
   * 実行ボタンがクリックされた時に呼び出されるハンドラー
   */
  onSubmitHandler: () => void

  /**
   * リセットボタンがクリックされた時に呼び出されるハンドラー
   */
  onResetHandler: () => void
}

export const SimulationButton: React.FC<SimulationButtonProps> = ({
  disabled,
  submitButtonVisible,
  resetButtonVisible,
  onSubmitHandler,
  onResetHandler,
}) => {
  const closeDropdown = () => setExpanded(false)

  useEffect(() => {
    document.addEventListener('click', closeDropdown)
    return function cleanup() {
      document.removeEventListener('click', closeDropdown)
    }
  }, [])

  const [expanded, setExpanded] = useState(false)

  return (
    <Container>
      <BaseSimulationButton
        disabled={disabled}
        onClick={(event) => {
          if (disabled === true) {
            // jsdom may not have pointer event feature.
            // Ref: https://github.com/jsdom/jsdom/issues/2527
            return
          }
          setExpanded(!expanded)
          event.stopPropagation()
        }}
        data-testid="simulation-button"
      >
        <Label disabled={disabled}>入出荷シミュレーション</Label>
        <TrianbleIconContainer>
          <TriangleIcon expanded={expanded.toString()} disabled={disabled} />
        </TrianbleIconContainer>
      </BaseSimulationButton>
      {disabled === false ? (
        <DropdownContainer visible={expanded.toString()}>
          {submitButtonVisible === true ? (
            <DropdownOption onClick={onSubmitHandler} data-testid="simulation-submit-button">
              実行<Remark>（翌朝リセット）</Remark>
            </DropdownOption>
          ) : null}
          {resetButtonVisible === true ? (
            <DropdownOption onClick={onResetHandler} data-testid="simulation-reset-button">
              リセット
            </DropdownOption>
          ) : null}
        </DropdownContainer>
      ) : null}
    </Container>
  )
}
