import React, { useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import firebase from 'firebase/app'

import {
  Configuration,
  DefaultApi,
  DeleteUserRequest,
  ModelError,
  PutUserRequest,
  PutUserOperationRequest,
  User,
  UserRole,
  ErrorTitle,
} from '../../api-client'

import { UserOperationType } from '../../constants'

import { useUser } from '../../hooks'

import { ErrorMessagePanel, NotificationField, PageHeader } from '../../components/atoms'
import { DeleteConfirmationModal, DeleteUserErrorModal, UserForm } from '../../components/molecules'

import { DeleteUserLink, ErrorMessagePanelContainer, NotificationErrorContainer, UserFormContainer } from './style'

export interface EditUserPathParams {
  userId: string
}

export interface EditUserProps {
  /**
   * ログイン中のユーザー
   */
  currentUser: User

  /**
   * ユーザーが更新されたときに呼び出されるハンドラー
   */
  onEditUserHandler: (user: User) => void
}

export const EditUser: React.FC<EditUserProps> = ({ currentUser, onEditUserHandler }) => {
  const history = useHistory()

  const { userId } = useParams<EditUserPathParams>()
  const userIdNum = Number.parseInt(userId)

  const { statusCode, user } = useUser(userIdNum)

  const [deleteUserConfirmationModalVisible, setDeleteUserConfiramtionModalVisible] = useState(false)
  const [deleteUserErrorModalVisible, setDeleteUserErrorModalVisible] = useState(false)
  const [isDeleteUserButtonDisabled, setIsDeleteUserButtonDisabled] = useState(false)
  const [editUserErrorMessage, setEditUserErrorMessage] = useState<string | null | undefined>(undefined)

  return statusCode != null && statusCode !== 200 ? (
    <ErrorMessagePanelContainer>
      <ErrorMessagePanel statusCode={statusCode} />
    </ErrorMessagePanelContainer>
  ) : (
    <div>
      <PageHeader pageTitle="ユーザー情報編集" backLink={true} />
      {user != null ? (
        <UserFormContainer>
          {editUserErrorMessage != null ? (
            <NotificationErrorContainer>
              <NotificationField type="error" title="登録エラー" bodyMessage={editUserErrorMessage} />
            </NotificationErrorContainer>
          ) : null}
          <UserForm
            user={user}
            emailEditable={currentUser.role === UserRole.Manager}
            roleEditable={currentUser.role === UserRole.Manager && currentUser.id !== user.id}
            onSaveUserClick={(name, password, email, role) => {
              firebase
                .auth()
                .currentUser?.getIdToken(true)
                .then((token) => {
                  const conf = new Configuration({
                    basePath: process.env.REACT_APP_API_PATH,
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  })
                  const api = new DefaultApi(conf)
                  const bodyParam: PutUserRequest = {
                    email,
                    name,
                    password,
                    role,
                  }
                  const param: PutUserOperationRequest = {
                    userId: userIdNum,
                    bodyParam,
                  }
                  return api.putUser(param)
                })
                .then((response) => {
                  onEditUserHandler(response)
                  history.push({
                    pathname: '/users',
                    state: {
                      userOperationType: UserOperationType.Update,
                    },
                  })
                })
                .catch((error: Response) => {
                  if (error.status === 400) {
                    error.json().then((e: ModelError) => {
                      if (e.title === ErrorTitle._400BadRequestEmailAlreadyExists) {
                        setEditUserErrorMessage('登録済のメールアドレスです。')
                      } else if (e.title === ErrorTitle._400BadRequestUserHasOrderGroup) {
                        setEditUserErrorMessage('このユーザーに紐づく発注グループがある為、管理者に変更できません。')
                      } else {
                        setEditUserErrorMessage('不明なエラーです。システム管理者にお問い合わせ下さい。')
                      }
                    })
                  } else {
                    setEditUserErrorMessage('不明なエラーです。システム管理者にお問い合わせ下さい。')
                  }
                })
            }}
          />
          {currentUser.role === UserRole.Manager && currentUser.id !== userIdNum ? (
            <DeleteUserLink>
              <Link to="#" onClick={() => setDeleteUserConfiramtionModalVisible(true)} data-testid="delete-user-link">
                このユーザーを削除
              </Link>
            </DeleteUserLink>
          ) : null}
        </UserFormContainer>
      ) : null}
      {deleteUserConfirmationModalVisible === true ? (
        <DeleteConfirmationModal
          message="このユーザーを削除します。"
          disabled={isDeleteUserButtonDisabled}
          onCloseHandler={() => setDeleteUserConfiramtionModalVisible(false)}
          onSubmitHandler={() => {
            setIsDeleteUserButtonDisabled(true)
            firebase
              .auth()
              .currentUser?.getIdToken(true)
              .then((token) => {
                const conf = new Configuration({
                  basePath: process.env.REACT_APP_API_PATH,
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                })
                const api = new DefaultApi(conf)
                const param: DeleteUserRequest = {
                  userId: userIdNum,
                }
                return api.deleteUser(param)
              })
              .then((response) => {
                history.push({
                  pathname: '/users',
                  state: {
                    userOperationType: UserOperationType.Delete,
                  },
                })
                setDeleteUserConfiramtionModalVisible(false)
                setIsDeleteUserButtonDisabled(false)
                setDeleteUserErrorModalVisible(true)
              })
              .catch((error: Response) => {
                if (error.status === 400) {
                  error.json().then((e: ModelError) => {
                    if (e.title === ErrorTitle._400BadRequestUserHasOrderGroup) {
                      setDeleteUserConfiramtionModalVisible(false)
                      setIsDeleteUserButtonDisabled(false)
                      setDeleteUserErrorModalVisible(true)
                    }
                  })
                }
              })
          }}
        />
      ) : null}
      {deleteUserErrorModalVisible === true ? (
        <DeleteUserErrorModal onCloseHandler={() => setDeleteUserErrorModalVisible(false)} />
      ) : null}
    </div>
  )
}
