import React from 'react'
import { Link } from 'react-router-dom'

import { ShipmentReason } from '../../../api-client'

import {
  BaseShipmentReasonsTable,
  ShipmentReasonsTableBodyRow,
  ShipmentReasonsTableData,
  ShipmentReasonsTableHeader,
} from './style'

export interface ShipmentReasonsTableProps {
  shipmentReasons: Array<ShipmentReason>
}

export const ShipmentReasonsTable: React.FC<ShipmentReasonsTableProps> = ({ shipmentReasons }) => {
  return (
    <BaseShipmentReasonsTable>
      <thead>
        <tr>
          <ShipmentReasonsTableHeader width={150}>JANコード</ShipmentReasonsTableHeader>
          <ShipmentReasonsTableHeader width={470}>商品名</ShipmentReasonsTableHeader>
          <ShipmentReasonsTableHeader width={200}>在庫管理単位</ShipmentReasonsTableHeader>
          <ShipmentReasonsTableHeader width={170}></ShipmentReasonsTableHeader>
          <ShipmentReasonsTableHeader></ShipmentReasonsTableHeader>
        </tr>
      </thead>
      <tbody>
        {shipmentReasons.map((shipmentReason, idx) => (
          <ShipmentReasonsTableBodyRow key={idx}>
            <ShipmentReasonsTableData>{shipmentReason.janCode}</ShipmentReasonsTableData>
            <ShipmentReasonsTableData>{`${shipmentReason.skuName}・${shipmentReason.skuSpecName}`}</ShipmentReasonsTableData>
            <ShipmentReasonsTableData>{`${shipmentReason.inventoryUnitName}(${shipmentReason.logisticPatternName})`}</ShipmentReasonsTableData>
            <ShipmentReasonsTableData>
              <Link
                to={`/inventory-units/${shipmentReason.inventoryUnitCode}/skus/${shipmentReason.janCode}/inventory-unit-shipment-plan`}
              >
                出荷予定数を確認する
              </Link>
            </ShipmentReasonsTableData>
            <ShipmentReasonsTableData></ShipmentReasonsTableData>
          </ShipmentReasonsTableBodyRow>
        ))}
      </tbody>
    </BaseShipmentReasonsTable>
  )
}
