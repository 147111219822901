import React, { useEffect, useState } from 'react'

import { OrderGroupsItem } from '../../api-client'

import { useOrderGroups } from '../../hooks'

import { Dropdown, DropdownOption, LoadingSpinner, PageHeader, TextBox, Button } from '../../components/atoms'

import { OrderGroupsListTable } from '../../components/molecules'

import {
  FilterContainer,
  FilterLabel,
  FiltersContainer,
  LoadingSpinnerContainer,
  OrderGroupsCountLabel,
  OrderGroupsListContainer,
} from './style'
import { useHistory, useLocation } from 'react-router-dom'

type DropdownOptionAll = 'All'

export interface OrderGroupsProps {}

// history に詰めるときの key
const ORDER_GRPUP_KEY = 'orderGroupName'
const DEPARTMENT_CODE_KEY = 'departmentCode'
const OPERATOR_ID_KEY = 'operatorId'

// 検索条件のデフォルト値
const DEFAULT_ORDER_GROUP = ''
const DEFAULT_DEPARTMENT_CODE = 'All'
const DEFAULT_OPERATOR_ID = -1

const departmentCodeDropdownOptions = (
  orderGroups: ReadonlyArray<OrderGroupsItem>
): Array<DropdownOption<string | DropdownOptionAll>> => {
  const uniqueDepartmentCodesSorted = Array.from(
    new Set(orderGroups?.flatMap((orderGroup) => orderGroup.departmentCodes))
  ).sort()

  return [
    {
      label: 'すべて',
      value: DEFAULT_DEPARTMENT_CODE,
    },
    ...uniqueDepartmentCodesSorted.map((code) => ({
      label: code,
      value: code,
    })),
  ]
}

const operatorDropdownOptions = (orderGroups: ReadonlyArray<OrderGroupsItem>): Array<DropdownOption<number>> => {
  const operatorIdSet = new Set<number>()
  const uniqueOperators: Array<{ id: number; name: string }> = []
  orderGroups.forEach((orderGroup) => {
    if (operatorIdSet.has(orderGroup.operatorId) === false) {
      uniqueOperators.push({
        id: orderGroup.operatorId,
        name: orderGroup.operatorName,
      })
      operatorIdSet.add(orderGroup.operatorId)
    }
  })

  const uniqueOperatorsSorted = uniqueOperators.sort()

  return [
    {
      label: 'すべて',
      value: -1,
    },
    ...uniqueOperatorsSorted.map((operator) => ({
      label: operator.name,
      value: operator.id,
    })),
  ]
}

export const OrderGroups: React.FC<OrderGroupsProps> = () => {
  const { orderGroups } = useOrderGroups()
  const [orderGroupNameSearchText, setOrderGroupNameSearchText] = useState(DEFAULT_ORDER_GROUP)
  const [selectedCode, setSelectedCode] = useState<string | DropdownOptionAll>(DEFAULT_DEPARTMENT_CODE)
  const [selectedOperatorId, setSelectedOperatorId] = useState<number>(DEFAULT_OPERATOR_ID)
  const [filteredOrderGroups, setFilterOrderGroups] = useState(orderGroups || [])

  const history = useHistory()
  const location = useLocation()

  const pushSeachCondition = () => {
    const params = new URLSearchParams(location.search)
    if(orderGroupNameSearchText === DEFAULT_ORDER_GROUP) {
      params.delete(ORDER_GRPUP_KEY)
    } else {
      params.set(ORDER_GRPUP_KEY, orderGroupNameSearchText)
    }
    if (selectedCode === DEFAULT_DEPARTMENT_CODE) {
      params.delete(DEPARTMENT_CODE_KEY)
    } else {
      params.set(DEPARTMENT_CODE_KEY, selectedCode)
    }
    if (selectedOperatorId === DEFAULT_OPERATOR_ID) {
      params.delete(OPERATOR_ID_KEY)
    } else {
      params.set(OPERATOR_ID_KEY, selectedOperatorId.toString())
    }
    history.push({ search: params.toString() })
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const orderGroupName = params.get(ORDER_GRPUP_KEY) || DEFAULT_ORDER_GROUP
    const departmentCode = params.get(DEPARTMENT_CODE_KEY) || DEFAULT_DEPARTMENT_CODE
    const operatorId = parseInt(params.get(OPERATOR_ID_KEY) || DEFAULT_OPERATOR_ID.toString())

    setOrderGroupNameSearchText(orderGroupName)
    setSelectedCode(departmentCode)
    setSelectedOperatorId(operatorId)

    const filterCondition = (orderGroup: OrderGroupsItem): boolean =>
        (orderGroupName === DEFAULT_ORDER_GROUP || orderGroup.name.includes(orderGroupName)) &&
        (departmentCode === DEFAULT_DEPARTMENT_CODE || orderGroup.departmentCodes.includes(departmentCode)) &&
        (operatorId === DEFAULT_OPERATOR_ID || orderGroup.operatorId === operatorId)
    setFilterOrderGroups(orderGroups?.filter(filterCondition) || [])
  }, [orderGroups, location.search])

  const orderGroupsTable = (orderGroups: Array<OrderGroupsItem>): Array<OrderGroupsItem> => {
    return orderGroups.sort((a,b) => { return a.name < b.name ? -1 : 1 })
  }

  return (
    <>
      <PageHeader pageTitle="発注グループ一覧" backLink={false} />
      <FiltersContainer>
        <FilterContainer>
          <FilterLabel>発注グループ</FilterLabel>
          <TextBox
            id="order-group-name-search-input"
            type="text"
            width={200}
            height={40}
            defaultValue={orderGroupNameSearchText}
            onChangeHandler={setOrderGroupNameSearchText}
          />
        </FilterContainer>
        <FilterContainer>
          <FilterLabel>部門</FilterLabel>
          <Dropdown
            options={departmentCodeDropdownOptions(orderGroups ?? [])}
            defaultValue={selectedCode}
            width={166}
            onChangeHandler={setSelectedCode}
          />
        </FilterContainer>
        <FilterContainer>
          <FilterLabel>担当者</FilterLabel>
          <Dropdown
            options={operatorDropdownOptions(orderGroups ?? [])}
            defaultValue={selectedOperatorId}
            width={166}
            onChangeHandler={setSelectedOperatorId}
          />
        </FilterContainer>
        <FilterContainer>
        <Button
          styleType="tertiary"
          label="検索"
          width={76}
          onClickHandler={pushSeachCondition}
          data-testid="order-group-search-button"
        />
        </FilterContainer>
      </FiltersContainer>
      {orderGroups != null ? (
        <OrderGroupsListContainer>
          {filteredOrderGroups.length === 0 ? (
            <OrderGroupsCountLabel>検索結果がありません。</OrderGroupsCountLabel>
          ) : (
            <>
              <OrderGroupsCountLabel>{filteredOrderGroups.length}件</OrderGroupsCountLabel>
              <OrderGroupsListTable orderGroups={orderGroupsTable(filteredOrderGroups)} />
            </>
          )}
        </OrderGroupsListContainer>
      ) : (
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      )}
    </>
  )
}
