/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for OMR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DraftPurchaseOrderPlanningInventoryUnitSKU,
    DraftPurchaseOrderPlanningInventoryUnitSKUFromJSON,
    DraftPurchaseOrderPlanningInventoryUnitSKUFromJSONTyped,
    DraftPurchaseOrderPlanningInventoryUnitSKUToJSON,
    DraftPurchaseOrderPlanningWeek,
    DraftPurchaseOrderPlanningWeekFromJSON,
    DraftPurchaseOrderPlanningWeekFromJSONTyped,
    DraftPurchaseOrderPlanningWeekToJSON,
} from './';

/**
 * コンテナの輸送計画の詳細情報
 * @export
 * @interface GetContainerPlanResponse
 */
export interface GetContainerPlanResponse {
    /**
     * 
     * @type {Array<DraftPurchaseOrderPlanningWeek>}
     * @memberof GetContainerPlanResponse
     */
    weeks: Array<DraftPurchaseOrderPlanningWeek>;
    /**
     * InventoryUnitxSKU 一覧
     * @type {Array<DraftPurchaseOrderPlanningInventoryUnitSKU>}
     * @memberof GetContainerPlanResponse
     */
    inventoryUnitSKUs: Array<DraftPurchaseOrderPlanningInventoryUnitSKU>;
}

export function GetContainerPlanResponseFromJSON(json: any): GetContainerPlanResponse {
    return GetContainerPlanResponseFromJSONTyped(json, false);
}

export function GetContainerPlanResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetContainerPlanResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'weeks': ((json['weeks'] as Array<any>).map(DraftPurchaseOrderPlanningWeekFromJSON)),
        'inventoryUnitSKUs': ((json['inventoryUnitSKUs'] as Array<any>).map(DraftPurchaseOrderPlanningInventoryUnitSKUFromJSON)),
    };
}

export function GetContainerPlanResponseToJSON(value?: GetContainerPlanResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'weeks': ((value.weeks as Array<any>).map(DraftPurchaseOrderPlanningWeekToJSON)),
        'inventoryUnitSKUs': ((value.inventoryUnitSKUs as Array<any>).map(DraftPurchaseOrderPlanningInventoryUnitSKUToJSON)),
    };
}


