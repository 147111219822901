import React, { useEffect, useState } from 'react'

import { ContainerPlanningOrderGroup } from '../../api-client'

import { useDraftPurchaseOrders } from '../../hooks'

import { Dropdown, DropdownOption, LoadingSpinner, PageHeader, Button } from '../../components/atoms'

import { OrderGroupsWithDraftPOListTable } from '../../components/molecules'

import {
  CountLabel,
  FilterContainer,
  FilterLabel,
  FiltersContainer,
  LoadingSpinnerContainer,
  OrderGroupsWithDraftPOListTableContaiiner,
  OrderGroupsWithDraftPOListTableTitle,
} from './style'

import { useHistory, useLocation } from 'react-router-dom'

// history に詰めるときの key
const DEPARTMENT_CODE_KEY = 'departmentCode'
const OPERATOR_ID_KEY = 'operatorId'

// 検索条件のデフォルト値
const DEFAULT_DEPARTMENT_CODE = 'All'
const DEFAULT_OPERATOR_ID = -1

type DropdownOptionAll = 'All'

export interface DashboardProps { }

export const Dashboard: React.FC<DashboardProps> = () => {
  const { orderGroups } = useDraftPurchaseOrders()

  const [selectedCode, setSelectedCode] = useState<string | DropdownOptionAll>(DEFAULT_DEPARTMENT_CODE)
  const [selectedOperatorId, setSelectedOperatorId] = useState<number>(DEFAULT_OPERATOR_ID)
  const [filteredOrderGroups, setFilteredOrderGroups] = useState<Array<ContainerPlanningOrderGroup>>(orderGroups || [])

  const history = useHistory()
  const location = useLocation()

  const codeDropdownOptions = (orderGroups: Array<ContainerPlanningOrderGroup>): Array<DropdownOption<string | DropdownOptionAll>> => {
    const uniqueDepartmentCodesSorted = Array.from(
      new Set(orderGroups?.flatMap((orderGroup) => orderGroup.departmentCodes))
    ).sort((a, b) => parseInt(a) - parseInt(b))
    return [
      {
        label: 'すべて',
        value: DEFAULT_DEPARTMENT_CODE,
      },
      ...uniqueDepartmentCodesSorted.map((code) => ({
        label: code,
        value: code,
      })),
    ]
  }
  const operatorDropdownOptions = (orderGroups: Array<ContainerPlanningOrderGroup>): Array<DropdownOption<number>> => {
    const operatorIdSet = new Set<number>()
    const uniquedOperators: Array<{ id: number; name: string }> = []
    orderGroups.forEach((orderGroup) => {
      if (operatorIdSet.has(orderGroup.operatorId) === false) {
        uniquedOperators.push({
          id: orderGroup.operatorId,
          name: orderGroup.operatorName,
        })
        operatorIdSet.add(orderGroup.operatorId)
      }
    })

    return [
      {
        label: 'すべて',
        value: DEFAULT_OPERATOR_ID,
      },
      ...uniquedOperators.map((operator) => ({
        label: operator.name,
        value: operator.id,
      })),
    ]
  }

  const pushSeachCondition = () => {
    const params = new URLSearchParams(location.search)
    if (selectedCode === DEFAULT_DEPARTMENT_CODE) {
      params.delete(DEPARTMENT_CODE_KEY)
    } else {
      params.set(DEPARTMENT_CODE_KEY, selectedCode)
    }
    if (selectedOperatorId === DEFAULT_OPERATOR_ID) {
      params.delete(OPERATOR_ID_KEY)
    } else {
      params.set(OPERATOR_ID_KEY, selectedOperatorId.toString())
    }
    history.push({ search: params.toString() })
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const departmentCode = params.get(DEPARTMENT_CODE_KEY) || DEFAULT_DEPARTMENT_CODE
    const operatorId = parseInt(params.get(OPERATOR_ID_KEY) || DEFAULT_OPERATOR_ID.toString())

    setSelectedCode(departmentCode)
    setSelectedOperatorId(operatorId)
    const filterCondition = (orderGroup: ContainerPlanningOrderGroup): boolean =>
      (departmentCode === DEFAULT_DEPARTMENT_CODE || orderGroup.departmentCodes.includes(departmentCode)) &&
      (operatorId === DEFAULT_OPERATOR_ID || operatorId === orderGroup.operatorId)
    setFilteredOrderGroups(orderGroups?.filter(filterCondition) || [])
  }, [orderGroups, location.search])

  const orderGroupsTable = (orderGroups: Array<ContainerPlanningOrderGroup>): Array<ContainerPlanningOrderGroup> => {
    return orderGroups.sort((a, b) => { return a.orderGroupName < b.orderGroupName ? -1 : 1 })
  }

  return (
    <>
      <PageHeader pageTitle="ダッシュボード" backLink={false} />
      <OrderGroupsWithDraftPOListTableTitle>発注案がある発注グループ一覧</OrderGroupsWithDraftPOListTableTitle>
      <FiltersContainer>
        <FilterContainer>
          <FilterLabel>部門</FilterLabel>
          <Dropdown
            options={codeDropdownOptions(orderGroups != null ? orderGroups : [])}
            defaultValue={selectedCode}
            width={166}
            onChangeHandler={setSelectedCode}
          />
        </FilterContainer>
        <FilterContainer>
          <FilterLabel>担当者</FilterLabel>
          <Dropdown
            options={operatorDropdownOptions(orderGroups != null ? orderGroups : [])}
            defaultValue={selectedOperatorId}
            width={166}
            onChangeHandler={setSelectedOperatorId}
          />
        </FilterContainer>
        <FilterContainer>
          <Button
            styleType="tertiary"
            label="検索"
            width={76}
            onClickHandler={pushSeachCondition}
            data-testid="order-group-search-button"
          />
        </FilterContainer>
      </FiltersContainer>
      {orderGroups != null ? (
        <OrderGroupsWithDraftPOListTableContaiiner>
          {filteredOrderGroups.length === 0 ? (
            <span>検索結果がありません。</span>
          ) : (
            <>
              <CountLabel>
                {filteredOrderGroups.length}/{orderGroups.length}件中
              </CountLabel>
              <OrderGroupsWithDraftPOListTable orderGroups={orderGroupsTable(filteredOrderGroups)} />
            </>
          )}
        </OrderGroupsWithDraftPOListTableContaiiner>
      ) : (
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      )}
    </>
  )
}
