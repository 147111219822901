interface TypeConverter {
  stringToBoolean: (value: string) => boolean
  stringToNumber: (value: string) => number
}

export const useTypeConverter = (): TypeConverter => {
  const stringToBoolean = (value: string): boolean => {
    // JSON.parse時など型情報がメモリに含まれている時があるため
    if (typeof value === 'boolean') {
      return value
    }

    // Boolean() では値があるかどうかで判定が入ってしまうため
    if (value === 'true') {
      return true
    }
    if (value === 'false') {
      return false
    }

    if (!value) {
      return false
    }

    throw new Error('Error: Invalid value expected boolean but got other type:' + value)
  }

  const stringToNumber = (value: string): number => {
    const num = Number(value)
    if (isNaN(num)) {
      throw new Error('Error: Invalid value expected number but got other type:' + value)
    }

    return Number(value)
  }

  return {
    stringToBoolean,
    stringToNumber,
  }
}
