import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import firebase from 'firebase/app'

import { Configuration, DefaultApi, User } from '../api-client'

export const useUser = (userId: number) => {
  const [statusCode, setStatusCode] = useState<number | undefined>(undefined)
  const [user, setUser] = useState<User | undefined>(undefined)

  useEffect(() => {
    firebase
      .auth()
      .currentUser?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        return api.getUserByID({ userId })
      })
      .then((u) => {
        // 正常系は200にしておく.
        setStatusCode(200)

        setUser(u)
      })
      .catch((err: Response) => {
        Sentry.captureException(err)
        setStatusCode(err.status)
      })
  }, [userId])

  return {
    statusCode,
    user,
  }
}
