import React, { useState } from 'react'

import { Button, TextBox } from '../../atoms'

import { PasswordInputContainer, PasswordConfirmationInputContainer } from './style'

export interface PasswordResetFormProps {
  /**
   * 実行ボタンが押された時に呼び出されるハンドラー
   */
  onPasswordResetClick: (password: string) => void
}

export const PasswordResetForm: React.FC<PasswordResetFormProps> = ({ onPasswordResetClick }) => {
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')

  const [forceValidate, setForceValidate] = useState(false)

  return (
    <div>
      <PasswordInputContainer>
        <TextBox
          id="password-reset-form-password-input"
          label="新しいパスワード"
          subLabel="（8文字以上）"
          width={427}
          height={40}
          type="password"
          autoComplete="new-password"
          required={true}
          minLength={8}
          maxLength={200}
          defaultValue={password}
          placeholder="********"
          forceValidate={forceValidate}
          onChangeHandler={(value: string) => setPassword(value)}
        />
      </PasswordInputContainer>
      <PasswordConfirmationInputContainer>
        <TextBox
          id="password-reset-form-password-confirmation-input"
          label="新しいパスワードの確認"
          width={427}
          height={40}
          type="password"
          required={true}
          autoComplete="new-password"
          defaultValue={passwordConfirmation}
          customErrorMessage={
            passwordConfirmation !== '' && passwordConfirmation !== password ? 'パスワードが一致しません。' : ''
          }
          placeholder="********"
          forceValidate={forceValidate}
          onChangeHandler={(value: string) => setPasswordConfirmation(value)}
        />
      </PasswordConfirmationInputContainer>
      <Button
        styleType="primary"
        label="パスワードを設定"
        width={427}
        onClickHandler={() => {
          setForceValidate(true)
          if (password === '' || password.length < 8 || password.length > 200) {
            return
          }
          if (password !== passwordConfirmation) {
            return
          }
          onPasswordResetClick(password)
        }}
        data-testid="password-reset-submit-button"
      />
    </div>
  )
}
