/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for OMR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserRole,
    UserRoleFromJSON,
    UserRoleFromJSONTyped,
    UserRoleToJSON,
} from './';

/**
 * 
 * @export
 * @interface PostUsersRequest
 */
export interface PostUsersRequest {
    /**
     * メールアドレス
     * @type {string}
     * @memberof PostUsersRequest
     */
    email: string;
    /**
     * ユーザー名
     * @type {string}
     * @memberof PostUsersRequest
     */
    name: string;
    /**
     * パスワード
     * @type {string}
     * @memberof PostUsersRequest
     */
    password: string;
    /**
     * 
     * @type {UserRole}
     * @memberof PostUsersRequest
     */
    role: UserRole;
}

export function PostUsersRequestFromJSON(json: any): PostUsersRequest {
    return PostUsersRequestFromJSONTyped(json, false);
}

export function PostUsersRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostUsersRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'name': json['name'],
        'password': json['password'],
        'role': UserRoleFromJSON(json['role']),
    };
}

export function PostUsersRequestToJSON(value?: PostUsersRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'name': value.name,
        'password': value.password,
        'role': UserRoleToJSON(value.role),
    };
}


