/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for OMR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ErrorTitle {
    _400BadRequest = '400 Bad Request',
    _400BadRequestUnexpectedAuthError = '400 Bad Request, Unexpected auth error.',
    _400BadRequestEmailAlreadyExists = '400 Bad Request, Email already exists.',
    _400BadRequestEmailFormatIsInvalid = '400 Bad Request, Email format is invalid.',
    _400BadRequestInvalidExcelFile = '400 Bad Request, Invalid EXCEL file',
    _400BadRequestInvalidExcelRows = '400 Bad Request, Invalid EXCEL Rows',
    _400BadRequestInvalidCsvFile = '400 Bad Request, Invalid CSV file',
    _400BadRequestInvalidCsvRows = '400 Bad Request, Invalid CSV Rows',
    _400BadRequestUserHasOrderGroup = '400 Bad Request, User has OrderGroup.',
    _400BadRequestInvalidJsonBody = '400 Bad Request, Invalid JSON body.',
    _400BadRequestWrongUserIdFormat = '400 Bad Request, Wrong userId format.',
    _403Forbidden = '403 Forbidden',
    _403ForbiddenInsufficientPermission = '403 Forbidden, Insufficient Permission.',
    _404NotFound = '404 Not Found',
    _404NotFoundResourceNotFound = '404 Not Found, Resource Not Found.',
    _500InternalServerError = '500 Internal Server Error'
}

export function ErrorTitleFromJSON(json: any): ErrorTitle {
    return ErrorTitleFromJSONTyped(json, false);
}

export function ErrorTitleFromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorTitle {
    return json as ErrorTitle;
}

export function ErrorTitleToJSON(value?: ErrorTitle | null): any {
    return value as any;
}

