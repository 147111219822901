import styled from 'styled-components'

const BaseSKUsTable = styled.table`
  border-right-color: var(--di-table-border-color);
  border-right-style: solid;
  border-right-width: 1px;
  table-layout: fixed;
`

const Container = styled.div`
  border-left: solid 1px var(--di-table-border-color);
  overflow-x: auto;
  white-space: nowrap;
  over-flow-y: scroll;
  height: 520px;
`

const NameLabel = styled.div`
  height: 50px;
  margin-top: 5px;
  white-space: normal;
  width: 240px;
`

const SKUsTableBodyRow = styled.tr`
  background-color: #fff;
  transition: background-color 0.3s 0s ease;

  &:hover {
    background-color: var(--di-table-body-bg-hover-color);
  }
`

const SKUsTableData = styled.td`
  border-bottom-color: var(--di-table-border-color);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  height: 60px;
  padding-left: 30px;
`

const SKUsTableHeader = styled.th<{width: number}>`
  background-color: #f1f1f1;
  border-bottom-color: var(--di-table-border-color);
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-top-color: var(--di-table-border-color);
  border-top-style: solid;
  border-top-width: 1px;
  height: 40px;
  text-align: left;
  padding-left: 30px;
  width: ${props => `${props.width}px`};
  position: web-kit-sticky;
  position: sticky;
  top: 0;
`

export {
  BaseSKUsTable,
  Container,
  NameLabel,
  SKUsTableBodyRow,
  SKUsTableData,
  SKUsTableHeader,
}
