/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for OMR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdjustmentRange,
    AdjustmentRangeFromJSON,
    AdjustmentRangeFromJSONTyped,
    AdjustmentRangeToJSON,
    AdjustmentTarget,
    AdjustmentTargetFromJSON,
    AdjustmentTargetFromJSONTyped,
    AdjustmentTargetToJSON,
    AdjustmentType,
    AdjustmentTypeFromJSON,
    AdjustmentTypeFromJSONTyped,
    AdjustmentTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface PutInventoryUnitShipmentPlanRequest
 */
export interface PutInventoryUnitShipmentPlanRequest {
    /**
     * 
     * @type {AdjustmentType}
     * @memberof PutInventoryUnitShipmentPlanRequest
     */
    adjustmentType: AdjustmentType | null;
    /**
     * 補正の指定比率
     * @type {number}
     * @memberof PutInventoryUnitShipmentPlanRequest
     */
    adjustmentCustomRate: number;
    /**
     * 
     * @type {AdjustmentRange}
     * @memberof PutInventoryUnitShipmentPlanRequest
     */
    adjustmentRange: AdjustmentRange | null;
    /**
     * 直近何週が補正対象か
     * @type {number}
     * @memberof PutInventoryUnitShipmentPlanRequest
     */
    numberOfWeeksToAdjust: number;
    /**
     * 
     * @type {AdjustmentTarget}
     * @memberof PutInventoryUnitShipmentPlanRequest
     */
    selectedDemandForecast: AdjustmentTarget;
}

export function PutInventoryUnitShipmentPlanRequestFromJSON(json: any): PutInventoryUnitShipmentPlanRequest {
    return PutInventoryUnitShipmentPlanRequestFromJSONTyped(json, false);
}

export function PutInventoryUnitShipmentPlanRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutInventoryUnitShipmentPlanRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'adjustmentType': AdjustmentTypeFromJSON(json['adjustmentType']),
        'adjustmentCustomRate': json['adjustmentCustomRate'],
        'adjustmentRange': AdjustmentRangeFromJSON(json['adjustmentRange']),
        'numberOfWeeksToAdjust': json['numberOfWeeksToAdjust'],
        'selectedDemandForecast': AdjustmentTargetFromJSON(json['selectedDemandForecast']),
    };
}

export function PutInventoryUnitShipmentPlanRequestToJSON(value?: PutInventoryUnitShipmentPlanRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'adjustmentType': AdjustmentTypeToJSON(value.adjustmentType),
        'adjustmentCustomRate': value.adjustmentCustomRate,
        'adjustmentRange': AdjustmentRangeToJSON(value.adjustmentRange),
        'numberOfWeeksToAdjust': value.numberOfWeeksToAdjust,
        'selectedDemandForecast': AdjustmentTargetToJSON(value.selectedDemandForecast),
    };
}


