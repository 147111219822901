/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for OMR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdjustmentRange,
    AdjustmentRangeFromJSON,
    AdjustmentRangeFromJSONTyped,
    AdjustmentRangeToJSON,
    AdjustmentTarget,
    AdjustmentTargetFromJSON,
    AdjustmentTargetFromJSONTyped,
    AdjustmentTargetToJSON,
    AdjustmentType,
    AdjustmentTypeFromJSON,
    AdjustmentTypeFromJSONTyped,
    AdjustmentTypeToJSON,
    InventoryUnitShipmentPlanPerWeek,
    InventoryUnitShipmentPlanPerWeekFromJSON,
    InventoryUnitShipmentPlanPerWeekFromJSONTyped,
    InventoryUnitShipmentPlanPerWeekToJSON,
    InventoryUnitShipmentPlanningWeek,
    InventoryUnitShipmentPlanningWeekFromJSON,
    InventoryUnitShipmentPlanningWeekFromJSONTyped,
    InventoryUnitShipmentPlanningWeekToJSON,
} from './';

/**
 * 出荷予定数の情報
 * @export
 * @interface GetInventoryUnitShipmentPlanResponse
 */
export interface GetInventoryUnitShipmentPlanResponse {
    /**
     * 在庫管理単位名
     * @type {string}
     * @memberof GetInventoryUnitShipmentPlanResponse
     */
    inventoryUnitName: string;
    /**
     * JANコード
     * @type {string}
     * @memberof GetInventoryUnitShipmentPlanResponse
     */
    janCode: string;
    /**
     * 商品名
     * @type {string}
     * @memberof GetInventoryUnitShipmentPlanResponse
     */
    skuName: string | null;
    /**
     * 規格名
     * @type {string}
     * @memberof GetInventoryUnitShipmentPlanResponse
     */
    skuSpecName: string | null;
    /**
     * ETA 週の日付
     * @type {string}
     * @memberof GetInventoryUnitShipmentPlanResponse
     */
    etaWeekDate: string | null;
    /**
     * SKUxInventoryUnitが紐づく発注グループの担当者のユーザーID
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanResponse
     */
    operatorId: number;
    /**
     * SKUxInventoryUnitが紐づく発注グループのID
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanResponse
     */
    orderGroupId: number;
    /**
     * 直近4週誤差
     * @type {string}
     * @memberof GetInventoryUnitShipmentPlanResponse
     */
    errorLevelInLast4Weeks: GetInventoryUnitShipmentPlanResponseErrorLevelInLast4WeeksEnum;
    /**
     * 直近13週誤差
     * @type {string}
     * @memberof GetInventoryUnitShipmentPlanResponse
     */
    errorLevelInLast13Weeks: GetInventoryUnitShipmentPlanResponseErrorLevelInLast13WeeksEnum;
    /**
     * 補正対象かどうか
     * @type {boolean}
     * @memberof GetInventoryUnitShipmentPlanResponse
     */
    isAdjusted: boolean;
    /**
     * 直近4週の達成率
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanResponse
     */
    achievementRateIn4Weeks: number | null;
    /**
     * 直近13週の達成率
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanResponse
     */
    achievementRateIn13Weeks: number | null;
    /**
     * 直近4週の平均週販
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanResponse
     */
    averageSalesIn4Weeks: number | null;
    /**
     * 直近13週の平均週販
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanResponse
     */
    averageSalesIn13Weeks: number | null;
    /**
     * 直近4週の昨年対比
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanResponse
     */
    yearOverYearIn4Weeks: number | null;
    /**
     * 直近13週の昨年対比
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanResponse
     */
    yearOverYearIn13Weeks: number | null;
    /**
     * 売上構成比直近4週の達成率
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanResponse
     */
    salesCompositionAchievementRateIn4Weeks: number | null;
    /**
     * 売上構成比直近13週の達成率
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanResponse
     */
    salesCompositionAchievementRateIn13Weeks: number | null;
    /**
     * 売上構成比直近4週の平均週販
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanResponse
     */
    salesCompositionAverageSalesIn4Weeks: number | null;
    /**
     * 売上構成比直近13週の平均週販
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanResponse
     */
    salesCompositionAverageSalesIn13Weeks: number | null;
    /**
     * 売上構成比直近4週の昨年対比
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanResponse
     */
    salesCompositionYearOverYearIn4Weeks: number | null;
    /**
     * 売上構成比直近13週の昨年対比
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanResponse
     */
    salesCompositionYearOverYearIn13Weeks: number | null;
    /**
     * 
     * @type {AdjustmentType}
     * @memberof GetInventoryUnitShipmentPlanResponse
     */
    permanentAdjustmentType: AdjustmentType | null;
    /**
     * 永続化された補正条件の指定比率
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanResponse
     */
    permanentAdjustmentCustomRate: number;
    /**
     * 
     * @type {AdjustmentRange}
     * @memberof GetInventoryUnitShipmentPlanResponse
     */
    permanentAdjustmentRange: AdjustmentRange | null;
    /**
     * 永続化された補正条件の指定期間
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanResponse
     */
    permanentNumberOfWeeksToAdjust: number;
    /**
     * 
     * @type {AdjustmentType}
     * @memberof GetInventoryUnitShipmentPlanResponse
     */
    temporaryAdjustmentType: AdjustmentType | null;
    /**
     * 一時的に指定された補正条件の指定比率
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanResponse
     */
    temporaryAdjustmentCustomRate: number | null;
    /**
     * 
     * @type {AdjustmentRange}
     * @memberof GetInventoryUnitShipmentPlanResponse
     */
    temporaryAdjustmentRange: AdjustmentRange | null;
    /**
     * 一時的に指定された補正条件のの指定期間
     * @type {number}
     * @memberof GetInventoryUnitShipmentPlanResponse
     */
    temporaryNumberOfWeeksToAdjust: number | null;
    /**
     * 実績（過去）週
     * @type {Array<InventoryUnitShipmentPlanningWeek>}
     * @memberof GetInventoryUnitShipmentPlanResponse
     */
    pastWeeks: Array<InventoryUnitShipmentPlanningWeek>;
    /**
     * 計画（現在および未来）週
     * @type {Array<InventoryUnitShipmentPlanningWeek>}
     * @memberof GetInventoryUnitShipmentPlanResponse
     */
    presentAndFutureWeeks: Array<InventoryUnitShipmentPlanningWeek>;
    /**
     * 過去の出荷予定
     * @type {{ [key: string]: InventoryUnitShipmentPlanPerWeek; }}
     * @memberof GetInventoryUnitShipmentPlanResponse
     */
    pastShipmentCalendar: { [key: string]: InventoryUnitShipmentPlanPerWeek; };
    /**
     * 現在および未来のInventoryUnit出荷予定
     * @type {{ [key: string]: InventoryUnitShipmentPlanPerWeek; }}
     * @memberof GetInventoryUnitShipmentPlanResponse
     */
    presentAndFutureShipmentCalendar: { [key: string]: InventoryUnitShipmentPlanPerWeek; };
    /**
     * 
     * @type {AdjustmentTarget}
     * @memberof GetInventoryUnitShipmentPlanResponse
     */
    selectedDemandForecast: AdjustmentTarget;
}

/**
* @export
* @enum {string}
*/
export enum GetInventoryUnitShipmentPlanResponseErrorLevelInLast4WeeksEnum {
    High = 'high',
    Middle = 'middle',
    Low = 'low',
    Unavailable = 'unavailable'
}/**
* @export
* @enum {string}
*/
export enum GetInventoryUnitShipmentPlanResponseErrorLevelInLast13WeeksEnum {
    High = 'high',
    Middle = 'middle',
    Low = 'low',
    Unavailable = 'unavailable'
}

export function GetInventoryUnitShipmentPlanResponseFromJSON(json: any): GetInventoryUnitShipmentPlanResponse {
    return GetInventoryUnitShipmentPlanResponseFromJSONTyped(json, false);
}

export function GetInventoryUnitShipmentPlanResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetInventoryUnitShipmentPlanResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inventoryUnitName': json['inventoryUnitName'],
        'janCode': json['janCode'],
        'skuName': json['skuName'],
        'skuSpecName': json['skuSpecName'],
        'etaWeekDate': json['etaWeekDate'],
        'operatorId': json['operatorId'],
        'orderGroupId': json['orderGroupId'],
        'errorLevelInLast4Weeks': json['errorLevelInLast4Weeks'],
        'errorLevelInLast13Weeks': json['errorLevelInLast13Weeks'],
        'isAdjusted': json['isAdjusted'],
        'achievementRateIn4Weeks': json['achievementRateIn4Weeks'],
        'achievementRateIn13Weeks': json['achievementRateIn13Weeks'],
        'averageSalesIn4Weeks': json['averageSalesIn4Weeks'],
        'averageSalesIn13Weeks': json['averageSalesIn13Weeks'],
        'yearOverYearIn4Weeks': json['yearOverYearIn4Weeks'],
        'yearOverYearIn13Weeks': json['yearOverYearIn13Weeks'],
        'salesCompositionAchievementRateIn4Weeks': json['salesCompositionAchievementRateIn4Weeks'],
        'salesCompositionAchievementRateIn13Weeks': json['salesCompositionAchievementRateIn13Weeks'],
        'salesCompositionAverageSalesIn4Weeks': json['salesCompositionAverageSalesIn4Weeks'],
        'salesCompositionAverageSalesIn13Weeks': json['salesCompositionAverageSalesIn13Weeks'],
        'salesCompositionYearOverYearIn4Weeks': json['salesCompositionYearOverYearIn4Weeks'],
        'salesCompositionYearOverYearIn13Weeks': json['salesCompositionYearOverYearIn13Weeks'],
        'permanentAdjustmentType': AdjustmentTypeFromJSON(json['permanentAdjustmentType']),
        'permanentAdjustmentCustomRate': json['permanentAdjustmentCustomRate'],
        'permanentAdjustmentRange': AdjustmentRangeFromJSON(json['permanentAdjustmentRange']),
        'permanentNumberOfWeeksToAdjust': json['permanentNumberOfWeeksToAdjust'],
        'temporaryAdjustmentType': AdjustmentTypeFromJSON(json['temporaryAdjustmentType']),
        'temporaryAdjustmentCustomRate': json['temporaryAdjustmentCustomRate'],
        'temporaryAdjustmentRange': AdjustmentRangeFromJSON(json['temporaryAdjustmentRange']),
        'temporaryNumberOfWeeksToAdjust': json['temporaryNumberOfWeeksToAdjust'],
        'pastWeeks': ((json['pastWeeks'] as Array<any>).map(InventoryUnitShipmentPlanningWeekFromJSON)),
        'presentAndFutureWeeks': ((json['presentAndFutureWeeks'] as Array<any>).map(InventoryUnitShipmentPlanningWeekFromJSON)),
        'pastShipmentCalendar': (mapValues(json['pastShipmentCalendar'], InventoryUnitShipmentPlanPerWeekFromJSON)),
        'presentAndFutureShipmentCalendar': (mapValues(json['presentAndFutureShipmentCalendar'], InventoryUnitShipmentPlanPerWeekFromJSON)),
        'selectedDemandForecast': AdjustmentTargetFromJSON(json['selectedDemandForecast']),
    };
}

export function GetInventoryUnitShipmentPlanResponseToJSON(value?: GetInventoryUnitShipmentPlanResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inventoryUnitName': value.inventoryUnitName,
        'janCode': value.janCode,
        'skuName': value.skuName,
        'skuSpecName': value.skuSpecName,
        'etaWeekDate': value.etaWeekDate,
        'operatorId': value.operatorId,
        'orderGroupId': value.orderGroupId,
        'errorLevelInLast4Weeks': value.errorLevelInLast4Weeks,
        'errorLevelInLast13Weeks': value.errorLevelInLast13Weeks,
        'isAdjusted': value.isAdjusted,
        'achievementRateIn4Weeks': value.achievementRateIn4Weeks,
        'achievementRateIn13Weeks': value.achievementRateIn13Weeks,
        'averageSalesIn4Weeks': value.averageSalesIn4Weeks,
        'averageSalesIn13Weeks': value.averageSalesIn13Weeks,
        'yearOverYearIn4Weeks': value.yearOverYearIn4Weeks,
        'yearOverYearIn13Weeks': value.yearOverYearIn13Weeks,
        'salesCompositionAchievementRateIn4Weeks': value.salesCompositionAchievementRateIn4Weeks,
        'salesCompositionAchievementRateIn13Weeks': value.salesCompositionAchievementRateIn13Weeks,
        'salesCompositionAverageSalesIn4Weeks': value.salesCompositionAverageSalesIn4Weeks,
        'salesCompositionAverageSalesIn13Weeks': value.salesCompositionAverageSalesIn13Weeks,
        'salesCompositionYearOverYearIn4Weeks': value.salesCompositionYearOverYearIn4Weeks,
        'salesCompositionYearOverYearIn13Weeks': value.salesCompositionYearOverYearIn13Weeks,
        'permanentAdjustmentType': AdjustmentTypeToJSON(value.permanentAdjustmentType),
        'permanentAdjustmentCustomRate': value.permanentAdjustmentCustomRate,
        'permanentAdjustmentRange': AdjustmentRangeToJSON(value.permanentAdjustmentRange),
        'permanentNumberOfWeeksToAdjust': value.permanentNumberOfWeeksToAdjust,
        'temporaryAdjustmentType': AdjustmentTypeToJSON(value.temporaryAdjustmentType),
        'temporaryAdjustmentCustomRate': value.temporaryAdjustmentCustomRate,
        'temporaryAdjustmentRange': AdjustmentRangeToJSON(value.temporaryAdjustmentRange),
        'temporaryNumberOfWeeksToAdjust': value.temporaryNumberOfWeeksToAdjust,
        'pastWeeks': ((value.pastWeeks as Array<any>).map(InventoryUnitShipmentPlanningWeekToJSON)),
        'presentAndFutureWeeks': ((value.presentAndFutureWeeks as Array<any>).map(InventoryUnitShipmentPlanningWeekToJSON)),
        'pastShipmentCalendar': (mapValues(value.pastShipmentCalendar, InventoryUnitShipmentPlanPerWeekToJSON)),
        'presentAndFutureShipmentCalendar': (mapValues(value.presentAndFutureShipmentCalendar, InventoryUnitShipmentPlanPerWeekToJSON)),
        'selectedDemandForecast': AdjustmentTargetToJSON(value.selectedDemandForecast),
    };
}


