/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for OMR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 商品区分
 * @export
 * @enum {string}
 */
export enum TypesOfMerchandise {
    Seasonal = 'seasonal',
    Staple = 'staple'
}

export function TypesOfMerchandiseFromJSON(json: any): TypesOfMerchandise {
    return TypesOfMerchandiseFromJSONTyped(json, false);
}

export function TypesOfMerchandiseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TypesOfMerchandise {
    return json as TypesOfMerchandise;
}

export function TypesOfMerchandiseToJSON(value?: TypesOfMerchandise | null): any {
    return value as any;
}

