import styled from 'styled-components'

const CountLabel = styled.div`
  text-align: right;
  margin-left: auto;
  margin-right: 0;
`

const DragAndDropArea = styled.div`
  align-items: center;
  border-color: #8e8e8e;
  border-style: dashed;
  border-width: 1px;
  display: flex;
  height: 185px;
  justify-content: center;
  margin-top: 27px;
`

const DragAndDropDescription = styled.div`
  text-align: center;
`

const DragAndDropInput = styled.input`
  display: none;
`

const DragAndDropLink = styled.label`
  color: var(--di-text-link-color);
  cursor: pointer;
`

const LoadingSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  padding-bottom: 30px;
`

const NotificationFieldContainer = styled.div`
  margin-left: 32px;
  margin-right: 32px;
`

const Panel = styled.div`
  background-color: #fff;
  min-height: 300px;
  margin-bottom: 24px;
  margin-left: 32px;
  margin-right: 32px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 19px;
`

const PanelDescription = styled.span`
  font-size: 13px;
  margin-left: 30px;
`

const PanelTitle = styled.h3`
  margin-bottom: 7px;
  margin-left: 32px;
  margin-top: 27px;
`

const ShipmentReasonsTableContainer = styled.div`
  margin-left: 32px;
  margin-right: 32px;
`

const ShipmentReasonsTableTitleContainer = styled.div`
  align-items: center;
  display: flex;
  margin-left: 32px;
  margin-right: 32px;
  margin-bottom: 10px;
  margin-top: 30px;
`

const ToastContainer = styled.div`
  position: absolute;
  right: 22px;
  top: 107px;
  z-index: 399;
`

export {
  CountLabel,
  DragAndDropArea,
  DragAndDropDescription,
  DragAndDropInput,
  DragAndDropLink,
  LoadingSpinnerContainer,
  NotificationFieldContainer,
  Panel,
  PanelDescription,
  PanelTitle,
  ShipmentReasonsTableContainer,
  ShipmentReasonsTableTitleContainer,
  ToastContainer,
}
