import React from 'react'

import {
  InventoryUnitReceiving,
  DraftPurchaseOrderPlanningInventoryUnitReceiving,
  DraftPurchaseOrderPlanningUnconfirmedInventoryUnitReceiving,
  DraftPurchaseOrderPlanningUnconfirmedInventoryUnitReceivingPurchaseOrderTypeEnum,
  DraftPurchaseOrderUnreceivedOrder,
  UnreceivedOrder,
  Week,
} from '../../../api-client'

import { Background, CancelIcon, CancelIconContainer, Container, NumbersContainer, Title, Window } from './style'

export interface PurchaseOrdersModalWindowProps {
  /**
   * 未確定の発注案のInventoryUnit入荷予定
   */
  unconfirmedInventoryUnitReceivings: Array<DraftPurchaseOrderPlanningUnconfirmedInventoryUnitReceiving>

  /**
   * 確定済の発注案のInventoryUnit入荷予定
   */
  inventoryUnitReceivings: InventoryUnitReceiving[] | DraftPurchaseOrderPlanningInventoryUnitReceiving[]

  /**
   * 入荷残数
   */
  unreceivedOrders: UnreceivedOrder[] | DraftPurchaseOrderUnreceivedOrder[]

  /**
   * ヤード残数
   */
  yardQuantity: number | null | undefined

  thisWeek: Week

  onCloseHandler: () => void
}

const dateToString = (d: Date) => `${d.getMonth() + 1}/${d.getDate()}`

export const PurchaseOrdersModalWindow: React.FC<PurchaseOrdersModalWindowProps> = ({
  unconfirmedInventoryUnitReceivings,
  inventoryUnitReceivings,
  unreceivedOrders,
  onCloseHandler,
  yardQuantity,
  thisWeek,
}) => {
  const purchaseOrderTypeToLabel = (
    type: DraftPurchaseOrderPlanningUnconfirmedInventoryUnitReceivingPurchaseOrderTypeEnum
  ) => {
    switch (type) {
      case DraftPurchaseOrderPlanningUnconfirmedInventoryUnitReceivingPurchaseOrderTypeEnum.Normal:
        return '通常'
      case DraftPurchaseOrderPlanningUnconfirmedInventoryUnitReceivingPurchaseOrderTypeEnum.MixedPurchaseOrder:
        return 'PO混載'
      case DraftPurchaseOrderPlanningUnconfirmedInventoryUnitReceivingPurchaseOrderTypeEnum.MultipleWeeks:
        return '複数週まとめ'
      case DraftPurchaseOrderPlanningUnconfirmedInventoryUnitReceivingPurchaseOrderTypeEnum.MixedPurchaseOrderAndmultipleWeeks:
        return 'PO混載・複数週'
      case DraftPurchaseOrderPlanningUnconfirmedInventoryUnitReceivingPurchaseOrderTypeEnum.LandTransportation:
        return '陸送'
      default:
        // TODO never句追加
        return ''
    }
  }

  const poList: React.ReactElement[] = []
  // PO残から格納する
  unreceivedOrders.map((UnreceivedOrder) => (
      poList.push(<p>
        P/O No.{UnreceivedOrder.inventoryUnitReceivings.purchaseOrderNumber}
        （入庫予定週 {dateToString(UnreceivedOrder.date)}）：
        {UnreceivedOrder.inventoryUnitReceivings.quantity.toLocaleString()}
      </p>)
  ))
  inventoryUnitReceivings.map((plannedInventoryUnitReceiving) => (
      poList.push(<p >
        P/O No.{plannedInventoryUnitReceiving.purchaseOrderNumber}
        （入庫予定週 {thisWeek.date}）：
        {plannedInventoryUnitReceiving.quantity.toLocaleString()}
      </p>)
  ))
  unconfirmedInventoryUnitReceivings.map((receiving ) => (
      poList.push(<p>
        {purchaseOrderTypeToLabel(receiving.purchaseOrderType)}
        {(receiving as DraftPurchaseOrderPlanningUnconfirmedInventoryUnitReceiving).containerType != null
            ? `（${
                (receiving as DraftPurchaseOrderPlanningUnconfirmedInventoryUnitReceiving).containerType
            }）`
            : ''}
        ：{receiving.quantity.toLocaleString()}
      </p>)
  ))

  return <Background onClick={() => onCloseHandler()}>
    <Window>
      <CancelIconContainer onClick={() => onCloseHandler()} data-testid="purchase-orders-modal-window-close-icon">
        <CancelIcon />
      </CancelIconContainer>
      <Container onClick={(e) => e.stopPropagation()} data-testid="purchase-orders-modal-window-body">
        {poList.length > 0 && <>
          <Title>■P/O 詳細</Title>
            <NumbersContainer>
              {poList.map((element ) => element)}
            </NumbersContainer>
          </>}
        {yardQuantity != null && <>
            <Title>■ヤード残数</Title>
            <NumbersContainer>{yardQuantity}</NumbersContainer>
          </>}
      </Container>
    </Window>
  </Background>
}
