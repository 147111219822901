import React, { useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import cloneDeep from 'lodash/cloneDeep'

import { User, UserRole } from '../../api-client'

import { useInventoryPlan } from '../../hooks'

import { LoadingSpinner, PageHeader } from '../../components/atoms'

import { SKUTotalInventoryCalendarTable, SimulationResetConfirmationModal } from '../../components/molecules'

import { DistributionCenterSKUPanel, SearchInventoryUnitSKUForm } from '../../components/organisms'

import {
  DistributionCenterCard,
  DistributionCenterCardLabel,
  DistributionCenterName,
  DistributionCentersContainer,
  DistributionCenterSKUPanelContainer,
  DistributionCenterSKUPanelsContainer,
  HasDraftPurchaseOrderMessagePanel,
  LoadingSpinnerContainer,
  MainContentsContainer,
  OperatorName,
  SKUCard,
  SKUCode,
  SKUHeader,
  SKUName,
  SKUSpec,
  SKUsContainer,
  PageContainer,
  TabBody,
  TabHeader,
  TabHeaderContainer,
  CollectiveAdjustContainer,
} from './style'
import { useSearch } from './useSearch.hooks'
import { useSimulation } from './useSimulation.hooks'

interface PathParams {
  orderGroupId: string
}

export interface InventoryPlanProps {
  currentUser: User
}

export const InventoryPlan: React.FC<InventoryPlanProps> = ({ currentUser }) => {
  const { orderGroupId } = useParams<PathParams>()
  const orderGroupIdNum = Number.parseInt(orderGroupId)
  const forceUpdate = useState(false)[1]

  const { inventoryPlan, getCSVFile, putSimulationParameters, deleteSimulationParameters } =
    useInventoryPlan(orderGroupIdNum)

  const inventoryPlanRef = useRef(cloneDeep(inventoryPlan))

  const downloadCSVFile = () => {
    getCSVFile(orderGroupIdNum)?.then(({ filename, blob }) => {
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename != null ? filename : '')
      document.body.appendChild(link)
      link.click()
      link.parentNode?.removeChild(link)
    })
  }

  // common states
  const [highlightedWeekDate, setHighlightedWeekDate] = useState('')
  const [inventoryCalendarTableScrollLeft, setInventoryCalendarTableScrollLeft] = useState(0)
  const [simulationResetConfirmationModalVisible, setSimulationResetConfirmationModalVisible] = useState(false)

  const [CommonSearch, SKUSearch, InventoryUnitSearch] = useSearch(inventoryPlan, inventoryPlanRef, forceUpdate)

  const [simulation] = useSimulation(inventoryPlan, inventoryPlanRef)

  return (
    <PageContainer>
      <PageHeader
        pageTitle={`発注グループ：${inventoryPlanRef.current != null ? inventoryPlanRef.current.orderGroup.name : ''}`}
        backLink={true}
      />
      {inventoryPlanRef.current != null && inventoryPlanRef.current.orderGroup.hasDraftPurchaseOrder === true ? (
        <HasDraftPurchaseOrderMessagePanel>
          <Link to={`/order-groups/${orderGroupIdNum}/draft-purchase-order`}>発注案があります。</Link>
        </HasDraftPurchaseOrderMessagePanel>
      ) : null}
      <TabHeaderContainer>
        <TabHeader
          active={CommonSearch.selectedTab === 'sku'}
          onClick={() => CommonSearch.setSelectedTab('sku')}
          data-testid="sku-tab-header"
        >
          SKU別
        </TabHeader>
        <TabHeader
          active={CommonSearch.selectedTab === 'distribution-center'}
          onClick={() => CommonSearch.setSelectedTab('distribution-center')}
          data-testid="distribution-center-tab-header"
        >
          在庫管理単位別
        </TabHeader>
        <OperatorName>
          担当者：
          {inventoryPlanRef.current != null ? inventoryPlanRef.current.orderGroup.operatorName : ''}
        </OperatorName>
      </TabHeaderContainer>
      <TabBody>
        {inventoryPlan != null && inventoryPlanRef.current != null ? (
          <React.Fragment>
            {CommonSearch.selectedTab === 'sku' ? (
              <div>
                <SKUsContainer>
                  {inventoryPlanRef.current.skus.map((sku, idx) => {
                    return (
                      <SKUCard
                        key={idx}
                        selected={idx === SKUSearch.selectedSKUIndex}
                        onClick={() => {
                          SKUSearch.setSelectedSKUIndex(idx)
                        }}
                        data-testid={`sku-card-${sku.code}`}
                      >
                        <div>{sku.code}</div>
                        <div>
                          {sku.name != null && sku.specName != null
                            ? `${sku.name}・${sku.specName}`
                            : sku.name != null && sku.specName == null
                            ? sku.name
                            : sku.name == null && sku.specName != null
                            ? sku.specName
                            : '-'}
                        </div>
                      </SKUCard>
                    )
                  })}
                </SKUsContainer>
              </div>
            ) : null}

            {CommonSearch.selectedTab === 'distribution-center' ? (
              <div>
                <DistributionCentersContainer>
                  <DistributionCenterCard
                    selected={
                      InventoryUnitSearch.allDistributionCentersSelected.current === true &&
                      InventoryUnitSearch.selectedDistributionCenterIndex.current === -1
                    }
                    onClick={() => {
                      // 「Total」以外は非選択状態にする
                      InventoryUnitSearch.allDistributionCentersSelected.current = true
                      InventoryUnitSearch.selectedDistributionCenterIndex.current = -1
                      forceUpdate((v) => !v)
                    }}
                    data-testid="distribution-center-card-all"
                  >
                    <DistributionCenterCardLabel>Total</DistributionCenterCardLabel>
                  </DistributionCenterCard>
                  {inventoryPlanRef.current.inventoryUnits.map((inventoryUnit, idx) => {
                    return (
                      <DistributionCenterCard
                        key={idx}
                        selected={
                          InventoryUnitSearch.allDistributionCentersSelected.current === false &&
                          InventoryUnitSearch.selectedDistributionCenterIndex.current === idx
                        }
                        onClick={() => {
                          // 国内InventoryUnit以外は非選択状態にする
                          InventoryUnitSearch.allDistributionCentersSelected.current = false
                          InventoryUnitSearch.selectedDistributionCenterIndex.current = idx
                          forceUpdate((v) => !v)
                        }}
                        data-testid={`distribution-center-card-${inventoryUnit.code}`}
                      >
                        <DistributionCenterCardLabel>{`${inventoryUnit.name}(${inventoryUnit.logisticPatternName})`}</DistributionCenterCardLabel>
                      </DistributionCenterCard>
                    )
                  })}
                </DistributionCentersContainer>
              </div>
            ) : null}

            <MainContentsContainer>
              {CommonSearch.selectedTab === 'sku' ? (
                <DistributionCenterSKUPanelsContainer>
                  {inventoryPlanRef.current.inventoryUnitSKUs.filter(SKUSearch.shouldInventoryPlanVisibleForSKUTab)
                    .length + inventoryPlanRef.current.skuTotals.filter(SKUSearch.shouldTotalInventoryPlanVisibleForSKUTab)
                      .length === 0 ? (null) : //「検索結果がありません」表示時は一括補正のリンクも表示しない
                      <CollectiveAdjustContainer>
                        {inventoryPlanRef.current.skus.map((sku, idx) => {
                          if (idx === SKUSearch.selectedSKUIndex) {
                            return (
                            <Link to="#"
                              onClick={()=>
                                window.open(`/skus/${sku.code}/shipment-plan`)
                              }>
                              JANコード:{sku.code}の一括補正
                            </Link>
                            )
                          }
                          return ("")
                        }, {})}
                      </CollectiveAdjustContainer>
                  }
                  <SearchInventoryUnitSKUForm
                    searchTextBoxVisible={false}
                    defaultValueForErrorLebelLowInLast4Weeks={SKUSearch.filterByErrorLebelLowInLast4WeeksForSKUTab}
                    defaultValueForErrorLebelMiddleInLast4Weeks={
                      SKUSearch.filterByErrorLebelMiddleInLast4WeeksForSKUTab
                    }
                    defaultValueForErrorLebelHighInLast4Weeks={SKUSearch.filterByErrorLebelHighInLast4WeeksForSKUTab}
                    defaultValueForrrorLebelLowInLast13Weeks={SKUSearch.filterByErrorLebelLowInLast13WeeksForSKUTab}
                    defaultValueForErrorLebelMiddleInLast13Weeks={
                      SKUSearch.filterByErrorLebelMiddleInLast13WeeksForSKUTab
                    }
                    defaultValueForErrorLebelHighInLast13Weeks={SKUSearch.filterByErrorLebelHighInLast13WeeksForSKUTab}
                    filterByAdjustmentTarget={SKUSearch.filterByAdjustmentTargetForSKUTab}
                    filterBySimulatedValue={SKUSearch.filterBySimulatedValueForSKUTab}
                    numberOfHits={
                      inventoryPlanRef.current.inventoryUnitSKUs.filter(SKUSearch.shouldInventoryPlanVisibleForSKUTab)
                        .length +
                      inventoryPlanRef.current.skuTotals.filter(SKUSearch.shouldTotalInventoryPlanVisibleForSKUTab)
                        .length
                    }
                    simulationButtonDisabled={
                      (currentUser.role !== UserRole.Manager &&
                        currentUser.id !== inventoryPlan.orderGroup.operatorId) ||
                      (simulation.simulationSubmitAvailable() === false &&
                        simulation.simulationResetAvailable() === false)
                    }
                    simulationSubmitButtonVisible={simulation.simulationSubmitAvailable()}
                    simulationResetButtonVisible={simulation.simulationResetAvailable()}
                    onSearchTextChangeHandler={() => {}}
                    onErrorLebelLowInLast4WeeksChangeHandler={SKUSearch.setFilterByErrorLebelLowInLast4WeeksForSKUTab}
                    onErrorLebelMiddleInLast4WeeksChangeHandler={
                      SKUSearch.setFilterByErrorLebelMiddleInLast4WeeksForSKUTab
                    }
                    onErrorLebelHighInLast4WeeksChangeHandler={SKUSearch.setFilterByErrorLebelHighInLast4WeeksForSKUTab}
                    onErrorLebelLowInLast13WeeksChangeHandler={SKUSearch.setFilterByErrorLebelLowInLast13WeeksForSKUTab}
                    onErrorLebelMiddleInLast13WeeksChangeHandler={
                      SKUSearch.setFilterByErrorLebelMiddleInLast13WeeksForSKUTab
                    }
                    onErrorLebelHighInLast13WeeksChangeHandler={
                      SKUSearch.setFilterByErrorLebelHighInLast13WeeksForSKUTab
                    }
                    onFilterByAdjustmentTargetChangeHandler={SKUSearch.setFilterByAdjustmentTargetForSKUTab}
                    onFilterBySimulatedValueChangeHandler={SKUSearch.setFilterBySimulatedValueForSKUTab}
                    onSimulationSubmitHandler={() => {
                      if (inventoryPlanRef.current == null) {
                        return
                      }
                      putSimulationParameters(orderGroupIdNum, inventoryPlanRef.current)
                    }}
                    onSimulationResetHandler={() => setSimulationResetConfirmationModalVisible(true)}
                    onDownloadButtonClickHandler={downloadCSVFile}
                  />

                  {inventoryPlanRef.current.inventoryUnitSKUs.filter(SKUSearch.shouldInventoryPlanVisibleForSKUTab)
                    .length +
                    inventoryPlanRef.current.skuTotals.filter(SKUSearch.shouldTotalInventoryPlanVisibleForSKUTab)
                      .length ===
                  0 ? (
                    <DistributionCenterSKUPanelContainer>検索結果がありません。</DistributionCenterSKUPanelContainer>
                  ) : null}

                  {inventoryPlanRef.current.skuTotals.map((skuTotal, idx) => {
                    if (SKUSearch.shouldTotalInventoryPlanVisibleForSKUTab(skuTotal) === false) {
                      return null
                    }

                    return (
                      <DistributionCenterSKUPanelContainer key={idx}>
                        <DistributionCenterName>【Total】</DistributionCenterName>
                        <SKUTotalInventoryCalendarTable
                          inventoryCalendar={skuTotal.inventoryCalendar}
                          yardQuantity={skuTotal.yardQuantity}
                          weeks={inventoryPlanRef.current != null ? inventoryPlanRef.current.weeks : []}
                          highlightedWeekDate={highlightedWeekDate}
                          scrollLeft={inventoryCalendarTableScrollLeft}
                          onHighlightedWeekDateChangeHandler={(weekDate) => {
                            if (weekDate === highlightedWeekDate) {
                              // No highlight
                              setHighlightedWeekDate('')
                            } else {
                              setHighlightedWeekDate(weekDate)
                            }
                          }}
                          onScrollHandler={(scrollLeft) => {
                            setInventoryCalendarTableScrollLeft(scrollLeft)
                          }}
                        />
                      </DistributionCenterSKUPanelContainer>
                    )
                  })}

                  {inventoryPlanRef.current.inventoryUnitSKUs.map((inventoryUnitSKU, idx) => {
                    if (inventoryPlanRef.current == null) {
                      return null
                    }

                    if (SKUSearch.shouldInventoryPlanVisibleForSKUTab(inventoryUnitSKU) === false) {
                      return null
                    }

                    const inventoryUnit = inventoryPlanRef.current.inventoryUnits.find(
                      (inventoryUnit) => inventoryUnit.code === inventoryUnitSKU.inventoryUnitCode
                    )
                    if (inventoryUnit == null) {
                      return null
                    }

                    return (
                      <DistributionCenterSKUPanelContainer key={idx}>
                        <DistributionCenterName>
                          {inventoryUnit.name}({inventoryUnit.logisticPatternName})
                        </DistributionCenterName>
                        <DistributionCenterSKUPanel
                          inventoryUnitSKU={inventoryUnitSKU}
                          inventoryUnitSKUOrigin={inventoryPlan.inventoryUnitSKUs[idx]}
                          weeks={inventoryPlanRef.current != null ? inventoryPlanRef.current.weeks : []}
                          highlightedWeekDate={highlightedWeekDate}
                          inventoryCalendarTableScrollLeft={inventoryCalendarTableScrollLeft}
                          readonly={
                            currentUser.role !== UserRole.Manager &&
                            currentUser.id !== inventoryPlan.orderGroup.operatorId
                          }
                          onHighlightedWeekDateChangeHandler={(weekDate) => {
                            if (weekDate === highlightedWeekDate) {
                              // No highlight
                              setHighlightedWeekDate('')
                            } else {
                              setHighlightedWeekDate(weekDate)
                            }
                          }}
                          onInventoryCalendarTableScrollLeftHandler={(scrollLeft) => {
                            setInventoryCalendarTableScrollLeft(scrollLeft)
                          }}
                          onReceivingQuantityForSimulationChangeHandler={(weekDate, quantity) => {
                            if (
                              inventoryUnitSKU.inventoryCalendar[weekDate].receivingQuantityForSimulation !== quantity
                            ) {
                              inventoryUnitSKU.inventoryCalendar[weekDate].receivingQuantityForSimulation = quantity
                              forceUpdate((v) => !v)
                            }
                          }}
                        />
                      </DistributionCenterSKUPanelContainer>
                    )
                  })}
                </DistributionCenterSKUPanelsContainer>
              ) : null}

              {CommonSearch.selectedTab === 'distribution-center' ? (
                <DistributionCenterSKUPanelsContainer>
                  {InventoryUnitSearch.allDistributionCentersSelected.current === true &&
                  InventoryUnitSearch.selectedDistributionCenterIndex.current === -1 ? (
                    <SearchInventoryUnitSKUForm
                      searchText={InventoryUnitSearch.filterByTextForInventoryUnitTab}
                      defaultValueForErrorLebelLowInLast4Weeks={false}
                      defaultValueForErrorLebelMiddleInLast4Weeks={false}
                      defaultValueForErrorLebelHighInLast4Weeks={false}
                      defaultValueForrrorLebelLowInLast13Weeks={false}
                      defaultValueForErrorLebelMiddleInLast13Weeks={false}
                      defaultValueForErrorLebelHighInLast13Weeks={false}
                      dropdownDisabled={true}
                      filterByAdjustmentTarget={false}
                      filterBySimulatedValue={false}
                      checkBoxDisabled={true}
                      numberOfHits={
                        inventoryPlanRef.current.skuTotals.filter((skuTotal) => {
                          if (inventoryPlanRef.current == null) {
                            return false
                          }
                          const sku = inventoryPlanRef.current.skus.find((sku) => sku.code === skuTotal.janCode)
                          return (
                            sku != null &&
                            (InventoryUnitSearch.filterByTextForInventoryUnitTab === '' ||
                              sku.code.includes(InventoryUnitSearch.filterByTextForInventoryUnitTab) ||
                              (sku.name != null &&
                                sku.name.includes(InventoryUnitSearch.filterByTextForInventoryUnitTab)) ||
                              (sku.specName != null &&
                                sku.specName.includes(InventoryUnitSearch.filterByTextForInventoryUnitTab)))
                          )
                        }).length
                      }
                      simulationButtonDisabled={true}
                      simulationSubmitButtonVisible={false}
                      simulationResetButtonVisible={false}
                      onSearchTextChangeHandler={InventoryUnitSearch.setFilterByTextForInventoryUnitTab}
                      onErrorLebelLowInLast4WeeksChangeHandler={() => {}}
                      onErrorLebelMiddleInLast4WeeksChangeHandler={() => {}}
                      onErrorLebelHighInLast4WeeksChangeHandler={() => {}}
                      onErrorLebelLowInLast13WeeksChangeHandler={() => {}}
                      onErrorLebelMiddleInLast13WeeksChangeHandler={() => {}}
                      onErrorLebelHighInLast13WeeksChangeHandler={() => {}}
                      onFilterByAdjustmentTargetChangeHandler={() => {}}
                      onFilterBySimulatedValueChangeHandler={() => {}}
                      onSimulationSubmitHandler={() => {}}
                      onSimulationResetHandler={() => {}}
                      onDownloadButtonClickHandler={downloadCSVFile}
                    />
                  ) : null}

                  {InventoryUnitSearch.allDistributionCentersSelected.current === false &&
                  InventoryUnitSearch.selectedDistributionCenterIndex.current > -1 ? (
                    <SearchInventoryUnitSKUForm
                      searchText={InventoryUnitSearch.filterByTextForInventoryUnitTab}
                      defaultValueForErrorLebelLowInLast4Weeks={
                        InventoryUnitSearch.filterByErrorLebelLowInLast4WeeksForInventoryUnitTab
                      }
                      defaultValueForErrorLebelMiddleInLast4Weeks={
                        InventoryUnitSearch.filterByErrorLebelMiddleInLast4WeeksForInventoryUnitTab
                      }
                      defaultValueForErrorLebelHighInLast4Weeks={
                        InventoryUnitSearch.filterByErrorLebelHighInLast4WeeksForInventoryUnitTab
                      }
                      defaultValueForrrorLebelLowInLast13Weeks={
                        InventoryUnitSearch.filterByErrorLebelLowInLast13WeeksForInventoryUnitTab
                      }
                      defaultValueForErrorLebelMiddleInLast13Weeks={
                        InventoryUnitSearch.filterByErrorLebelMiddleInLast13WeeksForInventoryUnitTab
                      }
                      defaultValueForErrorLebelHighInLast13Weeks={
                        InventoryUnitSearch.filterByErrorLebelHighInLast13WeeksForInventoryUnitTab
                      }
                      filterByAdjustmentTarget={InventoryUnitSearch.filterByAdjustmentTargetForInventoryUnitTab}
                      filterBySimulatedValue={InventoryUnitSearch.filterBySimulatedValueForInventoryUnitTab}
                      numberOfHits={
                        inventoryPlanRef.current.inventoryUnitSKUs.filter((inventoryUnitSKU) => {
                          if (inventoryPlanRef.current == null) {
                            return false
                          }
                          const sku = inventoryPlanRef.current.skus.find((sku) => sku.code === inventoryUnitSKU.janCode)
                          return (
                            // 選択中の国内InventoryUnitに含まれているかどうか
                            // TODO inventoryUnitSKU
                            InventoryUnitSearch.selectedDistributionCenterIndex.current >= 0 &&
                            inventoryUnitSKU.inventoryUnitCode ===
                              inventoryPlanRef.current.inventoryUnits[
                                InventoryUnitSearch.selectedDistributionCenterIndex.current
                              ].code &&
                            sku != null &&
                            // SKUの情報が検索文字列にヒットするかどうか
                            (InventoryUnitSearch.filterByTextForInventoryUnitTab === '' ||
                              sku.code.includes(InventoryUnitSearch.filterByTextForInventoryUnitTab) ||
                              (sku.name != null &&
                                sku.name.includes(InventoryUnitSearch.filterByTextForInventoryUnitTab)) ||
                              (sku.specName != null &&
                                sku.specName.includes(InventoryUnitSearch.filterByTextForInventoryUnitTab))) &&
                            // 誤差の値でフィルタリング
                            InventoryUnitSearch.isErrorLevelIncludedForInventoryUnitTab(
                              inventoryUnitSKU.errorLevelInLast4Weeks,
                              inventoryUnitSKU.errorLevelInLast13Weeks
                            ) &&
                            // 補正なしかどうかでフィルタリング
                            (InventoryUnitSearch.filterByAdjustmentTargetForInventoryUnitTab === false ||
                              inventoryUnitSKU.isAdjusted === false) &&
                            // シミュレーション値があるかどうかでフィルタリング
                            (InventoryUnitSearch.filterBySimulatedValueForInventoryUnitTab === false ||
                              Object.values(inventoryUnitSKU.inventoryCalendar).some(
                                (inventoryPlan) => inventoryPlan.receivingQuantityForSimulation != null
                              ) === true)
                          )
                        }).length
                      }
                      simulationButtonDisabled={
                        (currentUser.role !== UserRole.Manager &&
                          currentUser.id !== inventoryPlan.orderGroup.operatorId) ||
                        (simulation.simulationSubmitAvailable() === false &&
                          simulation.simulationResetAvailable() === false)
                      }
                      simulationSubmitButtonVisible={simulation.simulationSubmitAvailable()}
                      simulationResetButtonVisible={simulation.simulationResetAvailable()}
                      onSearchTextChangeHandler={InventoryUnitSearch.setFilterByTextForInventoryUnitTab}
                      onErrorLebelLowInLast4WeeksChangeHandler={
                        InventoryUnitSearch.setFilterByErrorLebelLowInLast4WeeksForInventoryUnitTab
                      }
                      onErrorLebelMiddleInLast4WeeksChangeHandler={
                        InventoryUnitSearch.setFilterByErrorLebelMiddleInLast4WeeksForInventoryUnitTab
                      }
                      onErrorLebelHighInLast4WeeksChangeHandler={
                        InventoryUnitSearch.setFilterByErrorLebelHighInLast4WeeksForInventoryUnitTab
                      }
                      onErrorLebelLowInLast13WeeksChangeHandler={
                        InventoryUnitSearch.setFilterByErrorLebelLowInLast13WeeksForInventoryUnitTab
                      }
                      onErrorLebelMiddleInLast13WeeksChangeHandler={
                        InventoryUnitSearch.setFilterByErrorLebelMiddleInLast13WeeksForInventoryUnitTab
                      }
                      onErrorLebelHighInLast13WeeksChangeHandler={
                        InventoryUnitSearch.setFilterByErrorLebelHighInLast13WeeksForInventoryUnitTab
                      }
                      onFilterByAdjustmentTargetChangeHandler={
                        InventoryUnitSearch.setFilterByAdjustmentTargetForInventoryUnitTab
                      }
                      onFilterBySimulatedValueChangeHandler={
                        InventoryUnitSearch.setFilterBySimulatedValueForInventoryUnitTab
                      }
                      onSimulationSubmitHandler={() => {
                        if (inventoryPlanRef.current == null) {
                          return
                        }
                        putSimulationParameters(orderGroupIdNum, inventoryPlanRef.current)
                      }}
                      onSimulationResetHandler={() => setSimulationResetConfirmationModalVisible(true)}
                      onDownloadButtonClickHandler={downloadCSVFile}
                    />
                  ) : null}

                  {InventoryUnitSearch.allDistributionCentersSelected.current === true &&
                  InventoryUnitSearch.selectedDistributionCenterIndex.current === -1 ? (
                    // Totalの場合
                    <React.Fragment>
                      {inventoryPlanRef.current.skuTotals.filter((skuTotal) => {
                        if (inventoryPlanRef.current == null) {
                          return false
                        }
                        const sku = inventoryPlanRef.current.skus.find((sku) => sku.code === skuTotal.janCode)
                        if (sku == null) {
                          return false
                        }
                        return (
                          InventoryUnitSearch.filterByTextForInventoryUnitTab === '' ||
                          sku.code.includes(InventoryUnitSearch.filterByTextForInventoryUnitTab) ||
                          (sku.name != null &&
                            sku.name.includes(InventoryUnitSearch.filterByTextForInventoryUnitTab)) ||
                          (sku.specName != null &&
                            sku.specName.includes(InventoryUnitSearch.filterByTextForInventoryUnitTab))
                        )
                      }).length === 0 ? (
                        <DistributionCenterSKUPanelContainer>
                          検索結果がありません。
                        </DistributionCenterSKUPanelContainer>
                      ) : (
                        inventoryPlanRef.current.skuTotals
                          .filter((skuTotal) => {
                            if (inventoryPlanRef.current == null) {
                              return false
                            }
                            const sku = inventoryPlanRef.current.skus.find((sku) => sku.code === skuTotal.janCode)
                            if (sku == null) {
                              return false
                            }
                            return (
                              InventoryUnitSearch.filterByTextForInventoryUnitTab === '' ||
                              sku.code.includes(InventoryUnitSearch.filterByTextForInventoryUnitTab) ||
                              (sku.name != null &&
                                sku.name.includes(InventoryUnitSearch.filterByTextForInventoryUnitTab)) ||
                              (sku.specName != null &&
                                sku.specName.includes(InventoryUnitSearch.filterByTextForInventoryUnitTab))
                            )
                          }).sort((a, b) => a.janCode > b.janCode ? 1 : -1)
                          .map((skuTotal, idx) => {
                            if (inventoryPlanRef.current == null) {
                              return null
                            }
                            const sku = inventoryPlanRef.current.skus.find((sku) => sku.code === skuTotal.janCode)
                            if (sku == null) {
                              return null
                            }
                            return (
                              <DistributionCenterSKUPanelContainer key={idx}>
                                <SKUHeader>
                                  <SKUCode>{sku.code}</SKUCode>
                                  {sku.name != null ? <SKUName>{sku.name}</SKUName> : null}
                                  {sku.specName != null ? <SKUSpec>{sku.specName}</SKUSpec> : null}
                                  {sku.name == null && sku.specName == null ? <b>-</b> : null}
                                </SKUHeader>
                                <SKUTotalInventoryCalendarTable
                                  inventoryCalendar={skuTotal.inventoryCalendar}
                                  yardQuantity={skuTotal.yardQuantity}
                                  weeks={inventoryPlanRef.current != null ? inventoryPlanRef.current.weeks : []}
                                  highlightedWeekDate={highlightedWeekDate}
                                  scrollLeft={inventoryCalendarTableScrollLeft}
                                  onHighlightedWeekDateChangeHandler={(weekDate) => {
                                    if (weekDate === highlightedWeekDate) {
                                      // No highlight
                                      setHighlightedWeekDate('')
                                    } else {
                                      setHighlightedWeekDate(weekDate)
                                    }
                                  }}
                                  onScrollHandler={(scrollLeft) => {
                                    setInventoryCalendarTableScrollLeft(scrollLeft)
                                  }}
                                />
                              </DistributionCenterSKUPanelContainer>
                            )
                          })
                      )}
                    </React.Fragment>
                  ) : null}

                  {InventoryUnitSearch.allDistributionCentersSelected.current === false &&
                  InventoryUnitSearch.selectedDistributionCenterIndex.current > -1 ? (
                    // 個別の在庫管理単位の場合
                    <React.Fragment>
                      {inventoryPlanRef.current.inventoryUnitSKUs.filter((inventoryUnitSKU) => {
                        if (inventoryPlanRef.current == null) {
                          return false
                        }
                        const sku = inventoryPlanRef.current.skus.find((sku) => sku.code === inventoryUnitSKU.janCode)
                        if (sku == null) {
                          return false
                        }
                        return (
                          InventoryUnitSearch.selectedDistributionCenterIndex.current >= 0 &&
                          inventoryUnitSKU.inventoryUnitCode ===
                            inventoryPlanRef.current.inventoryUnits[
                              InventoryUnitSearch.selectedDistributionCenterIndex.current
                            ].code &&
                          // SKUの情報が検索文字列にヒットするかどうか
                          (InventoryUnitSearch.filterByTextForInventoryUnitTab === '' ||
                            sku.code.includes(InventoryUnitSearch.filterByTextForInventoryUnitTab) ||
                            (sku.name != null &&
                              sku.name.includes(InventoryUnitSearch.filterByTextForInventoryUnitTab)) ||
                            (sku.specName != null &&
                              sku.specName.includes(InventoryUnitSearch.filterByTextForInventoryUnitTab))) &&
                          // 誤差の値でフィルタリング
                          InventoryUnitSearch.isErrorLevelIncludedForInventoryUnitTab(
                            inventoryUnitSKU.errorLevelInLast4Weeks,
                            inventoryUnitSKU.errorLevelInLast13Weeks
                          ) &&
                          // 補正なしかどうかでフィルタリング
                          (InventoryUnitSearch.filterByAdjustmentTargetForInventoryUnitTab === false ||
                            inventoryUnitSKU.isAdjusted === false) &&
                          // シミュレーション値があるかどうかでフィルタリング
                          (InventoryUnitSearch.filterBySimulatedValueForInventoryUnitTab === false ||
                            Object.values(inventoryUnitSKU.inventoryCalendar).some(
                              (inventoryPlan) => inventoryPlan.receivingQuantityForSimulation != null
                            ) === true)
                        )
                      }).length === 0 ? (
                        <DistributionCenterSKUPanelContainer>
                          検索結果がありません。
                        </DistributionCenterSKUPanelContainer>
                      ) : (
                        inventoryPlanRef.current.inventoryUnitSKUs.sort((a, b) => a.janCode > b.janCode ? 1 : -1).map((inventoryUnitSKU, idx) => {
                          if (inventoryPlanRef.current == null) {
                            return null
                          }
                          const sku = inventoryPlanRef.current.skus.find((sku) => sku.code === inventoryUnitSKU.janCode)
                          if (sku == null) {
                            return null
                          }
                          if (
                            InventoryUnitSearch.selectedDistributionCenterIndex.current >= 0 &&
                            inventoryUnitSKU.inventoryUnitCode ===
                              inventoryPlanRef.current.inventoryUnits[
                                InventoryUnitSearch.selectedDistributionCenterIndex.current
                              ].code &&
                            // SKUの情報が検索文字列にヒットするかどうか
                            (InventoryUnitSearch.filterByTextForInventoryUnitTab === '' ||
                              sku.code.includes(InventoryUnitSearch.filterByTextForInventoryUnitTab) ||
                              (sku.name != null &&
                                sku.name.includes(InventoryUnitSearch.filterByTextForInventoryUnitTab)) ||
                              (sku.specName != null &&
                                sku.specName.includes(InventoryUnitSearch.filterByTextForInventoryUnitTab))) &&
                            // 誤差の値でフィルタリング
                            InventoryUnitSearch.isErrorLevelIncludedForInventoryUnitTab(
                              inventoryUnitSKU.errorLevelInLast4Weeks,
                              inventoryUnitSKU.errorLevelInLast13Weeks
                            ) &&
                            // 補正なしかどうかでフィルタリング
                            (InventoryUnitSearch.filterByAdjustmentTargetForInventoryUnitTab === false ||
                              inventoryUnitSKU.isAdjusted === false) &&
                            // シミュレーション値があるかどうかでフィルタリング
                            (InventoryUnitSearch.filterBySimulatedValueForInventoryUnitTab === false ||
                              Object.values(inventoryUnitSKU.inventoryCalendar).some(
                                (inventoryPlan) => inventoryPlan.receivingQuantityForSimulation != null
                              ) === true)
                          ) {
                            return (
                              <DistributionCenterSKUPanelContainer key={idx}>
                                <SKUHeader>
                                  <SKUCode>{sku.code}</SKUCode>
                                  {sku.name != null ? <SKUName>{sku.name}</SKUName> : null}
                                  {sku.specName != null ? <SKUSpec>{sku.specName}</SKUSpec> : null}
                                  {sku.name == null && sku.specName == null ? <b>-</b> : null}
                                </SKUHeader>
                                <DistributionCenterSKUPanel
                                  inventoryUnitSKU={inventoryUnitSKU}
                                  inventoryUnitSKUOrigin={inventoryPlan.inventoryUnitSKUs[idx]}
                                  weeks={inventoryPlanRef.current != null ? inventoryPlanRef.current.weeks : []}
                                  highlightedWeekDate={highlightedWeekDate}
                                  inventoryCalendarTableScrollLeft={inventoryCalendarTableScrollLeft}
                                  readonly={
                                    currentUser.role !== UserRole.Manager &&
                                    currentUser.id !== inventoryPlan.orderGroup.operatorId
                                  }
                                  onHighlightedWeekDateChangeHandler={(weekDate) => {
                                    if (weekDate === highlightedWeekDate) {
                                      // No highlight
                                      setHighlightedWeekDate('')
                                    } else {
                                      setHighlightedWeekDate(weekDate)
                                    }
                                  }}
                                  onInventoryCalendarTableScrollLeftHandler={(scrollLeft) => {
                                    setInventoryCalendarTableScrollLeft(scrollLeft)
                                  }}
                                  onReceivingQuantityForSimulationChangeHandler={(weekDate, quantity) => {
                                    if (
                                      inventoryUnitSKU.inventoryCalendar[weekDate].receivingQuantityForSimulation !==
                                      quantity
                                    ) {
                                      inventoryUnitSKU.inventoryCalendar[weekDate].receivingQuantityForSimulation =
                                        quantity
                                      forceUpdate((v) => !v)
                                    }
                                  }}
                                />
                              </DistributionCenterSKUPanelContainer>
                            )
                          }
                          return null
                        })
                      )}
                    </React.Fragment>
                  ) : null}
                </DistributionCenterSKUPanelsContainer>
              ) : null}
            </MainContentsContainer>
            {simulationResetConfirmationModalVisible === true ? (
              <SimulationResetConfirmationModal
                inventoryUnits={inventoryPlanRef.current.inventoryUnits}
                skus={inventoryPlanRef.current.skus}
                inventoryUnitSKUs={inventoryPlanRef.current.inventoryUnitSKUs.filter((inventoryUnitSKU) =>
                  Object.values(inventoryUnitSKU.inventoryCalendar).some(
                    (inventoryPlan) => inventoryPlan.receivingQuantityForSimulation != null
                  )
                )}
                onSubmitHandler={() => {
                  setSimulationResetConfirmationModalVisible(false)
                  deleteSimulationParameters(orderGroupIdNum)
                }}
                onCloseHandler={() => setSimulationResetConfirmationModalVisible(false)}
              />
            ) : null}
          </React.Fragment>
        ) : (
          <LoadingSpinnerContainer>
            <LoadingSpinner />
          </LoadingSpinnerContainer>
        )}
      </TabBody>
    </PageContainer>
  )
}
