/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for OMR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdjustmentTarget,
    AdjustmentTargetFromJSON,
    AdjustmentTargetFromJSONTyped,
    AdjustmentTargetToJSON,
    DraftPurchaseOrderPlanningInventoryPlanPerWeek,
    DraftPurchaseOrderPlanningInventoryPlanPerWeekFromJSON,
    DraftPurchaseOrderPlanningInventoryPlanPerWeekFromJSONTyped,
    DraftPurchaseOrderPlanningInventoryPlanPerWeekToJSON,
} from './';

/**
 * 
 * @export
 * @interface DraftPurchaseOrderPlanningInventoryUnitSKU
 */
export interface DraftPurchaseOrderPlanningInventoryUnitSKU {
    /**
     * 在庫管理単位コード
     * @type {string}
     * @memberof DraftPurchaseOrderPlanningInventoryUnitSKU
     */
    inventoryUnitCode: string;
    /**
     * InventoryUnit名
     * @type {string}
     * @memberof DraftPurchaseOrderPlanningInventoryUnitSKU
     */
    inventoryUnitName: string;
    /**
     * JANコード
     * @type {string}
     * @memberof DraftPurchaseOrderPlanningInventoryUnitSKU
     */
    janCode: string;
    /**
     * 商品名
     * @type {string}
     * @memberof DraftPurchaseOrderPlanningInventoryUnitSKU
     */
    skuName: string | null;
    /**
     * 規格名
     * @type {string}
     * @memberof DraftPurchaseOrderPlanningInventoryUnitSKU
     */
    skuSpecName: string | null;
    /**
     * 欠品補充対象かどうか
     * @type {boolean}
     * @memberof DraftPurchaseOrderPlanningInventoryUnitSKU
     */
    isReplenishmentTarget: boolean;
    /**
     * 入荷後在庫週数
     * @type {number}
     * @memberof DraftPurchaseOrderPlanningInventoryUnitSKU
     */
    numberOfInventoryWeeksAfterShipment: number | null;
    /**
     * 入荷後在庫週の最低数
     * @type {number}
     * @memberof DraftPurchaseOrderPlanningInventoryUnitSKU
     */
    minNumberOfInventoryWeeksAfterShipment: number | null;
    /**
     * 直近4週平均週販
     * @type {number}
     * @memberof DraftPurchaseOrderPlanningInventoryUnitSKU
     */
    salesVolumeAverageInLast4Weeks: number | null;
    /**
     * MOQ調整が適用されたかどうか
     * @type {boolean}
     * @memberof DraftPurchaseOrderPlanningInventoryUnitSKU
     */
    isMOQAdjustmentApplied: boolean;
    /**
     * 補正対象かどうか
     * @type {boolean}
     * @memberof DraftPurchaseOrderPlanningInventoryUnitSKU
     */
    isAdjusted: boolean;
    /**
     * 4W達成率誤差
     * @type {string}
     * @memberof DraftPurchaseOrderPlanningInventoryUnitSKU
     */
    errorLevelInLast4Weeks: DraftPurchaseOrderPlanningInventoryUnitSKUErrorLevelInLast4WeeksEnum;
    /**
     * 13W達成率誤差
     * @type {string}
     * @memberof DraftPurchaseOrderPlanningInventoryUnitSKU
     */
    errorLevelInLast13Weeks: DraftPurchaseOrderPlanningInventoryUnitSKUErrorLevelInLast13WeeksEnum;
    /**
     * 
     * @type {{ [key: string]: DraftPurchaseOrderPlanningInventoryPlanPerWeek; }}
     * @memberof DraftPurchaseOrderPlanningInventoryUnitSKU
     */
    inventoryCalendar: { [key: string]: DraftPurchaseOrderPlanningInventoryPlanPerWeek; };
    /**
     * ヤード残数
     * @type {number}
     * @memberof DraftPurchaseOrderPlanningInventoryUnitSKU
     */
    yardQuantity: number;
    /**
     * 販売開始から26週経過しているかどうか
     * @type {boolean}
     * @memberof DraftPurchaseOrderPlanningInventoryUnitSKU
     */
    over26weeks?: boolean;
    /**
     * 
     * @type {AdjustmentTarget}
     * @memberof DraftPurchaseOrderPlanningInventoryUnitSKU
     */
    adjustmentTarget: AdjustmentTarget;
}

/**
* @export
* @enum {string}
*/
export enum DraftPurchaseOrderPlanningInventoryUnitSKUErrorLevelInLast4WeeksEnum {
    High = 'high',
    Middle = 'middle',
    Low = 'low',
    Unavailable = 'unavailable'
}/**
* @export
* @enum {string}
*/
export enum DraftPurchaseOrderPlanningInventoryUnitSKUErrorLevelInLast13WeeksEnum {
    High = 'high',
    Middle = 'middle',
    Low = 'low',
    Unavailable = 'unavailable'
}

export function DraftPurchaseOrderPlanningInventoryUnitSKUFromJSON(json: any): DraftPurchaseOrderPlanningInventoryUnitSKU {
    return DraftPurchaseOrderPlanningInventoryUnitSKUFromJSONTyped(json, false);
}

export function DraftPurchaseOrderPlanningInventoryUnitSKUFromJSONTyped(json: any, ignoreDiscriminator: boolean): DraftPurchaseOrderPlanningInventoryUnitSKU {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inventoryUnitCode': json['inventoryUnitCode'],
        'inventoryUnitName': json['inventoryUnitName'],
        'janCode': json['janCode'],
        'skuName': json['skuName'],
        'skuSpecName': json['skuSpecName'],
        'isReplenishmentTarget': json['isReplenishmentTarget'],
        'numberOfInventoryWeeksAfterShipment': json['numberOfInventoryWeeksAfterShipment'],
        'minNumberOfInventoryWeeksAfterShipment': json['minNumberOfInventoryWeeksAfterShipment'],
        'salesVolumeAverageInLast4Weeks': json['salesVolumeAverageInLast4Weeks'],
        'isMOQAdjustmentApplied': json['isMOQAdjustmentApplied'],
        'isAdjusted': json['isAdjusted'],
        'errorLevelInLast4Weeks': json['errorLevelInLast4Weeks'],
        'errorLevelInLast13Weeks': json['errorLevelInLast13Weeks'],
        'inventoryCalendar': (mapValues(json['inventoryCalendar'], DraftPurchaseOrderPlanningInventoryPlanPerWeekFromJSON)),
        'yardQuantity': json['yardQuantity'],
        'over26weeks': !exists(json, 'over26weeks') ? undefined : json['over26weeks'],
        'adjustmentTarget': AdjustmentTargetFromJSON(json['adjustmentTarget']),
    };
}

export function DraftPurchaseOrderPlanningInventoryUnitSKUToJSON(value?: DraftPurchaseOrderPlanningInventoryUnitSKU | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inventoryUnitCode': value.inventoryUnitCode,
        'inventoryUnitName': value.inventoryUnitName,
        'janCode': value.janCode,
        'skuName': value.skuName,
        'skuSpecName': value.skuSpecName,
        'isReplenishmentTarget': value.isReplenishmentTarget,
        'numberOfInventoryWeeksAfterShipment': value.numberOfInventoryWeeksAfterShipment,
        'minNumberOfInventoryWeeksAfterShipment': value.minNumberOfInventoryWeeksAfterShipment,
        'salesVolumeAverageInLast4Weeks': value.salesVolumeAverageInLast4Weeks,
        'isMOQAdjustmentApplied': value.isMOQAdjustmentApplied,
        'isAdjusted': value.isAdjusted,
        'errorLevelInLast4Weeks': value.errorLevelInLast4Weeks,
        'errorLevelInLast13Weeks': value.errorLevelInLast13Weeks,
        'inventoryCalendar': (mapValues(value.inventoryCalendar, DraftPurchaseOrderPlanningInventoryPlanPerWeekToJSON)),
        'yardQuantity': value.yardQuantity,
        'over26weeks': value.over26weeks,
        'adjustmentTarget': AdjustmentTargetToJSON(value.adjustmentTarget),
    };
}


