import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import firebase from 'firebase/app'

import {
  AdjustmentRange,
  AdjustmentType,
  DefaultApi,
  Configuration,
  GetInventoryUnitShipmentPlanRequest,
  GetInventoryUnitShipmentPlanResponse,
  PutInventoryUnitShipmentPlanOperationRequest,
  PutInventoryUnitShipmentPlanRequest,
  GetInventoryUnitShipmentPlanSelectedDemandForecastEnum,
  AdjustmentTarget,
  GetInventoryUnitShipmentPlanAdjustmentTypeEnum,
  GetInventoryUnitShipmentPlanAdjustmentRangeEnum,
} from '../api-client'
import {
  InventoryUnitShipmentPlanParamsBySelectedDemandForecast,
  useInventoryUnitShipmentPlanParams,
} from './useInventoryUnitShipmentPlanParams'

export type InventoryUnitShipmentPlanParamsProps = {
  adjustmentType: AdjustmentType
  adjustmentCustomRate: number
  adjustmentRange?: AdjustmentRange | null
  numberOfWeeksToAdjust: number
  selectedDemandForecast: string
}

export const useInventoryUnitShipmentPlan = (
  inventoryUnitCode: string,
  janCode: string,
  defaultSelectedDemandForecastFrom: GetInventoryUnitShipmentPlanSelectedDemandForecastEnum
) => {
  const [shipmentPlan, setShipmentPlan] = useState<GetInventoryUnitShipmentPlanResponse | undefined>(undefined)
  const {
    convAdjustmentType,
    convAdjustmentRange,
    convDemandForecast,
    saveParams,
    onChangeSelectedForecast,
    inventoryUnitShipmentPlanParams,
    setInventoryUnitShipmentPlanParams,
    setInitialInventoryUnitShipmentPlanParams,
    isInitialParams,
  } = useInventoryUnitShipmentPlanParams()

  useEffect(() => {
    firebase
      .auth()
      .currentUser?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        const params: GetInventoryUnitShipmentPlanRequest = {
          inventoryUnitCode,
          janCode,
          selectedDemandForecast: defaultSelectedDemandForecastFrom,
        }
        return api.getInventoryUnitShipmentPlan(params)
      })
      .then((data) => {
        //初期Prams設定
        if (data?.selectedDemandForecast) {
          setInventoryUnitShipmentPlanParams((prev) => {
            const defaultSettingPramas = {
              adjustmentType:
                data.temporaryAdjustmentType != null
                  ? data.temporaryAdjustmentType
                  : // 本来は not null であるべきだが, 一時的な指定の場合のプロパティと enum の型と共通化するために nullable となっている.
                  data.permanentAdjustmentType != null
                  ? data.permanentAdjustmentType
                  : AdjustmentType.NoAdjustment,
              adjustmentCustomRate:
                data.temporaryAdjustmentCustomRate != null
                  ? data.temporaryAdjustmentCustomRate
                  : data.permanentAdjustmentCustomRate,
              adjustmentRange:
                data.temporaryAdjustmentRange != null ? data.temporaryAdjustmentRange : data.permanentAdjustmentRange,
              numberOfWeeksToAdjust:
                data.temporaryNumberOfWeeksToAdjust != null
                  ? data.temporaryNumberOfWeeksToAdjust
                  : data.permanentNumberOfWeeksToAdjust,
              selectedDemandForecast: data.selectedDemandForecast,
            }

            let settingPramas: InventoryUnitShipmentPlanParamsBySelectedDemandForecast = prev
            if (data.selectedDemandForecast === AdjustmentTarget.DemandInsight) {
              settingPramas = {
                ...settingPramas,
                demandInsightParams: defaultSettingPramas,
                selectedDemandForecast: AdjustmentTarget.DemandInsight,
              }
            } else if (data.selectedDemandForecast === AdjustmentTarget.SalesComposition) {
              settingPramas = {
                ...settingPramas,
                salesCompositionParams: defaultSettingPramas,
                selectedDemandForecast: AdjustmentTarget.SalesComposition,
              }
            }
            setInitialInventoryUnitShipmentPlanParams({ ...settingPramas })
            return { ...settingPramas }
          })
        }

        setShipmentPlan(data)
      })
      .catch((err) => {
        Sentry.captureException(err)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryUnitCode, janCode])

  const getInventoryUnitShipmentPlan = (
    adjustmentType?: AdjustmentType,
    adjustmentCustomRate?: number,
    adjustmentRange?: AdjustmentRange,
    numberOfWeeksToAdjust?: number,
    selectedDemandForecast?: string
  ) => {
    return firebase
      .auth()
      .currentUser?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        const params: GetInventoryUnitShipmentPlanRequest = {
          inventoryUnitCode,
          janCode,
          adjustmentType: convAdjustmentType(adjustmentType) as GetInventoryUnitShipmentPlanAdjustmentTypeEnum,
          adjustmentCustomRate,
          adjustmentRange: convAdjustmentRange(adjustmentRange) as GetInventoryUnitShipmentPlanAdjustmentRangeEnum,
          numberOfWeeksToAdjust,
          selectedDemandForecast: convDemandForecast(
            selectedDemandForecast
          ) as GetInventoryUnitShipmentPlanSelectedDemandForecastEnum,
        }
        console.log('param=' + params.selectedDemandForecast)
        return api.getInventoryUnitShipmentPlan(params)
      })
      .then(setShipmentPlan)
      .catch((err) => {
        Sentry.captureException(err)
      })
  }

  const putInventoryUnitShipmentPlan = (
    adjustmentType: AdjustmentType,
    adjustmentCustomRate: number,
    adjustmentRange: AdjustmentRange | null,
    numberOfWeeksToAdjust: number,
    selectedDemandForecast: AdjustmentTarget
  ): Promise<void> | undefined => {
    return firebase
      .auth()
      .currentUser?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        const bodyParam: PutInventoryUnitShipmentPlanRequest = {
          adjustmentType,
          adjustmentCustomRate,
          adjustmentRange,
          numberOfWeeksToAdjust,
          selectedDemandForecast,
        }
        console.log('param=' + bodyParam)
        const params: PutInventoryUnitShipmentPlanOperationRequest = {
          inventoryUnitCode,
          janCode,
          bodyParam,
        }
        return api.putInventoryUnitShipmentPlan(params)
      })
      .then(() => {
        setInitialInventoryUnitShipmentPlanParams(inventoryUnitShipmentPlanParams)
      })
      .catch((err) => {
        Sentry.captureException(err)
      })
  }

  return {
    shipmentPlan,
    getInventoryUnitShipmentPlan,
    putInventoryUnitShipmentPlan,
    saveParams,
    onChangeSelectedForecast: (selecteForecast: AdjustmentTarget) =>
      onChangeSelectedForecast(selecteForecast, getInventoryUnitShipmentPlan),
    inventoryUnitShipmentPlanParams,
    setInventoryUnitShipmentPlanParams,
    isInitialParams,
  }
}
