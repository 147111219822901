import styled from 'styled-components'

const BaseInventoryUnitShipmentCalendarTable = styled.table<{width: number}>`
  table-layout: fixed;
  width: ${props => `${props.width}px`};
`

const InventoryUnitShipmentCalendarTableCol = styled.col<{width: number}>`
  width: ${props => `${props.width}px`};
`

const InventoryUnitShipmentCalendarTableContainer = styled.div`
  border-left: solid 1px var(--di-table-border-color);
  overflow-x: auto;
  white-space: nowrap;
`

const InventoryUnitShipmentCalendarTableData = styled.td<{boldbottom: boolean, backgroundgrey: boolean}>`
  background-color: ${props => props.backgroundgrey === true? '#3333331a' : '#fff'};
  border-bottom-color: ${props => props.boldbottom === true? '#707070' : 'var(--di-table-border-color)'};
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-right: solid 1px var(--di-table-border-color);
  border-top: solid 1px var(--di-table-border-color);
  padding-right: 10px;
  text-align: right;
`

const InventoryUnitShipmentCalendarTableDataHeader = styled.td<{boldbottom: boolean, left: number}>`
  background-color: #f1f1f1;
  border-bottom-color: ${props => props.boldbottom === true? '#707070' : 'var(--di-table-border-color)'};
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-right: solid 1px var(--di-table-border-color);
  border-top: solid 1px var(--di-table-border-color);
  left: ${props => `${props.left}px`};
  padding-left: 10px;
  position: sticky;
  vertical-align: text-top;
  z-index: 2;

  &::before {
    border-right: solid 1px var(--di-table-border-color);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`

const InventoryUnitShipmentCalendarTableHeader = styled.th<{textalign: string, boldbottom: boolean}>`
  background-color: #f1f1f1;
  border-bottom-color: ${props => props.boldbottom === true? '#707070' : 'var(--di-table-border-color)'};
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-right: solid 1px var(--di-table-border-color);
  border-top: solid 1px var(--di-table-border-color);
  font-weight: normal;
  padding-left: 10px;
  padding-right: 10px;
  text-align: ${props => `${props.textalign}`};

  &:first-child {
    left: 0;
    position: sticky;
    z-index: 2;
  }

  &:first-child::before {
    border-right: solid 1px var(--di-table-border-color);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`

export {
  BaseInventoryUnitShipmentCalendarTable,
  InventoryUnitShipmentCalendarTableCol,
  InventoryUnitShipmentCalendarTableContainer,
  InventoryUnitShipmentCalendarTableData,
  InventoryUnitShipmentCalendarTableDataHeader,
  InventoryUnitShipmentCalendarTableHeader,
}
