import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { User, UserRole } from '../../api-client'

import { UserOperationType } from '../../constants'

import { useUsers } from '../../hooks'

import { Button, LoadingSpinner, PageHeader, Toast, UsersTable } from '../../components/atoms'

import {
  CountLabel,
  LoadingSpinnerContainer,
  NewUserButtonContainer,
  ToastContainer,
  UsersTableContainer,
} from './style'

type HistoryLocationState = {
  userOperationType: UserOperationType | undefined
}

export interface UsersProps {
  currentUser: User
}

export const Users: React.FC<UsersProps> = ({ currentUser }) => {
  const history = useHistory<HistoryLocationState>()
  const { users } = useUsers()

  const getToastMessage = (operationType: UserOperationType | undefined | null) => {
    switch (operationType) {
      case UserOperationType.Create:
        return '登録しました。'
      case UserOperationType.Update:
        return '更新しました。'
      case UserOperationType.Delete:
        return '削除しました。'
      default:
        return ''
    }
  }

  const userOperationType = history.location.state != null ? history.location.state.userOperationType : null
  const toastMessage = useState(getToastMessage(userOperationType))[0]
  const [toastVisible, setToastVisible] = useState(userOperationType != null)

  useEffect(() => {
    history.push({
      pathname: '/users',
      state: {
        userOperationType: undefined,
      },
    })
  }, [history])

  return (
    <>
      <PageHeader pageTitle="ユーザー管理" backLink={false} />
      {toastVisible === true ? (
        <ToastContainer>
          <Toast
            message={toastMessage}
            onCloseHandler={() => {
              setTimeout(() => setToastVisible(false), 300)
            }}
          />
        </ToastContainer>
      ) : null}
      <UsersTableContainer>
        <NewUserButtonContainer>
          {currentUser.role === UserRole.Manager ? (
            <Button
              styleType="secondary"
              label="ユーザーを追加"
              width={160}
              plusIcon={true}
              onClickHandler={() => history.push('/users/new')}
            />
          ) : null}
          <CountLabel>{users != null ? `${users.length}件` : ''}</CountLabel>
        </NewUserButtonContainer>
        {users != null ? (
          <UsersTable currentUser={currentUser} users={users} />
        ) : (
          <LoadingSpinnerContainer>
            <LoadingSpinner />
          </LoadingSpinnerContainer>
        )}
      </UsersTableContainer>
    </>
  )
}
