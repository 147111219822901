import React, { useState } from 'react'
import firebase from 'firebase/app'
import { useHistory } from 'react-router-dom'

import {
  DefaultApi,
  ErrorTitle,
  Configuration,
  ModelError,
  PostUsersOperationRequest,
  PostUsersRequest,
  User,
  UserRole,
} from '../../api-client'

import { UserOperationType } from '../../constants'

import { ErrorMessagePanel, NotificationField, PageHeader } from '../../components/atoms'

import { UserForm } from '../../components/molecules'

import { ErrorMessagePanelContainer, NotificationErrorContainer, UserFormContainer } from './style'

export interface CreateUserProps {
  currentUser: User
}

export const CreateUser: React.FC<CreateUserProps> = ({ currentUser }) => {
  const history = useHistory()

  const [createUserErrorMessage, setCreateUserErrorMessage] = useState<string | undefined | null>(undefined)

  return currentUser.role === UserRole.Manager ? (
    <>
      <PageHeader pageTitle="ユーザー新規作成" backLink={true} />
      <UserFormContainer>
        {createUserErrorMessage != null ? (
          <NotificationErrorContainer>
            <NotificationField type="error" title="登録エラー" bodyMessage={createUserErrorMessage} />
          </NotificationErrorContainer>
        ) : null}
        <UserForm
          user={null}
          emailEditable={true}
          roleEditable={true}
          onSaveUserClick={(name, password, email, role) => {
            if (password == null) {
              // 子コンポーネントでnullチェックされているので通常はこのパスは通らない想定
              return
            }
            setCreateUserErrorMessage(null)
            firebase
              .auth()
              .currentUser?.getIdToken(true)
              .then((token) => {
                const conf = new Configuration({
                  basePath: process.env.REACT_APP_API_PATH,
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                })
                const api = new DefaultApi(conf)
                const bodyParam: PostUsersRequest = {
                  email,
                  name,
                  password,
                  role,
                }
                const param: PostUsersOperationRequest = {
                  bodyParam,
                }
                return api.postUsers(param)
              })
              .then((response) => {
                history.push({
                  pathname: '/users',
                  state: {
                    userOperationType: UserOperationType.Create,
                  },
                })
              })
              .catch((error: Response) => {
                if (error.status === 400) {
                  error.json().then((e: ModelError) => {
                    if (e.title === ErrorTitle._400BadRequestEmailAlreadyExists) {
                      setCreateUserErrorMessage('登録済のメールアドレスです。')
                    } else {
                      setCreateUserErrorMessage('不明なエラーです。システム管理者にお問い合わせ下さい。')
                    }
                  })
                } else {
                  setCreateUserErrorMessage('不明なエラーです。システム管理者にお問い合わせ下さい。')
                }
              })
          }}
        />
      </UserFormContainer>
    </>
  ) : (
    <ErrorMessagePanelContainer>
      <ErrorMessagePanel statusCode={403} />
    </ErrorMessagePanelContainer>
  )
}
