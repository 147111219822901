/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for OMR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 発注タイミング
 * @export
 * @enum {string}
 */
export enum OrderTiming {
    Default = 'default',
    StartAccumulation = 'startAccumulation',
    LastShipment = 'lastShipment',
    FirstShipment = 'firstShipment',
    StopFactory = 'stopFactory'
}

export function OrderTimingFromJSON(json: any): OrderTiming {
    return OrderTimingFromJSONTyped(json, false);
}

export function OrderTimingFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTiming {
    return json as OrderTiming;
}

export function OrderTimingToJSON(value?: OrderTiming | null): any {
    return value as any;
}

