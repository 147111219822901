/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for OMR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InventoryUnit,
    InventoryUnitFromJSON,
    InventoryUnitFromJSONTyped,
    InventoryUnitToJSON,
    InventoryUnitSKU,
    InventoryUnitSKUFromJSON,
    InventoryUnitSKUFromJSONTyped,
    InventoryUnitSKUToJSON,
    OrderGroup,
    OrderGroupFromJSON,
    OrderGroupFromJSONTyped,
    OrderGroupToJSON,
    SKU,
    SKUFromJSON,
    SKUFromJSONTyped,
    SKUToJSON,
    SKUTotal,
    SKUTotalFromJSON,
    SKUTotalFromJSONTyped,
    SKUTotalToJSON,
    Week,
    WeekFromJSON,
    WeekFromJSONTyped,
    WeekToJSON,
} from './';

/**
 * 
 * @export
 * @interface PutSimulationParametersResponse
 */
export interface PutSimulationParametersResponse {
    /**
     * 
     * @type {OrderGroup}
     * @memberof PutSimulationParametersResponse
     */
    orderGroup: OrderGroup;
    /**
     * InventoryUnit
     * @type {Array<InventoryUnit>}
     * @memberof PutSimulationParametersResponse
     */
    inventoryUnits: Array<InventoryUnit>;
    /**
     * SKU
     * @type {Array<SKU>}
     * @memberof PutSimulationParametersResponse
     */
    skus: Array<SKU>;
    /**
     * 
     * @type {Array<InventoryUnitSKU>}
     * @memberof PutSimulationParametersResponse
     */
    inventoryUnitSKUs: Array<InventoryUnitSKU>;
    /**
     * 週
     * @type {Array<Week>}
     * @memberof PutSimulationParametersResponse
     */
    weeks: Array<Week>;
    /**
     * SKU毎に合算された在庫計画の情報
     * @type {Array<SKUTotal>}
     * @memberof PutSimulationParametersResponse
     */
    skuTotals: Array<SKUTotal>;
}

export function PutSimulationParametersResponseFromJSON(json: any): PutSimulationParametersResponse {
    return PutSimulationParametersResponseFromJSONTyped(json, false);
}

export function PutSimulationParametersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutSimulationParametersResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderGroup': OrderGroupFromJSON(json['orderGroup']),
        'inventoryUnits': ((json['inventoryUnits'] as Array<any>).map(InventoryUnitFromJSON)),
        'skus': ((json['skus'] as Array<any>).map(SKUFromJSON)),
        'inventoryUnitSKUs': ((json['inventoryUnitSKUs'] as Array<any>).map(InventoryUnitSKUFromJSON)),
        'weeks': ((json['weeks'] as Array<any>).map(WeekFromJSON)),
        'skuTotals': ((json['skuTotals'] as Array<any>).map(SKUTotalFromJSON)),
    };
}

export function PutSimulationParametersResponseToJSON(value?: PutSimulationParametersResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderGroup': OrderGroupToJSON(value.orderGroup),
        'inventoryUnits': ((value.inventoryUnits as Array<any>).map(InventoryUnitToJSON)),
        'skus': ((value.skus as Array<any>).map(SKUToJSON)),
        'inventoryUnitSKUs': ((value.inventoryUnitSKUs as Array<any>).map(InventoryUnitSKUToJSON)),
        'weeks': ((value.weeks as Array<any>).map(WeekToJSON)),
        'skuTotals': ((value.skuTotals as Array<any>).map(SKUTotalToJSON)),
    };
}


