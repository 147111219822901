import styled from 'styled-components'

const BasePanel = styled.div`
  background-color: #fff;
  padding-bottom: 100px;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 50px;
  width: 450px;
`

const Container = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`

export {
  BasePanel,
  Container,
}
