import React, { useState } from 'react'
import * as Sentry from '@sentry/react'
import firebase from 'firebase/app'

import {
  ApiResponse,
  Configuration,
  DefaultApi,
  ErrorInvalidCsvRows,
  ErrorTitle,
  ExcelError,
  ModelError,
  PutDatabaseOrderGroupsCsvRequest,
  PutMoqGroupsCsvRequest,
  PutMasterOfStoresExcelRequest,
  PutMasterOfInventoryUnitsExcelRequest,
  ExcelErrorInvalidExcelRows,
  PutMasterOfSKUsExcelRequest,
} from '../../api-client'

import { Button, LoadingSpinner, NotificationField, PageHeader } from '../../components/atoms'

import {
  ButtonContainer,
  ContentsContainer,
  Description,
  DragAndDropArea,
  DragAndDropDescription,
  DragAndDropInput,
  DragAndDropLink,
  LoadingSpinnerContainer,
  NotificationFieldContainer,
  Panel,
  PanelContainer,
  PanelTitle,
  PanelTitleContainer,
  PanelsContainer,
} from './style'

export interface RegisterMasterDataProps {}

export const RegisterMasterData: React.FC<RegisterMasterDataProps> = () => {
  const [isOrderGroupCsvUploading, setIsOrderGroupCsvUploading] = useState(false)
  const [isOrderGroupCsvUploadingSucceeded, setIsOrderGroupCsvUploadingSucceeded] = useState(false)
  const [orderGroupCsvUplodingErrorMessage, setOrderGroupCsvUplodingErrorMessage] = useState('')
  const [orderGroupCsvInvalidRows, setOrderGroupCsvInvalidRows] = useState<Array<ErrorInvalidCsvRows>>([])

  const [isMoqGroupCsvUploading, setIsMoqGroupCsvUploading] = useState(false)
  const [isMoqGroupCsvUploadingSucceeded, setIsMoqGroupCsvUploadingSucceeded] = useState(false)
  const [moqGroupCsvUplodingErrorMessage, setMoqGroupCsvUplodingErrorMessage] = useState('')
  const [moqGroupCsvInvalidRows, setMoqGroupCsvInvalidRows] = useState<Array<ErrorInvalidCsvRows>>([])

  const [isSkuExcelUploading, setIsSkuExcelUploading] = useState(false)
  const [isSkuExcelUploadingSucceeded, setIsSkuExcelUploadingSucceeded] = useState(false)
  const [skuExcelUplodingErrorMessage, setSkuExcelUplodingErrorMessage] = useState('')
  const [skuExcelInvalidRows, setSkuExcelInvalidRows] = useState<Array<ExcelErrorInvalidExcelRows>>([])

  const [isMasterOfStoresExcelUploading, setIsMasterOfStoresExcelUploading] = useState(false)
  const [isMasterOfStoresExcelUploadingSucceeded, setIsMasterOfStoresExcelUploadingSucceeded] = useState(false)
  const [masterOfStoresExcelUplodingErrorMessage, setMasterOfStoresExcelUplodingErrorMessage] = useState('')
  const [masterOfStoresExcelInvalidRows, setMasterOfStoresExcelInvalidRows] = useState<Array<ErrorInvalidCsvRows>>([])

  const [isMasterOfInventoryUnitsExcelUploading, setIsMasterOfInventoryUnitsExcelUploading] = useState(false)
  const [isMasterOfInventoryUnitsExcelUploadingSucceeded, setIsMasterOfInventoryUnitsExcelUploadingSucceeded] =
    useState(false)
  const [masterOfInventoryUnitsExcelUplodingErrorMessage, setMasterOfInventoryUnitsExcelUplodingErrorMessage] =
    useState('')
  const [masterOfInventoryUnitsExcelInvalidRows, setMasterOfInventoryUnitsExcelInvalidRows] = useState<
    Array<ErrorInvalidCsvRows>
  >([])

  const blobToFile = (blob: Blob, response: ApiResponse<Blob>) => {
    const disposition = response.raw.headers.get('content-disposition')
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    const matches = filenameRegex.exec(disposition != null ? disposition : '')

    let filename
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '')
      filename = decodeURI(filename)
    }

    const url = window.URL.createObjectURL(new Blob([blob]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', filename != null ? filename : '')
    document.body.appendChild(link)
    link.click()
    link.parentNode?.removeChild(link)
  }

  const sortInvalidCsvRowsHandler = (a: ErrorInvalidCsvRows, b: ErrorInvalidCsvRows) =>
    a.rowNumber > b.rowNumber ? 1 : a.rowNumber < b.rowNumber ? -1 : 0

  const sortInvalidExcelRowsHandler = (a: ExcelErrorInvalidExcelRows, b: ExcelErrorInvalidExcelRows) =>
    a.rowNumber > b.rowNumber ? 1 : a.rowNumber < b.rowNumber ? -1 : 0

  return (
    <>
      <PageHeader pageTitle="マスター管理" backLink={false} />
      <ContentsContainer>
        <Description>アップロードされたデータで上書きします。</Description>
        <PanelsContainer>
          <PanelContainer>
            <Panel>
              <PanelTitleContainer>
                <PanelTitle>発注グループ</PanelTitle>
                <ButtonContainer>
                  <Button
                    styleType="tertiary"
                    label="ダウンロード"
                    width={134}
                    onClickHandler={() => {
                      firebase
                        .auth()
                        .currentUser?.getIdToken(true)
                        .then((token) => {
                          const conf = new Configuration({
                            basePath: process.env.REACT_APP_API_PATH,
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                          })
                          const api = new DefaultApi(conf)
                          return api.getDatabaseOrderGroupsCsvRaw().then((response) => {
                            return response.value().then((blob) => blobToFile(blob, response))
                          })
                        })
                        .catch((err) => {
                          Sentry.captureException(err)
                        })
                    }}
                    data-testid="download-order-group"
                  />
                </ButtonContainer>
              </PanelTitleContainer>
              <DragAndDropArea
                onDragOver={(event) => {
                  // onDrop を発火させるために onDragOver イベントを制御する。
                  // Ref: https://developer.mozilla.org/en-US/docs/Web/API/GlobalEventHandlers/ondrop
                  event.stopPropagation()
                  event.preventDefault()
                }}
                onDrop={(event) => {
                  event.stopPropagation()
                  event.preventDefault()
                  const files = event.dataTransfer.files
                  setIsOrderGroupCsvUploading(true)
                  setIsOrderGroupCsvUploadingSucceeded(false)
                  setOrderGroupCsvUplodingErrorMessage('')
                  setOrderGroupCsvInvalidRows([])
                  firebase
                    .auth()
                    .currentUser?.getIdToken(true)
                    .then((token) => {
                      const conf = new Configuration({
                        basePath: process.env.REACT_APP_API_PATH,
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                      })
                      const api = new DefaultApi(conf)
                      const params: PutDatabaseOrderGroupsCsvRequest = {
                        uploadedCsvFile: files[0],
                      }
                      return api
                        .putDatabaseOrderGroupsCsv(params)
                        .then(() => {
                          setIsOrderGroupCsvUploading(false)
                          setIsOrderGroupCsvUploadingSucceeded(true)
                        })
                        .catch((error: Response) => {
                          setIsOrderGroupCsvUploading(false)
                          setIsOrderGroupCsvUploadingSucceeded(false)
                          if (error.status === 400) {
                            error.json().then((e: ModelError) => {
                              if (e.title === ErrorTitle._400BadRequestInvalidCsvRows) {
                                if (e.invalidCsvRows != null) {
                                  e.invalidCsvRows.sort(sortInvalidCsvRowsHandler)
                                  setOrderGroupCsvInvalidRows(e.invalidCsvRows)
                                } else {
                                  setOrderGroupCsvUplodingErrorMessage(
                                    '不明なエラーです。システム管理者にお問い合わせ下さい。'
                                  )
                                }
                              } else if (e.title === ErrorTitle._400BadRequestInvalidCsvFile) {
                                setOrderGroupCsvUplodingErrorMessage('フォーマットに誤りがあります')
                              }
                            })
                          } else {
                            setOrderGroupCsvUplodingErrorMessage(
                              '不明なエラーです。システム管理者にお問い合わせ下さい。'
                            )
                          }
                        })
                    })
                }}
              >
                <DragAndDropDescription>
                  <div>ここにファイルをドラッグするか、</div>
                  <DragAndDropLink htmlFor="order-group-csv-input">ファイルを選択</DragAndDropLink>
                  <span>して下さい。</span>
                  <DragAndDropInput
                    type="file"
                    id="order-group-csv-input"
                    accept=".csv"
                    onChange={(event) => {
                      const files = event.target.files
                      if (files == null) {
                        return
                      }
                      setIsOrderGroupCsvUploading(true)
                      setIsOrderGroupCsvUploadingSucceeded(false)
                      setOrderGroupCsvUplodingErrorMessage('')
                      setOrderGroupCsvInvalidRows([])
                      firebase
                        .auth()
                        .currentUser?.getIdToken(true)
                        .then((token) => {
                          const conf = new Configuration({
                            basePath: process.env.REACT_APP_API_PATH,
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                          })
                          const api = new DefaultApi(conf)
                          const params: PutDatabaseOrderGroupsCsvRequest = {
                            uploadedCsvFile: files[0],
                          }
                          return api
                            .putDatabaseOrderGroupsCsv(params)
                            .then(() => {
                              // 即アップロードしてしまうので、入力状態をクリアする.
                              event.target.value = ''
                              setIsOrderGroupCsvUploading(false)
                              setIsOrderGroupCsvUploadingSucceeded(true)
                            })
                            .catch((error: Response) => {
                              // 即アップロードしてしまうので、入力状態をクリアする.
                              event.target.value = ''
                              setIsOrderGroupCsvUploading(false)
                              setIsOrderGroupCsvUploadingSucceeded(false)
                              if (error.status === 400) {
                                error.json().then((e: ModelError) => {
                                  if (e.title === ErrorTitle._400BadRequestInvalidCsvRows) {
                                    if (e.invalidCsvRows != null) {
                                      e.invalidCsvRows.sort(sortInvalidCsvRowsHandler)
                                      setOrderGroupCsvInvalidRows(e.invalidCsvRows)
                                    } else {
                                      setOrderGroupCsvUplodingErrorMessage(
                                        '不明なエラーです。システム管理者にお問い合わせ下さい。'
                                      )
                                    }
                                  } else if (e.title === ErrorTitle._400BadRequestInvalidCsvFile) {
                                    setOrderGroupCsvUplodingErrorMessage('フォーマットに誤りがあります')
                                  }
                                })
                              } else {
                                setOrderGroupCsvUplodingErrorMessage(
                                  '不明なエラーです。システム管理者にお問い合わせ下さい。'
                                )
                              }
                            })
                        })
                    }}
                    data-testid="order-group-csv-input"
                  />
                </DragAndDropDescription>
              </DragAndDropArea>
              {isOrderGroupCsvUploading === true ? (
                <LoadingSpinnerContainer>
                  <LoadingSpinner />
                </LoadingSpinnerContainer>
              ) : null}
              {isOrderGroupCsvUploadingSucceeded === true ? (
                <NotificationFieldContainer>
                  <NotificationField type="success" bodyMessage="登録完了しました" />
                </NotificationFieldContainer>
              ) : null}
              {orderGroupCsvUplodingErrorMessage !== '' ? (
                <NotificationFieldContainer>
                  <NotificationField type="error" title="登録エラー" bodyMessage={orderGroupCsvUplodingErrorMessage} />
                </NotificationFieldContainer>
              ) : null}
              {orderGroupCsvInvalidRows.length > 0 ? (
                <NotificationFieldContainer>
                  <NotificationField
                    type="error"
                    title="登録エラー"
                    // 行番号が0だったらエラー詳細のみ表示する. 特定の行に依存しないエラー（SKUが紐づくので削除できない, など）が入る想定.
                    bodyMessages={orderGroupCsvInvalidRows.map((row) =>
                      row.rowNumber !== 0 ? `${row.rowNumber}行目：${row.detail}` : row.detail
                    )}
                  />
                </NotificationFieldContainer>
              ) : null}
            </Panel>
          </PanelContainer>
          <PanelContainer>
            <Panel>
              <PanelTitleContainer>
                <PanelTitle>MOQ管理グループ</PanelTitle>
                <ButtonContainer>
                  <Button
                    styleType="tertiary"
                    label="ダウンロード"
                    width={134}
                    onClickHandler={() => {
                      firebase
                        .auth()
                        .currentUser?.getIdToken(true)
                        .then((token) => {
                          const conf = new Configuration({
                            basePath: process.env.REACT_APP_API_PATH,
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                          })
                          const api = new DefaultApi(conf)
                          return api.getMoqGroupsCsvRaw().then((response) => {
                            return response.value().then((blob) => blobToFile(blob, response))
                          })
                        })
                        .catch((err) => {
                          Sentry.captureException(err)
                        })
                    }}
                    data-testid="moq-group-download-button"
                  />
                </ButtonContainer>
              </PanelTitleContainer>
              <DragAndDropArea
                onDragOver={(event) => {
                  // onDrop を発火させるために onDragOver イベントを制御する。
                  // Ref: https://developer.mozilla.org/en-US/docs/Web/API/GlobalEventHandlers/ondrop
                  event.stopPropagation()
                  event.preventDefault()
                }}
                onDrop={(event) => {
                  event.stopPropagation()
                  event.preventDefault()
                  const files = event.dataTransfer.files
                  setIsMoqGroupCsvUploading(true)
                  setIsMoqGroupCsvUploadingSucceeded(false)
                  setMoqGroupCsvUplodingErrorMessage('')
                  setMoqGroupCsvInvalidRows([])
                  firebase
                    .auth()
                    .currentUser?.getIdToken(true)
                    .then((token) => {
                      const conf = new Configuration({
                        basePath: process.env.REACT_APP_API_PATH,
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                      })
                      const api = new DefaultApi(conf)
                      const params: PutMoqGroupsCsvRequest = {
                        uploadedCsvFile: files[0],
                      }
                      return api
                        .putMoqGroupsCsv(params)
                        .then(() => {
                          setIsMoqGroupCsvUploading(false)
                          setIsMoqGroupCsvUploadingSucceeded(true)
                        })
                        .catch((error: Response) => {
                          setIsMoqGroupCsvUploading(false)
                          setIsMoqGroupCsvUploadingSucceeded(false)
                          if (error.status === 400) {
                            error.json().then((e: ModelError) => {
                              if (e.title === ErrorTitle._400BadRequestInvalidCsvRows) {
                                if (e.invalidCsvRows != null) {
                                  e.invalidCsvRows.sort(sortInvalidCsvRowsHandler)
                                  setMoqGroupCsvInvalidRows(e.invalidCsvRows)
                                } else {
                                  setMoqGroupCsvUplodingErrorMessage(
                                    '不明なエラーです。システム管理者にお問い合わせ下さい。'
                                  )
                                }
                              } else if (e.title === ErrorTitle._400BadRequestInvalidCsvFile) {
                                setMoqGroupCsvUplodingErrorMessage('フォーマットに誤りがあります')
                              }
                            })
                          } else {
                            setMoqGroupCsvUplodingErrorMessage('不明なエラーです。システム管理者にお問い合わせ下さい。')
                          }
                        })
                    })
                }}
              >
                <DragAndDropDescription>
                  <div>ここにファイルをドラッグするか、</div>
                  <DragAndDropLink htmlFor="moq-group-csv-input">ファイルを選択</DragAndDropLink>
                  <span>して下さい。</span>
                  <DragAndDropInput
                    type="file"
                    id="moq-group-csv-input"
                    accept=".csv"
                    onChange={(event) => {
                      const files = event.target.files
                      if (files == null) {
                        return
                      }
                      setIsMoqGroupCsvUploading(true)
                      setIsMoqGroupCsvUploadingSucceeded(false)
                      setMoqGroupCsvUplodingErrorMessage('')
                      setMoqGroupCsvInvalidRows([])
                      firebase
                        .auth()
                        .currentUser?.getIdToken(true)
                        .then((token) => {
                          const conf = new Configuration({
                            basePath: process.env.REACT_APP_API_PATH,
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                          })
                          const api = new DefaultApi(conf)
                          const params: PutMoqGroupsCsvRequest = {
                            uploadedCsvFile: files[0],
                          }
                          return api
                            .putMoqGroupsCsv(params)
                            .then(() => {
                              // 即アップロードしてしまうので、入力状態をクリアする.
                              event.target.value = ''
                              setIsMoqGroupCsvUploading(false)
                              setIsMoqGroupCsvUploadingSucceeded(true)
                            })
                            .catch((error: Response) => {
                              // 即アップロードしてしまうので、入力状態をクリアする.
                              event.target.value = ''
                              setIsMoqGroupCsvUploading(false)
                              setIsMoqGroupCsvUploadingSucceeded(false)
                              if (error.status === 400) {
                                error.json().then((e: ModelError) => {
                                  if (e.title === ErrorTitle._400BadRequestInvalidCsvRows) {
                                    if (e.invalidCsvRows != null) {
                                      e.invalidCsvRows.sort(sortInvalidCsvRowsHandler)
                                      setMoqGroupCsvInvalidRows(e.invalidCsvRows)
                                    } else {
                                      setMoqGroupCsvUplodingErrorMessage(
                                        '不明なエラーです。システム管理者にお問い合わせ下さい。'
                                      )
                                    }
                                  } else if (e.title === ErrorTitle._400BadRequestInvalidCsvFile) {
                                    setMoqGroupCsvUplodingErrorMessage('フォーマットに誤りがあります')
                                  }
                                })
                              } else {
                                setMoqGroupCsvUplodingErrorMessage(
                                  '不明なエラーです。システム管理者にお問い合わせ下さい。'
                                )
                              }
                            })
                        })
                    }}
                    data-testid="moq-group-csv-input"
                  />
                </DragAndDropDescription>
              </DragAndDropArea>
              {isMoqGroupCsvUploading === true ? (
                <LoadingSpinnerContainer>
                  <LoadingSpinner />
                </LoadingSpinnerContainer>
              ) : null}
              {isMoqGroupCsvUploadingSucceeded === true ? (
                <NotificationFieldContainer>
                  <NotificationField type="success" bodyMessage="登録完了しました" />
                </NotificationFieldContainer>
              ) : null}
              {moqGroupCsvUplodingErrorMessage !== '' ? (
                <NotificationFieldContainer>
                  <NotificationField type="error" title="登録エラー" bodyMessage={moqGroupCsvUplodingErrorMessage} />
                </NotificationFieldContainer>
              ) : null}
              {moqGroupCsvInvalidRows.length > 0 ? (
                <NotificationFieldContainer>
                  <NotificationField
                    type="error"
                    title="登録エラー"
                    // 行番号が0だったらエラー詳細のみ表示する. 特定の行に依存しないエラー（SKUが紐づくので削除できない, など）が入る想定.
                    bodyMessages={moqGroupCsvInvalidRows.map((row) =>
                      row.rowNumber !== 0 ? `${row.rowNumber}行目：${row.detail}` : row.detail
                    )}
                  />
                </NotificationFieldContainer>
              ) : null}
            </Panel>
          </PanelContainer>
          <PanelContainer>
            <Panel>
              <PanelTitleContainer>
                <PanelTitle>SKU</PanelTitle>
                <ButtonContainer>
                  <Button
                    styleType="tertiary"
                    label="ダウンロード"
                    width={134}
                    onClickHandler={() => {
                      firebase
                        .auth()
                        .currentUser?.getIdToken(true)
                        .then((token) => {
                          const conf = new Configuration({
                            basePath: process.env.REACT_APP_API_PATH,
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                          })
                          const api = new DefaultApi(conf)
                          return api.getMasterOfSKUExcelRaw().then((response) => {
                            return response.value().then((blob) => blobToFile(blob, response))
                          })
                        })
                        .catch((err) => {
                          Sentry.captureException(err)
                        })
                    }}
                    data-testid="sku-download-button"
                  />
                </ButtonContainer>
              </PanelTitleContainer>
              <DragAndDropArea
                onDragOver={(event) => {
                  // onDrop を発火させるために onDragOver イベントを制御する。
                  // Ref: https://developer.mozilla.org/en-US/docs/Web/API/GlobalEventHandlers/ondrop
                  event.stopPropagation()
                  event.preventDefault()
                }}
                onDrop={(event) => {
                  event.stopPropagation()
                  event.preventDefault()
                  const files = event.dataTransfer.files
                  setIsSkuExcelUploading(true)
                  setIsSkuExcelUploadingSucceeded(false)
                  setSkuExcelUplodingErrorMessage('')
                  setSkuExcelInvalidRows([])
                  firebase
                    .auth()
                    .currentUser?.getIdToken(true)
                    .then((token) => {
                      const conf = new Configuration({
                        basePath: process.env.REACT_APP_API_PATH,
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                      })
                      const api = new DefaultApi(conf)
                      const params: PutMasterOfSKUsExcelRequest = {
                        uploadedExcelFile: files[0],
                      }
                      return api
                        .putMasterOfSKUsExcel(params)
                        .then(() => {
                          setIsSkuExcelUploading(false)
                          setIsSkuExcelUploadingSucceeded(true)
                        })
                        .catch((error: Response) => {
                          setIsSkuExcelUploading(false)
                          setIsSkuExcelUploadingSucceeded(false)
                          if (error.status === 400) {
                            error.json().then((e: ExcelError) => {
                              if (e.title === ErrorTitle._400BadRequestInvalidExcelRows) {
                                if (e.invalidExcelRows != null) {
                                  e.invalidExcelRows.sort(sortInvalidExcelRowsHandler)

                                  setSkuExcelInvalidRows(e.invalidExcelRows)
                                } else {
                                  setSkuExcelUplodingErrorMessage(
                                    '不明なエラーです。システム管理者にお問い合わせ下さい。'
                                  )
                                }
                              } else if (e.title === ErrorTitle._400BadRequestInvalidExcelFile) {
                                setSkuExcelUplodingErrorMessage('フォーマットに誤りがあります')
                              }
                            })
                          } else {
                            setSkuExcelUplodingErrorMessage('不明なエラーです。システム管理者にお問い合わせ下さい。')
                          }
                        })
                    })
                }}
              >
                <DragAndDropDescription>
                  <div>ここにファイルをドラッグするか、</div>
                  <DragAndDropLink htmlFor="sku-excel-input">ファイルを選択</DragAndDropLink>
                  <span>して下さい。</span>
                  <DragAndDropInput
                    type="file"
                    id="sku-excel-input"
                    accept=".xlsx"
                    onChange={(event) => {
                      const files = event.target.files
                      if (files == null) {
                        return
                      }
                      setIsSkuExcelUploading(true)
                      setIsSkuExcelUploadingSucceeded(false)
                      setSkuExcelUplodingErrorMessage('')
                      setSkuExcelInvalidRows([])
                      firebase
                        .auth()
                        .currentUser?.getIdToken(true)
                        .then((token) => {
                          const conf = new Configuration({
                            basePath: process.env.REACT_APP_API_PATH,
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                          })
                          const api = new DefaultApi(conf)
                          const params: PutMasterOfSKUsExcelRequest = {
                            uploadedExcelFile: files[0],
                          }
                          return api
                            .putMasterOfSKUsExcel(params)
                            .then(() => {
                              // 即アップロードしてしまうので、入力状態をクリアする.
                              event.target.value = ''
                              setIsSkuExcelUploading(false)
                              setIsSkuExcelUploadingSucceeded(true)
                            })
                            .catch((error: Response) => {
                              // 即アップロードしてしまうので、入力状態をクリアする.
                              event.target.value = ''
                              setIsSkuExcelUploading(false)
                              setIsSkuExcelUploadingSucceeded(false)
                              if (error.status === 400) {
                                error.json().then((e: ExcelError) => {
                                  console.log('error', e)
                                  if (e.title === ErrorTitle._400BadRequestInvalidExcelRows) {
                                    if (e.invalidExcelRows != null) {
                                      e.invalidExcelRows.sort(sortInvalidExcelRowsHandler)
                                      setSkuExcelInvalidRows(e.invalidExcelRows)
                                    } else {
                                      setSkuExcelUplodingErrorMessage(
                                        '不明なエラーです。システム管理者にお問い合わせ下さい。'
                                      )
                                    }
                                  } else if (e.title === ErrorTitle._400BadRequestInvalidExcelFile) {
                                    setSkuExcelUplodingErrorMessage('フォーマットに誤りがあります')
                                  }
                                })
                              } else {
                                setSkuExcelUplodingErrorMessage(
                                  '不明なエラーです。システム管理者にお問い合わせ下さい。'
                                )
                              }
                            })
                        })
                    }}
                    data-testid="sku-excel-input"
                  />
                </DragAndDropDescription>
              </DragAndDropArea>
              {isSkuExcelUploading === true ? (
                <LoadingSpinnerContainer>
                  <LoadingSpinner />
                </LoadingSpinnerContainer>
              ) : null}
              {isSkuExcelUploadingSucceeded === true ? (
                <NotificationFieldContainer>
                  <NotificationField type="success" bodyMessage="登録完了しました" />
                </NotificationFieldContainer>
              ) : null}
              {skuExcelUplodingErrorMessage !== '' ? (
                <NotificationFieldContainer>
                  <NotificationField type="error" title="登録エラー" bodyMessage={skuExcelUplodingErrorMessage} />
                </NotificationFieldContainer>
              ) : null}
              {skuExcelInvalidRows.length > 0 ? (
                <NotificationFieldContainer>
                  <NotificationField
                    type="error"
                    title="登録エラー"
                    // 行番号が0だったらエラー詳細のみ表示する. 特定の行に依存しないエラー（SKUが紐づくので削除できない, など）が入る想定.
                    bodyMessages={skuExcelInvalidRows.map((row) =>
                      row.rowNumber !== 0 ? `${row.rowNumber}行目：${row.detail}` : row.detail
                    )}
                  />
                </NotificationFieldContainer>
              ) : null}
            </Panel>
          </PanelContainer>
          <PanelContainer>
            <Panel>
              <PanelTitleContainer>
                <PanelTitle>店舗 マスタ</PanelTitle>
                <ButtonContainer>
                  <Button
                    styleType="tertiary"
                    label="ダウンロード"
                    width={134}
                    onClickHandler={() => {
                      firebase
                        .auth()
                        .currentUser?.getIdToken(true)
                        .then((token) => {
                          const conf = new Configuration({
                            basePath: process.env.REACT_APP_API_PATH,
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                          })
                          const api = new DefaultApi(conf)
                          return api.getMasterOfStoresExcelRaw().then((response) => {
                            return response.value().then((blob) => blobToFile(blob, response))
                          })
                        })
                        .catch((err) => {
                          Sentry.captureException(err)
                        })
                    }}
                    data-testid="master-of-stores-download-button"
                  />
                </ButtonContainer>
              </PanelTitleContainer>
              <DragAndDropArea
                onDragOver={(event) => {
                  // onDrop を発火させるために onDragOver イベントを制御する。
                  // Ref: https://developer.mozilla.org/en-US/docs/Web/API/GlobalEventHandlers/ondrop
                  event.stopPropagation()
                  event.preventDefault()
                }}
                onDrop={(event) => {
                  event.stopPropagation()
                  event.preventDefault()
                  const files = event.dataTransfer.files
                  setIsMasterOfStoresExcelUploading(true)
                  setIsMasterOfStoresExcelUploadingSucceeded(false)
                  setMasterOfStoresExcelUplodingErrorMessage('')
                  setMasterOfStoresExcelInvalidRows([])
                  firebase
                    .auth()
                    .currentUser?.getIdToken(true)
                    .then((token) => {
                      const conf = new Configuration({
                        basePath: process.env.REACT_APP_API_PATH,
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                      })
                      const api = new DefaultApi(conf)
                      const params: PutMasterOfStoresExcelRequest = {
                        uploadedExcelFile: files[0],
                      }
                      return api
                        .putMasterOfStoresExcel(params)
                        .then(() => {
                          setIsMasterOfStoresExcelUploading(false)
                          setIsMasterOfStoresExcelUploadingSucceeded(true)
                        })
                        .catch((error: Response) => {
                          setIsMasterOfStoresExcelUploading(false)
                          setIsMasterOfStoresExcelUploadingSucceeded(false)
                          if (error.status === 400) {
                            error.json().then((e: ExcelError) => {
                              if (e.title === ErrorTitle._400BadRequestInvalidExcelRows) {
                                if (e.invalidExcelRows != null) {
                                  e.invalidExcelRows.sort(sortInvalidExcelRowsHandler)
                                  setMasterOfStoresExcelInvalidRows(e.invalidExcelRows)
                                } else {
                                  setMasterOfStoresExcelUplodingErrorMessage(
                                    '不明なエラーです。システム管理者にお問い合わせ下さい。'
                                  )
                                }
                              } else if (e.title === ErrorTitle._400BadRequestInvalidExcelFile) {
                                setMasterOfStoresExcelUplodingErrorMessage('フォーマットに誤りがあります')
                              }
                            })
                          } else {
                            setMasterOfStoresExcelUplodingErrorMessage(
                              '不明なエラーです。システム管理者にお問い合わせ下さい。'
                            )
                          }
                        })
                    })
                }}
              >
                <DragAndDropDescription>
                  <div>ここにファイルをドラッグするか、</div>
                  <DragAndDropLink htmlFor="master-of-stores-excel-input">ファイルを選択</DragAndDropLink>
                  <span>して下さい。</span>
                  <DragAndDropInput
                    type="file"
                    id="master-of-stores-excel-input"
                    accept=".xlsx"
                    onChange={(event) => {
                      const files = event.target.files
                      if (files == null) {
                        return
                      }
                      setIsMasterOfStoresExcelUploading(true)
                      setIsMasterOfStoresExcelUploadingSucceeded(false)
                      setMasterOfStoresExcelUplodingErrorMessage('')
                      setMasterOfStoresExcelInvalidRows([])
                      firebase
                        .auth()
                        .currentUser?.getIdToken(true)
                        .then((token) => {
                          const conf = new Configuration({
                            basePath: process.env.REACT_APP_API_PATH,
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                          })
                          const api = new DefaultApi(conf)
                          const params: PutMasterOfStoresExcelRequest = {
                            uploadedExcelFile: files[0],
                          }
                          return api
                            .putMasterOfStoresExcel(params)
                            .then(() => {
                              // 即アップロードしてしまうので、入力状態をクリアする.
                              event.target.value = ''
                              setIsMasterOfStoresExcelUploading(false)
                              setIsMasterOfStoresExcelUploadingSucceeded(true)
                            })
                            .catch((error: Response) => {
                              // 即アップロードしてしまうので、入力状態をクリアする.
                              event.target.value = ''
                              setIsMasterOfStoresExcelUploading(false)
                              setIsMasterOfStoresExcelUploadingSucceeded(false)
                              if (error.status === 400) {
                                error.json().then((e: ExcelError) => {
                                  if (e.title === ErrorTitle._400BadRequestInvalidExcelRows) {
                                    if (e.invalidExcelRows != null) {
                                      e.invalidExcelRows.sort(sortInvalidExcelRowsHandler)
                                      setMasterOfStoresExcelInvalidRows(e.invalidExcelRows)
                                    } else {
                                      setMasterOfStoresExcelUplodingErrorMessage(
                                        '不明なエラーです。システム管理者にお問い合わせ下さい。'
                                      )
                                    }
                                  } else if (e.title === ErrorTitle._400BadRequestInvalidExcelFile) {
                                    setMasterOfStoresExcelUplodingErrorMessage('フォーマットに誤りがあります')
                                  }
                                })
                              } else {
                                setMasterOfStoresExcelUplodingErrorMessage(
                                  '不明なエラーです。システム管理者にお問い合わせ下さい。'
                                )
                              }
                            })
                        })
                    }}
                    data-testid="master-of-stores-excel-input"
                  />
                </DragAndDropDescription>
              </DragAndDropArea>
              {isMasterOfStoresExcelUploading === true ? (
                <LoadingSpinnerContainer>
                  <LoadingSpinner />
                </LoadingSpinnerContainer>
              ) : null}
              {isMasterOfStoresExcelUploadingSucceeded === true ? (
                <NotificationFieldContainer>
                  <NotificationField type="success" bodyMessage="登録完了しました" />
                </NotificationFieldContainer>
              ) : null}
              {masterOfStoresExcelUplodingErrorMessage !== '' ? (
                <NotificationFieldContainer>
                  <NotificationField
                    type="error"
                    title="登録エラー"
                    bodyMessage={masterOfStoresExcelUplodingErrorMessage}
                  />
                </NotificationFieldContainer>
              ) : null}
              {masterOfStoresExcelInvalidRows.length > 0 ? (
                <NotificationFieldContainer>
                  <NotificationField
                    type="error"
                    title="登録エラー"
                    // 行番号が0だったらエラー詳細のみ表示する. 特定の行に依存しないエラー（SKUが紐づくので削除できない, など）が入る想定.
                    bodyMessages={masterOfStoresExcelInvalidRows.map((row) =>
                      row.rowNumber !== 0 ? `${row.rowNumber}行目：${row.detail}` : row.detail
                    )}
                  />
                </NotificationFieldContainer>
              ) : null}
            </Panel>
          </PanelContainer>
          <PanelContainer>
            <Panel>
              <PanelTitleContainer>
                <PanelTitle>DC マスタ</PanelTitle>
                <ButtonContainer>
                  <Button
                    styleType="tertiary"
                    label="ダウンロード"
                    width={134}
                    onClickHandler={() => {
                      firebase
                        .auth()
                        .currentUser?.getIdToken(true)
                        .then((token) => {
                          const conf = new Configuration({
                            basePath: process.env.REACT_APP_API_PATH,
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                          })
                          const api = new DefaultApi(conf)
                          return api.getMasterOfInventoryUnitsExcelRaw().then((response) => {
                            return response.value().then((blob) => blobToFile(blob, response))
                          })
                        })
                        .catch((err) => {
                          Sentry.captureException(err)
                        })
                    }}
                    data-testid="master-of-inventoryUnits-download-button"
                  />
                </ButtonContainer>
              </PanelTitleContainer>
              <DragAndDropArea
                onDragOver={(event) => {
                  // onDrop を発火させるために onDragOver イベントを制御する。
                  // Ref: https://developer.mozilla.org/en-US/docs/Web/API/GlobalEventHandlers/ondrop
                  event.stopPropagation()
                  event.preventDefault()
                }}
                onDrop={(event) => {
                  event.stopPropagation()
                  event.preventDefault()
                  const files = event.dataTransfer.files
                  setIsMasterOfInventoryUnitsExcelUploading(true)
                  setIsMasterOfInventoryUnitsExcelUploadingSucceeded(false)
                  setMasterOfInventoryUnitsExcelUplodingErrorMessage('')
                  setMasterOfInventoryUnitsExcelInvalidRows([])
                  firebase
                    .auth()
                    .currentUser?.getIdToken(true)
                    .then((token) => {
                      const conf = new Configuration({
                        basePath: process.env.REACT_APP_API_PATH,
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                      })
                      const api = new DefaultApi(conf)
                      const params: PutMasterOfInventoryUnitsExcelRequest = {
                        uploadedExcelFile: files[0],
                      }
                      return api
                        .putMasterOfInventoryUnitsExcel(params)
                        .then(() => {
                          setIsMasterOfInventoryUnitsExcelUploading(false)
                          setIsMasterOfInventoryUnitsExcelUploadingSucceeded(true)
                        })
                        .catch((error: Response) => {
                          setIsMasterOfInventoryUnitsExcelUploading(false)
                          setIsMasterOfInventoryUnitsExcelUploadingSucceeded(false)
                          if (error.status === 400) {
                            error.json().then((e: ExcelError) => {
                              if (e.title === ErrorTitle._400BadRequestInvalidExcelRows) {
                                if (e.invalidExcelRows != null) {
                                  e.invalidExcelRows.sort(sortInvalidExcelRowsHandler)
                                  setMasterOfInventoryUnitsExcelInvalidRows(e.invalidExcelRows)
                                } else {
                                  setMasterOfInventoryUnitsExcelUplodingErrorMessage(
                                    '不明なエラーです。システム管理者にお問い合わせ下さい。'
                                  )
                                }
                              } else if (e.title === ErrorTitle._400BadRequestInvalidExcelFile) {
                                setMasterOfInventoryUnitsExcelUplodingErrorMessage('フォーマットに誤りがあります')
                              }
                            })
                          } else {
                            setMasterOfInventoryUnitsExcelUplodingErrorMessage(
                              '不明なエラーです。システム管理者にお問い合わせ下さい。'
                            )
                          }
                        })
                    })
                }}
              >
                <DragAndDropDescription>
                  <div>ここにファイルをドラッグするか、</div>
                  <DragAndDropLink htmlFor="master-of-inventoryUnits-excel-input">ファイルを選択</DragAndDropLink>
                  <span>して下さい。</span>
                  <DragAndDropInput
                    type="file"
                    id="master-of-inventoryUnits-excel-input"
                    accept=".xlsx"
                    onChange={(event) => {
                      const files = event.target.files
                      if (files == null) {
                        return
                      }
                      setIsMasterOfInventoryUnitsExcelUploading(true)
                      setIsMasterOfInventoryUnitsExcelUploadingSucceeded(false)
                      setMasterOfInventoryUnitsExcelUplodingErrorMessage('')
                      setMasterOfInventoryUnitsExcelInvalidRows([])
                      firebase
                        .auth()
                        .currentUser?.getIdToken(true)
                        .then((token) => {
                          const conf = new Configuration({
                            basePath: process.env.REACT_APP_API_PATH,
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                          })
                          const api = new DefaultApi(conf)
                          const params: PutMasterOfInventoryUnitsExcelRequest = {
                            uploadedExcelFile: files[0],
                          }
                          return api
                            .putMasterOfInventoryUnitsExcel(params)
                            .then(() => {
                              // 即アップロードしてしまうので、入力状態をクリアする.
                              event.target.value = ''
                              setIsMasterOfInventoryUnitsExcelUploading(false)
                              setIsMasterOfInventoryUnitsExcelUploadingSucceeded(true)
                            })
                            .catch((error: Response) => {
                              // 即アップロードしてしまうので、入力状態をクリアする.
                              event.target.value = ''
                              setIsMasterOfInventoryUnitsExcelUploading(false)
                              setIsMasterOfInventoryUnitsExcelUploadingSucceeded(false)
                              if (error.status === 400) {
                                error.json().then((e: ExcelError) => {
                                  if (e.title === ErrorTitle._400BadRequestInvalidExcelRows) {
                                    if (e.invalidExcelRows != null) {
                                      e.invalidExcelRows.sort(sortInvalidExcelRowsHandler)
                                      setMasterOfInventoryUnitsExcelInvalidRows(e.invalidExcelRows)
                                    } else {
                                      setMasterOfInventoryUnitsExcelUplodingErrorMessage(
                                        '不明なエラーです。システム管理者にお問い合わせ下さい。'
                                      )
                                    }
                                  } else if (e.title === ErrorTitle._400BadRequestInvalidExcelFile) {
                                    setMasterOfInventoryUnitsExcelUplodingErrorMessage('フォーマットに誤りがあります')
                                  }
                                })
                              } else {
                                setMasterOfInventoryUnitsExcelUplodingErrorMessage(
                                  '不明なエラーです。システム管理者にお問い合わせ下さい。'
                                )
                              }
                            })
                        })
                    }}
                    data-testid="master-of-inventoryUnits-excel-input"
                  />
                </DragAndDropDescription>
              </DragAndDropArea>
              {isMasterOfInventoryUnitsExcelUploading === true ? (
                <LoadingSpinnerContainer>
                  <LoadingSpinner />
                </LoadingSpinnerContainer>
              ) : null}
              {isMasterOfInventoryUnitsExcelUploadingSucceeded === true ? (
                <NotificationFieldContainer>
                  <NotificationField type="success" bodyMessage="登録完了しました" />
                </NotificationFieldContainer>
              ) : null}
              {masterOfInventoryUnitsExcelUplodingErrorMessage !== '' ? (
                <NotificationFieldContainer>
                  <NotificationField
                    type="error"
                    title="登録エラー"
                    bodyMessage={masterOfInventoryUnitsExcelUplodingErrorMessage}
                  />
                </NotificationFieldContainer>
              ) : null}
              {masterOfInventoryUnitsExcelInvalidRows.length > 0 ? (
                <NotificationFieldContainer>
                  <NotificationField
                    type="error"
                    title="登録エラー"
                    // 行番号が0だったらエラー詳細のみ表示する. 特定の行に依存しないエラー（SKUが紐づくので削除できない, など）が入る想定.
                    bodyMessages={masterOfInventoryUnitsExcelInvalidRows.map((row) =>
                      row.rowNumber !== 0 ? `${row.rowNumber}行目：${row.detail}` : row.detail
                    )}
                  />
                </NotificationFieldContainer>
              ) : null}
            </Panel>
          </PanelContainer>
        </PanelsContainer>
      </ContentsContainer>
    </>
  )
}
