/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for OMR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ShipmentReason,
    ShipmentReasonFromJSON,
    ShipmentReasonFromJSONTyped,
    ShipmentReasonToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetShipmentReasonsResponse
 */
export interface GetShipmentReasonsResponse {
    /**
     * 特売・積み増し（追加の出荷予定）情報の一覧
     * @type {Array<ShipmentReason>}
     * @memberof GetShipmentReasonsResponse
     */
    shipmentReasons: Array<ShipmentReason>;
}

export function GetShipmentReasonsResponseFromJSON(json: any): GetShipmentReasonsResponse {
    return GetShipmentReasonsResponseFromJSONTyped(json, false);
}

export function GetShipmentReasonsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetShipmentReasonsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shipmentReasons': ((json['shipmentReasons'] as Array<any>).map(ShipmentReasonFromJSON)),
    };
}

export function GetShipmentReasonsResponseToJSON(value?: GetShipmentReasonsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shipmentReasons': ((value.shipmentReasons as Array<any>).map(ShipmentReasonToJSON)),
    };
}


