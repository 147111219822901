import styled from 'styled-components'

const DistributionCenterCard = styled.div<{ selected: boolean }>`
  align-items: center;
  background-color: ${props => props.selected? '#f7f7f7' : 'transparent'};
  display: flex;
  font-weight: ${props => props.selected? 'bold' : 'normal' };
  min-height: 53px;
  width: 226px;

  &:hover {
    background-color: ${props => props.selected?  '#f7f7f7' : '#fafafa'};
    cursor: ${props => props.selected? 'default' : 'pointer'};
  }
`

const DistributionCenterCardLabel = styled.div`
  margin-left: 16px;
  width: calc(100% - 27px);
  overflow-wrap: break-word;
`

const DistributionCenterName = styled.div`
  font-weight: bold;
  margin-bottom: 9px;
`

const DistributionCentersContainer = styled.div`
  border-right: 1px solid var(--di-table-border-color);
  display: block;
  height: calc(100% - 32px);
  overflow-y: auto;
  margin-bottom: 16px;
  margin-top: 16px;
  padding-left: 8px;
  padding-right: 8px;
`

const DistributionCenterSKUPanelContainer = styled.div`
  border-top: 1px solid var(--di-table-border-color);
  display: block;
  margin-bottom: 20px;
  padding-left: 16px;
  padding-top: 16px;
`

const DistributionCenterSKUPanelsContainer = styled.div`
  display: block;
  min-width: 100%;
`

const HasDraftPurchaseOrderMessagePanel = styled.div`
  align-items: center;
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  display: flex;
  height: 40px;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 8px;
  padding-left: 16px;
`

const LoadingSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  min-width: 100%;
`

const MainContentsContainer = styled.div`
  margin-right: 16px;
  margin-top: 16px;
  height: calc(100% - 16px);
  overflow-y: auto;
  padding-left: 16px;
  width: 100%;
`

const OperatorName = styled.div`
  color: #999;
  margin-left: auto;
  margin-right: 16px;
  margin-top: 20px;
`

const PageContainer = styled.div`
  height: 100%;
`

const SKUCard = styled.div<{ selected: boolean }>`
  background-color: ${props => props.selected? '#f7f7f7' : 'transparent'};
  font-weight: ${props => props.selected? 'bold' : 'normal' };
  min-height: 62px;
  overflow-wrap: break-word;
  padding: 16px;
  width: 226px;

  &:hover {
    background-color: ${props => props.selected?  '#f7f7f7' : '#fafafa'};
    cursor: ${props => props.selected? 'default' : 'pointer'};
  }
`

const SKUCode = styled.div`
  font-weight: bold;
  margin-right: 16px;
`

const SKUHeader = styled.div`
  display: flex;
  margin-bottom: 9px;
`

const SKUName = styled.div`
  font-weight: bold;
  margin-right: 14px;
`

const SKUSpec = styled.div`
  font-weight: bold;
`

const SKUsContainer = styled.div`
  border-right: 1px solid var(--di-table-border-color);
  display: block;
  height: calc(100% - 32px);
  overflow-y: auto;
  margin-bottom: 16px;
  margin-top: 16px;
  padding-left: 8px;
  padding-right: 8px;
`

const TabBody = styled.div`
  background-color: #fff;
  display: flex;
  height: calc(100% - var(--di-page-header-height) - 48px);
`

const TabHeader = styled.div<{active: boolean}>`
  border-bottom: ${props => props.active? '2px solid #333' : 'none'};
  color: ${props => props.active? '#333' : '#999'};
  height: 48px;
  font-weight: bold;
  line-height: 48px;
  text-align: center;
  width: 110px;

  &:hover {
    color: #333;
    cursor: ${props => props.active? 'default' : 'pointer'};
  }
`

const TabHeaderContainer = styled.div`
  display: flex;
  height: 48px;
  padding-left: 16px;
`

const CollectiveAdjustContainer = styled.div`
  border-radius: 5px;
  height: 48px;
  text-decoration: none;
`

export {
  DistributionCenterCard,
  DistributionCenterCardLabel,
  DistributionCenterName,
  DistributionCentersContainer,
  DistributionCenterSKUPanelContainer,
  DistributionCenterSKUPanelsContainer,
  HasDraftPurchaseOrderMessagePanel,
  LoadingSpinnerContainer,
  MainContentsContainer,
  OperatorName,
  SKUCard,
  SKUCode,
  SKUHeader,
  SKUName,
  SKUSpec,
  SKUsContainer,
  PageContainer,
  TabBody,
  TabHeader,
  TabHeaderContainer,
  CollectiveAdjustContainer,
}
