import styled from 'styled-components'

import { ReactComponent as BaseCaretIcon } from '../../../assets/images/caret-down.svg'

const BaseSidebar = styled.div`
  background-color: #fff;
  border-right: 1px solid #e5e5e5;
  height: 100%;
  position: fixed;
  top: var(--global-header-height);
  width: 200px;
  z-index: 899;
`

const BaseSidebarCard = styled.div<{selected: boolean}>`
  align-items: center;
  background-color: ${props => props.selected? '#f7f7f7' : '#fff' };
  cursor: pointer;
  display: flex;
  height: 56px;
  padding-left: 24px;
  transition: background-color 0.3s ease-out;
  width: 176px;

  &:hover {
    background-color: #f7f7f7;
  }

  &:active {
    background-color: ${props => props.selected? '#f7f7f7' : '#a0a0a0' };
  }
`

const CaretIcon = styled(BaseCaretIcon)<{expanded : string}>`
  margin-left: auto;
  margin-right: 14px;
  transform: ${props => props.expanded === 'true'? 'scaleY(-1)' : 'none' };
  & path {
    fill: none;
    stroke: #707070;
    stroke-width: 2px;
  }
`

const ChildSidebarCardLabel = styled.div`
  color: var(--di-text-primary-color);
  font-size: 15px;
  margin-left: 24px;
`

const SidebarCardLabel = styled.div`
  color: var(--di-text-primary-color);
  font-size: 15px;
`

const SidebarCardIcon = styled.div`
  height: 24px;
  margin-right: 11px;
  width: 24px;
`

export {
  BaseSidebar,
  BaseSidebarCard,
  CaretIcon,
  ChildSidebarCardLabel,
  SidebarCardLabel,
  SidebarCardIcon,
}
