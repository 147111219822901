import styled from 'styled-components'


const AdjustmentFormContainer = styled.div`
  border-left: solid 1px var(--di-table-border-color);
  margin-left: 12px;
  padding-left: 12px;
`

const InventoryUnitShipmentChartContainer = styled.div`
  height: 450px;
  position: relative;
  width: 78%;
`

const InventoryUnitShipmentChartContainerOuter = styled.div`
  display: flex;
`

const Label = styled.div`
  color: #767676;
`

const LoadingSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
`

const Property = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 5px;
`

const SectionsContainer = styled.div`
  background-color: #fff;
  margin-bottom: 16px;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 16px;
  padding-left: 32px;
  padding-right: 16px;
  padding-top: 25px;
  padding-bottom: 16px;
`

const SectionTitle = styled.div`
  margin-bottom: 8px;
  margin-top: 13px;
`

const ToastContainer = styled.div`
  position: absolute;
  right: 22px;
  top: 107px;
  z-index: 399;
`

const Value = styled.div`
  margin-right: 20px;
`

const MessageAreaContainer = styled.div`
  right: 100px;
  top: 107px;
`

// 新規需要予測対応
const RadioButtonContainer = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 10px;
  margin-right: 0px;
  position: relative;
`

const RadioButtonLabel = styled.span`
  margin-left: 7px;
  margin-right: auto;
  color: #FF0000;
`

const RadioButtonContainerBlock = styled.div`
  display: block;
`

const RadioButtonContainerArea = styled.div`
  margin-left: auto;
  margin-right: 0;
  margin-top: 20px;
  margin-bottom: 5px;
  display: flex;
  width: 380px;
`

const RadioTitle = styled.div`
  font-weight: bold;
  width: 170px;
`

export {
  AdjustmentFormContainer,
  InventoryUnitShipmentChartContainer,
  InventoryUnitShipmentChartContainerOuter,
  Label,
  LoadingSpinnerContainer,
  Property,
  SectionsContainer,
  SectionTitle,
  ToastContainer,
  Value,
  MessageAreaContainer,
  RadioButtonContainer,
  RadioTitle,
  RadioButtonLabel,
  RadioButtonContainerArea,
  RadioButtonContainerBlock,
}
