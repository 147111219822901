import React, { useEffect, useRef, useState } from 'react'
import * as Sentry from '@sentry/react'
import { useParams } from 'react-router-dom'
import cloneDeep from 'lodash/cloneDeep'
import firebase from 'firebase/app'

import {
  User,
  Configuration,
  OrderTiming,
  DefaultApi,
  ApiResponse,
  GetLongTermDraftPurchaseOrderExcelRequest,
} from '../../api-client'

import { useDraftPurchaseOrderLongTerm } from '../../hooks'

import { Button, CheckBox, LoadingSpinner, PageHeader, SquareLabel, TextBox, Toast } from '../../components/atoms'

import {
  ContainerDetailPanel,
  ContainersListTable,
  InventoryCalendarTable,
  OrderTimingLabel,
} from '../../components/molecules'

import {
  CalculatedAtLabel,
  DownloadButtonContainer,
  CheckBoxContainer,
  ContainerListTableContainer,
  DetailContainer,
  DistributionCenterName,
  DistributionCenterSKUContainer,
  InventoryCalendarTableContainer,
  LoadingSpinnerContainer,
  MainContents,
  NoSearchHitMessage,
  NumberOfContainerLabel,
  OperatorName,
  SearchFormContainer,
  SearchHitCount,
  SectionTitle,
  SKUHeader,
  SKUCode,
  SKUName,
  SKUSpec,
  SquareLabelContainer,
  SummaryContainer,
  ToastContainer,
  Caution,
  PageTitle,
} from './style'

import './DraftPurchaseOrderLongTerm.css'
import { useDraftPurchaseOrderParamControl } from '../../hooks/useDraftPurchaseOrderParamControl'

interface PathParams {
  orderGroupId: string
}

export interface DraftPurchaseOrderLOngTermProps {
  /**
   * ログインユーザー
   */
  currentUser: User
}

export const DraftPurchaseOrderLongTerm: React.FC<DraftPurchaseOrderLOngTermProps> = ({ currentUser }) => {
  // 再レンダリングを行いたい場合に使う.
  // 全ての変更で再レンダリングしていると負荷がかかりすぎるので、必要な時にのみ実行すること.
  const forceUpdate = useState(false)[1]

  const { orderGroupId } = useParams<PathParams>()
  const orderGroupIdNum = Number.parseInt(orderGroupId)

  const {
    selectedContainerIndex,
    searchText,
    inventoryCalendarVisible,
    updateContainerInfo,
    updateSearchText,
    updateInventoryCalendarVisible,
  } = useDraftPurchaseOrderParamControl()

  const { draftPurchaseOrder, containerPlanDetail, getContainerPlan } = useDraftPurchaseOrderLongTerm(orderGroupIdNum)

  const draftPurchaseOrderRef = useRef(cloneDeep(draftPurchaseOrder))
  useEffect(() => {
    draftPurchaseOrderRef.current = cloneDeep(draftPurchaseOrder)
    forceUpdate((v) => !v)
  }, [draftPurchaseOrder, forceUpdate])

  const [highlightedWeekDate, setHighlightedWeekDate] = useState('')
  const [inventoryCalendarTableScrollLeft, setInventoryCalendarTableScrollLeft] = useState(0)

  const [toastVisible, setToastVisible] = useState(false)
  const toastMessageRef = useRef('')

  const blobToFile = (blob: Blob, response: ApiResponse<Blob>) => {
    const disposition = response.raw.headers.get('content-disposition')
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    const matches = filenameRegex.exec(disposition != null ? disposition : '')

    let filename
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '')
      filename = decodeURI(filename)
    }

    const url = window.URL.createObjectURL(new Blob([blob]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', filename != null ? filename : '')
    document.body.appendChild(link)
    link.click()
    link.parentNode?.removeChild(link)
  }

  return (
    <div>
      <PageHeader
        pageTitle={draftPurchaseOrderRef.current != null ? draftPurchaseOrderRef.current.orderGroupName : ''}
        backLink={false}
      />
      <OperatorName>
        担当者：
        {draftPurchaseOrderRef.current != null ? draftPurchaseOrderRef.current.operatorName : ''}
      </OperatorName>
      {toastVisible === true ? (
        <ToastContainer>
          <Toast
            message={toastMessageRef.current}
            onCloseHandler={() => setTimeout(() => setToastVisible(false), 300)}
          />
        </ToastContainer>
      ) : null}

      <MainContents>
        <PageTitle>先行発注</PageTitle>
        <SectionTitle>コンテナ一覧（先行発注あり）</SectionTitle>
        <Caution>※在庫過多にはご注意ください</Caution>
        <SummaryContainer>
          <NumberOfContainerLabel>コンテナ数：</NumberOfContainerLabel>
          <span>{draftPurchaseOrderRef.current != null ? draftPurchaseOrderRef.current.numberOfContainers : 0}本</span>
          <CalculatedAtLabel>計算時刻：</CalculatedAtLabel>
          <span>{`${
            draftPurchaseOrderRef.current != null ? draftPurchaseOrderRef.current.calculatedAt.getFullYear() : ''
          }年${
            draftPurchaseOrderRef.current != null ? draftPurchaseOrderRef.current.calculatedAt.getMonth() + 1 : ''
          }月${draftPurchaseOrderRef.current != null ? draftPurchaseOrderRef.current.calculatedAt.getDate() : ''}日 ${
            draftPurchaseOrderRef.current != null ? draftPurchaseOrderRef.current.calculatedAt.getHours() : ''
          }:${(draftPurchaseOrderRef.current != null
            ? '00' + draftPurchaseOrderRef.current.calculatedAt.getMinutes()
            : ''
          ).slice(-2)}`}</span>
          {draftPurchaseOrder && draftPurchaseOrder.orderTiming !== OrderTiming.Default ? (
            <span className="order-timing-label-container" data-testid="long-term-order-timing-label-container-id">
              <OrderTimingLabel orderTiming={draftPurchaseOrder.orderTiming} />
            </span>
          ) : null}
          <DownloadButtonContainer>
            <Button
              styleType="secondary"
              label="起案書ダウンロード"
              width={178}
              onClickHandler={() => {
                firebase
                  .auth()
                  .currentUser?.getIdToken(true)
                  .then((token) => {
                    const conf = new Configuration({
                      basePath: process.env.REACT_APP_API_PATH,
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    })
                    const api = new DefaultApi(conf)
                    const params: GetLongTermDraftPurchaseOrderExcelRequest = {
                      orderGroupId: orderGroupIdNum,
                    }
                    return api
                      .getLongTermDraftPurchaseOrderExcelRaw(params)
                      .then((response) => {
                        return response.value().then((blob) => blobToFile(blob, response))
                      })
                      .catch((apiErr) => {
                        console.log('Apiエラー' + apiErr.status)
                        if (apiErr.status === 404) {
                          toastMessageRef.current = 'ダウンロードできる発注案がありません。'
                          setToastVisible(true)
                        }
                      })
                  })
                  .catch((err) => {
                    Sentry.captureException(err)
                  })
              }}
              data-testid="long-term-download-draft-purchase-order-excel"
            />
          </DownloadButtonContainer>
        </SummaryContainer>
        {draftPurchaseOrder != null ? (
          <React.Fragment>
            <ContainerListTableContainer>
              <ContainersListTable
                containers={draftPurchaseOrder.containers}
                selectedContainerIndex={selectedContainerIndex}
                onContainerSelectedHandler={(index) => {
                  updateContainerInfo(index, draftPurchaseOrder.containers[index].id)
                  getContainerPlan(draftPurchaseOrder.containers[index].id)
                }}
              />
            </ContainerListTableContainer>
          </React.Fragment>
        ) : (
          <LoadingSpinnerContainer>
            <LoadingSpinner />
          </LoadingSpinnerContainer>
        )}
        <DetailContainer>
          <SectionTitle>コンテナ詳細</SectionTitle>
          <SearchFormContainer>
            <TextBox
              id="search-text-input"
              label=""
              type="search"
              height={40}
              width={350}
              placeholder="JANコード・商品名の検索"
              defaultValue={searchText}
              onChangeHandler={(value) => updateSearchText(value)}
            />
          </SearchFormContainer>
          <SearchHitCount>
            {containerPlanDetail != null
              ? containerPlanDetail.inventoryUnitSKUs.filter(
                  (inventoryUnitSKU) =>
                    searchText === '' ||
                    inventoryUnitSKU.janCode.includes(searchText) ||
                    (inventoryUnitSKU.skuName != null && inventoryUnitSKU.skuName.includes(searchText)) ||
                    (inventoryUnitSKU.skuSpecName != null && inventoryUnitSKU.skuSpecName.includes(searchText))
                ).length
              : 0}
            件
          </SearchHitCount>
          <CheckBoxContainer>
            <CheckBox
              key={`input_${inventoryCalendarVisible}`}
              id="checkbox"
              label="在庫計画を表示"
              labelMargin={20}
              defaultValue={inventoryCalendarVisible}
              onChangeHandler={(value) => updateInventoryCalendarVisible(value)}
            />
          </CheckBoxContainer>
        </DetailContainer>
        {draftPurchaseOrder != null && containerPlanDetail != null ? (
          <React.Fragment>
            {containerPlanDetail.inventoryUnitSKUs
              .filter(
                (inventoryUnitSKU) =>
                  searchText === '' ||
                  inventoryUnitSKU.janCode.includes(searchText) ||
                  (inventoryUnitSKU.skuName != null && inventoryUnitSKU.skuName.includes(searchText)) ||
                  (inventoryUnitSKU.skuSpecName != null && inventoryUnitSKU.skuSpecName.includes(searchText))
              )
              .map((inventoryUnitSKU, idx) => (
                <DistributionCenterSKUContainer key={idx}>
                  <SKUHeader>
                    <DistributionCenterName>【{inventoryUnitSKU.inventoryUnitName}】</DistributionCenterName>
                    <SKUCode>{inventoryUnitSKU.janCode}</SKUCode>
                    {inventoryUnitSKU.skuName != null ? <SKUName>{inventoryUnitSKU.skuName}</SKUName> : null}
                    {inventoryUnitSKU.skuSpecName != null ? <SKUSpec>{inventoryUnitSKU.skuSpecName}</SKUSpec> : null}
                    {inventoryUnitSKU.skuName == null && inventoryUnitSKU.skuSpecName == null ? <b>-</b> : null}
                    <span>　</span>
                    {inventoryUnitSKU.isReplenishmentTarget === true ? (
                      <SquareLabelContainer>
                        <SquareLabel color="red" text="欠品補充対象" width={100} />
                      </SquareLabelContainer>
                    ) : null}
                    {inventoryUnitSKU.over26weeks === true ? (
                      <SquareLabelContainer>
                        <SquareLabel color="blue" text="入荷後在庫週数26週以上" width={180} />
                      </SquareLabelContainer>
                    ) : null}
                  </SKUHeader>
                  <ContainerDetailPanel inventoryUnitSKU={inventoryUnitSKU} />
                  {inventoryCalendarVisible === true ? (
                    <InventoryCalendarTableContainer>
                      <InventoryCalendarTable
                        inventoryCalendar={inventoryUnitSKU.inventoryCalendar}
                        inventoryCalendarOrigin={inventoryUnitSKU.inventoryCalendar}
                        yardQuantity={inventoryUnitSKU.yardQuantity}
                        weeks={containerPlanDetail.weeks}
                        highlightedWeekDate={highlightedWeekDate}
                        scrollLeft={inventoryCalendarTableScrollLeft}
                        simulationAvailable={false}
                        readonly={true}
                        onClickColumnHandler={(weekDate) => setHighlightedWeekDate(weekDate)}
                        onScrollHandler={(scrollLeft) => setInventoryCalendarTableScrollLeft(scrollLeft)}
                        onReceivingQuantityForSimulationChangeHandler={() => {}}
                      />
                    </InventoryCalendarTableContainer>
                  ) : null}
                </DistributionCenterSKUContainer>
              ))}
            {containerPlanDetail.inventoryUnitSKUs.filter(
              (inventoryUnitSKU) =>
                searchText === '' ||
                inventoryUnitSKU.janCode.includes(searchText) ||
                (inventoryUnitSKU.skuName != null && inventoryUnitSKU.skuName.includes(searchText)) ||
                (inventoryUnitSKU.skuSpecName != null && inventoryUnitSKU.skuSpecName.includes(searchText))
            ).length === 0 ? (
              <NoSearchHitMessage>検索結果がありません。</NoSearchHitMessage>
            ) : null}
          </React.Fragment>
        ) : (
          <LoadingSpinnerContainer>
            <LoadingSpinner />
          </LoadingSpinnerContainer>
        )}
      </MainContents>
    </div>
  )
}
