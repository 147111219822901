import styled from 'styled-components'

const CountLabel = styled.div`
  margin-bottom: 8px;
`

const FilterContainer = styled.div`
  margin-right: 16px;
`

const FilterLabel = styled.div`
  margin-bottom: 4px;
`

const FiltersContainer = styled.div`
  align-items: flex-end;
  background-color: #f1f1f1;
  display: flex;
  height: 82px;
  margin-left: 48px;
  margin-right: 48px;
  padding-bottom: 18px;
  padding-left: 20px;
`

const LoadingSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`

const OrderGroupsWithDraftPOListTableContaiiner = styled.div`
  margin-left: 48px;
  margin-right: 48px;
  margin-top: 8px;
`

const OrderGroupsWithDraftPOListTableTitle = styled.h2`
  margin-bottom: 16px;
  margin-left: 48px;
  margin-top: 32px;
`

export {
  CountLabel,
  FilterContainer,
  FilterLabel,
  FiltersContainer,
  LoadingSpinnerContainer,
  OrderGroupsWithDraftPOListTableContaiiner,
  OrderGroupsWithDraftPOListTableTitle,
}
