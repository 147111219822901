import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import {
  AdjustmentType,
  GetInventoryUnitShipmentPlanResponseErrorLevelInLast4WeeksEnum,
  GetInventoryUnitShipmentPlanResponseErrorLevelInLast13WeeksEnum,
  User,
  UserRole,
  AdjustmentTarget,
  GetInventoryUnitShipmentPlanSelectedDemandForecastEnum,
} from '../../api-client'

import { useInventoryUnitShipmentPlan } from '../../hooks'

import {
  InventoryUnitShipmentCalendarTable,
  InventoryUnitShipmentChart,
  LoadingSpinner,
  RoundLabel,
  RoundLabelProps,
  PageHeader,
  Toast,
  RadioButton,
} from '../../components/atoms'

//InventoryCalenderTableの要素をインポート
import { ShipmentCalenderTable, ChangeLineMethod } from '../../components/atoms'

import { AdjustmentForm } from '../../components/molecules'

import {
  AdjustmentFormContainer,
  InventoryUnitShipmentChartContainer,
  InventoryUnitShipmentChartContainerOuter,
  Label,
  LoadingSpinnerContainer,
  Property,
  SectionsContainer,
  SectionTitle,
  ToastContainer,
  Value,
  RadioButtonContainer,
  RadioButtonLabel,
  RadioTitle,
  RadioButtonContainerArea,
  RadioButtonContainerBlock,
} from './style'
import { useUrlParams } from '../../hooks/useUrlParams'

interface PathParams {
  inventoryUnitCode: string
  janCode: string
}

export interface InventoryUnitShipmentPlanProps {
  currentUser: User
}

const getParameter = {
  selectedDemandForecast: 'selectedDemandForecast',
} as const

export const InventoryUnitShipmentPlan: React.FC<InventoryUnitShipmentPlanProps> = ({ currentUser }) => {
  const forceUpdate = useState(false)[1]

  const { inventoryUnitCode, janCode } = useParams<PathParams>()
  const urlParams = useUrlParams()
  const search = useLocation().search
  const query = new URLSearchParams(search)
  const defaultSelectedDemandForecast =
    query.get(getParameter.selectedDemandForecast) ===
    GetInventoryUnitShipmentPlanSelectedDemandForecastEnum.SalesComposition
      ? GetInventoryUnitShipmentPlanSelectedDemandForecastEnum.SalesComposition
      : GetInventoryUnitShipmentPlanSelectedDemandForecastEnum.DemandInsight

  const {
    shipmentPlan,
    getInventoryUnitShipmentPlan,
    putInventoryUnitShipmentPlan,
    saveParams,
    onChangeSelectedForecast,
    inventoryUnitShipmentPlanParams,
    setInventoryUnitShipmentPlanParams,
    isInitialParams,
  } = useInventoryUnitShipmentPlan(inventoryUnitCode, janCode, defaultSelectedDemandForecast)

  const adjustmentCustomRate = useRef<number | null>(null)
  const numberOfWeeksToAdjust = useRef<number | null>(null)

  useEffect(() => {
    if (shipmentPlan != null) {
      adjustmentCustomRate.current = shipmentPlan.permanentAdjustmentCustomRate
      numberOfWeeksToAdjust.current = shipmentPlan.permanentNumberOfWeeksToAdjust
    } else {
      adjustmentCustomRate.current = null
      numberOfWeeksToAdjust.current = null
    }
    forceUpdate((v) => !v)
  }, [shipmentPlan, forceUpdate])

  // shipment_planが更新されたときに親windowをreloadして値を反映させる
  const [reloadStatus, setReloadStatus] = useState(false)
  useEffect(() => {
    if (window.opener != null && reloadStatus === true) {
      window.opener.location.reload()
      setReloadStatus(false)
    }
  }, [reloadStatus])

  const [toastMessage, setToastMessage] = useState('')
  const [toastVisible, setToastVisible] = useState(false)

  const errorLevelToRoundLabelProps = (
    errorLevel:
      | GetInventoryUnitShipmentPlanResponseErrorLevelInLast4WeeksEnum
      | GetInventoryUnitShipmentPlanResponseErrorLevelInLast13WeeksEnum
  ): RoundLabelProps => {
    switch (errorLevel) {
      case GetInventoryUnitShipmentPlanResponseErrorLevelInLast4WeeksEnum.High:
        return {
          color: 'red',
          text: '50%以上',
          width: 80,
        }
      case GetInventoryUnitShipmentPlanResponseErrorLevelInLast4WeeksEnum.Middle:
        return {
          color: 'yellow',
          text: '20%以上',
          width: 80,
        }
      case GetInventoryUnitShipmentPlanResponseErrorLevelInLast4WeeksEnum.Low:
        return {
          color: 'green',
          text: '20%未満',
          width: 80,
        }
      default: // このケースは通らない想定
        return {
          color: 'green',
          text: '',
          width: 0,
        }
    }
  }

  const shipmentCalenderRef = useRef<ChangeLineMethod>(null)
  const radioClick = (selectedValue: string) => {
    shipmentCalenderRef.current?.changeLine(selectedValue)
  }

  return (
    <>
      <PageHeader
        pageTitle={
          shipmentPlan == null
            ? ''
            : shipmentPlan.skuName != null && shipmentPlan.skuSpecName != null
            ? `【${shipmentPlan.inventoryUnitName}】${shipmentPlan.janCode} ${shipmentPlan.skuName}・${shipmentPlan.skuSpecName}`
            : shipmentPlan.skuName != null && shipmentPlan.skuSpecName == null
            ? `【${shipmentPlan.inventoryUnitName}】${shipmentPlan.janCode} ${shipmentPlan.skuName}`
            : shipmentPlan.skuName == null && shipmentPlan.skuSpecName != null
            ? `【${shipmentPlan.inventoryUnitName}】${shipmentPlan.janCode} ${shipmentPlan.skuSpecName}`
            : `【${shipmentPlan.inventoryUnitName}】${shipmentPlan.janCode} -`
        }
        backLink={true}
        linkLabel="在庫計画画面"
        backLinkURL={`/order-groups/${shipmentPlan?.orderGroupId}/inventory-plan`}
      />
      {toastVisible === true ? (
        <ToastContainer>
          <Toast
            message={toastMessage}
            onCloseHandler={() => {
              setTimeout(() => setToastVisible(false), 300)
            }}
          />
        </ToastContainer>
      ) : null}
      <SectionsContainer>
        <Property>
          <Label>直近4週誤差：</Label>
          {shipmentPlan != null ? (
            <Value>
              {shipmentPlan.errorLevelInLast4Weeks ===
              GetInventoryUnitShipmentPlanResponseErrorLevelInLast4WeeksEnum.Unavailable ? (
                <span>-</span>
              ) : (
                <RoundLabel {...errorLevelToRoundLabelProps(shipmentPlan.errorLevelInLast4Weeks)} />
              )}
            </Value>
          ) : null}
          <Label>直近13週誤差：</Label>
          {shipmentPlan != null ? (
            <Value>
              {shipmentPlan.errorLevelInLast13Weeks ===
              GetInventoryUnitShipmentPlanResponseErrorLevelInLast13WeeksEnum.Unavailable ? (
                <span>-</span>
              ) : (
                <RoundLabel {...errorLevelToRoundLabelProps(shipmentPlan.errorLevelInLast13Weeks)} />
              )}
            </Value>
          ) : null}
          <Label>補正：</Label>
          {shipmentPlan != null ? (
            <Value>
              <b>{shipmentPlan.isAdjusted === true ? 'あり' : 'なし'}</b>
            </Value>
          ) : null}
        </Property>

        <ShipmentCalenderTable ref={shipmentCalenderRef}></ShipmentCalenderTable>
        <RadioButtonContainerArea>
          <RadioTitle>需要予測の出力切り替え</RadioTitle>
          <RadioButtonContainerBlock>
            <RadioButtonContainer>
              <RadioButton
                id="inventoryUnitShipmentPlan-form-radio-button-demand-insight"
                checked={inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight}
                disabled={false}
                onChangeHandler={function () {
                  setInventoryUnitShipmentPlanParams({
                    ...inventoryUnitShipmentPlanParams,
                    selectedDemandForecast: AdjustmentTarget.DemandInsight,
                  })
                  radioClick(AdjustmentTarget.DemandInsight)
                  onChangeSelectedForecast(AdjustmentTarget.DemandInsight)
                  urlParams.set(getParameter.selectedDemandForecast, AdjustmentTarget.DemandInsight)
                }}
              />
              <RadioButtonLabel>demand insightによる予測</RadioButtonLabel>
            </RadioButtonContainer>
            <RadioButtonContainer>
              <RadioButton
                id="inventoryUnitShipmentPlan-form-radio-button-sales-composition"
                checked={inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.SalesComposition}
                disabled={false}
                onChangeHandler={function () {
                  setInventoryUnitShipmentPlanParams({
                    ...inventoryUnitShipmentPlanParams,
                    selectedDemandForecast: AdjustmentTarget.SalesComposition,
                  })
                  radioClick(AdjustmentTarget.SalesComposition)
                  onChangeSelectedForecast(AdjustmentTarget.SalesComposition)
                  urlParams.set(getParameter.selectedDemandForecast, AdjustmentTarget.SalesComposition)
                }}
              />
              <RadioButtonLabel>売上構成比による予測</RadioButtonLabel>
            </RadioButtonContainer>
          </RadioButtonContainerBlock>
        </RadioButtonContainerArea>

        {shipmentPlan != null ? (
          <InventoryUnitShipmentChartContainerOuter>
            <InventoryUnitShipmentChartContainer>
              <InventoryUnitShipmentChart
                pastWeeks={shipmentPlan.pastWeeks}
                presentAndFutureWeeks={shipmentPlan.presentAndFutureWeeks}
                pastInventoryUnitShipmentCalendar={shipmentPlan.pastShipmentCalendar}
                presentAndFutureInventoryUnitShipmentCalendar={shipmentPlan.presentAndFutureShipmentCalendar}
                etaWeekDate={shipmentPlan.etaWeekDate}
              />
            </InventoryUnitShipmentChartContainer>
            <AdjustmentFormContainer>
              <AdjustmentForm
                achievementRateIn4Weeks={
                  inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight
                    ? shipmentPlan.achievementRateIn4Weeks
                    : shipmentPlan.salesCompositionAchievementRateIn4Weeks
                }
                achievementRateIn13Weeks={
                  inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight
                    ? shipmentPlan.achievementRateIn13Weeks
                    : shipmentPlan.salesCompositionAchievementRateIn13Weeks
                }
                averageSalesIn4Weeks={
                  inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight
                    ? shipmentPlan.averageSalesIn4Weeks
                    : shipmentPlan.salesCompositionAverageSalesIn4Weeks
                }
                averageSalesIn13Weeks={
                  inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight
                    ? shipmentPlan.averageSalesIn13Weeks
                    : shipmentPlan.salesCompositionAverageSalesIn13Weeks
                }
                yearOverYearIn4Weeks={
                  inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight
                    ? shipmentPlan.yearOverYearIn4Weeks
                    : shipmentPlan.salesCompositionYearOverYearIn4Weeks
                }
                yearOverYearIn13Weeks={
                  inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight
                    ? shipmentPlan.yearOverYearIn13Weeks
                    : shipmentPlan.salesCompositionYearOverYearIn13Weeks
                }
                currentAdjustmentType={
                  inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight
                    ? inventoryUnitShipmentPlanParams.demandInsightParams.adjustmentType
                    : inventoryUnitShipmentPlanParams.salesCompositionParams?.adjustmentType
                }
                selectableAdjustmentType={
                  // 「補正なし」以外が補正登録されていて, 一時補正条件が未指定または同じ条件（補正範囲だけが違う条件）の場合は「補正なし」しか選べないようにする.
                  shipmentPlan.permanentAdjustmentType !== AdjustmentType.NoAdjustment &&
                  (shipmentPlan.temporaryAdjustmentType === null ||
                    shipmentPlan.temporaryAdjustmentType === shipmentPlan.permanentAdjustmentType)
                    ? AdjustmentType.NoAdjustment
                    : // 「補正なし」以外が補正登録されていて,「補正なし」が一時補正条件として指定されている場合はすでに補正登録されている条件しか選べないようにする.
                    shipmentPlan.permanentAdjustmentType !== AdjustmentType.NoAdjustment &&
                      shipmentPlan.temporaryAdjustmentType === AdjustmentType.NoAdjustment
                    ? shipmentPlan.permanentAdjustmentType
                    : null
                }
                currentAdjustmentCustomRate={
                  inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight
                    ? inventoryUnitShipmentPlanParams.demandInsightParams.adjustmentCustomRate
                    : inventoryUnitShipmentPlanParams.salesCompositionParams.adjustmentCustomRate
                }
                currentAdjustmentRange={
                  inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight
                    ? inventoryUnitShipmentPlanParams.demandInsightParams.adjustmentRange ?? null
                    : inventoryUnitShipmentPlanParams.salesCompositionParams.adjustmentRange ?? null
                }
                currentNumberOfWeeksToAdjust={
                  inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight
                    ? inventoryUnitShipmentPlanParams.demandInsightParams.numberOfWeeksToAdjust
                    : inventoryUnitShipmentPlanParams.salesCompositionParams.numberOfWeeksToAdjust
                }
                readonly={currentUser.role !== UserRole.Manager && shipmentPlan.operatorId !== currentUser.id}
                onChangeHandler={(
                  adjustmentType,
                  adjustmentCustomRate,
                  adjustmentRange,
                  numberOfWeeksToAdjust,
                  selectedDemandForecast
                ) => {
                  saveParams({
                    adjustmentType,
                    adjustmentCustomRate,
                    adjustmentRange: adjustmentRange ?? undefined,
                    numberOfWeeksToAdjust,
                    selectedDemandForecast,
                  })

                  getInventoryUnitShipmentPlan(
                    adjustmentType,
                    adjustmentCustomRate != null ? adjustmentCustomRate : undefined,
                    adjustmentRange != null ? adjustmentRange : undefined,
                    numberOfWeeksToAdjust != null ? numberOfWeeksToAdjust : undefined,
                    selectedDemandForecast
                  )
                }}
                onClickSaveButtonHandler={(
                  adjustmentType,
                  adjustmentCustomRate,
                  adjustmentRange,
                  numberOfWeeksToAdjust,
                  selectedDemandForecast
                ) => {
                  putInventoryUnitShipmentPlan(
                    adjustmentType,
                    adjustmentCustomRate,
                    adjustmentRange,
                    numberOfWeeksToAdjust,
                    selectedDemandForecast
                  )?.then(() => {
                    getInventoryUnitShipmentPlan(
                      adjustmentType,
                      adjustmentCustomRate != null ? adjustmentCustomRate : undefined,
                      adjustmentRange != null ? adjustmentRange : undefined,
                      numberOfWeeksToAdjust != null ? numberOfWeeksToAdjust : undefined,
                      selectedDemandForecast
                    )
                    setReloadStatus(true)
                    setToastMessage('補正登録しました。')
                    setToastVisible(true)
                  })
                }}
                selectedDemandForecast={inventoryUnitShipmentPlanParams.selectedDemandForecast}
              />
            </AdjustmentFormContainer>
          </InventoryUnitShipmentChartContainerOuter>
        ) : (
          <LoadingSpinnerContainer>
            <LoadingSpinner />
          </LoadingSpinnerContainer>
        )}

        <SectionTitle>出荷予定数内訳</SectionTitle>
        {shipmentPlan != null ? (
          <InventoryUnitShipmentCalendarTable
            pastWeeks={shipmentPlan.pastWeeks}
            presentAndFutureWeeks={shipmentPlan.presentAndFutureWeeks}
            pastInventoryUnitShipmentCalendar={shipmentPlan.pastShipmentCalendar}
            presentAndFutureInventoryUnitShipmentCalendar={shipmentPlan.presentAndFutureShipmentCalendar}
            selectedDemandForecast={inventoryUnitShipmentPlanParams.selectedDemandForecast}
            isInitialParams={isInitialParams}
          />
        ) : (
          <LoadingSpinnerContainer>
            <LoadingSpinner />
          </LoadingSpinnerContainer>
        )}
      </SectionsContainer>
    </>
  )
}
