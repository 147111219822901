/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for OMR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 特売・積み増し（追加の出荷予定）情報
 * @export
 * @interface ShipmentReason
 */
export interface ShipmentReason {
    /**
     * JANコード
     * @type {string}
     * @memberof ShipmentReason
     */
    janCode: string;
    /**
     * 商品名
     * @type {string}
     * @memberof ShipmentReason
     */
    skuName: string;
    /**
     * 規格名
     * @type {string}
     * @memberof ShipmentReason
     */
    skuSpecName: string;
    /**
     * 在庫管理単位コード
     * @type {string}
     * @memberof ShipmentReason
     */
    inventoryUnitCode: string;
    /**
     * 在庫管理単位の名前
     * @type {string}
     * @memberof ShipmentReason
     */
    inventoryUnitName: string;
    /**
     * 物流パターン名
     * @type {string}
     * @memberof ShipmentReason
     */
    logisticPatternName: string;
}

export function ShipmentReasonFromJSON(json: any): ShipmentReason {
    return ShipmentReasonFromJSONTyped(json, false);
}

export function ShipmentReasonFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipmentReason {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'janCode': json['janCode'],
        'skuName': json['skuName'],
        'skuSpecName': json['skuSpecName'],
        'inventoryUnitCode': json['inventoryUnitCode'],
        'inventoryUnitName': json['inventoryUnitName'],
        'logisticPatternName': json['logisticPatternName'],
    };
}

export function ShipmentReasonToJSON(value?: ShipmentReason | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'janCode': value.janCode,
        'skuName': value.skuName,
        'skuSpecName': value.skuSpecName,
        'inventoryUnitCode': value.inventoryUnitCode,
        'inventoryUnitName': value.inventoryUnitName,
        'logisticPatternName': value.logisticPatternName,
    };
}


