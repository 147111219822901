import React, { useEffect, useRef } from 'react'
import Chart, { ChartConfiguration, ChartData, ChartOptions } from 'chart.js/auto'
import { LineController } from 'chart.js'

import { InventoryUnitShipmentPlanningWeek, InventoryUnitShipmentPlanPerWeek } from '../../../api-client'

// Ref: https://www.chartjs.org/docs/latest/developers/charts.html
declare module 'chart.js' {
  interface ChartTypeRegistry {
    customLine: ChartTypeRegistry['line']
  }
}

interface CustomChartOptions extends ChartOptions {
  /**
   * 当週
   */
  currentWeek: number

  /**
   * LT
   */
  leadtimeWeek: number | null

  /**
   * 各週に特売・プロモがあるかどうか
   */
  promotionWeek: Array<boolean>
}

class CustomLineController extends LineController {
  // TODO: jsdom の canvas だと draw が発火しなさそうなので時間があるときにワークアラウンドを検討してカバレッジを通す
  /* istanbul ignore next */
  draw() {
    super.draw()

    const currentWeek = (this.chart.options as CustomChartOptions).currentWeek
    const leadtimeWeek = (this.chart.options as CustomChartOptions).leadtimeWeek
    const promotionWeek = (this.chart.options as CustomChartOptions).promotionWeek

    const pointCurrentWeek = this.getMeta().data[currentWeek]

    if (this.getMeta().data.length === 0) {
      return
    }

    this.chart.ctx.save()

    // もし計画週が空だったりした場合は当週もLTも正常なグラフは表示できない
    // 運用上ないかもしれないが, 理論上存在するため考慮しておく.
    if (pointCurrentWeek != null) {
      /**
       * 当週の線を引く
       */
      this.chart.ctx.strokeStyle = '#757575'
      this.chart.ctx.lineWidth = 2
      this.chart.ctx.beginPath()
      this.chart.ctx.moveTo(pointCurrentWeek.x, 1000)
      this.chart.ctx.lineTo(pointCurrentWeek.x, 40)
      this.chart.ctx.stroke()

      this.chart.ctx.fillStyle = '#333'
      this.chart.ctx.font = '13px "Noto Sans JP"'
      // 実績週がない場合, 当週は一番左側に寄るため文字が描画領域からはみ出さないように調整する.
      const currentWeekX = currentWeek > 0 ? pointCurrentWeek.x - 13 : pointCurrentWeek.x
      this.chart.ctx.fillText('当週', currentWeekX, 30)
    }

    if (leadtimeWeek != null) {
      /**
       * LT の線を引く
       */
      const pointLeadtimeWeek = this.getMeta().data[leadtimeWeek]
      this.chart.ctx.strokeStyle = '#757575'
      this.chart.ctx.lineWidth = 2
      this.chart.ctx.beginPath()
      this.chart.ctx.moveTo(pointLeadtimeWeek.x, 1000)
      this.chart.ctx.lineTo(pointLeadtimeWeek.x, 40)
      this.chart.ctx.stroke()

      this.chart.ctx.fillStyle = '#333'
      this.chart.ctx.font = '13px "Noto Sans JP"'
      this.chart.ctx.fillText('ETA', pointLeadtimeWeek.x - 7, 30)
    }

    /**
     * 特売・プロモの週をハイライトする.
     */
    promotionWeek.forEach((v, idx) => {
      if (v === true) {
        const pointPromotion = this.getMeta().data[idx]
        const point0 = this.getMeta().data[0]
        const point1 = this.getMeta().data[1]
        const distance = point1.x - point0.x

        this.chart.ctx.beginPath()
        this.chart.ctx.fillStyle = '#3333331A'
        this.chart.ctx.fillRect(pointPromotion.x, 0, distance, 1000)

        // 文字列が重ならないように間隔を開ける
        if (
          idx === 0 ||
          (idx === 1 && promotionWeek[0] === false) ||
          (idx === 2 && promotionWeek[0] === false && promotionWeek[1] === false) ||
          (idx === 3 && promotionWeek[0] === false && promotionWeek[1] === false && promotionWeek[2] === false) ||
          (promotionWeek[idx - 4] === false &&
            promotionWeek[idx - 3] === false &&
            promotionWeek[idx - 2] === false &&
            promotionWeek[idx - 1] === false)
        ) {
          this.chart.ctx.fillStyle = '#333'
          this.chart.ctx.font = '13px "Noto Sans JP"'
          this.chart.ctx.fillText('特売・プロモ期間', pointPromotion.x, 30)
        }
      }
    })

    this.chart.ctx.restore()
  }
}
CustomLineController.id = 'customLine'
CustomLineController.defaults = LineController.defaults
Chart.register(CustomLineController)

export interface InventoryUnitShipmentChartProps {
  /**
   * 実績週
   */
  pastWeeks: Array<InventoryUnitShipmentPlanningWeek>

  /**
   * 計画週
   */
  presentAndFutureWeeks: Array<InventoryUnitShipmentPlanningWeek>

  /**
   * 実績週のInventoryUnit出荷予定
   */
  pastInventoryUnitShipmentCalendar: { [key: string]: InventoryUnitShipmentPlanPerWeek }

  /**
   * 計画週のInventoryUnit出荷予定
   */
  presentAndFutureInventoryUnitShipmentCalendar: { [key: string]: InventoryUnitShipmentPlanPerWeek }

  /**
   * リードタイム週の日付
   */
  etaWeekDate: string | null
}

export const InventoryUnitShipmentChart: React.FC<InventoryUnitShipmentChartProps> = ({
  pastWeeks,
  presentAndFutureWeeks,
  pastInventoryUnitShipmentCalendar,
  presentAndFutureInventoryUnitShipmentCalendar,
  etaWeekDate,
}) => {
  const canvas = useRef<HTMLCanvasElement>(null)
  const chart = useRef<Chart | null>(null)

  useEffect(() => {
    if (canvas.current == null) {
      return
    }

    const labels = [...pastWeeks.map((w) => w.date), ...presentAndFutureWeeks.map((w) => w.date)]
    const permanentlyPlannedSalesQuantity = [
      ...pastWeeks.map((w) =>
        pastInventoryUnitShipmentCalendar[w.date] != null
          ? pastInventoryUnitShipmentCalendar[w.date].permanentlyPlannedSalesQuantity
          : null
      ),
      ...presentAndFutureWeeks.map((w) =>
        presentAndFutureInventoryUnitShipmentCalendar[w.date] != null
          ? presentAndFutureInventoryUnitShipmentCalendar[w.date].permanentlyPlannedSalesQuantity
          : null
      ),
    ]
    const temporarilyPlannedSalesQuantity = [
      ...pastWeeks.map((w) =>
        pastInventoryUnitShipmentCalendar[w.date] != null
          ? pastInventoryUnitShipmentCalendar[w.date].temporarilyPlannedSalesQuantity
          : null
      ),
      ...presentAndFutureWeeks.map((w) =>
        presentAndFutureInventoryUnitShipmentCalendar[w.date] != null
          ? presentAndFutureInventoryUnitShipmentCalendar[w.date].temporarilyPlannedSalesQuantity
          : null
      ),
    ]
    const actualSalesQuantity = [
      ...pastWeeks.map((w) =>
        pastInventoryUnitShipmentCalendar[w.date] != null
          ? pastInventoryUnitShipmentCalendar[w.date].actualSalesQuantity
          : null
      ),
      ...presentAndFutureWeeks.map((w) =>
        presentAndFutureInventoryUnitShipmentCalendar[w.date] != null
          ? presentAndFutureInventoryUnitShipmentCalendar[w.date].actualSalesQuantity
          : null
      ),
    ]
    const promotionWeek = [
      ...pastWeeks.map((w) => {
        if (pastInventoryUnitShipmentCalendar[w.date] == null) {
          return false
        }
        return pastInventoryUnitShipmentCalendar[w.date].plannedSalesOverwriteQuantity != null
      }),
      ...presentAndFutureWeeks.map((w) => {
        if (presentAndFutureInventoryUnitShipmentCalendar[w.date] == null) {
          return false
        }
        return presentAndFutureInventoryUnitShipmentCalendar[w.date].plannedSalesOverwriteQuantity != null
      }),
    ]
    const lastYearActualSalesQuantity = [
      ...pastWeeks.map((w) =>
        pastInventoryUnitShipmentCalendar[w.date] != null
          ? pastInventoryUnitShipmentCalendar[w.date].lastYearActualSalesQuantity
          : null
      ),
      ...presentAndFutureWeeks.map((w) =>
        presentAndFutureInventoryUnitShipmentCalendar[w.date] != null
          ? presentAndFutureInventoryUnitShipmentCalendar[w.date].lastYearActualSalesQuantity
          : null
      ),
    ]
    const permanentlySalesCompositionQuantity = [
      ...pastWeeks.map((w) =>
        pastInventoryUnitShipmentCalendar[w.date] != null
          ? pastInventoryUnitShipmentCalendar[w.date].permanentlySalesCompositionQuantity
          : null
      ),
      ...presentAndFutureWeeks.map((w) =>
        presentAndFutureInventoryUnitShipmentCalendar[w.date] != null
          ? presentAndFutureInventoryUnitShipmentCalendar[w.date].permanentlySalesCompositionQuantity
          : null
      ),
    ]
    const noAdjustmentSalesQuantity = [
      ...pastWeeks.map((w) =>
        pastInventoryUnitShipmentCalendar[w.date] != null
          ? pastInventoryUnitShipmentCalendar[w.date].noAdjustmentPlannedSalesQuantity
          : null
      ),
      ...presentAndFutureWeeks.map((w) =>
        presentAndFutureInventoryUnitShipmentCalendar[w.date] != null
          ? presentAndFutureInventoryUnitShipmentCalendar[w.date].noAdjustmentPlannedSalesQuantity
          : null
      ),
    ]
    const datasets = []
    datasets.push({
      label: 'DI需要予測数',
      data: [...permanentlyPlannedSalesQuantity],
      backgroundColor: '#fff',
      borderColor: '#00f',
      pointHitRadius: 10,
      pointHoverRadius: 0,
      pointRadius: 0,
      pointStyle: 'line',
    })
    if (noAdjustmentSalesQuantity.some((q) => q != null)) {
      datasets.push({
        label: '補正なし',
        data: [...noAdjustmentSalesQuantity],
        backgroundColor: '#fff',
        borderColor: '#f88',
        borderDash: [2, 2],
        pointHitRadius: 10,
        pointHoverRadius: 0,
        pointBorderColor: '#f88',
        pointRadius: 0,
        pointStyle: 'line',
      })
    }
    // 一時的な計算の値がなければグラフ・凡例ごと表示しない.
    if (temporarilyPlannedSalesQuantity.some((q) => q != null)) {
      datasets.push({
        label: '販売計画数（補正後）',
        data: [...temporarilyPlannedSalesQuantity],
        backgroundColor: '#fff',
        borderColor: '#4bf',
        borderDash: [5, 4],
        pointHitRadius: 10,
        pointHoverRadius: 0,
        pointBorderColor: '#4bf',
        pointRadius: 0,
        pointStyle: 'dash',
      })
    }
    datasets.push({
      label: '売上構成比による予測数',
      data: [...permanentlySalesCompositionQuantity],
      backgroundColor: '#fff',
      borderColor: '#ff69b4',
      pointHitRadius: 10,
      pointHoverRadius: 0,
      pointRadius: 0,
      pointStyle: 'line',
    })
    datasets.push({
      label: '販売実績数',
      data: [...actualSalesQuantity],
      backgroundColor: '#fff',
      borderColor: '#0c0',
      pointHitRadius: 10,
      pointHoverRadius: 0,
      pointRadius: 0,
      pointStyle: 'line',
    })
    datasets.push({
      label: '（参考）昨年度同週販売実績数',
      data: [...lastYearActualSalesQuantity],
      backgroundColor: '#fff',
      borderColor: '#fa0',
      pointHitRadius: 10,
      pointHoverRadius: 0,
      pointRadius: 0,
      pointStyle: 'line',
    })
    const data: ChartData = {
      labels,
      datasets,
    }
    const options: CustomChartOptions = {
      maintainAspectRatio: false,
      plugins: {
        // Ref: https://www.chartjs.org/docs/latest/configuration/legend.html#configuration-options
        legend: {
          position: 'bottom',
          align: 'end',
          // Ref: https://www.chartjs.org/docs/latest/configuration/legend.html#legend-label-configuration
          labels: {
            usePointStyle: false,
          },
        },
        // Ref: https://www.chartjs.org/docs/latest/configuration/tooltip.html#tooltip-configuration
        tooltip: {
          mode: 'index',
          backgroundColor: '#ffffffe6',
          titleColor: '#333',
          titleFont: {
            size: 13,
          },
          bodyColor: '#333',
          bodyFont: {
            size: 13,
          },
          caretSize: 0,
          cornerRadius: 0,
          borderColor: '#ccc',
          borderWidth: 1,
          usePointStyle: true,
        },
      },
      scales: {
        x: {
          grid: {
            drawTicks: false,
          },
          ticks: {
            // Ref: https://www.chartjs.org/docs/latest/axes/cartesian/linear.html#common-tick-options-to-all-axes
            padding: 10,

            // Ref: https://www.chartjs.org/docs/latest/axes/cartesian/linear.html#linear-axis-specific-tick-options
            maxTicksLimit: 22,

            // Ref: https://www.chartjs.org/docs/latest/axes/cartesian/linear.html#common-tick-options-to-all-cartesian-axes
            maxRotation: 0,
          },
        },
        y: {
          // https://www.chartjs.org/docs/latest/axes/#common-options-to-all-axes
          suggestedMin: 0,
          grid: {
            drawTicks: false,
          },
          ticks: {
            padding: 15,
          },
        },
      },
      currentWeek: pastWeeks.length,
      leadtimeWeek:
        etaWeekDate != null ? pastWeeks.length + presentAndFutureWeeks.findIndex((w) => w.date === etaWeekDate) : null,
      promotionWeek,
    }

    const conf: ChartConfiguration = {
      type: 'customLine',
      data,
      options,
    }

    if (chart.current == null) {
      chart.current = new Chart(canvas.current, conf)
    } else {
      chart.current.config.data = data
      chart.current.update()
    }
  }, [
    pastWeeks,
    presentAndFutureWeeks,
    pastInventoryUnitShipmentCalendar,
    presentAndFutureInventoryUnitShipmentCalendar,
    etaWeekDate,
  ])

  return <canvas ref={canvas} />
}
