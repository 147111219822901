/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for OMR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Week,
    WeekFromJSON,
    WeekFromJSONTyped,
    WeekToJSON,
} from './';

/**
 * 
 * @export
 * @interface PutSimulationParametersRequestInventoryCalendar
 */
export interface PutSimulationParametersRequestInventoryCalendar {
    /**
     * 
     * @type {Week}
     * @memberof PutSimulationParametersRequestInventoryCalendar
     */
    week: Week;
    /**
     * 
     * @type {number}
     * @memberof PutSimulationParametersRequestInventoryCalendar
     */
    receivingQuantityForSimulation: number | null;
}

export function PutSimulationParametersRequestInventoryCalendarFromJSON(json: any): PutSimulationParametersRequestInventoryCalendar {
    return PutSimulationParametersRequestInventoryCalendarFromJSONTyped(json, false);
}

export function PutSimulationParametersRequestInventoryCalendarFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutSimulationParametersRequestInventoryCalendar {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'week': WeekFromJSON(json['week']),
        'receivingQuantityForSimulation': json['receivingQuantityForSimulation'],
    };
}

export function PutSimulationParametersRequestInventoryCalendarToJSON(value?: PutSimulationParametersRequestInventoryCalendar | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'week': WeekToJSON(value.week),
        'receivingQuantityForSimulation': value.receivingQuantityForSimulation,
    };
}


