/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for OMR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InventoryUnit
 */
export interface InventoryUnit {
    /**
     * 在庫管理単位コード
     * @type {string}
     * @memberof InventoryUnit
     */
    code: string;
    /**
     * 在庫管理単位名
     * @type {string}
     * @memberof InventoryUnit
     */
    name: string;
    /**
     * 物流パターン名
     * @type {string}
     * @memberof InventoryUnit
     */
    logisticPatternName: string;
}

export function InventoryUnitFromJSON(json: any): InventoryUnit {
    return InventoryUnitFromJSONTyped(json, false);
}

export function InventoryUnitFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryUnit {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
        'name': json['name'],
        'logisticPatternName': json['logisticPatternName'],
    };
}

export function InventoryUnitToJSON(value?: InventoryUnit | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'name': value.name,
        'logisticPatternName': value.logisticPatternName,
    };
}


