import React, { memo, useEffect, useRef, useState } from 'react'

import { DatabaseSKU } from '../../api-client'

import { useSKUs } from '../../hooks'

import { Button, LoadingSpinner, PageHeader, SKUsTable, TextBox } from '../../components/atoms'

import {
  CountLabel,
  FilterContainer,
  FilterLabel,
  FiltersContainer,
  LoadingSpinnerContainer,
  NoHitLabel,
  SKUsTableContainer,
} from './style'
import { Panel } from '../RegisterMasterData/style'
import * as Sentry from '@sentry/react'
import firebase from 'firebase/app'
import {
  Configuration,
  DefaultApi,
} from '../../api-client'

export interface SKUsProps {}

const MemoSKUsTable = memo(SKUsTable)

export const SKUs: React.FC<SKUsProps> = () => {
  const forceUpdate = useState(false)[1]

  const { skus } = useSKUs()

  const [codeSearchText, setCodeSearchText] = useState('')
  const [nameSearchText, setNameSearchText] = useState('')
  const [orderGroupNameSearchText, setOrderGroupNameSearchText] = useState('')
  const [departmentSearchText, setDepartmentSearchText] = useState('')
  const [inChargeSearchText, setInChargeSearchText] = useState('')
  const filteredSKUs = useRef<Array<DatabaseSKU>>(skus != null ? skus : [])

  const skuFilterHandler = (sku: DatabaseSKU) =>
    (codeSearchText === '' || sku.code.includes(codeSearchText)) &&
    (nameSearchText === '' ||
      (sku.name != null && sku.name.includes(nameSearchText)) ||
      (sku.specName != null && sku.specName.includes(nameSearchText))) &&
    (orderGroupNameSearchText === '' || sku.orderGroupName.includes(orderGroupNameSearchText)) &&
    (departmentSearchText === '' || 
      (sku.departmentCode != null && sku.departmentCode.includes(departmentSearchText))) &&
    (inChargeSearchText === '' || 
      (sku.inCharge != null && sku.inCharge.includes(inChargeSearchText)))

  useEffect(() => {
    if (skus != null) {
      filteredSKUs.current = skus
    } else {
      filteredSKUs.current = []
    }
    forceUpdate((v) => !v)
  }, [skus, forceUpdate])

  return (
    <>
      <PageHeader pageTitle="SKU一覧" backLink={false} />
      <Panel>
        <Button
          styleType='tertiary'
          label='SKU一覧ダウンロード'
          width={200}
          onClickHandler={() => {
            firebase
              .auth()
              .currentUser?.getIdToken(true)
              .then((token) => {
                const conf = new Configuration({
                  basePath: process.env.REACT_APP_API_PATH,
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                })
                const api = new DefaultApi(conf)
                return api.getSkusExcelRaw().then((response) => {
                  return response.value().then(blob => {
                    const disposition = response.raw.headers.get('content-disposition')
                    const filenameRegex = /filename[^;=\n]*=((['"']).*?\2|[^;\n]*)/
                    const matches = filenameRegex.exec(disposition != null ? disposition : '')

                    let filename
                    if (matches != null && matches[1]) {
                      filename = matches[1].replace(/['"']/g, '')
                      filename = decodeURI(filename)
                    }

                    const url = window.URL.createObjectURL(new Blob([blob]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', filename != null ? filename : '')
                    document.body.appendChild(link)
                    link.click()
                    link.parentNode?.removeChild(link)
                  })
                })
              })
              .catch((err) => {
                Sentry.captureException(err)
              })
          }}
        />
      </Panel>
      <FiltersContainer>
        <FilterContainer>
          <FilterLabel>JANコード</FilterLabel>
          <TextBox
            id="code-search-text-input"
            type="text"
            width={200}
            height={40}
            defaultValue={codeSearchText}
            onChangeHandler={setCodeSearchText}
          />
        </FilterContainer>
        <FilterContainer>
          <FilterLabel>商品名</FilterLabel>
          <TextBox
            id="name-search-text-input"
            type="text"
            width={200}
            height={40}
            defaultValue={nameSearchText}
            onChangeHandler={setNameSearchText}
          />
        </FilterContainer>
        <FilterContainer>
          <FilterLabel>発注グループ</FilterLabel>
          <TextBox
            id="order-group-name-search-text-input"
            type="text"
            width={200}
            height={40}
            defaultValue={orderGroupNameSearchText}
            onChangeHandler={setOrderGroupNameSearchText}
          />
        </FilterContainer>
        <FilterContainer>
          <FilterLabel>部門</FilterLabel>
          <TextBox
            id="department-search-text-input"
            type="text"
            width={200}
            height={40}
            defaultValue={departmentSearchText}
            onChangeHandler={setDepartmentSearchText}
          />
        </FilterContainer>
        <FilterContainer>
          <FilterLabel>担当者</FilterLabel>
          <TextBox
            id="in-charge-search-text-input"
            type="text"
            width={200}
            height={40}
            defaultValue={inChargeSearchText}
            onChangeHandler={setInChargeSearchText}
          />
        </FilterContainer>
        <Button
          styleType="tertiary"
          label="検索"
          width={76}
          onClickHandler={() => {
            if (skus == null) {
              return
            }
            filteredSKUs.current = skus.filter(skuFilterHandler)
            forceUpdate((v) => !v)
          }}
          data-testid="sku-search-button"
        />
      </FiltersContainer>
      <SKUsTableContainer>
        {skus != null ? (
          <>
            {filteredSKUs.current.length === 0 ? (
              <NoHitLabel>検索結果がありません。</NoHitLabel>
            ) : (
              <>
                <CountLabel>{`${filteredSKUs.current.length}/${skus.length}件`}</CountLabel>
                <MemoSKUsTable skus={filteredSKUs.current} />
              </>
            )}
          </>
        ) : (
          <LoadingSpinnerContainer>
            <LoadingSpinner />
          </LoadingSpinnerContainer>
        )}
      </SKUsTableContainer>
    </>
  )
}
