import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { AdjustmentTarget, AdjustmentType, User, UserRole } from '../../api-client'

import { useInventoryUnitShipmentPlanCollective } from '../../hooks'
import { NotificationField } from '../../components/atoms'

import {
  InventoryUnitShipmentCalendarTable,
  InventoryUnitShipmentChart,
  LoadingSpinner,
  PageHeader,
  Toast,
  RadioButton,
} from '../../components/atoms'

//InventoryCalenderTableの要素をインポート
import { ShipmentCalenderTable, ChangeLineMethod } from '../../components/atoms'

import { CollectiveAdjustmentForm } from '../../components/molecules'

import {
  AdjustmentFormContainer,
  InventoryUnitShipmentChartContainer,
  InventoryUnitShipmentChartContainerOuter,
  LoadingSpinnerContainer,
  MessageAreaContainer,
  SectionsContainer,
  SectionTitle,
  ToastContainer,
  RadioButtonContainer,
  RadioButtonLabel,
  RadioTitle,
  RadioButtonContainerArea,
  RadioButtonContainerBlock,
} from './style'

interface PathParams {
  janCode: string
}

export interface InventoryUnitShipmentPlanCollectiveProps {
  currentUser: User
}

export const InventoryUnitShipmentPlanCollective: React.FC<InventoryUnitShipmentPlanCollectiveProps> = ({
  currentUser,
}) => {
  const forceUpdate = useState(false)[1]

  const { janCode } = useParams<PathParams>()

  const {
    shipmentPlanCollective,
    getInventoryUnitShipmentPlanCollective,
    putInventoryUnitShipmentPlanCollective,
    saveParams,
    onChangeSelectedForecast,
    inventoryUnitShipmentPlanParams,
    setInventoryUnitShipmentPlanParams,
  } = useInventoryUnitShipmentPlanCollective(janCode)

  const adjustmentCustomRate = useRef<number | null>(null)
  const numberOfWeeksToAdjust = useRef<number | null>(null)

  useEffect(() => {
    if (shipmentPlanCollective != null) {
      adjustmentCustomRate.current = shipmentPlanCollective.defaultAdjustmentCustomRate
      numberOfWeeksToAdjust.current = shipmentPlanCollective.defaultNumberOfWeeksToAdjust
    } else {
      adjustmentCustomRate.current = null
      numberOfWeeksToAdjust.current = null
    }
    forceUpdate((v) => !v)
  }, [shipmentPlanCollective, forceUpdate])

  // shipment_planが更新されたときに親windowをreloadして値を反映させる
  const [reloadStatus, setReloadStatus] = useState(false)
  useEffect(() => {
    if (window.opener != null && reloadStatus === true) {
      window.opener.location.reload()
      setReloadStatus(false)
    }
  }, [reloadStatus])

  const [toastMessage, setToastMessage] = useState('')
  const [toastVisible, setToastVisible] = useState(false)

  const shipmentCalenderRef = useRef<ChangeLineMethod>(null)
  const radioClick = (selectedValue: string) => {
    shipmentCalenderRef.current?.changeLine(selectedValue)
  }

  return (
    <>
      <PageHeader
        pageTitle={`【補正一括設定】JANコード：${janCode}`}
        backLink={true}
        linkLabel="在庫計画画面"
        backLinkURL={`/order-groups/${shipmentPlanCollective?.orderGroupId}/inventory-plan`}
      />
      {toastVisible === true ? (
        <ToastContainer>
          <Toast
            message={toastMessage}
            onCloseHandler={() => {
              setTimeout(() => setToastVisible(false), 300)
            }}
          />
        </ToastContainer>
      ) : null}
      {shipmentPlanCollective != null && shipmentPlanCollective.outOfBulkMessage !== '' ? (
        <MessageAreaContainer>
          <NotificationField type="success" bodyMessage={shipmentPlanCollective.outOfBulkMessage}></NotificationField>
        </MessageAreaContainer>
      ) : null}
      <SectionsContainer>
        <ShipmentCalenderTable ref={shipmentCalenderRef}></ShipmentCalenderTable>
        <RadioButtonContainerArea>
          <RadioTitle>需要予測の出力切り替え</RadioTitle>
          <RadioButtonContainerBlock>
            <RadioButtonContainer>
              <RadioButton
                id="inventoryUnitShipmentPlan-form-radio-button-demand-insight"
                checked={inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight}
                disabled={false}
                onChangeHandler={function () {
                  // setSelectedDemandForecast(AdjustmentTarget.DemandInsight)
                  setInventoryUnitShipmentPlanParams({
                    ...inventoryUnitShipmentPlanParams,
                    selectedDemandForecast: AdjustmentTarget.DemandInsight,
                  })
                  radioClick(AdjustmentTarget.DemandInsight)
                  onChangeSelectedForecast(AdjustmentTarget.DemandInsight)
                }}
              />
              <RadioButtonLabel>demand insightによる予測</RadioButtonLabel>
            </RadioButtonContainer>
            <RadioButtonContainer>
              <RadioButton
                id="inventoryUnitShipmentPlan-form-radio-button-sales-composition"
                checked={inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.SalesComposition}
                disabled={false}
                onChangeHandler={function () {
                  setInventoryUnitShipmentPlanParams({
                    ...inventoryUnitShipmentPlanParams,
                    selectedDemandForecast: AdjustmentTarget.SalesComposition,
                  })
                  radioClick(AdjustmentTarget.SalesComposition)
                  onChangeSelectedForecast(AdjustmentTarget.SalesComposition)
                }}
              />
              <RadioButtonLabel>売上構成比による予測</RadioButtonLabel>
            </RadioButtonContainer>
          </RadioButtonContainerBlock>
        </RadioButtonContainerArea>

        {shipmentPlanCollective != null ? (
          <InventoryUnitShipmentChartContainerOuter>
            <InventoryUnitShipmentChartContainer>
              <InventoryUnitShipmentChart
                pastWeeks={shipmentPlanCollective.pastWeeks}
                presentAndFutureWeeks={shipmentPlanCollective.presentAndFutureWeeks}
                pastInventoryUnitShipmentCalendar={shipmentPlanCollective.pastShipmentCalendar}
                presentAndFutureInventoryUnitShipmentCalendar={shipmentPlanCollective.presentAndFutureShipmentCalendar}
                etaWeekDate={shipmentPlanCollective.etaWeekDate}
              />
            </InventoryUnitShipmentChartContainer>
            <AdjustmentFormContainer>
              <CollectiveAdjustmentForm
                achievementRateIn4Weeks={
                  inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight
                    ? shipmentPlanCollective.averageAchievementRateIn4Weeks
                    : shipmentPlanCollective.averageSalesCompositionAchievementRateIn4Weeks
                }
                achievementRateIn13Weeks={
                  inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight
                    ? shipmentPlanCollective.averageAchievementRateIn13Weeks
                    : shipmentPlanCollective.averageSalesCompositionAchievementRateIn13Weeks
                }
                averageSalesIn4Weeks={
                  inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight
                    ? shipmentPlanCollective.averageSalesIn4Weeks
                    : shipmentPlanCollective.salesCompositionAverageSalesIn4Weeks
                }
                averageSalesIn13Weeks={
                  inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight
                    ? shipmentPlanCollective.averageSalesIn13Weeks
                    : shipmentPlanCollective.salesCompositionAverageSalesIn13Weeks
                }
                yearOverYearIn4Weeks={
                  inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight
                    ? shipmentPlanCollective.averageYearOverYearIn4Weeks
                    : shipmentPlanCollective.averageSalesCompositionYearOverYearIn4Weeks
                }
                yearOverYearIn13Weeks={
                  inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight
                    ? shipmentPlanCollective.averageYearOverYearIn13Weeks
                    : shipmentPlanCollective.averageSalesCompositionYearOverYearIn13Weeks
                }
                minAchievement4weeksRate={
                  inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight
                    ? shipmentPlanCollective.minAchievement4weeksRate != null
                      ? shipmentPlanCollective.minAchievement4weeksRate
                      : null
                    : shipmentPlanCollective.minSaleCompositionAchievement4weeksRate != null
                    ? shipmentPlanCollective.minSaleCompositionAchievement4weeksRate
                    : null
                }
                maxAchievement4weeksRate={
                  inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight
                    ? shipmentPlanCollective.maxAchievement4weeksRate != null
                      ? shipmentPlanCollective.maxAchievement4weeksRate
                      : null
                    : shipmentPlanCollective.maxSaleCompositionAchievement4weeksRate != null
                    ? shipmentPlanCollective.maxSaleCompositionAchievement4weeksRate
                    : null
                }
                minAchievement13weeksRate={
                  inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight
                    ? shipmentPlanCollective.minAchievement13weeksRate != null
                      ? shipmentPlanCollective.minAchievement13weeksRate
                      : null
                    : shipmentPlanCollective.minSaleCompositionAchievement13weeksRate != null
                    ? shipmentPlanCollective.minSaleCompositionAchievement13weeksRate
                    : null
                }
                maxAchievement13weeksRate={
                  inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight
                    ? shipmentPlanCollective.maxAchievement13weeksRate != null
                      ? shipmentPlanCollective.maxAchievement13weeksRate
                      : null
                    : shipmentPlanCollective.maxSaleCompositionAchievement13weeksRate != null
                    ? shipmentPlanCollective.maxSaleCompositionAchievement13weeksRate
                    : null
                }
                minAverage4weeks={
                  inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight
                    ? shipmentPlanCollective.minAverage4weeks != null
                      ? shipmentPlanCollective.minAverage4weeks
                      : null
                    : shipmentPlanCollective.minSaleCompositionAverage4weeks != null
                    ? shipmentPlanCollective.minSaleCompositionAverage4weeks
                    : null
                }
                maxAverage4weeks={
                  inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight
                    ? shipmentPlanCollective.maxAverage4weeks != null
                      ? shipmentPlanCollective.maxAverage4weeks
                      : null
                    : shipmentPlanCollective.maxSaleCompositionAverage4weeks != null
                    ? shipmentPlanCollective.maxSaleCompositionAverage4weeks
                    : null
                }
                minAverage13weeks={
                  inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight
                    ? shipmentPlanCollective.minAverage13weeks != null
                      ? shipmentPlanCollective.minAverage13weeks
                      : null
                    : shipmentPlanCollective.minSaleCompositionAverage13weeks != null
                    ? shipmentPlanCollective.minSaleCompositionAverage13weeks
                    : null
                }
                maxAverage13weeks={
                  inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight
                    ? shipmentPlanCollective.maxAverage13weeks != null
                      ? shipmentPlanCollective.maxAverage13weeks
                      : null
                    : shipmentPlanCollective.maxSaleCompositionAverage13weeks != null
                    ? shipmentPlanCollective.maxSaleCompositionAverage13weeks
                    : null
                }
                minLastYear4weeks={
                  inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight
                    ? shipmentPlanCollective.minLastYear4weeks != null
                      ? shipmentPlanCollective.minLastYear4weeks
                      : null
                    : shipmentPlanCollective.minSaleCompositionLastYear4weeks != null
                    ? shipmentPlanCollective.minSaleCompositionLastYear4weeks
                    : null
                }
                maxLastYear4weeks={
                  inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight
                    ? shipmentPlanCollective.maxLastYear4weeks != null
                      ? shipmentPlanCollective.maxLastYear4weeks
                      : null
                    : shipmentPlanCollective.maxSaleCompositionLastYear4weeks != null
                    ? shipmentPlanCollective.maxSaleCompositionLastYear4weeks
                    : null
                }
                minLastYear13weeks={
                  inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight
                    ? shipmentPlanCollective.minLastYear13weeks != null
                      ? shipmentPlanCollective.minLastYear13weeks
                      : null
                    : shipmentPlanCollective.minSaleCompositionLastYear13weeks != null
                    ? shipmentPlanCollective.minSaleCompositionLastYear13weeks
                    : null
                }
                maxLastYear13weeks={
                  inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight
                    ? shipmentPlanCollective.maxLastYear13weeks != null
                      ? shipmentPlanCollective.maxLastYear13weeks
                      : null
                    : shipmentPlanCollective.maxSaleCompositionLastYear13weeks != null
                    ? shipmentPlanCollective.maxSaleCompositionLastYear13weeks
                    : null
                }
                disableAchievement4weeks={
                  inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight
                    ? shipmentPlanCollective.disableAchievement4weeks != null
                      ? shipmentPlanCollective.disableAchievement4weeks
                      : false
                    : shipmentPlanCollective.disableSaleCompositionAchievement4weeks != null
                    ? shipmentPlanCollective.disableSaleCompositionAchievement4weeks
                    : false
                }
                disableAchievement13weeks={
                  inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight
                    ? shipmentPlanCollective.disableAchievement13weeks != null
                      ? shipmentPlanCollective.disableAchievement13weeks
                      : false
                    : shipmentPlanCollective.disableSaleCompositionAchievement13weeks != null
                    ? shipmentPlanCollective.disableSaleCompositionAchievement13weeks
                    : false
                }
                disableAverage4weeks={
                  inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight
                    ? shipmentPlanCollective.disableAverage4weeks != null
                      ? shipmentPlanCollective.disableAverage4weeks
                      : false
                    : shipmentPlanCollective.disableSaleCompositionAverage4weeks != null
                    ? shipmentPlanCollective.disableSaleCompositionAverage4weeks
                    : false
                }
                disableAverage13weeks={
                  inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight
                    ? shipmentPlanCollective.disableAverage13weeks != null
                      ? shipmentPlanCollective.disableAverage13weeks
                      : false
                    : shipmentPlanCollective.disableSaleCompositionAverage13weeks != null
                    ? shipmentPlanCollective.disableSaleCompositionAverage13weeks
                    : false
                }
                disableLastYear4weeks={
                  inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight
                    ? shipmentPlanCollective.disableLastYear4weeks != null
                      ? shipmentPlanCollective.disableLastYear4weeks
                      : false
                    : shipmentPlanCollective.disableSaleCompositionLastYear4weeks != null
                    ? shipmentPlanCollective.disableSaleCompositionLastYear4weeks
                    : false
                }
                disableLastYear13weeks={
                  inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight
                    ? shipmentPlanCollective.disableLastYear13weeks != null
                      ? shipmentPlanCollective.disableLastYear13weeks
                      : false
                    : shipmentPlanCollective.disableSaleCompositionLastYear13weeks != null
                    ? shipmentPlanCollective.disableSaleCompositionLastYear13weeks
                    : false
                }
                currentAdjustmentType={
                  inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight
                    ? inventoryUnitShipmentPlanParams.demandInsightParams.adjustmentType
                    : inventoryUnitShipmentPlanParams.salesCompositionParams?.adjustmentType
                }
                selectableAdjustmentType={
                  shipmentPlanCollective.defaultAdjustmentType !== AdjustmentType.NoAdjustment &&
                  shipmentPlanCollective.temporaryAdjustmentType === AdjustmentType.NoAdjustment
                    ? shipmentPlanCollective.defaultAdjustmentType
                    : null
                }
                currentAdjustmentCustomRate={
                  inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight
                    ? inventoryUnitShipmentPlanParams.demandInsightParams.adjustmentCustomRate
                    : inventoryUnitShipmentPlanParams.salesCompositionParams.adjustmentCustomRate
                }
                currentAdjustmentRange={
                  inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight
                    ? inventoryUnitShipmentPlanParams.demandInsightParams.adjustmentRange ?? null
                    : inventoryUnitShipmentPlanParams.salesCompositionParams.adjustmentRange ?? null
                }
                currentNumberOfWeeksToAdjust={
                  inventoryUnitShipmentPlanParams.selectedDemandForecast === AdjustmentTarget.DemandInsight
                    ? inventoryUnitShipmentPlanParams.demandInsightParams.numberOfWeeksToAdjust
                    : inventoryUnitShipmentPlanParams.salesCompositionParams.numberOfWeeksToAdjust
                }
                readonly={currentUser.role !== UserRole.Manager && shipmentPlanCollective.operatorId !== currentUser.id}
                onChangeHandler={(
                  adjustmentType,
                  adjustmentCustomRate,
                  adjustmentRange,
                  numberOfWeeksToAdjust,
                  selectedDemandForecast
                ) => {
                  saveParams({
                    adjustmentType,
                    adjustmentCustomRate,
                    adjustmentRange: adjustmentRange ?? undefined,
                    numberOfWeeksToAdjust,
                    selectedDemandForecast,
                  })

                  getInventoryUnitShipmentPlanCollective(
                    adjustmentType,
                    adjustmentCustomRate != null ? adjustmentCustomRate : undefined,
                    adjustmentRange != null ? adjustmentRange : undefined,
                    numberOfWeeksToAdjust != null ? numberOfWeeksToAdjust : undefined,
                    selectedDemandForecast
                  )
                }}
                onClickSaveButtonHandler={(
                  adjustmentType,
                  adjustmentCustomRate,
                  adjustmentRange,
                  numberOfWeeksToAdjust,
                  selectedDemandForecast
                ) => {
                  putInventoryUnitShipmentPlanCollective(
                    adjustmentType,
                    adjustmentCustomRate,
                    adjustmentRange,
                    numberOfWeeksToAdjust,
                    selectedDemandForecast
                  )?.then(() => {
                    getInventoryUnitShipmentPlanCollective(
                      adjustmentType,
                      adjustmentCustomRate != null ? adjustmentCustomRate : undefined,
                      adjustmentRange != null ? adjustmentRange : undefined,
                      numberOfWeeksToAdjust != null ? numberOfWeeksToAdjust : undefined,
                      selectedDemandForecast
                    )
                    setReloadStatus(true)
                    setToastMessage('補正登録しました。')
                    setToastVisible(true)
                  })
                }}
                selectedDemandForecast={inventoryUnitShipmentPlanParams.selectedDemandForecast}
              />
            </AdjustmentFormContainer>
          </InventoryUnitShipmentChartContainerOuter>
        ) : (
          <LoadingSpinnerContainer>
            <LoadingSpinner />
          </LoadingSpinnerContainer>
        )}

        <SectionTitle>出荷予定数内訳</SectionTitle>
        {shipmentPlanCollective != null ? (
          <InventoryUnitShipmentCalendarTable
            pastWeeks={shipmentPlanCollective.pastWeeks}
            presentAndFutureWeeks={shipmentPlanCollective.presentAndFutureWeeks}
            pastInventoryUnitShipmentCalendar={shipmentPlanCollective.pastShipmentCalendar}
            presentAndFutureInventoryUnitShipmentCalendar={shipmentPlanCollective.presentAndFutureShipmentCalendar}
            selectedDemandForecast={inventoryUnitShipmentPlanParams.selectedDemandForecast}
            isInitialParams={false}
          />
        ) : (
          <LoadingSpinnerContainer>
            <LoadingSpinner />
          </LoadingSpinnerContainer>
        )}
      </SectionsContainer>
    </>
  )
}
