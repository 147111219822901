import React from 'react'
import { OrderTiming } from '../../../api-client'

import './OrderTimingLabel.css'

export interface OrderTimingLabelProps {
  orderTiming: OrderTiming
}

const toText = (orderTiming: OrderTiming) => {
  switch (orderTiming) {
    case OrderTiming.Default:
      return null
    case OrderTiming.StartAccumulation:
      return '積み増し開始'
    case OrderTiming.LastShipment:
      return '最終出荷'
    case OrderTiming.FirstShipment:
      return '初出荷'
    case OrderTiming.StopFactory:
      return '工場停止期間の折り込み'
    default:
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = orderTiming
      return null
  }
}

export const OrderTimingLabel: React.FC<OrderTimingLabelProps> = ({ orderTiming }) => {
  const text = toText(orderTiming)
  if (!text) return null
  return (
    <span className="order-timing-label" data-testid="order-timing-label-id">
      サプライヤー休日：{text}
    </span>
  )
}
