import styled from 'styled-components'

const CountLabel = styled.div`
  margin-bottom: 8px;
`

const FilterContainer = styled.div`
  margin-right: 16px;
`

const FilterLabel = styled.div`
  margin-bottom: 4px;
`

const FiltersContainer = styled.div`
  align-items: flex-end;
  background-color: #f1f1f1;
  display: flex;
  height: 82px;
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 32px;
  padding-bottom: 18px;
  padding-left: 20px;
`

const LoadingSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
`

const NoHitLabel = styled.div`
  margin-left: 14px;
`

const SKUsTableContainer = styled.div`
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 22px;
`

export {
  CountLabel,
  FilterContainer,
  FilterLabel,
  FiltersContainer,
  LoadingSpinnerContainer,
  NoHitLabel,
  SKUsTableContainer,
}
