import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

// https://github.com/firebase/firebase-js-sdk/tree/master/packages/firebase#include-only-the-features-you-need
import 'firebase/auth'

import './index.css'

import { App } from './App'
import { Auth, FirebaseOptions } from './Auth'

const firebaseOptions: FirebaseOptions = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

// Ref: https://docs.sentry.io/platforms/javascript/
Sentry.init({
  enabled: process.env.REACT_APP_SENTRY_DSN != null && process.env.REACT_APP_SENTRY_DSN !== '',
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
})

const nonBusinessHoursPageEnabled = process.env.REACT_APP_NON_BUSINESS_HOURS_PAGE_ENABLED === 'true'

ReactDOM.render(
  <React.StrictMode>
    <Auth firebaseOptions={firebaseOptions}>
      <App nonBusinessHoursPageEnabled={nonBusinessHoursPageEnabled} />
    </Auth>
  </React.StrictMode>,
  document.getElementById('root')
)
