import styled from 'styled-components'

const colors = (color: string): string => {
  switch (color) {
    case 'yellow':
      return '#db7521'
    case 'red':
      return '#b72727'
    case 'green':
      return '#1d721f'
    default:
      return 'transparent'
  }
}

const BaseRoundLabel = styled.div<{
  color: string
  width: number
}>`
  border-color: ${props => colors(props.color)};
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  color: ${props => colors(props.color)};
  font-weight: bold;
  height: 22px;
  text-align: center;
  width: ${props => `${props.width}px`};
`

export {
  BaseRoundLabel,
}
