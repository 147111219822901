/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for OMR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InventoryUnitReceiving
 */
export interface InventoryUnitReceiving {
    /**
     * P/O No.
     * @type {string}
     * @memberof InventoryUnitReceiving
     */
    purchaseOrderNumber: string;
    /**
     * 入荷予定数
     * @type {number}
     * @memberof InventoryUnitReceiving
     */
    quantity: number;
}

export function InventoryUnitReceivingFromJSON(json: any): InventoryUnitReceiving {
    return InventoryUnitReceivingFromJSONTyped(json, false);
}

export function InventoryUnitReceivingFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryUnitReceiving {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'purchaseOrderNumber': json['purchaseOrderNumber'],
        'quantity': json['quantity'],
    };
}

export function InventoryUnitReceivingToJSON(value?: InventoryUnitReceiving | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'purchaseOrderNumber': value.purchaseOrderNumber,
        'quantity': value.quantity,
    };
}


