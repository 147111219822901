/* tslint:disable */
/* eslint-disable */
/**
 * demand insight for OMR
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Container,
    ContainerFromJSON,
    ContainerFromJSONTyped,
    ContainerToJSON,
    OrderTiming,
    OrderTimingFromJSON,
    OrderTimingFromJSONTyped,
    OrderTimingToJSON,
} from './';

/**
 * 発注グループに紐づくコンテナの輸送計画の一覧
 * @export
 * @interface GetLongTermContainerPlansResponse
 */
export interface GetLongTermContainerPlansResponse {
    /**
     * 発注グループ名
     * @type {string}
     * @memberof GetLongTermContainerPlansResponse
     */
    orderGroupName: string;
    /**
     * 担当者のユーザーID
     * @type {number}
     * @memberof GetLongTermContainerPlansResponse
     */
    operatorId: number;
    /**
     * 担当者名
     * @type {string}
     * @memberof GetLongTermContainerPlansResponse
     */
    operatorName: string;
    /**
     * コンテナ数
     * @type {number}
     * @memberof GetLongTermContainerPlansResponse
     */
    numberOfContainers: number;
    /**
     * 計算時刻
     * @type {Date}
     * @memberof GetLongTermContainerPlansResponse
     */
    calculatedAt: Date;
    /**
     * 
     * @type {OrderTiming}
     * @memberof GetLongTermContainerPlansResponse
     */
    orderTiming: OrderTiming;
    /**
     * コンテナ一覧
     * @type {Array<Container>}
     * @memberof GetLongTermContainerPlansResponse
     */
    containers: Array<Container>;
}

export function GetLongTermContainerPlansResponseFromJSON(json: any): GetLongTermContainerPlansResponse {
    return GetLongTermContainerPlansResponseFromJSONTyped(json, false);
}

export function GetLongTermContainerPlansResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetLongTermContainerPlansResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderGroupName': json['orderGroupName'],
        'operatorId': json['operatorId'],
        'operatorName': json['operatorName'],
        'numberOfContainers': json['numberOfContainers'],
        'calculatedAt': (new Date(json['calculatedAt'])),
        'orderTiming': OrderTimingFromJSON(json['orderTiming']),
        'containers': ((json['containers'] as Array<any>).map(ContainerFromJSON)),
    };
}

export function GetLongTermContainerPlansResponseToJSON(value?: GetLongTermContainerPlansResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderGroupName': value.orderGroupName,
        'operatorId': value.operatorId,
        'operatorName': value.operatorName,
        'numberOfContainers': value.numberOfContainers,
        'calculatedAt': (value.calculatedAt.toISOString()),
        'orderTiming': OrderTimingToJSON(value.orderTiming),
        'containers': ((value.containers as Array<any>).map(ContainerToJSON)),
    };
}


