import styled from 'styled-components'

const ErrorMessagePanelContainer = styled.div`
  height: calc(100% - 60px);
  padding-bottom: 30px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 30px;
`

const NotificationErrorContainer = styled.div`
  margin-bottom: 15px;
`

const UserFormContainer = styled.div`
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 25px;
`

export {
  ErrorMessagePanelContainer,
  NotificationErrorContainer,
  UserFormContainer,
}
