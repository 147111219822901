import styled from 'styled-components'

const backgroundcolors = (color: string): string => {
  switch (color) {
    case 'green':
      return '#d2f2ce'
    case 'yellow':
      return '#efedbc'
    case 'black':
      return '#d9d9d9'
    case 'blue':
      return '#d8e7fa'
    case 'red':
      return '#f8d9d9'
    default: // color は必須なのと型で有効値が決まっているのででこの条件は通らない想定
      return 'transparent'
  }
}

const colors = (color: string): string => {
  switch (color) {
    case 'green':
      return '#1d721f'
    case 'yellow':
      return '#725915'
    case 'black':
      return '#505050'
    case 'blue':
      return '#1e57b0'
    case 'red':
      return '#b72727'
    default: // color は必須なのと型で有効値が決まっているのででこの条件は通らない想定
      return 'inherit'
  }
}

const BaseFilledSquareLabel = styled.div<{
  color: string,
  width: number
}>`
  background-color: ${props => backgroundcolors(props.color)};
  border-radius: 2px;
  color: ${props => colors(props.color)};
  font-weight: bold;
  height: 22px;
  text-align: center;
  width: ${props => `${props.width}px`};
`

export {
  BaseFilledSquareLabel,
}
